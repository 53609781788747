import { FC, useContext } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import NavigationItem from "@/tenant-context/navigation/components/NavigationItem";
import { NavigationSidebarContext } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.container";

const NavigationTenantDetailsItem: FC = () => {
  const {
    tenantDetails
  } = useContext(NavigationSidebarContext);

  if (!tenantDetails) {
    return null;
  }

  return (
    <NavigationItem
      isWithTooltip={ false }
      title={ tenantDetails.name }
      type="button"
      isRedirectOnClick
    >
      <PersonAvatar
        width="25px"
        height="25px"
        personName={ tenantDetails?.name || 'Tenant' }
        personAvatarUrl={ tenantDetails.logoSmallBase64 }
      />
    </NavigationItem>
  );
};

export default NavigationTenantDetailsItem;
