import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Dispatch, RootState } from "@/core/store";
import { IMarker } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import ProfileAddressesTab from "@/tenant-context/control-profile/components/tabs/ProfileAddressesTab/ProfileAddressesTab.component";
import { ProfileAddress, ReferenceType } from "@/tenant-context/control-profile/types/profile";

const ProfileAddressesTabContainer: FC = () => {
  const {
    profile: {
      loadAddresses,
      getProfileReferenceData,
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  const params = useParams();

  const [activeMarker, setActiveMarker] = useState<IMarker | undefined>(undefined);
  const locationListData = useSelector((state: RootState) => state.profile?.addresses?.items);

  const allMarkers = useMemo(() => {
    const markersArray: IMarker[] = [];
    const data = locationListData || [];
    data.forEach((location) => {
      if (location?.latitude && location?.longitude) {
        markersArray.push(
          {
            lat: +location?.latitude,
            lon: +location?.longitude,
            title: location.title
          }
        );
      }
    });

    return markersArray;
  }, [locationListData]);

  const handleMarkerClick = useCallback((e: mapboxgl.MapboxEvent<MouseEvent>, marker: IMarker) => {
    e.originalEvent.stopPropagation();
    setActiveMarker(marker);
  }, []);

  const handleAddressClick = useCallback((address: ProfileAddress) => {
    if (address?.longitude && address?.latitude) {
      setActiveMarker({
        lon: +address?.longitude,
        lat: +address?.latitude,
        title: address?.title || 'No title'
      });
    }
  }, []);


  const addresses = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ profile: { addresses } }: RootState) => addresses
  );

  useEffect(() => {
    loadAddresses();
  }, [loadAddresses]);

  useEffect(() => {
    getProfileReferenceData(ReferenceType.ADDRESS_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOwnProfileCheck = useCallback((): boolean => {
    return isLoggedInProfileId(params.userId);
  }, [isLoggedInProfileId, params.userId]);


  return (
    <ProfileAddressesTab
      addresses={ addresses }
      isOwnProfile={ handleOwnProfileCheck }
      handleMarkerClick={ handleMarkerClick }
      activeMarker={ activeMarker }
      setActiveMarker={ setActiveMarker }
      allMarkers={ allMarkers }
      handleAddressClick={ handleAddressClick }
    />
  );
};

export default ProfileAddressesTabContainer;
