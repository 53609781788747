import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { openConfirmationModal } from '@/common/components/Modal/ConfirmationModal';
import usePermission from "@/common/hooks/usePermission";
import { Dispatch, RootState } from '@/core/store';
import ProfileTagsSectionComponent from "@/tenant-context/control-profile/components/ui/ProfileTagsSection/ProfileTagsSection.component";
import { LIMIT_TO_SHOW_PROFILE_TAGS_WHEN_SEE_LESS } from '@/tenant-context/control-profile/components/ui/ProfileTagsSection/ProfileTagsSection.config';
import { ProfileRequiredPoliciesConfig } from "@/tenant-context/control-profile/config/ProfileRequiredPolicies.config";
import { ProfileTag } from '@/tenant-context/control-profile/types/profile';

const ProfileTagsSectionContainer: FC = () => {
  const [isShowMore, setShowMore] = useState(false);
  const [showingPersonProfileTags, setShowingPersonProfileTags] = useState<ProfileTag[]>([]);

  const profileTagsListForProfile = useSelector((state: RootState) => state.profile?.profileTagsListForProfile);
  const profileId = useSelector((state: RootState) => state.profile?.general?.profileId);
  const isCanEditProfileTags = usePermission(ProfileRequiredPoliciesConfig.EDIT_PROFILE_TAGS);

  const {
    profile: {
      loadProfileTagsForProfile,
      openProfileTagsPopover,
      removeTagFromProfile,
      SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER,
      SET_SELECTED_TAG_TO_EDIT
    }
  } = useDispatch<Dispatch>();

  const handleSelect = useCallback((index: number) => {
    if (!profileTagsListForProfile) {
      return;
    }

    try {
      const target = profileTagsListForProfile[index];
      SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER([target]);
      SET_SELECTED_TAG_TO_EDIT(target);
      openProfileTagsPopover('EDIT');
    } catch (e) {
      return;
    }
  }, [
    SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER,
    SET_SELECTED_TAG_TO_EDIT,
    openProfileTagsPopover,
    profileTagsListForProfile
  ]);

  const handleRemove = useCallback((index: number) => {
    if (!profileTagsListForProfile) {
      return;
    }

    try {
      const tagToRemove = profileTagsListForProfile[index];
      removeTagFromProfile(tagToRemove);
    } catch (e) {
      console.log(e);
    }
  }, [profileTagsListForProfile, removeTagFromProfile]);

  const handleRemovalConfirmation = useCallback((index: number) => {
    openConfirmationModal({
      text: "Are you sure you want to remove the profile tag this profile?",
      onConfirm: () => handleRemove(index),
      title: "Confirm Removal"
    });
  }, [handleRemove]);

  const toggleShowMore = useCallback(() => {
    setShowMore(!isShowMore);
  }, [isShowMore]);

  const isShowMoreDisabled = useMemo(() => {
    if (!profileTagsListForProfile) {
      return true;
    }

    return profileTagsListForProfile.length <= LIMIT_TO_SHOW_PROFILE_TAGS_WHEN_SEE_LESS;
  }, [profileTagsListForProfile]);

  useEffect(() => {
    if (!profileId) {
      return;
    }

    loadProfileTagsForProfile(profileId);
  }, [loadProfileTagsForProfile, profileId]);

  useEffect(() => {
    if (!profileTagsListForProfile) {
      setShowingPersonProfileTags([]);
      return;
    }

    if (isShowMore) {
      setShowingPersonProfileTags(profileTagsListForProfile);
    } else {
      setShowingPersonProfileTags(profileTagsListForProfile.slice(0, LIMIT_TO_SHOW_PROFILE_TAGS_WHEN_SEE_LESS));
    }
  }, [isShowMore, profileTagsListForProfile]);

  return (
    <ProfileTagsSectionComponent
      profileTagsListForProfile={ showingPersonProfileTags }
      handleSelect={ handleSelect }
      onRemove={ handleRemovalConfirmation }
      isShowMore={ isShowMore }
      isShowMoreDisabled={ isShowMoreDisabled }
      toggleShowMore={ toggleShowMore }
      isCanEditProfileTags={ isCanEditProfileTags }
    />
  );
};

export default ProfileTagsSectionContainer;
