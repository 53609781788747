import { Title } from "@mantine/core";
import { ICellRendererParams } from "ag-grid-community";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide/ServerDataGrid.container";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import {
  columnDefs
} from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/BuildingListTable/BuildingListTable.config";
import {
  BuildingListItem
} from "@/tenant-context/control-location-management/types/ManageLocations.types";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

type Props = {
  getData: (payload: {
    gridParams: GridParams,
  }) => Promise<PaginatedResult<BuildingListItem>>,
  actionCellRenderer: (params: ICellRendererParams) => ReactNode,
  onRowClicked: (e: RowClickedEvent<BuildingListItem>) => void
}
const BuildingListTableComponent: ForwardRefRenderFunction<DataGrid, Props>  = ({
  getData,
  actionCellRenderer,
  onRowClicked
}, ref) => {
  return (
    <>
      <Title size={ 'h4' }>Buildings</Title>
      <div className="ag-theme-alpine-dark ag-theme-rt ">
        <ServerDataGrid
          id ={ "buildingList-grid" }
          columnDefs={ columnDefs(actionCellRenderer) }
          ref = { ref }
          getData = { getData }
          onRowClicked={ onRowClicked }
        />
      </div>
    </>
  );
};

export default forwardRef(BuildingListTableComponent) as (
  props: Props & React.RefAttributes<DataGrid>
) => JSX.Element;
