import { FileWithPath } from "@mantine/dropzone";
import { FC, useCallback, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useDispatch } from "react-redux";

import { handleError } from "@/common/util/common-functions";
import { Dispatch } from "@/core/store";
import FloorUploadComponent
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/FloorUpload/FloorUpload.component";

type Props = {
  setIsExpanded: (isExpanded: boolean) => void
}
const FloorUpload: FC<Props> = ({
  setIsExpanded
}) => {

  const {
    manageLocations: {
      SET_ACTIVE_FLOOR_PLAN,
      SET_ACTIVE_FLOOR
    }
  } = useDispatch<Dispatch>();
  const [uploadedFile, setUploadedFile] = useState<FileWithPath | null>(null);
  const [uploadedFileError, setUploadedFileError] = useState<FileRejection | null>(null);

  const parseUploadedFile = useCallback((file: FileWithPath, callback) => {
    const fr = new FileReader();
    fr.onload = () => callback(null, fr.result);
    fr.onerror = (err) => callback(err);
    fr.readAsText(file);
  }, []);

  const handleSaveUploadedFile = useCallback(() => {
    if (uploadedFile) {
      parseUploadedFile(uploadedFile, (err: string, res: string) => {
        if (err) {
          handleError({ message: err, name: 'File Reader Error' });
        } else {
          SET_ACTIVE_FLOOR_PLAN(JSON.parse(res));
        }
      });
    } else {
      SET_ACTIVE_FLOOR_PLAN(undefined);
    }
  }, [SET_ACTIVE_FLOOR_PLAN, parseUploadedFile, uploadedFile]);

  const onUploadCancelButtonClick = useCallback(() => {
    setUploadedFile(null);
    setUploadedFileError(null);
    SET_ACTIVE_FLOOR_PLAN(undefined);
    setIsExpanded(false);
    SET_ACTIVE_FLOOR(undefined);
  }, [SET_ACTIVE_FLOOR, SET_ACTIVE_FLOOR_PLAN, setIsExpanded]);

  const onDropFileUpload = useCallback((file: FileWithPath[]) => {
    setUploadedFileError(null);
    setUploadedFile(file[0] as File);
  }, []);

  const onRejectFileUpload = useCallback((file: FileRejection[]) => {
    setUploadedFile(null);
    setUploadedFileError(file[0]);
  }, []);

  return (
    <FloorUploadComponent
      uploadedFile={ uploadedFile }
      handleSaveUploadedFile={ handleSaveUploadedFile }
      uploadedFileError={ uploadedFileError }
      onUploadCancelButtonClick={ onUploadCancelButtonClick }
      onDropFileUpload={ onDropFileUpload }
      onRejectFileUpload={ onRejectFileUpload }
    />
  );
};

export default FloorUpload;
