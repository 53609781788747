import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '@/core/store';
import RiskEventsProviderSelection
  from '@/tenant-context/control-risk-config/components/RiskEventsProviderSelection/RiskEventsProviderSelection.component';
import { RiskProviderItem } from '@/tenant-context/control-risk-config/types/risk';

type Props = {
  onRiskProviderSelection: (riskProviderId: string) => void,
  riskProviders: RiskProviderItem[]
};

const RiskEventsProviderSelectionContainer: FC<Props> = ({
  onRiskProviderSelection,
  riskProviders
}) => {

  const {
    riskEventFilterDataOptions: {
      isGlobalCategoryToggleOnRiskline,
      isGlobalCategoryToggleOnBSOC,
      isGlobalCategoryToggleOnDataMinr,
      isGlobalCategoryToggleOnMaxSecurity
    },
    riskAlerts: {
      showOnlyImpactedRiskEvents
    }
  } = useSelector((state: RootState) => state);
  
  const {
    riskEventFilterDataOptions: {
      ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      ENABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      DISABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
      DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY
    },
    riskAlerts: {
      SET_SHOW_ONLY_IMPACTED_RISK_EVENTS
    } } = useDispatch<Dispatch>();

  const alertEnabledStatus = useMemo(() => {
    return {
      riskline: isGlobalCategoryToggleOnRiskline,
      bsoc: isGlobalCategoryToggleOnBSOC,
      dataminr: isGlobalCategoryToggleOnDataMinr,
      'max-security': isGlobalCategoryToggleOnMaxSecurity
    };
  }, [
    isGlobalCategoryToggleOnMaxSecurity,
    isGlobalCategoryToggleOnBSOC,
    isGlobalCategoryToggleOnDataMinr,
    isGlobalCategoryToggleOnRiskline
  ]);

  const onRiskProviderSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { value, checked } = event.currentTarget;
    
    if (value === 'riskline') {
      if (checked) {
        ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE();
      } else {
        DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE();
      }
    } else if (value === 'bsoc') {
      if (checked) {
        ENABLE_GLOBAL_CATEGORY_FILTER_BSOC();
      } else {
        DISABLE_GLOBAL_CATEGORY_FILTER_BSOC();
      }
    } else if (value === 'dataminr') {
      if (checked) {
        ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR();
      } else {
        DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR();
      }
    } else if (value === 'max-security') {
      if (checked) {
        ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY();
      } else {
        DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY();
      }
    }
  }, [
    DISABLE_GLOBAL_CATEGORY_FILTER_BSOC,
    DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
    DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
    DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
    ENABLE_GLOBAL_CATEGORY_FILTER_BSOC,
    ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
    ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
    ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY
  ]);

  const handleImpactRiskEventsToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    SET_SHOW_ONLY_IMPACTED_RISK_EVENTS(checked);

  }, [SET_SHOW_ONLY_IMPACTED_RISK_EVENTS]);

  return (
    <RiskEventsProviderSelection
      onRiskProviderSelection={ onRiskProviderSelection }
      riskProviders={ riskProviders }
      onRiskProviderSwitch={ onRiskProviderSwitch }
      alertEnabledStatus={ alertEnabledStatus }
      onImpactRiskEventsToggleChange={ handleImpactRiskEventsToggle }
      isShowOnlyImpactedAlerts={ showOnlyImpactedRiskEvents }
    />
  );
};

export default RiskEventsProviderSelectionContainer;
