import { callApi } from "@/common/util/api";
import { ConnectorsLegacyCheck,SiteLocation } from "@/tenant-context/control-site/types/site";

export const getSiteGeoJson = (
  siteId: string
) => callApi<SiteLocation[]>(
  `location-management-service/v1/categories/Areas/sub-categories/Country/locations/${siteId}/geoJson`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);

export const getSiteConnectors = () => callApi<ConnectorsLegacyCheck>(
  `/connector-core-service/v1/connectors`,
  {
    method: 'get'
  }
);
