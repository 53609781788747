export const SeverityLevelsRequiredPoliciesConfig = {
  SEVERITY_LEVELS_FULL_ACCESS: {
    policyNames: [
      'SystemAdmin_ICM-Global_Severity_FullAccess'
    ]
  },
  SEVERITY_LEVELS_READ_ACCESS: {
    policyNames: [
      'SystemAdmin_ICM-Global_Severity_ReadOnly',
      'SystemAdmin_ICM-Global_Severity_FullAccess'
    ]
  }
};
