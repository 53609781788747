import { FeatureCollection, Point } from "geojson";
import { createContext, FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import useContextValue from "@/common/hooks/useContextValue";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { mapImpactEngineRiskAlertToRiskAlertEvent } from "@/tenant-context/common/util/risk";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import {
  closeArcCaseById,
  createArcCaseEventsStream,
  getArcSentCommunicationsList,
  getPersonTrackingDetailsForClosedAlert,
  getPersonTrackingDetailsForOpenAlert,
  getRiskAlertById
} from "@/tenant-context/control-action-response-center/api/risk-alert";
import {
  ArcCaseEvent,
  ArcImpactDetail,
  ArcLocationImpactDetail,
  ArcPersonImpactDetail, ArcSentCommunication
} from "@/tenant-context/control-action-response-center/types/ARC.types";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type ArcContextType = {
  riskAlertJsonMeta?: FeatureCollection<Point, RiskAlertEvent>
  impactPeople?: FeatureCollection<Point, ArcPersonImpactDetail>
  rawImpactPeople: Array<ArcPersonImpactDetail>
  impactAssets?: FeatureCollection<Point, ArcImpactDetail>
  riskAlert?: RiskAlertEvent;
  arcCase?: ArcCaseEvent;
  impactedDetails: Array<ArcImpactDetail>,
  activeTab: string,
  isArcOpenInDetachedView: boolean;
  arcSentCommunications: Array<ArcSentCommunication>;
  setActiveTab: (tabValue: string) => void;
  handleRowExpand: (personId: string) => Promise<void>;
  handleCloseArcCase: () => void
};

export const ArcContext = createContext<ArcContextType>({} as ArcContextType);

const ArcProvider: FC = ({ children }) => {
  const [riskAlert, setRiskAlert] = useState<RiskAlertEvent>();
  const [riskAlertJsonMeta, setRiskAlertJsonMeta] = useState<FeatureCollection<Point, RiskAlertEvent>>();
  const [arcCase, setArcCase] = useState<ArcCaseEvent>();
  const [impactedDetails, setImpactedDetails] = useState<Array<ArcImpactDetail>>([]);
  const [impactPeople, setImpactPeople] = useState<FeatureCollection<Point, ArcPersonImpactDetail>>();
  const [impactAssets, setImpactAssets] = useState<FeatureCollection<Point, ArcImpactDetail>>();
  const [rawImpactPeople, setRawImpactPeople] = useState<Array<ArcPersonImpactDetail>>([]);
  const [activeTab, setActiveTab] = useState<string>("summary");
  const [currentEvent, setCurrentEvent] = useState<ArcCaseEvent | ArcPersonImpactDetail | ArcLocationImpactDetail
    | undefined>(undefined);
  const [arcSentCommunications, setArcSentCommunications] = useState<Array<ArcSentCommunication>>([]);

  const arcCaseEventStream = useRef<EventSource>();

  const { caseId: caseIdFromParams } = useParams();
  const tenantId = useSelector((state: RootState) => state.commonData.tenantId);
  const caseIdFromProps = useSelector((state: RootState) => state.arc.currentCaseId);
  const {
    arc: {
      SET_IS_ARC_OPEN
    },
    drawer: {
      closeDrawer
    }
  } = useDispatch<Dispatch>();
  const isArcOpenInDetachedView = !!caseIdFromParams;

  const {
    reverseGeocoding: {
      getReverseGeoLocation
    },
    globalPeople: {
      getMiniProfileById
    }
  } = useDispatch<Dispatch>();

  const handleCloseArcCase = useCallback(async () => {
    const arcCaseId = arcCase?.caseId || caseIdFromParams || caseIdFromProps;

    if (arcCaseId){
      const arcClose = await closeArcCaseById(arcCaseId, tenantId);
      if(arcClose){
        if (!isArcOpenInDetachedView) {
          SET_IS_ARC_OPEN(false);
          closeDrawer();
        }
        showNotification({
          title: 'Successful',
          message: 'Arc case closed successfully!',
          color: 'success'
        });
      }
    }
  }, [SET_IS_ARC_OPEN, arcCase?.caseId, caseIdFromParams, caseIdFromProps, 
    closeDrawer, isArcOpenInDetachedView, tenantId]);

  useEffect(() => {
    (async () => {
      if (!caseIdFromParams && !caseIdFromProps) {
        return;
      }
      const caseId = caseIdFromParams || caseIdFromProps;

      if (!caseId) {
        console.error('Case Id not defined');

        return;
      }

      // create arc event stream
      arcCaseEventStream.current = await createArcCaseEventsStream(
        caseId,
        tenantId,
        async (ev) => setCurrentEvent(ev)
      );

      // Arc sent communications
      const source = caseId.toString().split('_')[0];
      const arcSentCommunicationsResponse = await getArcSentCommunicationsList(
        caseId.toString().replace(`${ source }_`, ''),
        tenantId
      );

      const arcSentCommunicationsSorted = arcSentCommunicationsResponse
        .sort((a, b) => (b.sentDate || 0) - (a.sentDate || 0));

      setArcSentCommunications(arcSentCommunicationsSorted);
    })();

    return () => {
      arcCaseEventStream.current?.close();
    };
  }, [caseIdFromParams, caseIdFromProps, tenantId]);

  // This effect will take care to update riskAlert and riskAlertJsonMeta for every update event
  useEffect(() => {
    if (!currentEvent || currentEvent._type !== "ArcCaseResponse") {
      return;
    }

    (async () => {
      setArcCase(currentEvent as ArcCaseEvent);
      const arcCaseEvt = currentEvent as ArcCaseEvent;
      if (!arcCaseEvt.riskId) {
        return;
      }

      // get risk alert data to show on the map
      const source = arcCaseEvt.caseId.split('_')[0];
      const alert = await getRiskAlertById(`${ source }_${arcCaseEvt.riskId}`);
      const alertEvent = mapImpactEngineRiskAlertToRiskAlertEvent(alert);

      if (!alertEvent) {
        return;
      }

      setRiskAlert(alertEvent as RiskAlertEvent);
      const { data } = await parseGeoJSON({
        data: [alertEvent],
        params: {
          Point: [
            'json.meta.geojson.geometry.coordinates.1',
            'json.meta.geojson.geometry.coordinates.0'
          ]
        }
      });

      setRiskAlertJsonMeta(data as FeatureCollection<Point, RiskAlertEvent>);
    })();
  }, [currentEvent]);

  // This effect will take care to update impactPeople and rawImpactPeople for every update event
  useEffect(() => {
    if (!currentEvent || currentEvent._type !== "ArcProfileDetailResponse") {
      return;
    }

    (async () => {
      const impactedPerson = currentEvent as ArcPersonImpactDetail;
      impactedPerson.personInitials = impactedPerson.fistName ? getPersonInitials(impactedPerson.fistName) : "";

      const mappedImpactedPeople = rawImpactPeople
        .filter(person => person.personId !== impactedPerson.personId)
        .concat(impactedPerson);

      setRawImpactPeople(mappedImpactedPeople);

      const parsedImpactedPeople = await parseGeoJSON({
        data: mappedImpactedPeople,
        params: {
          Point: [
            'geoPoint.lat',
            'geoPoint.lon'
          ]
        }
      });

      if (parsedImpactedPeople) {
        setImpactPeople(parsedImpactedPeople.data as FeatureCollection<Point, ArcPersonImpactDetail>);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

  // This effect will take care to update impactedDetails and impactAssets for every update event
  useEffect(() => {
    if (!currentEvent || currentEvent._type !== "ArcLocationDetailResponse") {
      return;
    }

    (async () => {
      const impactedAsset = currentEvent as ArcImpactDetail;

      const mappedImpactedAssets = impactedDetails.filter(asset => asset.assetId !== impactedAsset.assetId)
        .concat(impactedAsset);

      setImpactedDetails(mappedImpactedAssets);
      const parsedImpactedAssets = await parseGeoJSON({
        data: mappedImpactedAssets,
        params: {
          Point: [
            'geoPoint.lat',
            'geoPoint.lon'
          ]
        }
      });

      if (parsedImpactedAssets) {
        setImpactAssets(parsedImpactedAssets.data as FeatureCollection<Point, ArcImpactDetail>);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

  const handleRowExpand = useCallback(async (personId: string) => {
    const caseId = caseIdFromParams || caseIdFromProps;

    if (!caseId) {
      return;
    }

    const impactedPeople = [...rawImpactPeople];
    const index = impactedPeople.findIndex(person => person.personId === personId);

    const isExpandedDataAlreadyPresent = impactedPeople[index].topRank && impactedPeople[index].geoLocation
      && impactedPeople[index].personDetails;

    if (index > -1 && !isExpandedDataAlreadyPresent) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];
      const isARCOpen = arcCase?.status === "OPEN";

      if(isARCOpen){
        promises.push(getPersonTrackingDetailsForOpenAlert(personId));
      } else{
        promises.push(getPersonTrackingDetailsForClosedAlert(caseId, personId));
      }

      promises.push(getReverseGeoLocation({ lat: impactedPeople[index].geoPoint.lat,
        lon: impactedPeople[index].geoPoint.lon }));
      promises.push(getMiniProfileById(personId));

      const results = await Promise.all(promises);
      const [trackingDetails, locationDetails, personDetails] = results;

      impactedPeople[index].topRank = isARCOpen ? trackingDetails.topRank : trackingDetails.latestTrackingResponse;
      impactedPeople[index].geoLocation = locationDetails;
      impactedPeople[index].personDetails = personDetails;

      setRawImpactPeople(impactedPeople);
    }
  }, [arcCase?.status, caseIdFromParams, caseIdFromProps, getMiniProfileById, getReverseGeoLocation, rawImpactPeople]);

  return (
    <ArcContext.Provider value={ useContextValue({
      riskAlert,
      riskAlertJsonMeta,
      arcCase,
      impactedDetails,
      impactPeople,
      rawImpactPeople,
      impactAssets,
      activeTab,
      isArcOpenInDetachedView,
      arcSentCommunications,
      setActiveTab,
      handleRowExpand,
      handleCloseArcCase
    }) }>
      { children }
    </ArcContext.Provider>
  );
};

export default ArcProvider;
