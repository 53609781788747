import { coerceNumber } from "@visx/scale";

import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

// Return list of dates between given start and end date epochs
export const fillDates = (startTime: number, endTime: number) => {
  const dates: Array<Date> = [];
  const date = new Date(startTime);

  // eslint-disable-next-line fp/no-loops
  while (date.getTime() < endTime) {
    date.setDate(date.getDate() + 1);
    dates.push(new Date(date));
  }

  return dates;
};

// Given an array of dates, return the min and max dates to align with visx coerceNumber
export const getMinMax = (data: Date[]) => {
  const numericVals: number[] = data.map(coerceNumber) as number[];
  return [Math.min(...numericVals), Math.max(...numericVals)];
};

// Converts the given date to a UTC date
// The resulting utc object isn't really a UTC date, but a local date shifted to match the UTC time.
// However, in practice it does the job.
export const toUTCDate = (date: Date): Date => {
  const millisForSecond = 60000;
  return new Date(date.getTime() + date.getTimezoneOffset() * millisForSecond);
};

// Groups the given array of elements by the given groupBy function {f}
// Example: Below will group risk events by the start date of the alert
// groupBy<RiskAlertEvent>(
//         allRiskEvents, // Array of data
//         (item) => [item.json.alert.start_date] // Group by function
//       )
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T>(arr: Array<T>, f: (item: T) => Array<any>): Array<Array<T>> {
  const groups: Record<string, Array<T>> = {};
  arr.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });

  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

// Implementation of Risk events filtering for the use of Risk Timeline.
// Make sure if you add any new filters or update anything - you need to update the same in the
// `RiskAlertLayer.component.tsx` layer filtering too.
export const filterRiskEventsForRiskTimeline = (
  riskAlert: RiskAlertEvent,
  startTime: number,
  endTime: number,
  showOnlyImpactedRiskEvents: boolean,
  disabledRiskCategories: Array<string>,
  riskLevelUpperLimit: number,
  riskLevelLowerLimit: number
): boolean => {
  const {
    isImpacted,
    json: {
      alert: {
        start_date,
        category: { id: categoryId },
        risk_level: { id: riskLevelId }
      }
    }
  } = riskAlert;

  // Date domain filter
  const startDate = new Date(start_date);
  if (toUTCDate(startDate).getTime() < startTime || toUTCDate(startDate).getTime() > endTime) {
    return false;
  }

  // Impacted risk events filter
  if (showOnlyImpactedRiskEvents && !isImpacted) {
    return false;
  }

  // Disabled categories filter
  if (disabledRiskCategories.includes(categoryId)) {
    return false;
  }

  // Risk alert level filter
  if (
    riskLevelId > riskLevelUpperLimit.toString() || riskLevelId < riskLevelLowerLimit.toString()) {
    return false;
  }

  return true;
};
