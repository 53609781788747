import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import useContextValue from "@/common/hooks/useContextValue";
import usePermission from "@/common/hooks/usePermission";
import { Products } from "@/common/types/products";
import { Dispatch, RootState } from "@/core/store";
import { DrawingTypes } from "@/tenant-context/control-draw/types/draw";
import {
  LocationManagementPoliciesConfig
} from "@/tenant-context/control-location-management/config/location-management.policies";
import { AuthContext } from "@/tenant-context/core/context/Auth.context";

import AddLocations from "../components/AddLocations/AddLocations.component";

export enum LocationTab {
  LocationDetails = 'LocationDetails',
  AdditionalDetails = 'AdditionalDetails',
  SitePlan = 'SitePlan',
  Buildings_Floors = 'Buildings_Floors',
  Zones = 'Zones',
  Logs = 'Logs'
}

export type TabProps = {
  value: string;
  label: string;
  isAuthorized?: boolean;
}

export type LocationInfoContextType = {
  handleTabChange: (tab: string | null) => void,
  activeTab: LocationTab,
  handleDrawingToolSelect: (type: DrawingTypes) => void,
  tabs: TabProps[],
  drawControl: React.MutableRefObject<MapboxDraw | undefined>,
  selectedDrawing: React.MutableRefObject<Feature<Geometry, GeoJsonProperties> | undefined>,
  isNewLocation: boolean,
  isAddBuildingFormOpened: boolean,
  setIsAddBuildingFormOpened: (value: boolean) => void
  handleOpenAddAndEditBuildingForm: () => void
  isAddBuildingButtonVisible: () => boolean
  locationCategory: string | null
  isAuthorizedToEdit: boolean
  isLocationExists: boolean
  buildingView: boolean
  setBuildingView: (value: boolean) => void
  handlePrepareAddFloorForm: () => void
  isAddFloorFormOpened: boolean
  setIsAddFloorFormOpened: (value: boolean) => void
  isAllowedToUseSaveLocationButton: boolean
}

export const LocationInfoContext = createContext<LocationInfoContextType>
({} as LocationInfoContextType);

export const LocationInfoContextProvider: FC = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(LocationTab.LocationDetails);
  const [buildingView, setBuildingView] = useState(true);
  const [isAddBuildingFormOpened, setIsAddBuildingFormOpened] = useState(false);
  const [isAddFloorFormOpened, setIsAddFloorFormOpened] = useState(false);
  const [isNewLocation] = useState(id === 'add');

  const {
    manageLocations: {
      enteredLocationDetails
    }
  } = useSelector((state: RootState) => state);

  const {
    isProductSubscribed
  } = useContext(AuthContext);

  const [params] = useSearchParams();

  const locationCategory = params.get("category");

  const isLocationExists = !!enteredLocationDetails?.tid;

  const isAdvancedLocationServiceSubscribed = isProductSubscribed(Products.ADVANCED_LOCATION_SERVICES);

  const isAuthorizedToAccessTab = isAdvancedLocationServiceSubscribed && isLocationExists;

  const isAllowedToUseSaveLocationButton =
    activeTab === LocationTab.LocationDetails || activeTab === LocationTab.AdditionalDetails;

  const isAuthorizedToEdit = usePermission(
    LocationManagementPoliciesConfig.ADD_LOCATIONS_FULL_ACCESS
  );

  const {
    manageLocations: {
      getLocationDetails,
      CLEAR_ADD_LOCATION_DATA,
      SET_ACTIVE_BUILDING,
      SET_ACTIVE_FLOOR,
      getSitePlanList
    }
  } = useDispatch<Dispatch>();

  const handlePrepareAddBuildingForm = useCallback(() => {
    setIsAddBuildingFormOpened(true);
    SET_ACTIVE_BUILDING(undefined);
  }, [SET_ACTIVE_BUILDING]);

  const handlePrepareAddFloorForm = useCallback(() => {
    setIsAddFloorFormOpened(true);
    SET_ACTIVE_FLOOR(undefined);
  }, [SET_ACTIVE_FLOOR]);

  const handleTabChange = useCallback((tab: string | null) => {
    if (!tab) {
      return;
    }

    switch(tab){
    case LocationTab.LocationDetails:
      if(!isNewLocation && locationCategory && id){
        getLocationDetails({ tid: id, category: locationCategory });
      }
      break;

    default: break;
    }

    setActiveTab(tab as LocationTab);
  }, [isNewLocation, locationCategory, id, getLocationDetails]);

  const drawControl = useRef<MapboxDraw>();
  const selectedDrawing = useRef<Feature>();

  const tabs: TabProps[] = useMemo(() => [
    {
      value: LocationTab.LocationDetails,
      label: 'Location Details',
      isAuthorized: true
    },
    {
      value: LocationTab.AdditionalDetails,
      label: 'Additional Details',
      isAuthorized: isLocationExists
    },
    {
      value: LocationTab.SitePlan,
      label: 'Site Plan',
      isAuthorized: isLocationExists
    },
    {
      value: LocationTab.Buildings_Floors,
      label: 'Buildings & Floors',
      isAuthorized: isAuthorizedToAccessTab
    },
    {
      value: LocationTab.Zones,
      label: 'Zones',
      isAuthorized: isAuthorizedToAccessTab
    }
    //pending dev
    // {
    //   value: LocationTab.Logs,
    //   label: 'Logs',
    //   isAuthorized: false 
    // }
  ], [isAuthorizedToAccessTab, isLocationExists]);

  const isAddBuildingButtonVisible = useCallback(() => {
    return activeTab === LocationTab.Buildings_Floors;
  }, [activeTab]);

  const handleDrawingToolSelect = useCallback((type: DrawingTypes) => {
    if (!drawControl) {
      return;
    }
    ({
      [DrawingTypes.POLYGON]: () => drawControl.current?.changeMode('draw_polygon'),
      [DrawingTypes.CIRCLE]: () => drawControl.current?.changeMode('draw_point'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [DrawingTypes.DRAG_CIRCLE]: () => drawControl.current?.changeMode('drag_circle'),
      [DrawingTypes.SQUARE]: () => drawControl.current?.changeMode('draw_point'),
      [DrawingTypes.LINE]: () => drawControl.current?.changeMode('draw_line_string'),
      [DrawingTypes.DELETE]: () => {
        if (selectedDrawing.current?.id) {
          drawControl.current?.delete(selectedDrawing.current.id as string);
        } else {
          drawControl.current?.deleteAll();
        }
      },
      [DrawingTypes.NONE]: () => null
    })[type]();
  }, []);

  useEffect(() => {
    getSitePlanList();
  }, [getSitePlanList]);

  useEffect(()=>{
    if((id !== 'add') && locationCategory && id){
      getLocationDetails({ tid: id, category: locationCategory });
    }

    return () => {
      CLEAR_ADD_LOCATION_DATA();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <LocationInfoContext.Provider value={ useContextValue({
      activeTab,
      handleTabChange,
      handleDrawingToolSelect,
      tabs,
      drawControl,
      selectedDrawing,
      isNewLocation,
      isAddBuildingFormOpened,
      setIsAddBuildingFormOpened,
      handleOpenAddAndEditBuildingForm: handlePrepareAddBuildingForm,
      isAddBuildingButtonVisible,
      locationCategory,
      isAuthorizedToEdit,
      isLocationExists,
      buildingView,
      setBuildingView,
      handlePrepareAddFloorForm,
      isAddFloorFormOpened,
      setIsAddFloorFormOpened,
      isAllowedToUseSaveLocationButton
    }) }>
      <AddLocations />
    </LocationInfoContext.Provider>
  );
};
