import { callApi, TokenType } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { SituationType } from "../types/situationTypes";

export const getSituationTypesData = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<SituationType>>(
  `/managed/icm-service/v1/situation-categories?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListSituationCategory'
    },
    method: 'get',
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);

export const saveSituationType = (params: SituationType) => callApi(
  `/managed/icm-service/v1/situation-categories`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=CreateSituationCategory'
    },
    method: 'post',
    body: JSON.stringify(params),
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);

export const updateSituationType = (params: SituationType) => callApi(
  `/managed/icm-service/v1/situation-categories/${params._id}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateSituationCategory'
    },
    method: 'post',
    body: JSON.stringify(params),
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);

export const getSituationsData = (
  page = 0,
  size = 10,
  situationTypeId: string,
  searchQuery?: string
) => callApi<PaginatedResult<SituationType>>(
  `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations?size=${size}&page=${page}&orderBy=situationName:ASC${searchQuery ? '&search=(' + searchQuery + ')' : ''}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListSituation'
    },
    method: 'get',
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);

export const deleteSituationType = (situationTypeId: string)=> callApi(
  `/managed/icm-service/v1/situation-categories/${situationTypeId}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=DeleteSituationCategory'
    },
    method: 'delete',
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);

export const deleteSituation = (
  situationTypeId: string,
  situationId: string
) => callApi<PaginatedResult<SituationType>>(
  `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations/${situationId}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=DeleteSituation'
    },
    method: 'delete',
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);


export const saveSituation = (situationTypeId: string, params: SituationType) => callApi(
  `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=CreateSituation'
    },
    method: 'post',
    body: JSON.stringify(params),
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);

export const updateSituation = (situationTypeId: string, params: SituationType) => callApi(
  `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations/${params._id}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateSituation'
    },
    method: 'post',
    body: JSON.stringify(params),
    withAuthorization: true,
    tokenType: TokenType.ACCESS
  }
);