import { closeAllModals } from "@mantine/modals";
import { createContext, FC, useCallback } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import ProfileEmergencyContactsModal, { ProfileEmergencyContactsModalFormData } from "@/tenant-context/control-profile/components/modals/ProfileEmergencyContactsModal/ProfileEmergencyContactsModal.component";
import { ProfileEmergencyContact } from "@/tenant-context/control-profile/types/profile";

type FormData = Partial<ProfileEmergencyContact> & {
  phone1: string | undefined,
  phone2: string | undefined
};

type ProfileEmergencyContactsModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn<FormData>
};

export const ProfileEmergencyContactsModalContext = createContext<ProfileEmergencyContactsModalContextType>(
  {} as ProfileEmergencyContactsModalContextType
);

type Props = {
  mode?: 'add' | 'edit',
  editableContact?: ProfileEmergencyContact
}

export const ProfileEmergencyContactsModalProvider: FC<Props> = ({
  mode = 'add',
  editableContact
}) => {
  const formControls = useForm({ defaultValues: {
    phone1: editableContact?.phoneList[0],
    phone2: editableContact?.phoneList[1]
  } });

  const {
    profile: {
      modifyEmergencyContact,
      deleteEmergencyContactForProfile
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as ProfileEmergencyContactsModalFormData;

    if (mode === 'edit' && !editableContact) {
      return;
    }

    const {
      phone1,
      phone2,
      ...other
    } = data;

    const payload = {
      ...other,
      phoneList: [phone1, phone2].filter(Boolean),
      mode,
      identifier: editableContact?._id,
      email: data.email === '' ? null : data.email
    };

    const success = await modifyEmergencyContact(payload);
    if (success) {
      closeAllModals();
    }
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  const handleRemove = useCallback(async () => {
    if (!editableContact) {
      return;
    }

    const success = await deleteEmergencyContactForProfile(editableContact._id);

    if (success) {
      closeAllModals();
    }
  }, [deleteEmergencyContactForProfile, editableContact]);

  return (
    <ProfileEmergencyContactsModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls,
      handleRemove
    }) }>
      <ProfileEmergencyContactsModal
        handleRemove={ handleRemove }
        mode={ mode }
        editableContact={ editableContact }
      />
    </ProfileEmergencyContactsModalContext.Provider>
  );
};
