import { openModal } from '@mantine/modals';
import groupBy from 'lodash/groupBy';
import { FC, Fragment, useCallback, useEffect } from "react";
import { Map, NavigationControl, Popup, useMap } from 'react-map-gl';

import { ReactComponent as OfficeIcon } from '@/common/icons/building.svg';
import { ReactComponent as HomeIcon } from '@/common/icons/home.svg';
import { Products } from '@/common/types/products';
import { capitalizeWord } from '@/common/util/format/string';
import { WORLD_MAX_BOUNDS } from '@/tenant-context/common/util/constants';
import LocationMarker from "@/tenant-context/control-location-management/layers/LocationMarker";
import { IMarker } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import ProfileAddressesModal from '@/tenant-context/control-profile/components/modals/ProfileAddressesModal';
import { useProfileAddressesTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfileAddressesTab/ProfileAddressesTab.style';
import { useProfilePersonalContactTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.style';
import ButtonWithOptions from "@/tenant-context/control-profile/components/ui/ButtonWithOptions";
import EditButton from '@/tenant-context/control-profile/components/ui/EditButton';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { PaginatedResult, ProfileAddress, ProfileEmergencyContact } from "@/tenant-context/control-profile/types/profile";
import AuthenticatedMap from '@/tenant-context/core/components/AuthenticatedMap/AuthenticatedMap.component';

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

// TODO unify with the other implementation
const renderAddress = ({
  line1,
  line2,
  line3,
  city,
  country,
  // TODO figure region out, not as per specification / design
  region: _region, // !?
  postCode
}: ProfileEmergencyContact | ProfileAddress) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  // eslint-disable-next-line fp/no-let
  let displayCountry: string | undefined = '';

  try {
    displayCountry = regionNames.of(country);
  } catch {
    displayCountry = country;
  }
  const lines = [
    line1,
    line2,
    line3,
    postCode,
    city,
    displayCountry
  ].filter(Boolean);

  return (
    <>
      { lines.map((line, index) => (
        <Fragment key={ index }>
          { line }
          <br />
        </Fragment>
      )) }
    </>
  );
};

type Props = {
  addresses?: PaginatedResult<ProfileAddress> | null,
  isOwnProfile: ()  => boolean
  activeMarker: IMarker | undefined,
  allMarkers: IMarker[],
  setActiveMarker: React.Dispatch<React.SetStateAction<IMarker | undefined>>,
  handleMarkerClick: (e: mapboxgl.MapboxEvent<MouseEvent>, marker: IMarker) => void
  handleAddressClick: (address: ProfileAddress) => void
}

const ProfilePersonalContactTab: FC<Props> = ({
  addresses,
  isOwnProfile,
  activeMarker,
  allMarkers,
  setActiveMarker,
  handleMarkerClick,
  handleAddressClick
}) => {

  // TODO refactor
  const { classes } = useProfilePersonalContactTabStyles();
  const { classes: { main, defaultAddressLabel, addressTitle, mapContainer, edit } } = useProfileAddressesTabStyles();
  const { profileAddressesMap: map } = useMap();
  
  const openEditModal = useCallback((addressId: string) => {
    if (!addresses) {
      return;
    }

    const address = addresses.items.find(({ _id }) => addressId === _id);

    if (!address) {
      return;
    }

    openModal({
      title: `Edit ${capitalizeWord(address.type)} Address`,
      children: (
        <ProfileAddressesModal
          mode='edit'
          editableAddress={ address }
        />
      ),
      closeOnClickOutside: false
    });
  }, [addresses]);

  const openAddAddressModal = useCallback((_: string, type: string) => {
    return (
      <ProfileAddressesModal
        type={ type as "Work" | "Home" }/>
    );
  }, []);

  const renderAddAction = useCallback(() => (
    <ButtonWithOptions
      renderModal={ openAddAddressModal }
      sectionType={ 'ADDRESS' }
      requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_ADDRESS }
    />
  ), [isOwnProfile, openAddAddressModal]);

  const renderEdit = useCallback((addressId: string) => (
    <EditButton
      // eslint-disable-next-line react/jsx-no-bind
      onClick={ () => openEditModal(addressId) }
      className={ edit }
      requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_ADDRESS }
    />
  ), [edit, isOwnProfile, openEditModal]);

  useEffect(() => {
    if (activeMarker && map) {
      const { lat, lon } = activeMarker;
      map?.flyTo({
        center: {
          lat,
          lon
        },
        zoom: 14,
        speed: 1.8
      });
    }
  }, [activeMarker, map]);

  if (!addresses) {
    return <span>Loading...</span>;
  }

  const sections = groupBy(addresses.items, 'type');

  return (
    <GenericProfileTab
      className={ main }
      heading="Addresses"
      renderAction={ renderAddAction }
    >
      <div>
        { Object.entries(sections).map(([key, section]) => (
          <section className={ classes.section } key={ key }>
            { /* TODO size */ }
            <h6 className={ classes.sectionHeading }>{ key }</h6>

            <ul>
              { section
                .sort(({ isPrimary, primary }) => isPrimary || primary ? -1 : 1)
                .map((address) => (
                  // eslint-disable-next-line react/jsx-no-bind
                  <ProfileBlock
                    handleAddressClick={ handleAddressClick }
                    address={ address }
                    key={ address._id } element="li">
                    <ProfileKeyValueBlock
                      maxColumns={ 4 }
                      // TODO absolute positioning for action buttons
                      gtc={ '2fr 3fr 2fr 0.5fr' }
                    >
                      <ProfileKeyValue
                        firstItem="emphasis"
                        name={ (
                          <span className={ addressTitle }>
                            { key.toLowerCase() === 'home' && <HomeIcon/> }
                            { key.toLowerCase() === 'work' && <OfficeIcon/> }
                            { address.title || address?.name }
                          </span>
                        ) }
                        value={ [
                          renderAddress(address),
                          address.primary
                            ? <span className={ defaultAddressLabel }>Primary Address</span>
                            : "",
                          renderEdit(address._id)
                        ] }
                      />
                    </ProfileKeyValueBlock>
                  </ProfileBlock>
                )) }
            </ul>
          </section>
        )) }
      </div>

      <div className={ mapContainer }>
        <AuthenticatedMap requiredProduct={ Products.CORE_SERVICE }>
          <Map
            id="profileAddressesMap"
            mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
            minZoom={ 1 }
            initialViewState={ {
              latitude: 0,
              longitude: 0,
              zoom: 1
            } }
            mapStyle='mapbox://styles/smehmood/cl4feh10w000114pyksvp6mu9'
            reuseMaps
            testMode
            renderWorldCopies={ true }
            maxBounds={ WORLD_MAX_BOUNDS }
            projection='mercator'
            fog={ {} }
          >
            { allMarkers.map((marker, i) => (
              <LocationMarker key={ i } marker={ marker } handleMarkerClick={ handleMarkerClick }/>
            )) }

            <NavigationControl
              position="bottom-right"
              showCompass={ false }
              style={ { order: 5 } }
            />
            { activeMarker && <Popup
              latitude={ activeMarker.lat }
              longitude={ activeMarker.lon }
              anchor="bottom-left"
              /* eslint-disable-next-line react/jsx-no-bind */
              onClose={ () => setActiveMarker(undefined) }
              closeButton={ true }
              closeOnClick={ true }
              offset={ [10, -30] }
            >
              <div className={ classes.popupContainer }>{ activeMarker.title }</div>
            </Popup> }
          </Map>
        </AuthenticatedMap>
      </div>
    </GenericProfileTab>
  );
};

export default ProfilePersonalContactTab;
