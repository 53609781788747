import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useDrawerControlStyles = createStyles((theme, size?: string) => ({
  drawerHeader: {
    display: 'flex',
    width: '100%',
    padding: '-8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: '100%'
  },
  drawerPanel:{
    [`.${CSS_KEY}-Drawer-drawer`]: {
      transitionProperty: `transform, opacity, width !important`,
      transitionDuration: '250ms, 250ms, 500ms !important',
      transitionTimingFunction: 'ease'
    },
    [`.${CSS_KEY}-Drawer-body`]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      gap: `${theme.spacing.xl}px`
    }
  },
  drawerContent:{
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'visible',
    display: 'inline-flex',
    width: 'auto',
    flexDirection: 'column',
    marginRight: `-${theme.spacing.md}px`,
    paddingRight: `${theme.spacing.md}px`
  },
  drawerChildren: {
    overflowY: 'scroll',
    flexGrow: 1,
    marginRight: `-${theme.spacing.md}px`,
    paddingRight: `calc(${theme.spacing.md}px - 8px)`,
    '&::-webkit-scrollbar:disabled': {
      backgroundColor: 'transparent'
    },
    [theme.fn.smallerThan('md')]: {
      marginRight: `-${theme.spacing.xs}px`,
      paddingRight: `calc(${theme.spacing.xs}px - 8px)`
    }
  },
  drawerTitle: {
    display: size === 'full' ? 'none' : undefined
  },
  drawerTitleFont: {
    fontSize: 26
  },
  titleUnderLine: {
    width: '56px',
    height: '2px',
    backgroundColor: theme.colors.blue[6]
  },
  drawerCloseButton: {
    '> svg': {
      width: '20px',
      height: '20px'
    }
  },
  drawerCloseButtonAlign: {
    position: 'absolute',
    right: '-6px'
  },
  drawerTitleBorder: {},
  subDrawerHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subDrawerTitle:{
    color:theme.colors.neutral[5]
  },
  fullWidth: {
    width: '100%'
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.colors.neutral[6]}`
  }
}));
