import { Button } from '@mantine/core';
import { useCallback } from "react";
import { FC } from "react";
import { useDispatch } from 'react-redux';

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as DiceIcon } from '@/common/icons/dice.svg';
import { TestableComponent } from "@/common/types/testable-component";
import { Dispatch } from '@/core/store';
import RankingSettings from "@/tenant-context/control-ranking-settings/components/RankingSettings";

const RankingSettingsControl: FC<TestableComponent> = ({ dataTestId = "right-menu-people-timeline" }) => {
  const {
    rankingSettings: {
      resetRankingSettingsFilters
    }
  } = useDispatch<Dispatch>();

  const renderButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    <ButtonControl label='People Timeline' onClick={ onClick }
      data-testid={ dataTestId }
    >
      <DiceIcon />
    </ButtonControl>
  ), [dataTestId]);

  const resetFilters = useCallback(() => {
    resetRankingSettingsFilters();
  },[resetRankingSettingsFilters]);

  return (
    <DrawerControl
      id='global-ranking-settings-control'
      title='People Timeline'
      renderButton={ renderButton }
      footer={ <Button
        onClick={ resetFilters }
        fullWidth
      >
        Reset Filters
      </Button> }
    >
      <RankingSettings />
    </DrawerControl>
  );
};

export default RankingSettingsControl;
