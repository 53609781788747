import { ReactNode } from 'react';

import { RouterConfig } from "@/core/components/Router/Router.config";
import { environment } from "@/core/config/env";
import {
  DashboardRequiredPoliciesConfig
} from "@/tenant-context/control-connected-sites-dashboard/config/DashboardRequiredPolicies.config";
import { GeofenceRequiredPoliciesConfig } from "@/tenant-context/control-draw/config/GeofenceRequiredPolicies.config";
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";

enum ProductName {
  LoneWorker = 'Lone Worker',
  ICM = "Incident & Crisis Manager",
  LocationManagement = "Location",
  LastMile = "Last Mile",
  CM = "Crisis Management",
  TravelPlanning = "Travel Planning",
  GlobalMap = "Global Map",
  MassComms = "Mass Comms",
  References = 'References'
}

export enum ProductArea {
  Global = 'global',
  UnifiedExperience = 'unified-experience',
  OutSystems = 'outsystems',
  ThirdParty = 'third-party',
  OfflineProxy = 'offline-proxy'
}

export enum NavigationItemId {
  Map = 'map',
  Comms = 'comms',
  People = 'users',
  Sites = 'assets',
  Location = 'location',
  LocationManagement = 'locationManagement',
  Travel = 'travel',
  Incident = 'icm',
  Reports = 'reports',
  Settings = 'settings',
  Support = 'support',
  Tenant = 'tenant',
  Geofence = 'geofence',
  MyProfile = 'myProfile',
  References = 'references',
  CrossTenant = 'crossTenant',
  AccessCards = 'accessCards',
  CardLayouts = 'cardLayouts',
  PrintJobs = 'printJobs',
  MoreSettings = 'moreSettings',
  CompanyManagement = 'companyManagement',
  UserManagement = 'userManagement',
  UserGroups = 'userGroups',
  FunctionalRoles = 'functionalRoles',
  VerifiedCheckin = 'verifiedCheckin',
  EndTimeConfig = 'endTimeConfig',
  ConfiguredSites = 'configuredSites',
  IncidentCrisisMan = 'incidentCrisisManager',
  SitesConfig = 'sitesConfig',
  AddLocations = 'addLocations',
  PeopleUsers = 'peopleUsers',
  PeopleGroups = 'peopleGroups',
  Visitors = 'visitors',
  ControlRoomContact = 'controlRoomContact',
  ConnectedSitesDashboard = 'connectedSitesDashboardModel',
  OfflineProxy = 'offlineProxy'
}

export enum OutSystemsApplication {
  GlobalDashboard = 'GD',
  Sites = 'GD-SITES',
  ICM = 'IMS',
  Reporting = 'GD-REPORTING',
  LastMile = 'GDBO',
  ICMConfig = 'IMS-CONFIG'
}
export type NavigationSidebarItem = {
  // Icon?: React.ComponentType<NavIconProps>;
  title: string;
  hintMessage?: string | ReactNode;
  path?: string;
  itemId: NavigationItemId;
  area: ProductArea;
  unifiedExperienceApplication?: string;
  outsystemsApplication?: OutSystemsApplication;
  isNotReady?: boolean;
  policies?: string[];
  productName?: ProductName;
  isAlwaysEnabled?: boolean;
  type?: 'link' | 'button' | 'menu',
  isDivided?: boolean;
  subMenuItems?: Array<
    {
      sectionHeader: string,
      subItems: Array<NavigationSidebarItem>
    }>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redirectParams?: Record<string, any>
};

export const navigationSidebarItems: NavigationSidebarItem[] = [
  {
    // Icon: MapIcon,
    title: 'Global Map',
    hintMessage: 'Monitor for incidents globally',
    path: '/',
    itemId: NavigationItemId.Map,
    area: ProductArea.Global
    // productName: ProductName.GlobalMap
  },
  {
    // Icon: MassIcon,
    title: 'Mass Comms',
    hintMessage: <><span>Manage & Send</span><span>Mass Communication messages</span></>,
    path: RouterConfig.routes.massComms,
    itemId: NavigationItemId.Comms,
    area: ProductArea.Global,
    isNotReady: false,
    // productName: ProductName.MassComms,
    policies: [
      ...MassCommsPoliciesConfig.MC_SENT_COMMS_PAGE.policyNames
    ]
  },
  {
    // Icon: PeopleIcon,
    title: 'People',
    itemId: NavigationItemId.People,
    area: ProductArea.Global,
    isNotReady: false,
    type: 'menu',
    subMenuItems: [
      {
        sectionHeader: 'PEOPLE MANAGEMENT',
        subItems: [
          {
            itemId: NavigationItemId.PeopleUsers,
            area: ProductArea.Global,
            title: 'People',
            policies: [
              'Tenant_System_People-SensitiveData_Readonly',
              'Tenant_System_People-SensitiveData_FullAccess',
              'Tenant_System_People-Addresses_FullAccess',
              'Tenant_System_People-Addresses_Readonly',
              'Tenant_System_People-CompanyDetails_FullAccess',
              'Tenant_System_People-CompanyDetails_Readonly',
              'Tenant_System_People-ContactDetails_FullAccess',
              'Tenant_System_People-ContactDetails_Readonly',
              'Tenant_System_People-Documents_FullAccess',
              'Tenant_System_People-Documents_Readonly',
              'Tenant_System_People-EmergencyContact_FullAccess',
              'Tenant_System_People-EmergencyContact_Readonly',
              'Tenant_System_People-Features_FullAccess',
              'Tenant_System_People-Features_Readonly',
              'Tenant_System_People-FullProfile_FullAccess',
              'Tenant_System_People-FullProfile_Readonly',
              'Tenant_System_People-Groups_FullAccess',
              'Tenant_System_People-Groups_Readonly',
              'Tenant_System_People-MedicalDetails_FullAccess',
              'Tenant_System_People-MedicalDetails_Readonly',
              'Tenant_System_People-MiniProfile_Readonly',
              'Tenant_System_People-PersonalAttributes_FullAccess',
              'Tenant_System_People-PersonalAttributes_Readonly',
              'Tenant_System_People-PersonalDetails_FullAccess',
              'Tenant_System_People-PersonalDetails_Readonly'
            ],
            path: RouterConfig.routes.profileList
          },
          {
            itemId: NavigationItemId.PeopleGroups,
            area: ProductArea.Global,
            title: 'People Groups',
            policies: ['Tenant_System_People-Groups_Readonly', 'Tenant_System_People-Groups_FullAccess'],
            path: RouterConfig.routes.peopleGroups
          }
        ]
      }
    ]
  },
  {
    // Icon: SitesIcon,
    title: 'Sites',
    hintMessage: 'Manage your Connected Sites',
    itemId: NavigationItemId.Sites,
    area: ProductArea.OutSystems,
    type: 'menu',
    subMenuItems: [
      {
        sectionHeader: 'SITES',
        subItems: [
          {
            title: 'Connected Sites Dashboard',
            path: RouterConfig.routes.connectedSitesDashboard,
            itemId: NavigationItemId.ConnectedSitesDashboard,
            area: ProductArea.Global,
            isNotReady: false,
            policies: [
              ...DashboardRequiredPoliciesConfig.DASHBOARD_ROUTE_ACCESS.policyNames
            ]
          },
          {
            title: 'Sites (Legacy)',
            itemId: NavigationItemId.Sites,
            area: ProductArea.OutSystems,
            outsystemsApplication: OutSystemsApplication.Sites,
            policies: ['Tenant_LM_Portal_Access'],
            productName: ProductName.LastMile
          }
        ]
      }
    ]
  },
  {
    title: 'References',
    hintMessage: <><span>Store and Manage </span><span>shared documents and resources</span></>,
    path: '/references/document',
    itemId: NavigationItemId.References,
    area: ProductArea.UnifiedExperience,
    unifiedExperienceApplication: 'references',
    policies: ['Tenant_ReferenceLibrary_References_ReadOnly', 'Tenant_ReferenceLibrary_References_FullAccess', 'Tenant_ReferenceLibrary_References_Download'],
    productName: ProductName.References
  },
  {
    title: 'Location Management',
    path: '/tenant-location/',
    itemId: NavigationItemId.Location,
    area: ProductArea.UnifiedExperience,
    productName: ProductName.LocationManagement,
    type: 'menu',
    subMenuItems: [
      {
        sectionHeader: 'LOCATION MANAGEMENT',
        subItems: [
          {
            title: 'Location Management',
            path: '/location-management/',
            itemId: NavigationItemId.LocationManagement,
            area: ProductArea.Global,
            policies: ['Tenant_System_Tenant-Location-RO_FullAccess', 'Tenant_System_Tenant-Location-RO_ReadOnly'],
            productName: ProductName.LocationManagement
          },
          {
            title: 'Location Management Legacy',
            path: '/tenant-location/',
            itemId: NavigationItemId.LocationManagement,
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-location',
            policies: ['Tenant_System_Tenant-Location_FullAccess', 'Tenant_System_Tenant-Location_ReadOnly'],
            productName: ProductName.LocationManagement
          }
        ]
      }
    ]
  },
  {
    // Icon: IncidentIcon,
    title: 'Incident & Crisis Manager',
    hintMessage: 'Manage your incidents here',
    itemId: NavigationItemId.Incident,
    area: ProductArea.OutSystems,
    outsystemsApplication: OutSystemsApplication.ICM,
    policies: ['Tenant_ICM_Case_Access'],
    productName: ProductName.ICM
  },
  {
    title: 'Geofences',
    hintMessage: 'Manage and Create Geofences',
    path: RouterConfig.routes.geofenceManage,
    itemId: NavigationItemId.Geofence,
    area: ProductArea.Global,
    policies: [...GeofenceRequiredPoliciesConfig.LONE_WORKER_MANAGE_GEO_FENCE_READ_ACCESS.policyNames],
    isNotReady: false
  },
  {
    // Icon: ReportsIcon,
    title: 'Reports & Analytics',
    itemId: NavigationItemId.Reports,
    area: ProductArea.OutSystems,
    outsystemsApplication: OutSystemsApplication.Reporting,
    policies: ['Tenant_GlobalDashboard_Portal_Access']
  }
];

export const navigationSidebarBottomItems: NavigationSidebarItem[] = [
  {
    // Icon: SettingsIcon,
    title: 'Settings & Configuration',
    itemId: NavigationItemId.Settings,
    area: ProductArea.UnifiedExperience,
    path: '',
    unifiedExperienceApplication: 'tenant-landing-page',
    isAlwaysEnabled: true,
    type: 'menu',
    subMenuItems: [
      {
        sectionHeader: 'CONNECTED SITES',
        subItems: [
          {
            itemId: NavigationItemId.SitesConfig,
            area: ProductArea.OutSystems,
            title: 'Sites Configuration',
            policies: ['Tenant_LM_Config_Access'],
            outsystemsApplication: OutSystemsApplication.LastMile
          }
        ]
      },
      {
        sectionHeader: 'INCIDENT & CRISIS MANAGER',
        subItems: [
          {
            itemId: NavigationItemId.IncidentCrisisMan,
            area: ProductArea.OutSystems,
            title: 'Incident & Crisis Configuration',
            policies: ['Tenant_ICM_Config_Access'],
            outsystemsApplication: OutSystemsApplication.ICMConfig
          }
        ]
      },
      {
        sectionHeader: 'LONE WORKER',
        subItems: [
          {
            itemId: NavigationItemId.VerifiedCheckin,
            area: ProductArea.UnifiedExperience,
            title: 'Verified Check-In',
            policies: [
              'Tenant_LoneWorker_Configuration_FullAccess',
              'Tenant_LoneWorker_Configuration_ReadOnly',
              'Tenant_LoneWorker_VerifiedCheckInConfig_FullAccess',
              'Tenant_LoneWorker_VerifiedCheckInConfig_ReadOnly'],
            unifiedExperienceApplication: 'tenant-verified-checkin'
          },
          {
            itemId: NavigationItemId.EndTimeConfig,
            area: ProductArea.UnifiedExperience,
            title: 'End Task',
            policies: [
              'Tenant_LoneWorker_Configuration_FullAccess',
              'Tenant_LoneWorker_Configuration_ReadOnly',
              'Tenant_LoneWorker_EndTaskConfig_ReadOnly',
              'Tenant_LoneWorker_EndTaskConfig_FullAccess'],
            unifiedExperienceApplication: 'tenant-end-task-config'
          }
        ]
      },
      {
        sectionHeader: 'USER MANAGEMENT',
        subItems: [
          {
            itemId: NavigationItemId.UserManagement,
            title: 'Users',
            policies: ['Tenant_System_User_FullAccess', 'Tenant_System_User_ReadOnly'],
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-user-management'
          },
          {
            itemId: NavigationItemId.UserGroups,
            title: 'User Groups',
            policies: ['Tenant_System_UserGroup_FullAccess', 'Tenant_System_UserGroup_ReadOnly'],
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-user-group-management'
          },
          {
            itemId: NavigationItemId.FunctionalRoles,
            title: 'Functional Roles',
            policies: ['Tenant_System_FunctionRole_ReadOnly', 'Tenant_System_FunctionRole_FullAccess'],
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-functional-roles'
          }
        ]
      },
      {
        sectionHeader: 'COMPANY MANAGEMENT',
        subItems: [
          {
            itemId: NavigationItemId.CompanyManagement,
            area: ProductArea.Global,
            title: 'Companies',
            policies: ['Tenant_System_Company_FullAccess', 'Tenant_System_Company_ReadOnly'],
            path: RouterConfig.routes.companiesManagement.companies
          }
        ]
      },
      {
        sectionHeader: 'ACCESS CONTROL',
        subItems: [
          {
            itemId: NavigationItemId.AccessCards,
            title: 'Access Cards',
            policies: ['Tenant_AccessCards_Management_FullAccess', 'Tenant_AccessCards_Management_ReadOnly'],
            area: ProductArea.Global,
            path: RouterConfig.routes.tenantAdmin.tenantAccessCards
          },
          {
            itemId: NavigationItemId.CardLayouts,
            title: 'Card Layouts',
            policies: ['Tenant_AccessCards_Layouts_FullAccess', 'Tenant_AccessCards_Layouts_ReadOnly'],
            area: ProductArea.Global,
            path: RouterConfig.routes.tenantAdmin.tenantAccessCardsLayouts
          },
          {
            itemId: NavigationItemId.PrintJobs,
            title: 'Print Jobs',
            policies: ['Tenant_AccessCards_PrintJobs_ReadOnly', 'Tenant_AccessCards_PrintJobs_FullAccess'],
            area: ProductArea.Global,
            path: RouterConfig.routes.tenantAdmin.printJobList
          }
        ]
      },
      {
        sectionHeader: 'CONFIGURATIONS',
        subItems: [
          {
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'tenant-control-room-contact',
            title: 'Control Room Contacts',
            isDivided: true,
            path: '',
            policies: ['Tenant_Core_ControlRoomContacts_ReadOnly', 'Tenant_Core_ControlRoomContacts_FullAccess'],
            itemId: NavigationItemId.ControlRoomContact
          },
          {
            area: ProductArea.UnifiedExperience,
            unifiedExperienceApplication: 'landing-page',
            title: 'Cross Tenant',
            path: '',
            // todo validate
            itemId: NavigationItemId.CrossTenant
          }
        ]
      }
    ]
  },
  {
    // Icon: SupportIcon,
    title: 'Help & Support',
    path: 'https://restrataone.zendesk.com/hc/en-gb',
    itemId: NavigationItemId.Support,
    area: ProductArea.ThirdParty
  }
];

export const baseUrlMap: Record<ProductArea, string> = {
  [ProductArea.OutSystems]: environment.outSystemsGlobalDashboardUrl,
  [ProductArea.UnifiedExperience]: environment.unifiedExperienceUrl,
  [ProductArea.Global]: '',
  [ProductArea.ThirdParty]: '',
  [ProductArea.OfflineProxy]: ''
};
