import { createModel } from "@rematch/core";
import { polygon } from "@turf/helpers";
import pointsWithinPolygon from "@turf/points-within-polygon";
import { FeatureCollection, Point } from "geojson";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { timeScaleDomainDeltaMap } from "@/tenant-context/control-location-graph/store/location-graph/location-graph.model";
import {
  GroupedRiskTimelineEvents,
  RiskTimelineDataDependencies
} from "@/tenant-context/control-risk-timeline/types/risk-timeline.types";
import { filterRiskEventsForRiskTimeline, groupBy } from "@/tenant-context/control-risk-timeline/utils/risk-timeline.utils";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

export type RiskTimelineModelState = {
  chartRiskEvents: GroupedRiskTimelineEvents[],
  startTime: number,
  endTime: number,
  isFilterTimelineByMapViewPortChecked: boolean,
  filterDataRange: [Date | null, Date | null]
}

const now = Date.now();

const riskTimelineChartModel = {
  name: 'riskTimeline',
  state: {
    chartRiskEvents: [],
    // eslint-disable-next-line no-magic-numbers
    startTime: now - (timeScaleDomainDeltaMap.Hours * 7),
    // eslint-disable-next-line no-magic-numbers
    endTime: now + (timeScaleDomainDeltaMap.Hours * 7),
    isFilterTimelineByMapViewPortChecked: false,
    filterDataRange: [ null, null ]
  } as RiskTimelineModelState,
  reducers: {
    SET_CHART_RISK_EVENTS: (
      state: RiskTimelineModelState,
      payload: GroupedRiskTimelineEvents[]
    ) => ({
      ...state,
      chartRiskEvents: payload
    }),
    SET_TIME_DOMAIN: (
      state: RiskTimelineModelState,
      payload: {
        startTime: number,
        endTime: number
      }
    ) => ({
      ...state,
      startTime: payload.startTime,
      endTime: payload.endTime
    }),
    SET_FILTER_TIMELINE_BY_MAP_VIEWPORT: (
      state: RiskTimelineModelState,
      payload: boolean
    ) => ({
      ...state,
      isFilterTimelineByMapViewPortChecked: payload
    }),
    SET_FILTER_DATE_RANGE: (
      state: RiskTimelineModelState,
      payload: [Date | null, Date | null]
    ) => ({
      ...state,
      filterDataRange: payload
    })
  },
  effects: (dispatch: Dispatch) => ({
    fetchChartData(
      riskTimelineDependencies: RiskTimelineDataDependencies,
      state: RootState
    ): void {
      const {
        riskAlertsGeoData,
        startTime,
        endTime,
        showOnlyImpactedRiskEvents,
        disabledRiskCategories,
        riskLevelLowerLimit,
        riskLevelUpperLimit
      } = riskTimelineDependencies;

      const {
        riskTimeline: {
          isFilterTimelineByMapViewPortChecked
        },
        bigMap: {
          currentViewPort
        }
      } = state;

      // Filtering out the risk events that are within the current viewport. `viewportFilteredRiskEvents` default value
      // is the current risk alerts geo data array. If the viewport filtering is turned on and if the current viewport
      // is not empty, then we assign the featureCollection coming from turf/pointsWithinPolygon.
      // eslint-disable-next-line fp/no-let
      let viewportFilteredRiskEvents: FeatureCollection<Point, RiskAlertEvent> = riskAlertsGeoData;
      if (isFilterTimelineByMapViewPortChecked && currentViewPort.length > 0) {
        // For turf, it needs to be a polygon, where the starting position === ending position, and convert the viewport
        // to a GeoJSON Polygon
        const viewPortPolygon = polygon([[
          ...currentViewPort,
          currentViewPort[0]
        ]]);

        // Uses turf/points-within-polygon to filter out the risk events that are within the current viewport
        viewportFilteredRiskEvents = pointsWithinPolygon(
          riskAlertsGeoData,
          viewPortPolygon
        ) as FeatureCollection<Point, RiskAlertEvent>;
      }

      const allRiskEvents: Array<RiskAlertEvent> = viewportFilteredRiskEvents.features.map((riskAlert) => {
        return riskAlert.properties;
      }).filter((riskAlert) => filterRiskEventsForRiskTimeline(
        riskAlert,
        startTime,
        endTime,
        showOnlyImpactedRiskEvents,
        disabledRiskCategories,
        riskLevelUpperLimit,
        riskLevelLowerLimit
      ));

      const riskEventsGroupedByDate: RiskAlertEvent[][] = groupBy<RiskAlertEvent>(
        allRiskEvents,
        (item) => [item.json.alert.start_date]
      );

      const organizedData: GroupedRiskTimelineEvents[] = riskEventsGroupedByDate.map((group) => {
        const riskEventsGroupedByRiskLevel = groupBy<RiskAlertEvent>(group, (item) => [item.json.alert.risk_level])
          .map((riskLevelGroup) => {
            return {
              riskLevel: riskLevelGroup[0].json.alert.risk_level.id,
              riskEvents: riskLevelGroup
            };
          });

        return {
          date: new Date(group[0].json.alert.start_date),
          items: riskEventsGroupedByRiskLevel
        };
      });

      dispatch.riskTimeline.SET_CHART_RISK_EVENTS(organizedData);
    }
  })
};

export const riskTimeline = createModel<RootModel>()(
  riskTimelineChartModel
);
