import { FC } from "react";

// TODO optimise this
import { ReactComponent as AddLocationsActive } from '@/core/navigation/icons/addLocations/add-locations-active.svg';
import { ReactComponent as AddLocationsDisabled } from '@/core/navigation/icons/addLocations/add-locations-disabled.svg';
import { ReactComponent as AddLocationsEnabled } from '@/core/navigation/icons/addLocations/add-locations-enabled.svg';
import { NavigationItemId } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";
import { ReactComponent as AssetsActiveIcon } from '@/tenant-context/navigation/icons/assets/assets-active.svg';
import { ReactComponent as AssetsDisabledIcon } from '@/tenant-context/navigation/icons/assets/assets-disabled.svg';
import { ReactComponent as AssetsEnabledIcon } from '@/tenant-context/navigation/icons/assets/assets-enabled.svg';
import { ReactComponent as CommsActiveIcon } from '@/tenant-context/navigation/icons/comms/comms-active.svg';
import { ReactComponent as CommsDisabledIcon } from '@/tenant-context/navigation/icons/comms/comms-disabled.svg';
import { ReactComponent as CommsEnabledIcon } from '@/tenant-context/navigation/icons/comms/comms-enabled.svg';
import { ReactComponent as GeofencesActiveIcon } from '@/tenant-context/navigation/icons/geofences/geofences-active.svg';
import { ReactComponent as GeofencesDisabledIcon } from '@/tenant-context/navigation/icons/geofences/geofences-disabled.svg';
import { ReactComponent as GeofencesEnabledIcon } from '@/tenant-context/navigation/icons/geofences/geofences-enabled.svg';
import { ReactComponent as IcmActiveIcon } from '@/tenant-context/navigation/icons/icm/icm-active.svg';
import { ReactComponent as IcmDisabledIcon } from '@/tenant-context/navigation/icons/icm/icm-disabled.svg';
import { ReactComponent as IcmEnabledIcon } from '@/tenant-context/navigation/icons/icm/icm-enabled.svg';
import { ReactComponent as LocationActiveIcon } from '@/tenant-context/navigation/icons/location/location-active.svg';
import { ReactComponent as LocationDisabledIcon } from '@/tenant-context/navigation/icons/location/location-disabled.svg';
import { ReactComponent as LocationEnabledIcon } from '@/tenant-context/navigation/icons/location/location-enabled.svg';
import { ReactComponent as LocationManagementDisabledIcon } from '@/tenant-context/navigation/icons/location/location-management-disabled.svg';
import { ReactComponent as LocationManagementActiveIcon } from '@/tenant-context/navigation/icons/location/location-management-enabled.svg';
import { ReactComponent as MapActiveIcon } from '@/tenant-context/navigation/icons/map/map-active.svg';
import { ReactComponent as MapDisabledIcon } from '@/tenant-context/navigation/icons/map/map-disabled.svg';
import { ReactComponent as MapEnabledIcon } from '@/tenant-context/navigation/icons/map/map-enabled.svg';
import { ReactComponent as ReferencesActiveIcon } from '@/tenant-context/navigation/icons/references/references-active.svg';
import { ReactComponent as ReferencesDisabledIcon } from '@/tenant-context/navigation/icons/references/references-disabled.svg';
import { ReactComponent as ReferencesEnabledIcon } from '@/tenant-context/navigation/icons/references/references-enabled.svg';
import { ReactComponent as ReportsActiveIcon } from '@/tenant-context/navigation/icons/reports/reports-active.svg';
import { ReactComponent as ReportsDisabledIcon } from '@/tenant-context/navigation/icons/reports/reports-disabled.svg';
import { ReactComponent as ReportsEnabledIcon } from '@/tenant-context/navigation/icons/reports/reports-enabled.svg';
import { ReactComponent as AccessCardActive } from '@/tenant-context/navigation/icons/settings/access-card-active.svg';
import { ReactComponent as AccessCardDisabled } from '@/tenant-context/navigation/icons/settings/access-card-disabled.svg';
import { ReactComponent as CardDesignActive } from '@/tenant-context/navigation/icons/settings/card-designs-active.svg';
import { ReactComponent as CardDesignDisabled } from '@/tenant-context/navigation/icons/settings/card-designs-disabled.svg';
import { ReactComponent as ControlRoomActive } from '@/tenant-context/navigation/icons/settings/control-room-active.svg';
import { ReactComponent as ControlRoomDisabled } from '@/tenant-context/navigation/icons/settings/control-room-disabled.svg';
import { ReactComponent as ContextSwitchIcon } from '@/tenant-context/navigation/icons/settings/cross-tenant-back.svg';
import { ReactComponent as EndTaskActive } from '@/tenant-context/navigation/icons/settings/end-task-active.svg';
import { ReactComponent as EndTaskDisabled } from '@/tenant-context/navigation/icons/settings/end-task-disabled.svg';
import { ReactComponent as ICMConfigActive } from '@/tenant-context/navigation/icons/settings/icm-config-active.svg';
import { ReactComponent as ICMConfigDisabled } from '@/tenant-context/navigation/icons/settings/icm-config-disabled.svg';
import { ReactComponent as PrintJobActive } from '@/tenant-context/navigation/icons/settings/print-job-active.svg';
import { ReactComponent as PrintJobDisabled } from '@/tenant-context/navigation/icons/settings/print-job-disabled.svg';
import { ReactComponent as SettingsActiveIcon } from '@/tenant-context/navigation/icons/settings/settings-active.svg';
import { ReactComponent as SettingsDisabledIcon } from '@/tenant-context/navigation/icons/settings/settings-disabled.svg';
import { ReactComponent as SettingsEnabledIcon } from '@/tenant-context/navigation/icons/settings/settings-enabled.svg';
import { ReactComponent as MoreSettingsActive } from '@/tenant-context/navigation/icons/settings/settings-wheel-active.svg';
import { ReactComponent as MoreSettingsDisabled } from '@/tenant-context/navigation/icons/settings/settings-wheel-disabled.svg';
import { ReactComponent as SitesActive } from '@/tenant-context/navigation/icons/settings/sites-active.svg';
import { ReactComponent as SitesDisabled } from '@/tenant-context/navigation/icons/settings/sites-disabled.svg';
import { ReactComponent as UserGroupActive } from '@/tenant-context/navigation/icons/settings/user-groups-active.svg';
import { ReactComponent as UserGroupDisabled } from '@/tenant-context/navigation/icons/settings/user-groups-disabled.svg';
import { ReactComponent as UserRolesActive } from '@/tenant-context/navigation/icons/settings/user-roles-active.svg';
import { ReactComponent as UserRolesDisabled } from '@/tenant-context/navigation/icons/settings/user-roles-disabled.svg';
import { ReactComponent as UserEnabled } from '@/tenant-context/navigation/icons/settings/users-active.svg';
import { ReactComponent as UserDisabled } from '@/tenant-context/navigation/icons/settings/users-disabled.svg';
import { ReactComponent as VerifiedCheckinActive } from '@/tenant-context/navigation/icons/settings/verified-checkin-active.svg';
import { ReactComponent as VerifiedCheckinDisabled } from '@/tenant-context/navigation/icons/settings/verified-checkin-disabled.svg';
import { ReactComponent as SupportActiveIcon } from '@/tenant-context/navigation/icons/support/support-active.svg';
import { ReactComponent as SupportDisabledIcon } from '@/tenant-context/navigation/icons/support/support-disabled.svg';
import { ReactComponent as SupportEnabledIcon } from '@/tenant-context/navigation/icons/support/support-enabled.svg';
import { ReactComponent as TravelActiveIcon } from '@/tenant-context/navigation/icons/travel/travel-active.svg';
import { ReactComponent as TravelDisabledIcon } from '@/tenant-context/navigation/icons/travel/travel-disabled.svg';
import { ReactComponent as TravelEnabledIcon } from '@/tenant-context/navigation/icons/travel/travel-enabled.svg';
import { ReactComponent as UsersActiveIcon } from '@/tenant-context/navigation/icons/users/users-active.svg';
import { ReactComponent as UsersDisabledIcon } from '@/tenant-context/navigation/icons/users/users-disabled.svg';
import { ReactComponent as UsersEnabledIcon } from '@/tenant-context/navigation/icons/users/users-enabled.svg';





type NavigationIconMapping = {
  active: FC,
  disabled: FC,
  enabled: FC,
};

function mapping(active: FC, disabled: FC, enabled: FC): NavigationIconMapping {
  return {
    active,
    disabled,
    enabled
  };
}

export const navigationIconMap: Partial<Record<NavigationItemId, NavigationIconMapping>> = {
  [NavigationItemId.Map]: mapping(MapActiveIcon, MapDisabledIcon, MapEnabledIcon),
  [NavigationItemId.Comms]: mapping(CommsActiveIcon, CommsDisabledIcon, CommsEnabledIcon),
  [NavigationItemId.People]: mapping(UsersActiveIcon, UsersDisabledIcon, UsersEnabledIcon),
  [NavigationItemId.Sites]: mapping(AssetsActiveIcon, AssetsDisabledIcon, AssetsEnabledIcon),
  [NavigationItemId.Location]: mapping(LocationActiveIcon, LocationDisabledIcon, LocationEnabledIcon),
  [NavigationItemId.LocationManagement]: mapping(
    LocationManagementActiveIcon, 
    LocationManagementDisabledIcon, 
    LocationManagementActiveIcon
  ),
  [NavigationItemId.Travel]: mapping(TravelActiveIcon, TravelDisabledIcon, TravelEnabledIcon),
  [NavigationItemId.Incident]: mapping(IcmActiveIcon, IcmDisabledIcon, IcmEnabledIcon),
  [NavigationItemId.Reports]: mapping(ReportsActiveIcon, ReportsDisabledIcon, ReportsEnabledIcon),
  [NavigationItemId.Settings]: mapping(SettingsActiveIcon, SettingsDisabledIcon, SettingsEnabledIcon),
  [NavigationItemId.Support]: mapping(SupportActiveIcon, SupportDisabledIcon, SupportEnabledIcon),
  [NavigationItemId.Geofence]: mapping(GeofencesActiveIcon, GeofencesDisabledIcon, GeofencesEnabledIcon),
  [NavigationItemId.References]: mapping(ReferencesActiveIcon, ReferencesDisabledIcon, ReferencesEnabledIcon),
  [NavigationItemId.CrossTenant]: mapping(ContextSwitchIcon, ContextSwitchIcon, ContextSwitchIcon),
  [NavigationItemId.AccessCards]: mapping(AccessCardActive, AccessCardDisabled, AccessCardActive),
  [NavigationItemId.CardLayouts]: mapping(CardDesignActive, CardDesignDisabled, CardDesignActive),
  [NavigationItemId.PrintJobs]: mapping(PrintJobActive, PrintJobDisabled, PrintJobActive),
  [NavigationItemId.MoreSettings]: mapping(MoreSettingsActive, MoreSettingsDisabled, MoreSettingsActive),
  [NavigationItemId.CompanyManagement]: mapping(AccessCardActive, AccessCardDisabled, AccessCardActive),
  [NavigationItemId.UserManagement]: mapping(UserEnabled, UserDisabled, UserEnabled),
  [NavigationItemId.UserGroups]: mapping(UserGroupActive, UserGroupDisabled, UserGroupActive),
  [NavigationItemId.FunctionalRoles]: mapping(UserRolesActive, UserRolesDisabled, UserRolesActive),
  [NavigationItemId.VerifiedCheckin]: mapping(VerifiedCheckinActive, VerifiedCheckinDisabled, VerifiedCheckinActive),
  [NavigationItemId.EndTimeConfig]: mapping(EndTaskActive, EndTaskDisabled, EndTaskActive),
  [NavigationItemId.SitesConfig]: mapping(SitesActive, SitesDisabled, SitesActive),
  [NavigationItemId.IncidentCrisisMan]: mapping(ICMConfigActive, ICMConfigDisabled, ICMConfigActive),
  [NavigationItemId.AddLocations]: mapping(AddLocationsActive, AddLocationsDisabled, AddLocationsEnabled),
  [NavigationItemId.PeopleUsers]: mapping(UserEnabled, UserDisabled, UserEnabled),
  [NavigationItemId.PeopleGroups]: mapping(UserGroupActive, UserGroupDisabled, UserGroupActive),
  [NavigationItemId.Visitors]: mapping(UserGroupActive, UserGroupDisabled, UserGroupActive),
  [NavigationItemId.ControlRoomContact]: mapping(ControlRoomActive, ControlRoomDisabled, ControlRoomActive),
  [NavigationItemId.ConnectedSitesDashboard]: mapping(AssetsActiveIcon, AssetsDisabledIcon, AssetsEnabledIcon)
};

type Props = {
  itemId: NavigationItemId,
  state: 'active' | 'disabled' | 'enabled',
}

const NavigationIcon: FC<Props> = ({
  itemId,
  state
}) => {
  const Icon = navigationIconMap[itemId]?.[state];

  if (!Icon) {
    return null;
  }

  return <Icon />;
};

export default NavigationIcon;
