/* eslint-disable react/jsx-props-no-spreading,react/jsx-props-no-spreading */

import { Checkbox, Select, TextInput, Tooltip } from "@mantine/core";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { RouterConfig } from "@/core/components/Router/Router.config";
import { ENV, Environment } from "@/core/config/env";
import { Dispatch } from "@/core/store";
import {
  useCreateCommsPageStyles
} from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import ResponseMethods from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Response-Methods";
import { ReactComponent as EmailIcon } from "@/tenant-context/control-mass-comms/icons/emailIcon.svg";
import { ReactComponent as MessageSmsIcon } from "@/tenant-context/control-mass-comms/icons/messageSmsIcon.svg";
import { ReactComponent as WorkAppIcon } from "@/tenant-context/control-mass-comms/icons/mobileAppIcon.svg";
import { ReactComponent as PhoneIcon } from "@/tenant-context/control-mass-comms/icons/phoneIcon.svg";
import { DeliveryChannel, TemplateList } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import MassCommsSection from "../MassCommsSection/MassCommsSegment.component";

type Props = {
  deliveryChannels: DeliveryChannel,
  templateItems: TemplateList | undefined,
  communicationName: string | undefined,
  defaultResponseMethod: string | undefined,
  onCommunicationNameChanged: (data: React.ChangeEvent<HTMLInputElement>) => void,
  onDeliveryChannelChanged: (event: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>) => void,
  commSequenceNo: number | null,
  alertSequenceNo: number | null,
  isInTemplateCreationFlow: boolean
}

const CreateCommsFormComponent: FC<Props> = ({
  deliveryChannels,
  onDeliveryChannelChanged,
  onCommunicationNameChanged,
  templateItems,
  communicationName,
  commSequenceNo,
  alertSequenceNo,
  isInTemplateCreationFlow
}) => {
  const [ disabledChannels, setDisabledChannels ] = useState(['voice']);

  const { classes, cx } = useCreateCommsPageStyles({
    isCommsSequenceNoAvailable: !!commSequenceNo
  });
  const { register, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: { commName: communicationName }
  });

  const getCalculatedCommunicationName = useCallback(() => {
    const split = communicationName?.split('-');

    if (split && split.length > 1) {
      return split[1];
    } else {
      return communicationName;
    }
  }, [communicationName]);

  const {
    massComms: {
      getSelectedTemplate,
      CLEAR_EMAIL_CONTENT
    }
  } = useDispatch<Dispatch>();

  const onTemplateChanged = useCallback(async (templateId: string) => {
    if (+templateId === 0) {
      CLEAR_EMAIL_CONTENT();
    } else {
      getSelectedTemplate({ templateId, isInTemplateEdit: false });
    }
  }, [CLEAR_EMAIL_CONTENT, getSelectedTemplate]);

  useEffect(() => {
    const isInDevMode = (ENV === Environment.Dev || ENV === Environment.Qa);
    if (!isInDevMode) {
      setDisabledChannels(['app', 'voice']);
    }
  }, []);

  return (
    <MassCommsSection className={ classes.commsWrapper }>
      { !isInTemplateCreationFlow && <div className={ classes.sectionHeader }>Create Communication</div> }
      <div className={ cx(classes.extendedMargin, classes.commNameContainer) }>
        <form>
          <TextInput
            maxLength={ 60 }
            icon={ (commSequenceNo !== null) && (<div className={ classes.sequenceNo }>
              { commSequenceNo && ` ${commSequenceNo} -` }
            </div>) }
            { ...register('commName', { maxLength: 60 }) }
            value={ getCalculatedCommunicationName() }
            onChange={ onCommunicationNameChanged }
            rightSection={ alertSequenceNo && <div className={ classes.alertNo }>
              { alertSequenceNo && ` #${alertSequenceNo}` }
            </div> }
            error={ errors.commName && errors.commName.type === "maxLength" && 'name cannot exceed 60 characters' }
            disabled={ !commSequenceNo && !isInTemplateCreationFlow }
            label={ 'Communication Name' }
          />
        </form>
      </div>
      { !isInTemplateCreationFlow && <div>
        <Select
          className={ classes.templateLabel }
          onChange={ onTemplateChanged }
          size={ 'lg' }
          data={ [{ value: "0", label: "No Template" }].concat(templateItems || []) }
          label={ <>
            Template
            <Link
              className={ classes.manageTemplatesLink }
              to={ RouterConfig.routes.massCommsTemplates }>
              Manage Templates
            </Link>
          </> }
        />

      </div> }
      <div className={ cx(classes.sectionHeader, classes.extendedMargin) }>Delivery Channels</div>
      <div className={ classes.responseMethodContainer }>
        <button
          name="email"
          className={ cx({
            [classes.responseItemContainer]: true,
            [classes.selectedItem]: deliveryChannels.email
          }) }
          onClick={ onDeliveryChannelChanged }
        >
          <div className={ classes.checkBoxSection }>
            <Checkbox
              size="xs"
              checked={ deliveryChannels.email }
              onChange={ onDeliveryChannelChanged }
              name="email"
            />
          </div>
          <div className={ classes.iconSection }>
            <EmailIcon/>
          </div>
          <div className={ classes.iconTextSection }>
            Email
          </div>
        </button>
        <button
          name="sms"
          className={ cx({
            [classes.responseItemContainer]: true,
            [classes.selectedItem]: deliveryChannels.sms
          }) }
          onClick={ onDeliveryChannelChanged }
        >
          <div className={ classes.checkBoxSection }>
            <Checkbox
              size="xs"
              checked={ deliveryChannels.sms }
              onChange={ onDeliveryChannelChanged }
              name="sms"
            />
          </div>
          <div className={ classes.iconSection }>
            <MessageSmsIcon/>
          </div>

          <div className={ classes.iconTextSection }>
            SMS
          </div>
        </button>

        <div>
          <button
            onClick={ onDeliveryChannelChanged }
            className={ cx({
              [classes.responseItemContainer]: true,
              [classes.selectedItem]: deliveryChannels.app,
              [classes.responseItemContainerDisabled]: disabledChannels.includes('app')
            }) }
            name={ 'app' }
            disabled={ disabledChannels.includes('app') }
          >
            <div className={ classes.checkBoxSection }>
              <Checkbox
                size="xs"
                checked={ deliveryChannels.app }
                name={ 'app' }
                onChange={ onDeliveryChannelChanged }
                disabled={ disabledChannels.includes('app') }
                hidden={ disabledChannels.includes('app') }
              />
            </div>
            <div className={ classes.iconSection }>
              <WorkAppIcon className={ disabledChannels.includes('app') ? classes.responseItemIconDisabled : undefined } />
            </div>
            <div className={ classes.iconTextSection }>
              Restrata App
            </div>
          </button>
        </div>

        <Tooltip label={ 'Coming Soon' } withArrow>
          <div>
            <button
              onClick={ onDeliveryChannelChanged }
              className={ cx({
                [classes.responseItemContainer]: true,
                [classes.selectedItem]: deliveryChannels.voice,
                [classes.responseItemContainerDisabled]: true
              }) }
              name={ 'voice' }
              disabled
            >
              <div className={ classes.checkBoxSection }>
                <Checkbox
                  size="xs"
                  checked={ deliveryChannels.voice }
                  name={ 'voice' }
                  onChange={ onDeliveryChannelChanged }
                  disabled
                  hidden
                />
              </div>
              <div className={ classes.iconSection }>
                <PhoneIcon className={ classes.responseItemIconDisabled }/>
              </div>
              <div className={ classes.iconTextSection }>
                Call
              </div>
            </button>
          </div>
        </Tooltip>

      </div>

      <ResponseMethods/>

    </MassCommsSection>
  );
};

export default CreateCommsFormComponent;
