import { createContext, FC, useEffect, useRef, useState } from "react";
import { useMap } from "react-map-gl";

import useContextValue from "@/common/hooks/useContextValue";
import { GLOBAL_LEVEL_ZOOM } from "@/tenant-context/common/util/constants";

export type BigMapContextType = {
  isBigMapZoomedIn: boolean,
  bigMapZoomLevel:number,
  customIcons: React.MutableRefObject<CustomIcon[]>
};

type CustomIcon = {
  name: string,
  icon: HTMLImageElement | ImageData | ImageBitmap
}

export const BigMapContext = createContext<BigMapContextType>({} as BigMapContextType);

export const BigMapProvider: FC = ({
  children
}) => {
  const { AssetLocationSnapshotMap: map } = useMap();

  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const customIcons = useRef<CustomIcon[]>([]);

  // Set is map zoomed in
  useEffect(() => {
    if (!map) {
      return;
    }

    const handleMapZoom = () => {
      setIsZoomedIn(
        map.getZoom() > GLOBAL_LEVEL_ZOOM
      );
      setZoomLevel(map.getZoom());
    };

    map.on(
      'zoom',
      handleMapZoom
    );

    return () => {
      map.off(
        'zoom',
        handleMapZoom
      );
    };
  }, [map]);

  return (
    <BigMapContext.Provider value={ useContextValue({
      isBigMapZoomedIn: isZoomedIn,
      bigMapZoomLevel:zoomLevel,
      customIcons: customIcons
    }) }>
      { children }
    </BigMapContext.Provider>
  );
};
