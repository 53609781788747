import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";

export type DataOptionsState = {
  disabledLayerTypes: ToggleableLayerType[]
}

export const drawerState: DataOptionsState = {
  disabledLayerTypes: [
    ToggleableLayerType.CountryRiskLevel,
    ToggleableLayerType.TravelCountriesView,
    ToggleableLayerType.TravelCountryDetailsView
  ]
};
