import { FC, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { REGULAR_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from '@/common/util/browser-database';
import { RouterConfig } from "@/core/components/Router/Router.config";
import ProtectedRouteElement from "@/core/components/RouterRoutes/ProtectedRouteElement.component";
import RouterOverlay from "@/core/components/RouterRoutes/RouterOverlay.component";
import SSOIntegration from '@/core/components/SSOIntegration';
import { environment } from "@/core/config/env";
import { SeverityLevelsRequiredPoliciesConfig } from "@/platform-context/control-icm-management/components/SeverityLevels/config/SeverityLevelsRequiredPolicies.config";
import { ICMEventPoliciesConfig } from "@/platform-context/control-icm-management/config/ICMEventRequiredPolicies.config";
import { SituationTypePoliciesConfig } from "@/platform-context/control-icm-management/config/SituationTypeRequiredPolicies.config";
import { SiteConfigurationPolicies } from "@/platform-context/control-site-config-management/config/site-configuration.policies";
import { ServiceConfigurationRequiredPoliciesConfig } from "@/platform-context/control-system-admin/config/ServiceConfigurationRequiredPolicies.config";
import NotFoundContainer from "@/platform-context/core/components/NotFound";


const ExternalRedirect = lazy(() => import('@/common/components/ExternalRedirect/ExternalRedirect.component'));
const EventsManagement = lazy(() => import('@/platform-context/control-icm-management/components/Event'));
const SeverityLevelsManagement = lazy(() => import('@/platform-context/control-icm-management/components/SeverityLevels/components/SeverityLevelsManagement'));
const SeverityLevels = lazy(() => import('@/platform-context/control-icm-management/components/SeverityLevels/SeverityLevels.container'));
const SituationTypes = lazy(() => import('@/platform-context/control-icm-management/components/SituationTypes'));
const SiteConfigItem = lazy(() => import('@/platform-context/control-site-config-management/components/SiteConfigItem'));
const SiteConfigList = lazy(() => import('@/platform-context/control-site-config-management/components/SiteConfigList'));
const ServiceConfiguration = lazy(() => import('@/platform-context/control-system-admin/components/ServiceConfig'));
const ServiceConfigurationDetails = lazy(() => import('@/platform-context/control-system-admin/components/ServiceConfigDetails'));


const { routes } = RouterConfig;

const RouterRoutes: FC = () => {
  // Rerender on location change
  useLocation();

  const regularToken = browserDatabase.getItem(REGULAR_TOKEN_KEY);

  const isAuthenticated = !!regularToken;

  return (
    <>
      <Routes>
        { isAuthenticated && (<>
          <Route
            path={ routes.notFound }
            element={ <NotFoundContainer/> }
          />
          <Route
            path={ routes.icmConfig.situationTypes }
            element={ <ProtectedRouteElement
              pagePolicies={ SituationTypePoliciesConfig.SITUATION_TYPE_ROUTE_ACCESS }>
              <SituationTypes />
            </ProtectedRouteElement> }
          />
          <Route
            path={ routes.icmConfig.eventsManagement }
            element={ <ProtectedRouteElement
              pagePolicies={ ICMEventPoliciesConfig.ICM_EVENT_ROUTE_ACCESS }>
              <EventsManagement />
            </ProtectedRouteElement> }
          />
          <Route
            path={ routes.icmConfig.severityLevelsId() }
            element={ <ProtectedRouteElement
              pagePolicies={ SeverityLevelsRequiredPoliciesConfig.SEVERITY_LEVELS_READ_ACCESS }>
              <SeverityLevelsManagement />
            </ProtectedRouteElement> }
          />
          <Route
            path={ routes.icmConfig.severityLevels }
            element={ <ProtectedRouteElement
              pagePolicies={ SeverityLevelsRequiredPoliciesConfig.SEVERITY_LEVELS_READ_ACCESS }>
              <SeverityLevels />
            </ProtectedRouteElement> }
          />
          <Route
            path={ routes.systemAdmin.serviceConfiguration }
            element={ <ProtectedRouteElement
              pagePolicies={ ServiceConfigurationRequiredPoliciesConfig.SERVICE_CONFIG_ROUTE_ACCESS }>
              <ServiceConfiguration />
            </ProtectedRouteElement> }
          />
          <Route
            path={ routes.systemAdmin.siteConfigManagement }
            element={ <ProtectedRouteElement
              pagePolicies={ SiteConfigurationPolicies.SITE_CONFIG_ALL_ACCESS }>
              <SiteConfigList /></ProtectedRouteElement> }
          />
          <Route
            path={ routes.systemAdmin.siteConfigManagementDetails() }
            element={ <ProtectedRouteElement
              pagePolicies={ SiteConfigurationPolicies.SITE_CONFIG_ALL_ACCESS }>
              <SiteConfigItem /></ProtectedRouteElement> }
          />
          <Route
            path={ routes.systemAdmin.serviceConfigurationDetails() }
            element={ <ProtectedRouteElement
              pagePolicies={ ServiceConfigurationRequiredPoliciesConfig.SERVICE_CONFIG_ROUTE_ACCESS }>
              <ServiceConfigurationDetails />
            </ProtectedRouteElement> }
          />
          <Route
            path="*"
            element={ <Navigate replace to={ routes.notFound }/> }
          />
        </>) }


        { /*NOTE: This section is only for public routes and fallback routes. DO NOT PLACE ANY OTHER AUTHENTICATED ROUTES HERE.*/ }
        { /*Place them in the above section.*/ }

        { /* SSO Integration */ }
        <Route
          path={ routes.ssoIntegration }
          element={ <SSOIntegration/> }
        />
        { /* Fallback to login for unauthenticated users */ }
        { /* Redirect to Unified Experience to perform login */ }
        { !isAuthenticated && (
          <Route
            path="*"
            element={ <ExternalRedirect url={ environment.unifiedExperienceUrl }/> }
          />
        ) }
      </Routes>
      <RouterOverlay />
    </>
  );
};

export default RouterRoutes;
