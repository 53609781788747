/* eslint-disable no-magic-numbers */
import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import theme from "@/core/styles/mantine/theme";
import {
  CommunicationConfigRequest, DeliveryItems,
  DeliverySuccessData,
  FilterParams,
  FilterState,
  MassCommsReportsRecipientDetail,
  MassCommsSummaryChartDataItem,
  OverallDeliveryKeys,
  OverallDeliverySuccessData,
  ReportCommContent,
  ReportPollResponseTypes,
  ReportResponseConfigs,
  ReportStatItem,
  ReportTimeDetails
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

import { createDeliverySuccessStream, createOverallDeliveryStream, createPollResponsesStream, getCommunicationsByName } from "../api/mass-comms";


type MassCommsReportState = {
    currentCommunication: CommunicationConfigRequest | undefined,
    communicationContent: ReportCommContent,
    pollResponses: ReportPollResponseTypes,
    deliveryResponses: DeliverySuccessData,
    overallDeliverySuccess: OverallDeliverySuccessData,
    responseConfigs: ReportResponseConfigs,
    activeChannels: Array<string>,
    reportTimeInfo: ReportTimeDetails,
    recipientsDetailsList: Array<MassCommsReportsRecipientDetail>,
    filters: FilterState,
    activeFilters: MassCommsSummaryChartDataItem[] | []
    isMassCommsFromArc: boolean
    isMassCommsFromLookup: boolean
}

// eslint-disable-next-line fp/no-let
let deliverySuccessStream :EventSource | null = null;
// eslint-disable-next-line fp/no-let
let overallDeliveryStream :EventSource | null = null;
// eslint-disable-next-line fp/no-let
let pollResponseStream :EventSource | null = null;

const massCommsReportDefaultState: MassCommsReportState = {
  currentCommunication: undefined,
  communicationContent: {
    emailConent: '',
    smsContent: '',
    callContent: 'Call communication content',
    appContent: 'Voice communication content'
  },
  pollResponses: {
    items: [],
    total: { title: 'RECIPIENTS', count: 0 }
  },
  deliveryResponses: {
    communicationTid: null,
    appCommsSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }],
    emailSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }],
    smsSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }],
    voiceSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }]
  },
  overallDeliverySuccess: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'recipientCount', count: 0 }],
  responseConfigs: null,
  activeChannels: [],
  reportTimeInfo: {
    lastResponse: 0,
    sentTime: 0
  },
  recipientsDetailsList: [],
  filters: {
    deliveryStatus: 'ALL',
    pollResponse: "ALL",
    channel: "ALL"
  },
  activeFilters: [],
  isMassCommsFromArc: false,
  isMassCommsFromLookup: false
};

const massCommsReportModel = {
  name: 'massCommsReport',
  state: massCommsReportDefaultState,
  reducers: {
    SET_CURRENT_COMMUNICATION(
      state: MassCommsReportState,
      payload: CommunicationConfigRequest
    ) {
      return {
        ...state,
        currentCommunication: payload
      };
    },
    SET_COMMUNICATION_CONTENT(
      state: MassCommsReportState,
      payload: ReportCommContent
    ) {
      return {
        ...state,
        communicationContent: payload
      };
    },
    SET_POLL_RESPONSES(
      state: MassCommsReportState,
      payload: ReportPollResponseTypes
    ) {
      return {
        ...state,
        pollResponses: payload
      };
    },
    SET_DELIVERY_RESPONSES(
      state: MassCommsReportState,
      payload: DeliverySuccessData
    ) {
      return {
        ...state,
        deliveryResponses: payload
      };
    },
    SET_OVERALL_DELIVERY_RESPONSES(
      state: MassCommsReportState,
      payload: OverallDeliverySuccessData
    ) {
      return {
        ...state,
        overallDeliverySuccess: payload
      };
    },
    SET_RESPONSE_CONFIGS(
      state: MassCommsReportState,
      payload: ReportResponseConfigs
    ) {
      return {
        ...state,
        responseConfigs: payload
      };
    },
    SET_ACTIVE_CHANNELS(
      state: MassCommsReportState,
      payload: Array<string>
    ) {
      return {
        ...state,
        activeChannels: payload
      };
    },
    SET_REPORT_TIME_INFO(
      state: MassCommsReportState,
      payload: ReportTimeDetails
    ) {
      return {
        ...state,
        reportTimeInfo: payload
      };
    },
    SET_REPORTS_DEFAULT_STATE(
      _state: MassCommsReportState,
      payload: MassCommsReportState
    ) {
      return {
        ...payload
      };
    },

    SET_RECIPIENTS_DETAILS_LIST(
      state: MassCommsReportState,
      payload: Array<MassCommsReportsRecipientDetail>
    ) {
      return {
        ...state,
        recipientsDetailsList: payload
      };
    },
    SET_ACTIVE_FILTERS(state: MassCommsReportState, payload: MassCommsSummaryChartDataItem[]){
      return {
        ...state,
        activeFilters: payload
      };
    },
    SET_FILTERS(state: MassCommsReportState, payload: FilterParams){
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.type]: payload.field,
          channel: payload.channel || state.filters.channel
        }
      };
    },
    SET_IS_MASS_COMMS_FROM_ARC(state: MassCommsReportState, payload: boolean){
      return {
        ...state,
        isMassCommsFromArc: payload
      };
    },
    SET_IS_MASS_COMMS_FROM_LOOKUP(state: MassCommsReportState, payload: boolean){
      return {
        ...state,
        isMassCommsFromLookup: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async loadReportCommContent(commName: string | undefined, state: RootState): Promise<void> {
      if (!commName) {
        return;
      }
      const {
        commonData: {
          tenantId
        },
        massCommsReport: {
          communicationContent,
          reportTimeInfo
        }
      } = state;

      const reportCommContent = await getCommunicationsByName(commName, tenantId);
      dispatch.massCommsReport.SET_CURRENT_COMMUNICATION(reportCommContent);

      const deliveryChannels = ['APP', 'EMAIL', 'SMS', 'VOICE']
        .filter(channel => reportCommContent.deliveryChannels?.includes(channel as DeliveryItems)) || [];

      dispatch.massCommsReport.SET_COMMUNICATION_CONTENT({
        ...communicationContent,
        emailConent: reportCommContent.emailConfiguration?.content || 'Email communication content',
        smsContent: reportCommContent.smsConfiguration?.content || 'SMS communication content'
      });
      dispatch.massCommsReport.SET_ACTIVE_CHANNELS(deliveryChannels);
      dispatch.massCommsReport.SET_RESPONSE_CONFIGS(reportCommContent.responseMethod || null);
      dispatch.massCommsReport.SET_REPORT_TIME_INFO({
        ...reportTimeInfo,
        sentTime: reportCommContent.meta?.createdTime || 0
      });
    },
    async createDeliveryStatsStream(tid: string, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;

      deliverySuccessStream = await createDeliverySuccessStream(tenantId, tid, async (data) => {
        const delResponses = {
          communicationTid: data.communicationTid,
          appCommsSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }],
          emailSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }],
          smsSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }],
          voiceSummary: [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'total', count: 0 }]
        };

        if (data.appSummary !== null) {
          Object.keys(data.appSummary).forEach((itemKey) => {
            const updatingItem = delResponses.appCommsSummary.find(item => item.field === itemKey);
            if (updatingItem) {
              updatingItem.count = data.appSummary[itemKey as ReportStatItem['field']];
            }
          });
        }
        if (data.emailSummary !== null) {
          Object.keys(data.emailSummary).forEach((itemKey) => {
            const updatingItem = delResponses.emailSummary.find(item => item.field === itemKey);
            if (updatingItem) {
              updatingItem.count = data.emailSummary[itemKey as ReportStatItem['field']];
            }
          });
        }
        if (data.smsSummary !== null) {
          Object.keys(data.smsSummary).forEach((itemKey) => {
            const updatingItem = delResponses.smsSummary.find(item => item.field === itemKey);
            if (updatingItem) {
              updatingItem.count = data.smsSummary[itemKey as ReportStatItem['field']];
            }
          });
        }
        if (data.voiceSummary !== null) {
          Object.keys(data.voiceSummary).forEach((itemKey) => {
            const updatingItem = delResponses.voiceSummary.find(item => item.field === itemKey);
            if (updatingItem) {
              updatingItem.count = data.voiceSummary[itemKey as ReportStatItem['field']];
            }
          });
        }
        dispatch.massCommsReport.SET_DELIVERY_RESPONSES(delResponses as DeliverySuccessData);
      });

      overallDeliveryStream = await createOverallDeliveryStream(tenantId, tid, async (data) => {
        const overallDelSuccess = [{ field: 'fail', count: 0 }, { field: 'pending', count: 0 }, { field: 'success', count: 0 }, { field: 'recipientCount', count: 0 }];
        Object.keys(data).forEach((itemKey) => {
          if (itemKey !== 'communicationTid') {
            const updatingItem = overallDelSuccess.find(item => item.field === itemKey);
            if (updatingItem) {
              updatingItem.count = data[itemKey as OverallDeliveryKeys];
            }
          }
        });
        dispatch.massCommsReport.SET_OVERALL_DELIVERY_RESPONSES(overallDelSuccess as OverallDeliverySuccessData);
      });

      pollResponseStream = await createPollResponsesStream(tenantId, tid, async (data) => {
        const responseItems: ReportPollResponseTypes['items'] = [];
        data.responses
          ?.filter(response => response.count > 0)
          ?.forEach(response => responseItems.push(
            { title: response.responseLabel, color: response.responseLabel === 'NO_REPLY'
              ? (theme.other?.semantic.accessibility.outer || '#576275') : response.responseLabelColor || '', count: response.count }
          ));
        const totalResponses = data.responses?.reduce((accumulator, obj) => accumulator + obj['count'], 0) || 0;
        dispatch.massCommsReport.SET_POLL_RESPONSES({ items: responseItems, total: { title: 'RECIPIENTS', count: totalResponses } });
      });
    },
    async closeDeliveryStatsStream(): Promise<void> {
      deliverySuccessStream?.close();
      overallDeliveryStream?.close();
      pollResponseStream?.close();
      dispatch.massCommsReport.SET_REPORTS_DEFAULT_STATE(massCommsReportDefaultState);
    }
  })
};

export const massCommsReport = createModel<RootModel>()(
  massCommsReportModel
);
