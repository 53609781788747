import { ActionIcon } from "@mantine/core";
import { RECORD_STATUS, useAudioRecorder } from '@sarafhbk/react-audio-recorder';
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as RecordingIcon } from "@/common/icons/record.svg";
import { Dispatch } from "@/core/store";
import {
  useVoiceRecordingStyles
} from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/VoiceConfiguration/VoiceMessageTypes/VoiceRecording/VoiceRecording.styles";

const VoiceRecordingComponent: FC = () => {

  const { classes } = useVoiceRecordingStyles();

  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    status
  } = useAudioRecorder();

  const [ analyzer, setAnalyzer ] = useState<AnalyserNode | undefined>(undefined);
  const [ scriptProcessorRef, setScriptProcessorRef ] = useState<ScriptProcessorNode | undefined>(undefined);
  const [ audioContextRef, setAudioContextRef ] = useState<AudioContext | undefined>(undefined);
  const [ currentVolume, setCurrentVolume ] = useState(0);

  const {
    massComms: {
      SET_VOICE_CONTENT
    }
  } = useDispatch<Dispatch>();

  const initiateVolumeBoxes = useCallback((side: 'left' | 'right') => {
    const boxes: Array<ReactElement> = [];

    if (side === 'left') {
      // eslint-disable-next-line fp/no-loops,fp/no-let
      for (let i = 0; i < 10; i++) {
        boxes.push(
          <div
            className={ (currentVolume || 0) >= i + 1
              ? `${classes.volumeBox} ${classes.volumeBoxActive}`
              : classes.volumeBox }
          />
        );
      }
    } else {
      // eslint-disable-next-line fp/no-loops,fp/no-let
      for (let i = 10; i > 0; i--) {
        boxes.push(
          <div
            className={ (currentVolume || 0) >= i
              ? `${classes.volumeBox} ${classes.volumeBoxActive}`
              : classes.volumeBox }
          />
        );
      }
    }

    return boxes;
  }, [ classes.volumeBox, classes.volumeBoxActive, currentVolume ]);

  const restrictFrequencyValue = (value: number) => {
    if (value < 0) {
      return 0;
    } else if (value > 100) {
      return 10;
    } else {
      return value / 10;
    }
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  };

  const audioToBase64 = useCallback((audioUrl: string) => {
    (async () => {

      const blob = await fetch(audioUrl)
        .then(r => r.blob());

      const base64 = await blobToBase64(blob);
      SET_VOICE_CONTENT(base64);
    })();
  }, [ SET_VOICE_CONTENT ]);

  useEffect(() => {
    if (!audioResult) {
      return;
    }

    audioToBase64(audioResult);
  }, [ audioResult, audioToBase64 ]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true
      })
      .then(function (stream) {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext
          .createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);

        setAudioContextRef(audioContext);
        setAnalyzer(analyser);
        setScriptProcessorRef(scriptProcessor);
      })
      .catch(function (err) {
        /* handle the error */
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (status !== RECORD_STATUS.RECORDING || !audioContextRef || !analyzer || !scriptProcessorRef) {
      scriptProcessorRef?.disconnect();
      return;
    }

    scriptProcessorRef.connect(audioContextRef.destination);
    scriptProcessorRef.onaudioprocess = function () {
      const array = new Uint8Array(analyzer.frequencyBinCount);
      analyzer.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      const currentFreq = restrictFrequencyValue(Math.round(average));
      setCurrentVolume(currentFreq);
    };
  }, [ status, audioContextRef, analyzer, scriptProcessorRef ]);

  return (
    <div className={ classes.wrapper }>
      { !audioResult && (<>
        <div className={ classes.volumeBoxWrapper }>
          <span className={ classes.currentTime }>{ new Date(timer * 1000).toISOString().slice(11, 19) }</span>

          { initiateVolumeBoxes('right').map((box) => box) }
          <div className={ classes.volumeBoxDivider }></div>
          { initiateVolumeBoxes('left').map((box) => box) }
        </div>

        <ActionIcon
          className={ classes.recordButton }
          onClick={ status !== RECORD_STATUS.RECORDING ? startRecording : stopRecording }>
          <RecordingIcon/>
        </ActionIcon>
      </>) }

      { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
      { audioResult && (<audio id="audio" controls src={ audioResult }>
        Your browser does not support the audio element.
      </audio>) }

    </div>
  );
};

export default VoiceRecordingComponent;
