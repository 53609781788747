import { Select, Slider } from "@mantine/core";
import range from "lodash/range";
import React, { FC, useCallback, useMemo } from "react";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { useRankingSettingsStyles } from "@/tenant-context/control-ranking-settings/components/RankingSettings/RankingSettings.styles";
import { TimeSliderType } from "@/tenant-context/control-ranking-settings/store/control-ranking-settings/control-ranking-settings.model";

export const DAYS_DELTA_MAX = 30;
export const HOURS_DELTA_MAX = 12;

const SLIDER_MAX_PERCENTAGE = 100;
const SLIDER_HALF_PERCENTAGE = 50;
const SLIDER_QUARTER_PERCENTAGE = 25;

export const HOURSLIDER = "Hours";
export const DAYSSLIDER = "Days";


const daysSliderArray = range(0,SLIDER_MAX_PERCENTAGE+1, 5);
const hoursSliderArray = range(0,SLIDER_MAX_PERCENTAGE+1, (SLIDER_MAX_PERCENTAGE/ (HOURS_DELTA_MAX * 2)));

const marksForDaysSlider = daysSliderArray.map(item => {
  const rounded = Math.round(item);
  return {
    value:rounded,
    label:rounded === 0 ? '-30' : rounded === SLIDER_HALF_PERCENTAGE ? 'TODAY' :
      rounded === SLIDER_MAX_PERCENTAGE ? '+30': ''
  };
});

const marksForHourSlider =  hoursSliderArray.map(item => {
  const rounded = Math.round(item);
  return {
    value:rounded,
    label:rounded === 0 ? '- 12' : rounded === SLIDER_QUARTER_PERCENTAGE ? '- 6' :
      rounded === SLIDER_HALF_PERCENTAGE ? 'NOW'
        : rounded === (SLIDER_HALF_PERCENTAGE+SLIDER_QUARTER_PERCENTAGE) ? '+ 6' : rounded === SLIDER_MAX_PERCENTAGE ? '+ 12': ''
  };
});


type Props = {
  isShowOnlyHighestRanksForPeople: boolean,
  onShowOnlyHighestRanksForPeopleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  usedAdapters: string[],
  disabledAdapters: string[],
  onAdapterToggle: (ev: React.ChangeEvent<HTMLInputElement>) => void,
  onProbabilityThresholdChange: (value: number[]) => void,
  onTimeSliderValueChange: (value: number) => void,
  calculateTimeSliderValue: (value: number, timeAdjustment:string) => number,
  timeSliderValuePercentage: number,
  probabilityThresholdPercentageUpperEnd: number,
  probabilityThresholdPercentageLowerEnd:number,
  onClickResetFilters?: () => void
  timeSelectionMode:string,
  onChangeTimeSelectionMode: (value: TimeSliderType | null) => void;
};

const RankingSettings: FC<Props> = ({
  onTimeSliderValueChange,
  calculateTimeSliderValue,
  timeSliderValuePercentage,
  timeSelectionMode,
  onChangeTimeSelectionMode
}) => {
  const getTimeSliderLabel = useCallback(
    (value: number) => {
      const sliderValue = calculateTimeSliderValue(value, timeSelectionMode);

      if(timeSelectionMode === DAYSSLIDER){
        if (sliderValue === 0) {
          return null;
        }

        return `${sliderValue > 0 ? `+${ sliderValue}` : sliderValue } days`;
      } else{
        if (sliderValue === 0) {
          return null;
        }

        return `${sliderValue > 0 ? `+${ sliderValue}` : sliderValue } hours`;
      }

    },
    [calculateTimeSliderValue, timeSelectionMode]
  );

  const timeAdjustmentSliderMarks = {
    [HOURSLIDER]: marksForHourSlider,
    [DAYSSLIDER]: marksForDaysSlider
  };

  const sliderStep = useMemo(
    () => timeSelectionMode === DAYSSLIDER
      ?
      100 / (DAYS_DELTA_MAX * 2)
      :
      100 / (HOURS_DELTA_MAX * 2),
    [timeSelectionMode]
  );

  const {
    classes
  } = useRankingSettingsStyles();

  return (
    <div className={ classes.rankingSettingsContainer }>
      { /* <div  className={ classes.settingBlock }>
        <p className={ classes.txtInfo }>Only show highest probability people markers</p>
        <Switch
          checked={ isShowOnlyHighestRanksForPeople }
          onChange={ onShowOnlyHighestRanksForPeopleChange }
        />
      </div>
      <Divider
        className={ classes.divider }
      />
      <h5 className={ classes.thresholdTxt }>
        Probability Threshold Adjustment
      </h5>
      <p className={ classes.txtInfo } >
        Show a person highest probability location marker and any other probably
        location markers withn a %  range of probability.
      </p>
      <RangeSlider
        className={ classes.probabilitySlider }
        defaultValue={ [0, 100] }
        value={ [probabilityThresholdPercentageLowerEnd, probabilityThresholdPercentageUpperEnd] }
        labelAlwaysOn
        step={ 10 }
        onChangeEnd={ onProbabilityThresholdChange }
        thumbChildren={ [<SliderIcon  key="1" />, <SliderIcon key="2" />] }
        // eslint-disable-next-line react/jsx-no-bind
        label={ (value) => `${value}%` }
        marks={ [
          { value: 0, label: '0' },
          { value: 100, label: '100' }
        ] }
      />
      <Divider
        className={ classes.dividerProbabilityThresold }
      />
      <div className={ classes.RankingDataSource }>
        <h5>Ranking data source</h5>

        { usedAdapters.map((adapterId) => (
          <Checkbox
            className={ classes.dataOptionChkBox }
            key={ adapterId }
            checked={ !disabledAdapters.includes(adapterId) }
            onChange={ onAdapterToggle }
            label={ adapterId }
            value={ adapterId }
          />
        )) }
      </div>
      <Divider
        className={ classes.dividerRankingData }
      /> */ }
      <div>
        <div className={ classes.timeAdjustRow }>
          <h5>Time Adjustment</h5>
          <Select
            size={ 'sm' }
            className={ classes.timeSelector }
            data={ [TimeSliderType.hoursSlider, TimeSliderType.daysSlider] }
            value={ timeSelectionMode }
            onChange = { onChangeTimeSelectionMode }
            required
          />
        </div>

        <p className={ classes.txtInfo }>
          Move the slider (forward and back) to see the effects of time on the probable location
          of people. Note when this menu is closed the people location markers will revert back to their default
          position - i.e. present time UTC.
        </p>
        <Slider
          defaultValue={ timeSliderValuePercentage }
          // className={ timeSelectionMode === DAYSSLIDER ? classes.timeSliderDays : classes.timeSliderHours }
          className={ classes.slider }
          styles={ {
            markWrapper: {
              [`&:nth-of-type(${
                timeSelectionMode === DAYSSLIDER ? 11 : 13
              }) .${CSS_KEY}-Slider-mark`]: { height: 40 }
            },
            bar: { backgroundColor: 'transparent' }
          } }
          labelAlwaysOn
          step={ sliderStep }
          marks={ timeAdjustmentSliderMarks[timeSelectionMode as TimeSliderType] }
          label={ getTimeSliderLabel }
          onChange={ onTimeSliderValueChange }
          value = { timeSliderValuePercentage }
        />
      </div>
    </div>
  );
};

export default RankingSettings;
