import { FeatureCollection } from "geojson";
import { FC } from "react";

import IndoorsMapLayer from "@/tenant-context/plugin-indoors/layers/IndoorsMapLayer";

type Props = {
  site?: FeatureCollection
}

const IndoorsSiteLayer: FC<Props> = ({
  site
}) => {
  if (!site) {
    return null;
  }

  return (
    <IndoorsMapLayer
      indoorsMapData={ site }
    />
  );
};

export default IndoorsSiteLayer;