import { INDOORS_VISIBILITY_MIN_ZOOM } from "@f/map-gl-indoor/IndoorLayer";
import { bbox } from "@turf/turf";
import { Point } from "geojson";
import mapboxgl from "mapbox-gl";
import { FC, useCallback, useEffect } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import useLayerTimer from "@/common/hooks/useLayerTimer";
import { Dispatch, RootState } from "@/core/store";
import useMapPopup from "@/tenant-context/common/hooks/useMapPopup";
import { fitToViewportBounds } from "@/tenant-context/common/util/map";
import { getClickedOnFeatures, getClusteredFeatures, zoomIntoCluster } from "@/tenant-context/common/util/map-click";
import { SITE_INFO_CONTROL_DRAWER_ID } from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import useLocationsMarkerIcons from "@/tenant-context/visualisation-customer-locations/hooks/useLocationsMarkerIcons";
import { SiteLocationSnapshotProperties } from "@/tenant-context/visualisation-site/types/site.types";
import { WidgetSite } from "@/tenant-context/widget-overview/types/site.types";

import { CriticalLocationLayerConfig } from "../../config/CriticalLocationLayerConfig";
import CriticalLocationLayerComponent from "./CriticalLocationLayer.component";


const popupDelayInMilliSeconds = 1000;
const CriticalLocationLayer: FC = () => {
  const {
    setIsPopupShown: setIsSiteNamePopupShown,
    popupCoordinates,
    setPopupCoordinates,
    popupRef: siteNamePopupRef
  } = useMapPopup("MapLocation");

  const {
    isPopupShown: isSiteDetailPopupShown,
    setIsPopupShown: setIsSiteDetailPopupShown,
    popupRef: siteDetailsPopupRef
  } = useMapPopup("MapLocation");

  const popupContent = useSelector((state: RootState) => state.sitePopup.popupContent);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);

  const { current: map } = useMap();

  const {
    drawer: {
      openRightDrawer
    },
    sitePopup: {
      SET_SITE_POPUP_CONTENT
    },
    site: {
      setSiteGeoJson
    },
    commonData: {
      SET_SELECTED_SITE
    }
  } = useDispatch<Dispatch>();

  useLocationsMarkerIcons(map);

  const geoData = useSelector((state: RootState) => state.siteLocations.geoData);
  const siteGeoJson = useSelector((state: RootState) => state.site?.geoJson);

  // Site layer hover handler
  useLayerTimer(
    [
      ...CriticalLocationLayerConfig.map(layer => layer.id)
    ],
    (evt: mapboxgl.MapMouseEvent | mapboxgl.MapLayerMouseEvent) => {
      const { layerFeatures } = getClickedOnFeatures(
        evt,
        [
          ...CriticalLocationLayerConfig.map(layer => layer.id)
        ]
      );

      if (!layerFeatures.length) {
        return;
      }
      const feature = layerFeatures[0];
      const { coordinates } = (feature.geometry as Point);

      setIsSiteNamePopupShown(false);
      SET_SITE_POPUP_CONTENT(feature.properties as SiteLocationSnapshotProperties);
      // if (feature.properties?.siteContactId) {
      //   getSiteContactProfileData(feature.properties?.siteContactId);
      // }

      setPopupCoordinates({
        latitude: coordinates[1],
        longitude: coordinates[0]
      });
      setIsSiteNamePopupShown(true);
    },
    () =>{
      setIsSiteNamePopupShown(false);
    },
    popupDelayInMilliSeconds
  );

  // Site layer click handler
  useLayerListener(
    'click',
    [
      ...CriticalLocationLayerConfig.map(layer => layer.id)
    ],
    useCallback(
      (evt) => {
        const { layerFeatures } = getClickedOnFeatures(evt, [
          ...CriticalLocationLayerConfig.map(layer => layer.id)
        ]);

        if (!layerFeatures.length || !map) {
          return;
        }


        const feature = layerFeatures[0];
        const { coordinates } = (feature.geometry as Point);

        if (feature?.properties?.id) {
          setSiteGeoJson(feature?.properties?.id);
        }
        const selectedSite: WidgetSite = {
          name: feature.properties?.name ?? '',
          location: feature.properties?.countryName ?? '',
          lngLat: [coordinates[0], coordinates[1]],
          id: feature.properties?.id ?? '',
          code: feature.properties?.code ?? '',
          countryName: feature.properties?.countryName ?? '',
          address: JSON.parse(feature.properties?.address) ?? '',
          description: feature.properties?.description ?? '',
          isoCountryCode: feature.properties?.isoCountryCode ?? '',
          imageUrl: feature.properties?.imageUrl ?? '',
          locationType: feature.properties?.locationType ?? '',
          subCategory: feature.properties?.subCategory
        };

        SET_SELECTED_SITE(selectedSite);


        if (map.getZoom() < INDOORS_VISIBILITY_MIN_ZOOM) {
          map.flyTo({
            center: [coordinates[0], coordinates[1]],
            zoom: INDOORS_VISIBILITY_MIN_ZOOM
          });
        }

        setIsSiteNamePopupShown(false);
        setIsSiteDetailPopupShown(false);
        SET_SITE_POPUP_CONTENT(feature.properties as SiteLocationSnapshotProperties);

        setPopupCoordinates({
          latitude: coordinates[1],
          longitude: coordinates[0]
        });

        setIsSiteDetailPopupShown(map.getZoom() >= INDOORS_VISIBILITY_MIN_ZOOM);

        if (
          map.getZoom() >= INDOORS_VISIBILITY_MIN_ZOOM
        ) {
          openRightDrawer(SITE_INFO_CONTROL_DRAWER_ID);
        }

      },
      [
        map,
        openRightDrawer,
        setIsSiteNamePopupShown,
        setIsSiteDetailPopupShown,
        setPopupCoordinates,
        SET_SELECTED_SITE,
        SET_SITE_POPUP_CONTENT,
        setSiteGeoJson
      ]
    )
  );

  // Cluster layer click handler
  useLayerListener(
    'click',
    'r__criticalLocationLayer-cluster',
    useCallback(
      (evt) => {
        if (!map || !geoData) {
          return;
        }

        zoomIntoCluster(
          map,
          evt,
          ['r__criticalLocationLayer-cluster'],
          'r__criticalLocationSource'
        );
      },
      [geoData, map]
    )
  );

  useEffect(() => {
    if (siteGeoJson) {
      const bbox_ = bbox(siteGeoJson) as [number, number, number, number];
      map?.fitBounds(bbox_, { padding: 200 });
      openRightDrawer(SITE_INFO_CONTROL_DRAWER_ID);
    }
  }, [siteGeoJson, map, openRightDrawer]);

  // Cluster layer click handler
  useLayerListener(
    'click',
    'siteLocationSnapshotLayer-cluster',
    useCallback((evt) => {
      if (!map || !geoData) {
        return;
      }

      const featuresInCluster = getClusteredFeatures(map, evt, geoData, ['siteLocationSnapshotLayer-cluster']);

      const coords = featuresInCluster.map((feature) =>
          (feature.geometry as Point).coordinates as [number, number]);

      const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
      coords.forEach((coordinates) => bounds.extend(coordinates));

      fitToViewportBounds(map, bounds);
    }, [map, geoData])
  );

  return (
    <CriticalLocationLayerComponent
      isSiteNamePopupShown={ currentBigMapPopup === "MapLocation" }
      isSiteDetailPopupShown={ isSiteDetailPopupShown }
      popupCoordinates={ popupCoordinates }
      popupContent={ popupContent }
      geoData={ geoData }
      siteNamePopupRef={ siteNamePopupRef }
      siteDetailsPopupRef={ siteDetailsPopupRef }
      siteGeoJson={ siteGeoJson }
    />
  );
};

export default CriticalLocationLayer;
