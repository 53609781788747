export const SituationTypePoliciesConfig = {
  SITUATION_TYPE_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_ICM-Global_SituationType_ReadOnly',
      'Tenant_ICM-Global_SituationType_FullAccess',
      'SystemAdmin_ICM-Global_SituationType_ReadOnly',
      'SystemAdmin_ICM-Global_SituationType_FullAccess'
    ]
  },
  EDIT_SITUATION_TYPE: {
    policyNames: [
      'Tenant_ICM-Global_SituationType_FullAccess',
      'SystemAdmin_ICM-Global_SituationType_FullAccess'
    ]
  }
};