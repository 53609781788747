import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState, store } from "@/core/store";
import { standardizeTimestamp } from '@/tenant-context/common/util/data-standardize';
import {  getReverseGeoLocation } from "@/tenant-context/control-profile/api/mini-profile";

import { createAllAvailableFeedNotificationsStream, createLiveFeedEventStream } from "../api/live-feed";
import { FeedNotification, ReverseGeoLocation } from "../types/live-feed";

type LiveFeedState = {
    isAllAlertsFeedOpen: boolean;
    liveNotificationCount: number;
    liveNotificationList: FeedNotification[];
    allNotificationList: FeedNotification[];
    eventAllQueue: FeedNotification[];
    eventLiveQueue: FeedNotification[];
    isLoadNotificationsListCalled: boolean;
};

export type NonSerializableLiveFeedState = {
  liveFeedStream?: EventSource
  allAvailableFeedStream?: EventSource
};

export const LIVE_FEED_UPDATE_INTERVAL = 500;

const nonSerializableState: NonSerializableLiveFeedState = {};

const liveFeedModel = {
  name: 'liveFeed',
  state: {
    isAllAlertsFeedOpen: false,
    liveNotificationCount: 0,
    liveNotificationList: [],
    allNotificationList: [],
    eventAllQueue: [],
    eventLiveQueue: [],
    isLoadNotificationsListCalled: false
  } as LiveFeedState,
  reducers: {
    SET_ALL_ALERTS_FEED_OPEN: (state: LiveFeedState, isAllAlertsFeedOpen: LiveFeedState['isAllAlertsFeedOpen']) => ({
      ...state,
      isAllAlertsFeedOpen
    }),
    SET_LIVE_NOTIFICATION_COUNT: (state: LiveFeedState, liveNotificationCount: LiveFeedState['liveNotificationCount']) => ({
      ...state,
      liveNotificationCount
    }),
    SET_LIVE_NOTIFICATION_LIST: (state: LiveFeedState, liveNotificationList: LiveFeedState['liveNotificationList']) => ({
      ...state,
      liveNotificationList
    }),
    SET_ALL_NOTIFICATION_LIST: (state: LiveFeedState, allNotificationList: LiveFeedState['allNotificationList']) => ({
      ...state,
      allNotificationList
    }),
    SET_IS_LOAD_NOTIFICATIONS_LIST_CALLED: (state: LiveFeedState, isLoadNotificationsListCalled: LiveFeedState['isLoadNotificationsListCalled']) => ({
      ...state,
      isLoadNotificationsListCalled
    }),
    ENQUEUE_LIVE_ALL_FEED_EVENT({ eventAllQueue, ...state }: LiveFeedState, event: FeedNotification): LiveFeedState {
      return {
        ...state,
        eventAllQueue: [
          ...eventAllQueue,
          event
        ]
      };
    },
    ENQUEUE_LIVE_LIVE_FEED_EVENT({ eventLiveQueue, ...state }: LiveFeedState, event: FeedNotification): LiveFeedState {
      return {
        ...state,
        eventLiveQueue: [
          ...eventLiveQueue,
          event
        ]
      };
    },
    DEQUEUE_EVENTS_ALL(
      state: LiveFeedState,
      dequeuedEvents: FeedNotification[]
    ): LiveFeedState {
      const {
        eventAllQueue
      } = state;

      const dequeuedEventIds = new Set(
        dequeuedEvents.map(({
          notificationId
        }) => notificationId)
      );

      const updatedEventAllQueue = eventAllQueue.filter(
        ({ notificationId }) => !dequeuedEventIds.has(notificationId)
      );

      return {
        ...state,
        eventAllQueue: updatedEventAllQueue
      };
    },
    DEQUEUE_EVENTS_LIVE(
      state: LiveFeedState,
      dequeuedEvents: FeedNotification[]
    ): LiveFeedState {
      const {
        eventLiveQueue
      } = state;

      const dequeuedEventIds = new Set(
        dequeuedEvents.map(({
          notificationId
        }) => notificationId)
      );

      const updatedEventLiveQueue = eventLiveQueue.filter(
        ({ notificationId }) => !dequeuedEventIds.has(notificationId) 
      );

      return {
        ...state,
        eventLiveQueue: updatedEventLiveQueue
      };
    },
    CLOSE_NOTIFICATION_IN_DISPLAY_STACK(
      state: LiveFeedState,
      notificationId: string
    ): LiveFeedState {
      const {
        liveNotificationList
      } = state;

      const updatedLiveNotificationList = liveNotificationList.map(
        notification => {
          if (notification.notificationId === notificationId) {
            return {
              ...notification,
              isClosedInDisplayStack: true
            };
          }

          return notification;
        }
      );

      return {
        ...state,
        liveNotificationList: updatedLiveNotificationList
      };
    }
  },
  effects: (dispatch: Dispatch) => ({

    async subscribeToLiveFeedNotifications(_: void, state: RootState): Promise<void> {
      if (nonSerializableState?.liveFeedStream) {
        nonSerializableState.liveFeedStream.close();
      }

      const stream = await createLiveFeedEventStream(
        state.commonData.tenantId,
        (ev) => {

          dispatch.liveFeed.ENQUEUE_LIVE_LIVE_FEED_EVENT(ev);

          setInterval(() => {
            if (!store.getState().liveFeed.eventLiveQueue.length) {
              return;
            }
    
            dispatch.liveFeed.processEnqueuedLiveFeedLiveEvents();
          }, LIVE_FEED_UPDATE_INTERVAL);

        }
      );

      nonSerializableState.liveFeedStream = stream;
    },

    async loadNotificationsList(_: void, state: RootState): Promise<void> {
      if (nonSerializableState?.allAvailableFeedStream) {
        nonSerializableState.allAvailableFeedStream.close();
      }

      const stream = await createAllAvailableFeedNotificationsStream(
        state.commonData.tenantId,
        (ev) => {          
          dispatch.liveFeed.ENQUEUE_LIVE_ALL_FEED_EVENT(ev);
        }
      );

      setInterval(() => {
        if (!store.getState().liveFeed.eventAllQueue.length) {
          return;
        }

        dispatch.liveFeed.processEnqueuedLiveFeedAllEvents();
      }, LIVE_FEED_UPDATE_INTERVAL);

      nonSerializableState.allAvailableFeedStream = stream;
    },

    async processEnqueuedLiveFeedAllEvents(_: void, state: RootState): Promise<void> {
      const {
        liveFeed: {
          eventAllQueue
        }
      } = state;

      if (!eventAllQueue.length) {
        return;
      }

      const eventsToProcess = [...eventAllQueue];

      const filteredallNotificationList = [...state.liveFeed.allNotificationList].filter(
        notification => notification.notificationId !== eventsToProcess[0].notificationId
      );

      const getTimeDifference = (timeStamp: number): number => {
        const standardizeTimeStamp = standardizeTimestamp(timeStamp);
        const now = new Date().getTime();
        const eventTime = new Date(standardizeTimeStamp).getTime();
        return Math.abs(now - eventTime);
      };

      const updatedAllNotificationList = [...filteredallNotificationList, ...eventsToProcess].sort(
        (a, b) => getTimeDifference(a.openedDataTime) - getTimeDifference(b.openedDataTime)
      ).filter(notification => notification.profileId !== null || notification.impactInfo !== null);

      dispatch.liveFeed.SET_ALL_NOTIFICATION_LIST(updatedAllNotificationList);

      dispatch.liveFeed.DEQUEUE_EVENTS_ALL(eventsToProcess);
      
    },

    async processEnqueuedLiveFeedLiveEvents(_: void, state: RootState): Promise<void> {
      const {
        liveFeed: {
          eventLiveQueue
        }
      } = state;

      if (!eventLiveQueue.length) {
        return;
      }

      const eventsToProcess = [...eventLiveQueue];

      const updatedLiveNotificationList = [...state.liveFeed.liveNotificationList, ...eventsToProcess].sort(
        (a, b) => b.openedDataTime - a.openedDataTime
      ).filter(notification => notification.profileId !== null || notification.impactInfo !== null);

      dispatch.liveFeed.SET_LIVE_NOTIFICATION_COUNT(updatedLiveNotificationList.length);

      dispatch.liveFeed.SET_LIVE_NOTIFICATION_LIST(updatedLiveNotificationList);

      dispatch.liveFeed.DEQUEUE_EVENTS_LIVE(eventsToProcess);

    },

    removeNotificationFromLiveList(notificationId: string, state: RootState): void {
      const {
        liveFeed:{
          liveNotificationList,
          liveNotificationCount
        }
      } = state;

      const updatedLiveNotificationList = liveNotificationList.filter(
        (notification) => notification.notificationId !== notificationId
      );

      dispatch.liveFeed.SET_LIVE_NOTIFICATION_LIST(updatedLiveNotificationList);
      dispatch.liveFeed.SET_LIVE_NOTIFICATION_COUNT(liveNotificationCount - 1);
    },

    async getReverseGeoLocation(
      locationDetails: { lon: number, lat: number }
    ): Promise<ReverseGeoLocation> {

      const location = await getReverseGeoLocation(
        locationDetails.lon.toString(),
        locationDetails.lat.toString()
      );


      return location; 
    }
  })
};

export const liveFeed = createModel<RootModel>()(liveFeedModel);
