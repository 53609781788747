import { Checkbox, Divider, Switch } from "@mantine/core";
import React, { FC } from "react";

import { ReactComponent as BSOC } from "@/common/icons/bsoc.svg";
import { ReactComponent as CriticalRiskIcon } from "@/common/icons/bsocRiskLevelCriticalIcon.svg";
import { ReactComponent as NonCriticalRiskIcon } from "@/common/icons/bsocRiskLevelNonCriticalIcon.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as HighImpactExplosiveActivity } from "@/common/icons/HighImpactExplosiveActivity.svg";
import { ReactComponent as IDFUAVActivity } from "@/common/icons/IDFUAVActivity.svg";
import { ReactComponent as IED } from "@/common/icons/IED.svg";
import { ReactComponent as RiskInfoIcon } from "@/common/icons/riskInfoIcon.svg";
import { ReactComponent as SAF } from "@/common/icons/SAF.svg";
import { ReactComponent as SecurityOperations } from "@/common/icons/SecurityOperations.svg";
import { ReactComponent as ProtestsAndGatherings } from "@/common/icons/unrest.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import {
  BSOCEventsSettingsStyles
} from "@/tenant-context/control-risk-config/components/BSOCEventsSettings/BSOCEventsSettings.styles";
import { Sigact } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";

type Props = {
  onCategoryToggle: React.ChangeEventHandler<HTMLInputElement>
  disabledRiskFilterTypes: string[],
  onChangeGlobalCategoryToggle: React.ChangeEventHandler<HTMLInputElement>,
  isGlobalCategoryToggleOn: boolean
  onImpactRiskEventsToggleChange: React.ChangeEventHandler<HTMLInputElement>,
  isShowOnlyImpactedAlerts: boolean
  riskLevelFilters: {
    nonCritical: boolean
    critical: boolean
  }
  onRiskLevelToggle: React.ChangeEventHandler<HTMLInputElement>
}

const BSOCEventsSettings: FC<Props> = ({
  onCategoryToggle,
  disabledRiskFilterTypes,
  onChangeGlobalCategoryToggle,
  isGlobalCategoryToggleOn,
  onImpactRiskEventsToggleChange,
  isShowOnlyImpactedAlerts,
  riskLevelFilters,
  onRiskLevelToggle
}) => {
  const riskCategories = [
    {
      id: 'SAF',
      name: 'Small Arms Fire',
      Icon: SAF,
      categoryId: Sigact.SAF
    },
    {
      id: 'Crime',
      name: 'Crime',
      Icon: Crime,
      categoryId: Sigact.Crime
    },
    {
      id: 'ProtestsAndGatherings',
      name: 'Protest & Gatherings',
      Icon: ProtestsAndGatherings,
      categoryId: Sigact.ProtestsAndGatherings
    },
    {
      id: 'IED',
      name: 'IED & Incendiary Devices',
      Icon: IED,
      categoryId: Sigact.IED
    },
    {
      id: 'HighImpactExplosiveActivity',
      name: 'High Impact IED Activity',
      Icon: HighImpactExplosiveActivity,
      categoryId:Sigact.HighImpactExplosiveActivity
    },
    {
      id: 'IDFUAVActivity',
      name: 'IDF & UAV Activity',
      Icon: IDFUAVActivity,
      categoryId: Sigact.IDFUAVActivity
    },
    {
      id: 'SecurityOperations',
      name: 'Security Operations',
      Icon: SecurityOperations,
      categoryId:Sigact.SecurityOperations
    }];


  const riskLevels = [
    {
      id: 'critical',
      name: 'Critical event',
      Icon: CriticalRiskIcon,
      riskLevelFilter: riskLevelFilters.critical
    },
    {
      id: 'nonCritical',
      name: 'Non-Critical event',
      Icon: NonCriticalRiskIcon,
      riskLevelFilter: riskLevelFilters.nonCritical
    }
  ];
  const { classes, cx } = BSOCEventsSettingsStyles();

  return (
    <div className={ classes.riskEventSettingsContainer }>
      <div className={ classes.bsocRiskAlertSwitchContent }>
        <div className={ classes.bsocRiskAlertIconNDescription }>
          <BSOC className={ classes.bsocIcon }/>
          <p className={ classes.bsocRiskAlertDescription }>BSOC Risk Alerts</p>
        </div>

        <Switch className={ classes.riskAlertGlobalSwitch } onChange = { onChangeGlobalCategoryToggle }
          checked={ isGlobalCategoryToggleOn } />
      </div>

      <h1 className={ classes.sectionHeading }>Filter by Risk Category</h1>

      <ul className={ classes.orderList }>
        { riskCategories.map(({
          Icon, id, name,categoryId
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.bsocRiskAlertIconNDescription }>
              <Icon color={ genericColorPalette.neutral[7] }/>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked = { !disabledRiskFilterTypes.includes(categoryId.toString()) }
              onChange={ onCategoryToggle } value={ id }/>
          </li>
        )) }
      </ul>

      <Divider
        className={ classes.divider }
      />

      <div className={ classes.bsocRiskAlertSubTopic }>Filter by Risk Rating Level</div>

      <ul className={ cx(classes.orderList, classes.riskLevelsMargin) }>
        { riskLevels.map(({
          Icon, id, name, riskLevelFilter
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.bsocRiskAlertIconNDescription }>
              <Icon/>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked = { riskLevelFilter }
              onChange={ onRiskLevelToggle } value={ id }/>
          </li>
        )) }
      </ul>

      <Checkbox
        checked={ isShowOnlyImpactedAlerts }
        className={ classes.impactCalculationChkBox }
        onChange={ onImpactRiskEventsToggleChange }
        label={  <span className={ classes.checkBoxLabel }>
          <RiskInfoIcon/>
          Only show Risk Events impacting my assets and people</span> }
      />

      <Divider
        className={ classes.divider }
      />
    </div>
  );
};

export default BSOCEventsSettings;
