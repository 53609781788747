/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import { RootState } from "@/core/store";
import { unshiftDefaultItem } from '@/tenant-context/common/util/profile-reference-data';
import { PersonalDetailsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/PersonalDetailsModal/context/PersonalDetailsModal.context";
import { useEmergencyContactModalContentStyles } from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileGeneral } from "@/tenant-context/control-profile/types/profile";

export type PersonalDetailsModalFormData = Pick<
  ProfileGeneral,
  | 'title'
  | 'firstName'
  | 'middleNames'
  | 'lastName'
  | 'alternateName'
  | 'initials'
  | 'gender'
  | 'nationality'
  | 'countryOfResidenceRef'
> & {
  dateOfBirth: Date
};

interface Props{
  handleCancelConfirmation?: ()=> void
}

const PersonalDetailsModal: FC<Props> = ({ handleCancelConfirmation }: Props) => {
  const { classes } = useEmergencyContactModalContentStyles();
  const {
    onSubmit,
    formControls: { register, formState: { errors, isValid, isDirty }, setValue  }
  } = useContext(PersonalDetailsModalContext);

  const general = useSelector((state: RootState) => state.profile.general);
  const isAddNewProfile = useSelector((state: RootState) => state.profile?.isAddNewProfile);
  const referenceData = useSelector((state: RootState) => state.profile.referenceData);

  const titles = useMemo(() => {
    return unshiftDefaultItem(referenceData?.titles?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const nationality = useMemo(() => {
    return unshiftDefaultItem(referenceData?.nationality?.map((country) => {
      return { value: country?.name, label: country?.name };
    }));
  }, [referenceData]);

  const countries = useMemo(() => {
    return unshiftDefaultItem(referenceData?.country?.map((country) => {
      return { value: country?.name, label: country?.name };
    }));
  }, [referenceData]);

  const genders = useMemo(() => {
    return unshiftDefaultItem(referenceData?.gender?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);


  const handleTitleChange = useCallback((val) => {
    setValue('title', val, { shouldDirty: true });
  }, [setValue]);

  const handleGenderChange = useCallback((val) => {
    setValue('gender', val, { shouldDirty: true });
  }, [setValue]);

  const handleBirthDateChange = useCallback((val) => {
    setValue('dateOfBirth', val, { shouldDirty: true });
  }, [setValue]);

  const handleNationalityChange = useCallback((val) => {
    setValue('nationality', val, { shouldDirty: true });
  }, [setValue]);

  const handleCountryOfResidenceChange = useCallback((val) => {
    setValue('countryOfResidenceRef', val, { shouldDirty: true });
  }, [setValue]);

  const defaultDateOfBirth = (() => {
    const { dateOfBirth } = general || {};

    if (!dateOfBirth) {
      return null;
    }

    const [year, month, day] = dateOfBirth.split('-');

    return new Date(Number(year), Number(month) - 1, Number(day));
  })();

  useEffect(() => {
    if (!defaultDateOfBirth) {
      return;
    }

    register('dateOfBirth', addErrorMessages('Date of Birth', {
      required: false
    }));

    setValue('dateOfBirth', defaultDateOfBirth);
  }, [defaultDateOfBirth, register, setValue]);

  if (!general && !isAddNewProfile) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <form onSubmit={ onSubmit }>
      <ProfileLoadingOverlay/>
      <div className={ classes.grid }>
        <Select
          { ...register('title') }
          searchable
          data={ titles ? titles : [] }
          label="Title"
          defaultValue={ general?.title }
          error={ errors?.title?.message }
          onChange={ handleTitleChange }

        />

        <TextInput
          label="First Name *"
          defaultValue={ general?.firstName }
          error={ errors?.firstName?.message }
          { ...register('firstName', addErrorMessages('First Name', {
            required: true,
            maxLength: 128
          })) }
        />

        <TextInput
          label="Middle Names"
          defaultValue={ general?.middleNames }
          error={ errors?.middleNames?.message }
          { ...register('middleNames', addErrorMessages('Middle Names', {
            maxLength: 256
          })) }
        />

        <TextInput
          label="Last Name *"
          defaultValue={ general?.lastName }
          error={ errors?.lastName?.message }
          { ...register('lastName', addErrorMessages('Last Name', {
            required: true,
            maxLength: 128
          })) }
        />

        <TextInput
          label="Alternate Name"
          defaultValue={ general?.alternateName }
          error={ errors?.alternateName?.message }
          { ...register('alternateName', addErrorMessages('Alternate Name', {
            maxLength: 256
          })) }
        />

        <TextInput
          label="Initials"
          defaultValue={ general?.initials }
          error={ errors?.initials?.message }
          { ...register('initials', addErrorMessages('Initials', {
            maxLength: 256
          })) }
        />

        <DatePicker
          defaultValue={ defaultDateOfBirth }
          error={ errors?.dateOfBirth?.message }
          label="Date of Birth"
          onChange={ handleBirthDateChange }
          maxDate={ dayjs(new Date()).subtract(1, 'days').toDate() }
        />

        <Select
          { ...register('gender') }
          searchable
          onChange={ handleGenderChange }
          data={ genders ? genders : [] }
          label="Gender"
          defaultValue={ general?.gender }
          error={ errors?.gender?.message }
        />

        <Select
          { ...register('nationality', addErrorMessages('Nationality', {
            required: false
          })) }
          searchable
          onChange={ handleNationalityChange }
          data={ nationality ? nationality : [] }
          label="Nationality"
          defaultValue={ general?.nationality }
          error={ errors?.nationality?.message }
        />

        <Select
          { ...register('countryOfResidenceRef', addErrorMessages('Country of Residence', {
            required: false
          })) }
          searchable
          onChange={ handleCountryOfResidenceChange }
          data={ countries ? countries : [] }
          label="Country of Residence"
          defaultValue={ general?.countryOfResidenceRef }
          error={ errors?.countryOfResidenceRef?.message }
        />

        <Checkbox
          label="Is Active"
          defaultChecked={ general ? general.active : true }
          { ...register('active') }
        />
      </div>

      <ModalControlGroup onSecondary={ handleCancelConfirmation ?? undefined }
        primaryButtonDisabled={ !isValid || !isDirty }
        isCancelAdditional={ handleCancelConfirmation !== undefined }/>
    </form>
  );
};

export default PersonalDetailsModal;
