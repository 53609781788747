import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { PaginatedResult, ProfileGeneral } from "@/tenant-context/control-profile/types/profile";
import {
  getCurrentTenantDetails,
  getCurrentUsersPermissionsPolicies,
  getTenantUser
} from "@/tenant-context/navigation/api/navigation";
import { TenantDetails, TenantUser, UserPolicy } from "@/tenant-context/navigation/types/user";

import NavigationSidebar from './NavigationSidebar.component';

type NavigationSidebarContextValue = {
  isExpanded: boolean;
  setIsExpanded: (isNavExpanded: boolean) => void;
  usersPolicies: PaginatedResult<UserPolicy> | undefined;
  tenantDetails: TenantDetails | undefined;
  userDetails: TenantUser | undefined;
  profileDetails: ProfileGeneral | undefined;
}

export const NavigationSidebarContext = createContext<NavigationSidebarContextValue>(
  {} as NavigationSidebarContextValue
);

export const NavigationSidebarContextProvider: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [usersPolicies, setUsersPolicies] = useState<PaginatedResult<UserPolicy>>();
  const [tenantDetails, setTenantDetails] = useState<TenantDetails>();
  const [userDetails, setUserDetails] = useState<TenantUser>();
  const [profileDetails, setProfileDetails] = useState<ProfileGeneral>();

  const loggedUserInfo = useSelector(({
    profile:{
      loggedUser
    }
  }: RootState) => loggedUser);

  const {
    userProfile: {
      SET_CURRENT_USER_EMAIL
    },
    profile: {
      setLoggedUser
    },
    commonData: {
      SET_IS_NAVIGATION_EXPANDED
    }
  } = useDispatch<Dispatch>();

  const handleNavigationExpand = useCallback((isNavExpanded: boolean) => {
    setIsExpanded(isNavExpanded);
    SET_IS_NAVIGATION_EXPANDED(isNavExpanded);
  }, [ SET_IS_NAVIGATION_EXPANDED ]);

  useEffect(() => {
    getCurrentUsersPermissionsPolicies().then(
      (response) => response && setUsersPolicies(response)
    );
  }, []);

  useEffect(() => {
    getCurrentTenantDetails().then(
      (response) => {
        if(response){
          setTenantDetails(response);
        }
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTenantUser().then((response) => {
      if (!response) {
        return;
      }

      const { email } = response;

      SET_CURRENT_USER_EMAIL(email);

      setUserDetails(response);

      // If no profile linked => no picture
      if (!response.profileRef) {
        return;
      }
      setLoggedUser(response.profileRef);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    setProfileDetails(loggedUserInfo);
  }, [loggedUserInfo]);

  return (
    <NavigationSidebarContext.Provider value={ {
      isExpanded,
      setIsExpanded: handleNavigationExpand,
      usersPolicies,
      tenantDetails,
      userDetails,
      profileDetails
    } }>
      <NavigationSidebar/>
    </NavigationSidebarContext.Provider>
  );
};

export default NavigationSidebarContextProvider;
