import { callApi } from "@/common/util/api";
import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";
import { AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { ViewportBbox } from "@/tenant-context/control-draw/types/draw";
import { RankingOption } from "@/tenant-context/visualisation-people/store/people-locations/people-locations.model";
import { PersonCurrentTravelInfo, PersonLocationSnapshot } from "@/tenant-context/visualisation-people/types/people.types";
import { LocationsPeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

export const getPeopleLocationSnapshotsForBounds = (bounds: ViewportBbox) => callApi<{
  peopleLocationSnapshots: PersonLocationSnapshot[]
}>(
  'mockapi/peopleLocationSnapshots',
  {
    method: 'post',
    body: JSON.stringify({
      points: bounds,
      dateBand: {
        from: new Date(0).getTime(),
        to: new Date().getTime()
      }
    })
  }
);

export type PeopleLocationStreamUrlParams = {
  rankRequestTime: string,
  rankingOption: RankingOption,
  tenantId: string
};

export const createPeopleLocationStream = (
  handleEvent: SubscriptionEventHandler<AssetRankingSummary | LocationsPeopleCount>,
  params: PeopleLocationStreamUrlParams
) => createServerSentEventStream(
  '/asset-ranking-service/v1/stream/location-engine/people?' + new URLSearchParams(params),
  handleEvent
);

export const getCurrentTravelItenerary = (segmentId: string) => callApi<PersonCurrentTravelInfo>(
  `/travel-book-adapter-service/v1/travel-books/segments/${segmentId}`,
  {
    method: 'GET'
  }
);
