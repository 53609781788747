import { FeatureCollection, GeoJsonProperties, LineString, Position } from "geojson";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import useLayerClickOutside from "@/common/hooks/useLayerClickOutside";
import useLayerListener from "@/common/hooks/useLayerListener";
import { RootState } from "@/core/store";
import { zoomIntoCluster } from "@/tenant-context/common/util/map-click";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";
import PeopleLocationLayerComponent
  from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.component";
import {
  LWPersonStatus, PERSON_ALL_ALERT_STATUSES
} from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";
import { createLinesBetweenPopupAndRiskPoint } from "@/tenant-context/visualisation-risk-alerts/util/getRiskAlertPopupLine";

const PeopleLocationLayer: FC = () => {
  const { current: map } = useMap();
  const {
    handlePersonLocationHover,
    handlePersonLocationLeave,
    handlePersonLocationClick,
    hoveredOnPerson,
    calculatePopupOffset,
    lineEndCoordinates,
    handleClickOutside
  } = useContext(PeopleContext);

  const disabledAdapters = useSelector((state: RootState) => state.rankingSettings.disabledAdapters);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);
  const isPopupLineEnabled = currentBigMapPopup === "PersonLocation";

  const minRankThreshold = useSelector(
    (state: RootState) => state.rankingSettings.probabilityThresholdMin
  );

  const maxRankThreshold = useSelector(
    (state: RootState) => state.rankingSettings.probabilityThresholdMax
  );

  const isMinRankThresholdApplied = useSelector(
    (state: RootState) => state.rankingSettings.isApplyProbabilityThreshold
  );

  const geoData = useSelector((state: RootState) => state.peopleLocations.geoData);

  const generalGeoData = useMemo(() => {

    const filteredFeatures = geoData?.features.filter((feature) => {
      if (feature.properties?.topRank?.loneWorker?.status) {
        return !PERSON_ALL_ALERT_STATUSES.includes(
          feature.properties?.topRank?.loneWorker?.status as LWPersonStatus
        );
      } else {
        return true;
      }
    });

    return { ...geoData, features: filteredFeatures };

  }, [geoData]);

  const alarmGeoData = useMemo(() => {

    const filteredFeatures = geoData?.features.filter((feature) => {
      if (feature.properties?.topRank?.loneWorker?.status) {
        return PERSON_ALL_ALERT_STATUSES.includes(
          feature.properties?.topRank?.loneWorker?.status as LWPersonStatus
        );
      } else {
        return false;
      }
    });

    return { ...geoData, features: filteredFeatures };

  }, [geoData]);
  const [popupLine, setPopupLine] = useState<FeatureCollection<LineString, GeoJsonProperties>>();

  // People layer hover handler
  useLayerListener(
    'mouseenter',
    'r__peopleLocationSnapshotLayer',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseenter',
    'r__peopleLocationAlarmSnapshotLayer',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseleave',
    'r__peopleLocationSnapshotLayer',
    handlePersonLocationLeave
  );

  useLayerListener(
    'mouseleave',
    'r__peopleLocationAlarmSnapshotLayer',
    handlePersonLocationLeave
  );

  // People layer click handler
  useLayerListener(
    'click',
    'r__peopleLocationSnapshotLayer',
    handlePersonLocationClick
  );

  useLayerListener(
    'click',
    'r__peopleLocationAlarmSnapshotLayer',
    handlePersonLocationClick
  );

  useLayerClickOutside(
    'r__peopleLocationSnapshotLayer',
    handleClickOutside
  );

  useLayerClickOutside(
    'r__peopleLocationAlarmSnapshotLayer',
    handleClickOutside
  );

  // Cluster layer click handler
  useLayerListener(
    'click',
    'r__peopleLocationSnapshotLayer-cluster',
    useCallback(
      (evt) => {
        if (!map || !geoData) {
          return;
        }

        zoomIntoCluster(
          map,
          evt,
          ['r__peopleLocationSnapshotLayer-cluster'],
          'r__peopleLocationSource'
        );
      },
      [geoData, map]
    )
  );

  useLayerListener(
    'click',
    'r__peopleLocationAlarmSnapshotLayer-cluster',
    useCallback(
      (evt) => {
        if (!map || !geoData) {
          return;
        }

        zoomIntoCluster(
          map,
          evt,
          ['r__peopleLocationAlarmSnapshotLayer-cluster'],
          'r__peopleLocationAlarmSource'
        );
      },
      [geoData, map]
    )
  );

  useEffect(() => {
    if (!isPopupLineEnabled) {
      return;
    }

    if (!lineEndCoordinates) {
      setPopupLine(undefined);
      return;
    }

    const personLocation = hoveredOnPerson?.location.point;

    if(!personLocation){
      return;
    }
    const lines = createLinesBetweenPopupAndRiskPoint(
      [personLocation.lon, personLocation.lat ]as Position,
      [lineEndCoordinates?.longitude, lineEndCoordinates?.latitude] as Position
    );

    setPopupLine(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hoveredOnPerson?.location.point,
    lineEndCoordinates,
    lineEndCoordinates?.latitude,
    lineEndCoordinates?.longitude
  ] );


  useEffect(() => {
    if (!lineEndCoordinates) {
      return;
    }
    if(map){
      map.on(
        'move',
        calculatePopupOffset
      );
    }

    return () => {
      map?.off(
        'move',
        calculatePopupOffset
      );
    };
  }, [calculatePopupOffset, lineEndCoordinates, map]);

  return (
    <PeopleLocationLayerComponent
      isArcModeOn={ false }
      geoData={ generalGeoData }
      alarmGeoData={ alarmGeoData }
      disabledAdapters={ disabledAdapters }
      minRankThreshold={ minRankThreshold }
      maxRankThreshold={ maxRankThreshold }
      isMinRankThresholdApplied={ isMinRankThresholdApplied }
      lineToPopup={ popupLine }
      mapRef={ map }
    />
  );
};

export default PeopleLocationLayer;
