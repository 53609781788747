import { useCallback, useContext, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import AddBuildingFormComponent
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/AddBuildingForm/AddBuildingForm.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-management/context/LocationInfo.context";
import { Building } from "@/tenant-context/control-location-management/types/ManageLocations.types";

const AddBuildingForm = () => {

  const { manageLocations: {
    postCreateBuilding
  } } = useDispatch<Dispatch>();

  const {
    setIsAddBuildingFormOpened,
    isAddBuildingFormOpened,
    isAuthorizedToEdit
  } = useContext(LocationInfoContext);

  const activeBuilding = useSelector((state: RootState) => state.manageLocations?.activeBuilding);

  const formControls = useForm({ mode: 'onChange' });

  const handleFormSubmit = useCallback((data: FieldValues) => {
    postCreateBuilding({
      ...data,
      name: data.name.trim().toUpperCase(),
      code: data.code.trim().toUpperCase()
    } as Building).then((res) => {
      if (res) {
        setIsAddBuildingFormOpened(false);
      }
    });
  }, [postCreateBuilding, setIsAddBuildingFormOpened]);

  const handleFormClose = useCallback(() => {
    formControls.reset();
    setIsAddBuildingFormOpened(false);
  }, [formControls, setIsAddBuildingFormOpened]);

  useEffect(() => {
    if ((activeBuilding?.geoPoint?.lon || activeBuilding?.geoPoint?.lat) && isAddBuildingFormOpened) {
      formControls.setValue('geoPoint.lat', activeBuilding?.geoPoint?.lat, { shouldValidate: true });
      formControls.setValue('geoPoint.lon', activeBuilding?.geoPoint?.lon, { shouldValidate: true });
    }
  }, [activeBuilding?.geoPoint, formControls, isAddBuildingFormOpened]);

  return (
    <AddBuildingFormComponent
      onSubmit={ handleFormSubmit }
      formControls={ formControls }
      onClose={ handleFormClose }
      activeBuilding={ activeBuilding }
      isAuthorizedToEdit={ isAuthorizedToEdit }
    />
  );
};

export default AddBuildingForm;
