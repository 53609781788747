import React, { FC, useCallback } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ToggleableLayerType } from '@/common/components/ToggleableLayer/ToggleableLayer.config';
import { ReactComponent as DataOptionsIcon } from '@/common/icons/data-options.svg';
import { TestableComponent } from "@/common/types/testable-component";
import { AssetLocationLayers } from "@/tenant-context/core/controls/DataOptionsControl/components/AssetLocationLayers.components";
import { MainLayers } from "@/tenant-context/core/controls/DataOptionsControl/components/MainLayers.component";
import { useDataOptionsControlStyles } from '@/tenant-context/core/controls/DataOptionsControl/DataOptionsControl.styles';

export type LayerType = {
  type: ToggleableLayerType,
  label: string,
  Icon?: React.FunctionComponent<React.SVGProps<
    SVGSVGElement
  > & { title?: string }>,
  image?: string,
  isNavigable?: boolean
};

type Props = {
  disabledLayers: string[],
  onLayerToggle: React.ChangeEventHandler<HTMLInputElement>,
  isInSubPage: boolean,
  onPageSwitch?: () => void,
} & TestableComponent;

const BasemapLayerToggleControl: FC<Props> = ({
  onLayerToggle,
  disabledLayers,
  isInSubPage,
  onPageSwitch,
  dataTestId = "right-menu-layers"
}) => {
  const renderTestDrawerButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    <ButtonControl label='Business Data' onClick={ onClick } 
      data-testid={ dataTestId }
    >
      <DataOptionsIcon />
    </ButtonControl>
  ), [dataTestId]);

  const { classes } = useDataOptionsControlStyles();

  return (
    <DrawerControl
      id={ 'data-options-control' }
      title={ 'Business Data' }
      explainer={ !isInSubPage ? 'Select and deselect the layers data you wish to visualise on the map.' : undefined }
      isSubDrawer={ isInSubPage }
      onBackButtonClick={ onPageSwitch && onPageSwitch }
      renderButton={ renderTestDrawerButton }
    >
      { !isInSubPage && (
        <MainLayers
          classes={ classes }
          disabledLayers={ disabledLayers }
          onLayerToggle={ onLayerToggle }
          onPageSwitch={ onPageSwitch }
        />
      ) }

      { isInSubPage && (
        <AssetLocationLayers
          classes={ classes }
          disabledLayers={ disabledLayers }
          onLayerToggle={ onLayerToggle }
        />
      ) }
    </DrawerControl>
  );
};

export default BasemapLayerToggleControl;
