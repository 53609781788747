import { Button } from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { FC } from "react";
import { FileRejection } from "react-dropzone";

import { ReactComponent as DownloadIcon } from "@/common/icons/downloadFile.svg";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import {
  AllowedSitePlanUploadFileTypes
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/SitePlanTab.config";
import {
  MAX_SITE_PLAN_UPLOAD_SIZE
} from "@/tenant-context/control-location-management/config/location-management.config";

type Props = {
  uploadedFile: FileWithPath | null;
  handleSaveUploadedFile: () => void;
  uploadedFileError: FileRejection | null;
  onUploadCancelButtonClick: () => void
  onDropFileUpload: (file: FileWithPath[]) => void
  onRejectFileUpload: (file: FileRejection[]) => void
}
const FloorUploadComponent: FC<Props> = ({
  uploadedFile,
  handleSaveUploadedFile,
  uploadedFileError,
  onUploadCancelButtonClick,
  onDropFileUpload,
  onRejectFileUpload
})  => {

  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <div className={ classes.uploadFileContainer }>
      <Dropzone
        multiple={ false }
        style={ { background: 'none' } }
        onDrop={ onDropFileUpload }
        onReject={ onRejectFileUpload }
        accept={ AllowedSitePlanUploadFileTypes }
        maxSize={ MAX_SITE_PLAN_UPLOAD_SIZE }
      >
        <div className={ classes.optionContent }>
          <DownloadIcon/>
        &nbsp;
          <span>Drag & drop your file here or browse to upload</span>
        &nbsp;
          <span> Maximum file size: 2mb</span>
          <span>Valid file types: JSON, GeoJSON</span>
          { uploadedFile?.name &&
          (<span className={ classes.uploadedFileMessage }>Uploaded file: { uploadedFile?.name }</span>) }
          { uploadedFileError?.errors &&
          (<span className={ classes.errorMessage }>Error: { uploadedFileError?.errors?.[0].message }</span>) }
        </div>
      </Dropzone>
      <div className={ classes.buttonContainer }>
        <Button size="md" variant="outline" onClick={ onUploadCancelButtonClick }>Cancel</Button>
        <Button
          onClick={ handleSaveUploadedFile }
          size="md"
          disabled={ !uploadedFile }
        >
          Upload File
        </Button>
      </div>
    </div>
  );
};

export default FloorUploadComponent;
