import { Select } from "@mantine/core";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { ReactComponent as SearchIcon } from "@/common/icons/search.svg";
import { Dispatch, RootState } from "@/core/store";
import SelectItem from "@/tenant-context/widget-overview/components/MapSearch/components/SelectItem";
import { useMapSearchStyles } from "@/tenant-context/widget-overview/components/MapSearch/MapSearch.styles";

type Props = {
  service?: 'map-service' | 'lookup-service';
  isFullWidth?: boolean;
  label?: string;
  disabled?: boolean
}

const MapSearch: FC<Props> = ({
  service= 'map-service',
  isFullWidth = false,
  disabled = false
}) => {

  const { classes } = useMapSearchStyles({ isFullWidth });
  const suggestions = useSelector((state: RootState) => state.mapSearch?.suggestions);
  const activeLocation = useSelector((state: RootState) => state.mapSearch?.activeLocation);

  const [value, setValue] = useState<string | null>();
  const [searchValue, onSearchChange] = useState('');

  const {
    mapSearch: {
      loadSuggestions,
      loadFoundLocation
    }
  } = useDispatch<Dispatch>();

  const map = useMap().current;

  const debouncedLoadSuggestions = useDebouncedCallback((valueToSearch: string) => {
    loadSuggestions({
      location: valueToSearch,
      service
    });
  }, 500);

  const disableSelectSearchFilter = useCallback(() => {
    return true;
  }, []);

  const suggestionsData = useMemo(() => {
    if (!searchValue) {
      return [];
    }
    if (suggestions) {
      return suggestions?.map(suggestion => {
        return {
          value: suggestion.mapbox_id,
          label: suggestion.name,
          description: suggestion.full_address
        };
      });
    } else {
      return [];
    }
  }, [suggestions, searchValue]);

  const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    if (suggestionsData && suggestionsData.length > 0) {
      setValue(suggestionsData[0].value);
    }
  }, [suggestionsData]);

  useEffect(() => {
    if (!searchValue || searchValue.length === 0) {
      return;
    } else {
      debouncedLoadSuggestions(searchValue);
    }
  }, [ searchValue, loadSuggestions, debouncedLoadSuggestions ]);

  useEffect(() => {
    if (!value) {
      return;
    }
    loadFoundLocation({
      mapboxId: value,
      service: service
    });
  }, [value, loadFoundLocation, service]);


  useEffect(() => {
    if (!activeLocation || !map) {
      return;
    }
    map?.flyTo({
      center: {
        lng: activeLocation?.features[0]?.properties?.coordinates.longitude,
        lat: activeLocation?.features[0]?.properties?.coordinates.latitude
      },
      zoom: 12
    });

  }, [activeLocation, map]);

  return (
    <Select
      className={ classes.searchInput }
      icon={ <SearchIcon/> }
      value={ value }
      itemComponent={ SelectItem }
      maxDropdownHeight={ 500 }
      onChange={ setValue }
      placeholder="Search"
      data={ suggestionsData }
      searchable
      onSearchChange={ onSearchChange }
      onKeyPress={ handleKeyPress }
      filter={ disableSelectSearchFilter }
      nothingFound="Search for addresses, places & poi"
      size="lg"
      disabled={ disabled }
    />
  );
};

export default MapSearch;
