import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";

export const DEFAULT_MAP_URL = 'mapbox://styles/smehmood/cl4feh10w000114pyksvp6mu9';

type DevtoolsData = {
  mapUrl: string
};

const devtoolsModel = {
  name: 'devtools',
  state: {
    mapUrl: DEFAULT_MAP_URL
  } as DevtoolsData,
  reducers: {
    SET_MAP_URL(
      state: DevtoolsData,
      mapUrl?: string
    ) {
      return {
        ...state,
        mapUrl: mapUrl || DEFAULT_MAP_URL
      };
    }
  }
  // effects: (dispatch: Dispatch) => ({
  // })
};

export const devtools = createModel<RootModel>()(devtoolsModel);
