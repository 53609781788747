import { ColDef } from "ag-grid-community";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, ForwardRefRenderFunction } from "react";

import DataGrid from "@/common/components/AgGrid";
import {
  useUploadedFilesListStyles
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/UploadedFilesList/styles";
import { SitePlanList } from "@/tenant-context/control-location-management/types/ManageLocations.types";

type Props = {
  columnDefs: ColDef[],
  data: SitePlanList[]
  handleRowClick: (e: RowClickedEvent<SitePlanList>) => void
}

const UploadedFilesList: ForwardRefRenderFunction<AgGridReact, Props> = ({
  columnDefs,
  data,
  handleRowClick
}, ref) => {

  const { classes }= useUploadedFilesListStyles();
  return (
    <div className={ classes.tabContainer }>
      <div className="ag-theme-alpine-dark ag-theme-rt">
        <DataGrid
          id={ "uploaded-files-grid" }
          columnDefs={ columnDefs }
          ref={ ref }
          data={ data }
          pagination={ false }
          suppressCellFocus={ true }
          onRowClicked={ handleRowClick }
          domLayout='autoHeight'
        />
      </div>
    </div>
  );
};

export default forwardRef(UploadedFilesList) as (
  props: Props & React.RefAttributes<AgGridReact>
) => JSX.Element;

