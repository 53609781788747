import { createModel } from "@rematch/core";

import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import {
  createGroup,
  deleteProfileGroup,
  getAllGroups,
  getAllServices, getPeopleGroup, getProfiles, postBulkProfileAddToPeopleGroup
} from "@/tenant-context/control-groups/api/groups";
import { editProfileGroupParticipation, updateGroup } from "@/tenant-context/control-groups/api/groups";
import { peopleGroupsSearchQuery, profileInPeopleGroupSearchQuery } from "@/tenant-context/control-groups/store/groups/search-string";
import {
  BulkProfileListCreate,
  ControlGroupFilterType,
  ControlGroupProfilesFilterType,
  GroupItem,
  PeopleGroup,
  Profile,
  SortData
} from "@/tenant-context/control-groups/types/group";
import { bulkProfileListQueryString } from "@/tenant-context/control-profile/store/profile/bulk-profile-filter-query";
import { PaginatedResult, ProfileGroup, ReferenceDataService } from "@/tenant-context/control-profile/types/profile";

type GroupsState = {
  groups?: PaginatedResult<ProfileGroup>,
  services?: PaginatedResult<ReferenceDataService>
  activeGroupData?: GroupItem
  isFormEditing?: boolean
  isNewGroupCreated?: boolean
  allGroupsFilterData?: ControlGroupFilterType
  inGroupProfilesFilterData?: ControlGroupProfilesFilterType
  notInGroupProfilesFilterData?: ControlGroupProfilesFilterType
  activeGroupProfiles?: PaginatedResult<Profile>
  notInGroupProfiles?: PaginatedResult<Profile>
  notPaginatedProfiles?: PaginatedResult<Profile>
  activeGroupTab: string
  isPeopleGroupsLoading: boolean

};

const groupsDataModel = {
  name: 'groups',
  state: {
    groups: undefined,
    activeGroupData: undefined,
    isFormEditing: false,
    allGroupsFilterData: {},
    inGroupProfilesFilterData: {},
    notInGroupProfilesFilterData: {},
    activeGroupProfiles: undefined,
    notInGroupProfiles: undefined,
    notPaginatedProfiles: undefined,
    isNewGroupCreated: false,
    activeGroupTab: 'groupInfo',
    isPeopleGroupsLoading: false
  } as GroupsState,
  reducers: {
    SET_GROUPS: (state: GroupsState, groups: GroupsState['groups']) => ({
      ...state,
      groups
    }),
    SET_ACTIVE_GROUPS_PROFILES: (state: GroupsState, activeGroupProfiles: GroupsState['activeGroupProfiles']) => ({
      ...state,
      activeGroupProfiles
    }),
    SET_NOT_IN_GROUP_PROFILES: (state: GroupsState, notInGroupProfiles: GroupsState['notInGroupProfiles']) => ({
      ...state,
      notInGroupProfiles
    }),
    SET_SERVICES: (state: GroupsState, services: GroupsState['services']) => ({
      ...state,
      services
    }),
    SET_ACTIVE_GROUP_DATA: (state: GroupsState, activeGroupData: GroupsState['activeGroupData']) => ({
      ...state,
      activeGroupData
    }),
    SET_IS_FORM_EDITING: (state: GroupsState, isFormEditing: GroupsState['isFormEditing']) => ({
      ...state,
      isFormEditing
    }),
    SET_ALL_GROUPS_FILTER_DATA: (state: GroupsState, allGroupsFilterData: GroupsState['allGroupsFilterData']) => ({
      ...state,
      allGroupsFilterData
    }),
    SET_IN_GROUP_PROFILES_FILTER_DATA: (state: GroupsState, inGroupProfilesFilterData: GroupsState['inGroupProfilesFilterData']) => ({
      ...state,
      inGroupProfilesFilterData
    }),
    SET_NOT_IN_GROUP_PROFILES_FILTER_DATA: (state: GroupsState, notInGroupProfilesFilterData: GroupsState['notInGroupProfilesFilterData']) => ({
      ...state,
      notInGroupProfilesFilterData
    }),
    SET_NO_PAGINATED_PROFILES: (state: GroupsState, notPaginatedProfiles: GroupsState['notPaginatedProfiles']) => ({
      ...state,
      notPaginatedProfiles
    }),
    SET_ACTIVE_GROUP_TAB: (state: GroupsState, activeGroupTab: GroupsState['activeGroupTab']) => ({
      ...state,
      activeGroupTab
    }),
    SET_IS_NEW_GROUP_CREATED: (state: GroupsState, isNewGroupCreated: GroupsState['isNewGroupCreated']) => ({
      ...state,
      isNewGroupCreated
    }),
    SET_IS_PEOPLE_GROUPS_LOADING: (state: GroupsState, isPeopleGroupsLoading: GroupsState['isPeopleGroupsLoading']) => ({
      ...state,
      isPeopleGroupsLoading
    })
  },
  effects: (dispatch: Dispatch) => ({
    async loadAllGroups(
      _: void,
      state: RootState,
      meta?: { page?: number, size?: number, sort?: SortData }
    ): Promise<PaginatedResult<ProfileGroup> | undefined> {
      const {
        profile: { id },
        groups: { allGroupsFilterData }
      } = state;

      if (typeof id !== 'string') {
        return;
      }
      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(true);
      const groups = await getAllGroups(
        meta?.page,
        meta?.size,
        meta?.sort,
        peopleGroupsSearchQuery(allGroupsFilterData)
      );

      dispatch.groups.SET_GROUPS(groups);
      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(false);

      return groups;
    },

    async addProfileToGroup(
      groupId: string,
      state: RootState,
      meta?: { isBulk?: boolean, bulkData?: BulkProfileListCreate }
    ): Promise<void> {
      const {
        profile: { id, isAllProfilesChecked }
      } = state;

      const query = isAllProfilesChecked && bulkProfileListQueryString(state?.userProfile?.profileListFilterData) ? `(${bulkProfileListQueryString(state?.userProfile?.profileListFilterData).substr(8)})` : '';
      if (!id && !meta?.isBulk) {
        return;
      }

      if (id && !meta?.isBulk) {
        await editProfileGroupParticipation(
          id,
          groupId,
          false
        ).catch(handleError);

        return dispatch.profile.loadGroups();
      }

      if (meta?.isBulk) {
        await postBulkProfileAddToPeopleGroup(
          groupId,
          meta?.bulkData,
          query
        ).then(() => {
          dispatch.profile.SET_IS_ALL_PROFILES_CHECKED(false);
          dispatch.profile.SET_IS_PAGE_PROFILES_CHECKED(false);
          dispatch.profile.SET_SELECTED_PROFILES_IDS([]);
          dispatch.profile.SET_DESELECTED_PROFILES_IDS([]);
          dispatch.userProfile.getProfileList({});
        })
          .catch(handleError);
      }
    },

    async removeProfileFromGroup(groupId: string, state: RootState, profileId?: string): Promise<void> {
      const {
        profile: { id }
      } = state;

      if (!id) {
        return;
      }

      await editProfileGroupParticipation(
        profileId ? profileId : id,
        groupId,
        true
      );

      if (!profileId) {
        return dispatch.profile.loadGroups();
      }
    },

    async createGroup(
      group: PeopleGroup,
      state: RootState,
      meta?: { loadAllGroups?: boolean, isBulk?: boolean }
    ): Promise<boolean> {

      const {
        profile: { isAllProfilesChecked }
      } = state;

      const query = isAllProfilesChecked && bulkProfileListQueryString(state?.userProfile?.profileListFilterData) ? `(${bulkProfileListQueryString(state?.userProfile?.profileListFilterData).substr(8)})` : '';

      const result = await createGroup(group, query).catch(handleError);

      if(result){
        if (meta?.loadAllGroups) {
          const groups = await getAllGroups();
          dispatch.groups.SET_GROUPS(groups);
        } else {
          if (meta?.isBulk) {
            dispatch.profile.SET_IS_ALL_PROFILES_CHECKED(false);
            dispatch.profile.SET_IS_PAGE_PROFILES_CHECKED(false);
            dispatch.profile.SET_SELECTED_PROFILES_IDS([]);
            dispatch.profile.SET_DESELECTED_PROFILES_IDS([]);
            dispatch.userProfile.getProfileList({});
          } else {
            dispatch.profile.loadGroups();
          }
        }
        dispatch.groups.SET_ACTIVE_GROUP_DATA(undefined);
        dispatch.groups.SET_IS_NEW_GROUP_CREATED(true);
      }

      return !!result;
    
    },

    async loadAllServices(): Promise<void> {
      const services = await getAllServices();
      dispatch.groups.SET_SERVICES(services);
    },

    async deletePeopleGroup(groupId: string): Promise<void> {

      if (!groupId) {
        return;
      }

      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(true);
      await deleteProfileGroup(
        groupId
      );
      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(false);
      dispatch.groups.SET_ACTIVE_GROUP_DATA(undefined);
    },

    async updateGroupData(group: PeopleGroup, state: RootState): Promise<boolean> {
      const {
        groups: {
          activeGroupData
        }
      } = state;

      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(true);
      const result = await updateGroup(group, activeGroupData?._id ?? '').catch(handleError).finally(() => {
        dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(false);
      });

      if(result){
        const groups = await getAllGroups();
        dispatch.groups.SET_GROUPS(groups);  
        dispatch.groups.SET_ACTIVE_GROUP_DATA(undefined);
        dispatch.groups.SET_IS_NEW_GROUP_CREATED(true);  
      }

      return !!result;
    },

    async saveActiveGroupData(group: GroupItem | undefined): Promise<void> {
      dispatch.groups.SET_ACTIVE_GROUP_DATA(group);
      sessionStorage.setItem('activeGroupData', group ? JSON.stringify(group) : '');
    },

    async loadAllGroupProfiles(groupId = '', state: RootState, meta?: { notPagedProfilesWell?: boolean, page?: number, size?: number, sort?: SortData, inGroupProfiles?: boolean }): Promise<PaginatedResult<Profile> | undefined> {

      if (typeof groupId !== 'string') {
        return;
      }
      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(true);
      const id = groupId ? `peopleGroupTidToFilter=${groupId}:${meta?.notPagedProfilesWell}` : '';
      const filterData = groupId && meta?.inGroupProfiles
        ?
        state.groups.inGroupProfilesFilterData
        :
        state.groups.notInGroupProfilesFilterData;
      const profiles = await getProfiles(
        id,
        meta?.page,
        meta?.size,
        meta?.sort,
        profileInPeopleGroupSearchQuery(filterData)
      );

      if (meta?.notPagedProfilesWell) {
        const notPaginatedProfiles = await getProfiles(id, 0, 9999999);
        dispatch.groups.SET_NO_PAGINATED_PROFILES(notPaginatedProfiles);
      }

      if (groupId && meta?.notPagedProfilesWell) {
        dispatch.groups.SET_ACTIVE_GROUPS_PROFILES(profiles);

      } else {
        dispatch.groups.SET_NOT_IN_GROUP_PROFILES(profiles);
      }
      dispatch.groups.SET_IS_PEOPLE_GROUPS_LOADING(false);

      return profiles;
    },
    async updateGroup(group: PeopleGroup, state: RootState, groupId: string): Promise<void> {

      const id = groupId ? `peopleGroupTidToFilter=${groupId}:true` : '';

      await updateGroup(group, groupId);
      const notPaginatedProfiles = await getProfiles(id, 0, 9999999);
      dispatch.groups.SET_NO_PAGINATED_PROFILES(notPaginatedProfiles);
    },

    async setActiveGroup(groupId: string, _state: RootState): Promise<void> {

      const activeGroup = await getPeopleGroup(groupId);
      dispatch.groups.SET_ACTIVE_GROUP_DATA(activeGroup);
      sessionStorage.setItem('activeGroupData', activeGroup ? JSON.stringify(activeGroup) : '');
    }

  })
};


export const groups = createModel<RootModel>()(groupsDataModel);
