/* eslint-disable react/boolean-prop-naming */
import { CellClickedEvent, ColDef, GridOptions, RowHeightParams } from "ag-grid-community";
import { IsExternalFilterPresentParams } from "ag-grid-community/dist/lib/entities/iCallbackParams";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import DataGridComponent from "@/common/components/AgGrid/DataGrid.component";

type Props<T> = {
  id: string,
  data: Array<T>,
  columnDefs: Array<ColDef>,
  defaultColDef?: ColDef,
  pagination?: boolean,
  paginationPageSize?: number,
  animateRows?: boolean,
  suppressHorizontalScroll?: boolean,
  suppressRowClickSelection?: boolean,
  suppressDragLeaveHidesColumns?: boolean,
  suppressCellFocus?: boolean,
  domLayout?: 'normal' | 'autoHeight' | 'print',
  gridOptions?: GridOptions<T>,
  isExternalFilterPresent?: (params: IsExternalFilterPresentParams<T>) => boolean,
  doesExternalFilterPass?: (node: RowNode<T>) => boolean,
  isWithExpandableRows?: boolean,
  rowExpandMode?: 'single' | 'multiple',
  rowClass?: string;
  isInInvertedTheme?: boolean,
  getExpandedRowHeight?: (params: RowHeightParams<T>) => number,
  onExpandedRowsChange?: (expandedRows: Map<number, boolean>) => void,
  onCellClicked?: (event: CellClickedEvent<T>) => void,
  onRowClicked?: (event: RowClickedEvent<T>) => void,
}

const DataGrid: ForwardRefRenderFunction<AgGridReact, Props<Record<string, unknown>>> = ({
  id,
  data,
  columnDefs,
  defaultColDef,
  pagination = true,
  paginationPageSize = 10,
  animateRows = true,
  suppressHorizontalScroll = true,
  suppressRowClickSelection = true,
  suppressDragLeaveHidesColumns = true,
  suppressCellFocus = true,
  domLayout = 'autoHeight',
  gridOptions = {},
  isExternalFilterPresent,
  doesExternalFilterPass,
  isWithExpandableRows = false,
  rowExpandMode = 'multiple',
  rowClass,
  isInInvertedTheme = false,
  onExpandedRowsChange,
  getExpandedRowHeight,
  onCellClicked,
  onRowClicked
}, ref) => {

  const gridRef = useRef<AgGridReact | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useImperativeHandle(ref, () => gridRef.current);

  const [ expandedRows, setExpandedRows ] = useState<Map<number, boolean>>(new Map());

  useEffect(() => {
    onExpandedRowsChange?.(expandedRows);

    gridRef?.current?.api?.redrawRows();
    gridRef?.current?.api?.resetRowHeights();
  }, [ expandedRows, onExpandedRowsChange ]);

  const handleExpandRowChange = useCallback((rowIndex: number) => {
    // if rowExpandMode is single, then we need to collapse all other rows and expand only the selected row
    if (rowExpandMode === 'single') {
      const isRowExpanded = expandedRows.get(rowIndex) || false;
      const expandMap = new Map().set(rowIndex, !isRowExpanded);

      setExpandedRows(expandMap);
    } else {
      const isRowExpanded = expandedRows.get(rowIndex) || false;

      setExpandedRows(new Map(expandedRows.set(rowIndex, !isRowExpanded)));
    }
  }, [ expandedRows, rowExpandMode ]);


  return (
    <DataGridComponent
      id={ id }
      ref={ gridRef }
      data={ data }
      rowClass={ rowClass }
      columnDefs={ columnDefs }
      defaultColDef={ defaultColDef }
      pagination={ pagination }
      paginationPageSize={ paginationPageSize }
      animateRows={ animateRows }
      suppressHorizontalScroll={ suppressHorizontalScroll }
      suppressRowClickSelection={ suppressRowClickSelection }
      suppressDragLeaveHidesColumns={ suppressDragLeaveHidesColumns }
      suppressCellFocus={ suppressCellFocus }
      domLayout={ domLayout }
      gridOptions={ gridOptions }
      isWithExpandableRows={ isWithExpandableRows }
      expandedRows={ expandedRows }
      isInInvertedTheme={ isInInvertedTheme }
      onRowExpandChanged={ handleExpandRowChange }
      getExpandedRowHeight={ getExpandedRowHeight }
      isExternalFilterPresent={ isExternalFilterPresent }
      doesExternalFilterPass={ doesExternalFilterPass }
      onCellClicked={ onCellClicked }
      onRowClicked={ onRowClicked }
    />
  );
};

export default forwardRef(DataGrid) as <T>(
  props: Props<T> & React.RefAttributes<AgGridReact>
) => JSX.Element;
