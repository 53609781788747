import { CopyButton, Divider } from "@mantine/core";
import { FC, useCallback, useState } from "react";

import TimeUpCounter from "@/common/components/TimeUpCounter/TimeUpCounter.container";
import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip/TruncatedTextWithTooltip.component";
import { ReactComponent as TickIcon } from "@/common/icons/checked.svg";
import { ReactComponent as PasteIcon } from "@/common/icons/paste-icon.svg";
import { formatDate } from "@/common/util/format/date";
import IconTextMarker from "@/tenant-context/control-profile/components/ui/IconTextMarker/IconTextMarker.component";
import MiniProfileEmergencyBlock from "@/tenant-context/employee-app-action-response-center/components/ui/MiniProfileEmergencyBlock/MiniProfileEmergencyBlock.component";
import { LWPersonStatus } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";
import { PersonPopupAssetRankingEvent } from "@/tenant-context/visualisation-people/types/people.types";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";
import { handleAdapterSourceDisplay } from "@/tenant-context/visualisation-people/util/utils";

import { usePeopleLocationPopupStyles } from "./PeopleLocationPopup.styles";

type Props = {
    assetSummary: PersonPopupAssetRankingEvent
}

export const StandardPersonPopup: FC<Props> = ({
  assetSummary
}) => {
  const { classes, cx } = usePeopleLocationPopupStyles();
  const { location: { address } } = assetSummary;
  const [profilePictureThumbnailError, setProfilePictureThumbnailErrorr] = useState(false);

  const handleImageError = useCallback(() => {
    setProfilePictureThumbnailErrorr(true);
  }, []);

  return (
    <div className={ classes.travellerPopupcontainer }>
      <div className={ classes.personDetailWrapper }>
        <div className={ classes.personPic }>
          { (assetSummary.profilePictureThumbnailUrl && !profilePictureThumbnailError) ?
            <img onError={ handleImageError } alt="profile-img" src={ assetSummary.profilePictureThumbnailUrl } />
            : assetSummary.firstName && assetSummary.lastName ? getPersonInitials(`${assetSummary.firstName} ${assetSummary.lastName}`).toString().toUpperCase() :
              getPersonInitials(assetSummary.personFullName || 'N/A').toString().toUpperCase() }
        </div>
        <div className={ classes.profileInfo }>
          <div className={ classes.profileHeader }>{ <TruncatedTextWithTooltip
            fullText={ assetSummary.lastName && assetSummary.firstName ? `${assetSummary.firstName} ${assetSummary.lastName}` : assetSummary.personFullName } maxLength={ 15 } /> }</div>
          <div className={ classes.profileDesignation }>{ assetSummary.jobTitle }</div>
          <div className={ classes.profileCompany }>{ assetSummary.company }</div>
        </div>
      </div>
      <div className={ classes.personInfoContainer }>
        <Divider orientation="horizontal" />
        <div className={ classes.personInfo }>
          <div className={ classes.infoItem }>
            <div className={ classes.personInfoHeader }>PERSON TYPE</div>
            <div className={ classes.personInfoData }>{ assetSummary.personType }</div>
          </div>
          <div className={ classes.infoItem }>
            <div className={ classes.personInfoHeader }>NATIONALITY</div>
            <div className={ classes.personInfoData }>{ assetSummary.nationality }</div>
          </div>
          <div className={ classes.infoItem }>
            <div className={ classes.personInfoHeader }>RESIDENCE</div>
            <div className={ classes.personInfoData }>{ assetSummary.countryOfResidenceRef }</div>
          </div>
        </div>
        <Divider orientation="horizontal" />
      </div>
      { assetSummary?.lastKnownLocation &&
        <div className={ cx(classes.personInfoHeader, classes.standardMarginTop) }>LAST KNOWN LOCATION</div> }
      <div className={ cx({ [classes.personInfoData]: true, [classes.standardMarginTop]: !assetSummary?.lastKnownLocation }) }>{ `Via ${handleAdapterSourceDisplay(assetSummary?.adapterSource)}` }</div>
      <div className={ classes.personLocationInfo }>
        <div className={ classes.locationIconContainer }><IconTextMarker
          iconText={ assetSummary.personFullName } /></div>
        <div className={ classes.locationInfoBody }>{ address }
          <CopyButton value={ `${assetSummary.location.point.lat}, ${assetSummary.location.point.lon}` } >
            { ({ copied, copy }) => (
              <div
                className={ cx({ [classes.copyCordinates]: !copied, [classes.copiedCordinates]: copied }) }
                onKeyDown={ copy }
                tabIndex={ 0 }
                onClick={ copy }
                role="button"
              >
                <div>{ copied ? <TickIcon/> : <PasteIcon /> }</div>
                <div>{ copied ? 'Copied Coordinates' : 'Copy Coordinates' }</div>
              </div>
            ) }
          </CopyButton>
        </div>
      </div>

      <div className={ classes.travelTimeUtc }>
        SINCE
      </div>
      { Date.now() > Number(assetSummary.startTime) || Date.now() > Number(assetSummary.endTime) ?
        <div className={ classes.personInfoData }>
          <TimeUpCounter timeInMillis={ assetSummary.startTime || assetSummary.receivedTime } />
        </div> : '' }
      <Divider className={ classes.timeDivider } orientation="horizontal" />
      <div className={ classes.travelTimeUtc }>
        { formatDate(new Date(assetSummary.startTime || assetSummary.receivedTime)) }
      </div>
      <div className={ cx({ [classes.alarmBlockContainer]: assetSummary.loneWorker?.status }) }>
        <MiniProfileEmergencyBlock externalStatus={ {
          status: assetSummary.loneWorker?.status as LWPersonStatus, isExternal: true
        } } />
      </div>
    </div>
  )
  ;
};
