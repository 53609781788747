import { FeedNotification } from '@/tenant-context/control-live-feed/types/live-feed';

export function liveFeedTimeRangeFilter(notification: FeedNotification): boolean {
  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const timeStampUpperBound = Date.now() + 1000 * 60 * 60; // Current time + error margin 1 hour
  const timeStampLowerBound = Date.now() - MILLISECONDS_PER_DAY; // A day before the current time

  return notification.openedDataTime <= timeStampUpperBound &&
    notification.openedDataTime >= timeStampLowerBound;
}
