import { Button } from "@mantine/core";
import bbox from "@turf/bbox";
import { ICellRendererParams } from "ag-grid-community";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import { ReactNode, useCallback, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import { ReactComponent as TrashIcon } from "@/common/icons/trash.svg";
import { Dispatch, RootState } from "@/core/store";
import {
  useUploadedFilesListStyles
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/UploadedFilesList/styles";
import UploadedFilesList
  from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/UploadedFilesList/UploadedFilesList.component";
import {
  columnDefs
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/UploadedFilesList/UploadedFilesList.config";
import { SitePlanList } from "@/tenant-context/control-location-management/types/ManageLocations.types";

const UploadedFilesListContainer = () => {

  const { classes }= useUploadedFilesListStyles();
  const ref = useRef<AgGridReact>(null);
  const sitePlanListData = useSelector((state: RootState) => state.manageLocations?.sitePlanList);

  const {
    manageLocations: {
      getSitePlanList,
      deleteLocationSitePlan,
      SET_CURRENT_SITE_PLAN_GEO_JSON
    }
  } = useDispatch<Dispatch>();

  const { AddLocationMap: map } = useMap();

  const onFileDeleteHandler = useCallback((str: string) => deleteLocationSitePlan(str),[deleteLocationSitePlan]);

  const deleteFileHandler = useCallback(
    (str: string) => {
      openConfirmationModal({
        text: "Are you sure you want to remove this Site Plan?",
        title: "Confirm Removal",
        onConfirm: () => onFileDeleteHandler(str)
      });
    },
    [onFileDeleteHandler]
  );

  const handleRowClick = useCallback((e: RowClickedEvent<SitePlanList>) => {
    if (e.data?.geoJson) {
      const parsedGeoJson = JSON.parse(e.data?.geoJson);
      const bbox_ = bbox(parsedGeoJson);
      SET_CURRENT_SITE_PLAN_GEO_JSON(parsedGeoJson);
      if (map && bbox_) {
        map?.fitBounds(bbox_ as [number, number, number, number], { padding: 100 });
      }
    }
  }, [SET_CURRENT_SITE_PLAN_GEO_JSON, map]);

  const actionCellRenderer = useCallback((params: ICellRendererParams<SitePlanList>): ReactNode => {
    return (
      <div className={ classes.buttonWrapper }>
        <Button
          /* eslint-disable-next-line react/jsx-no-bind */
          onClick={ () => deleteFileHandler(params?.data?.tid ?? '') }
          className={ classes.btn }
        >
          <TrashIcon/>
        </Button>
      </div>
    );
  }, [classes.btn, classes.buttonWrapper, deleteFileHandler]);

  useEffect(() => {
    getSitePlanList();
  }, [getSitePlanList]);

  return (
    <UploadedFilesList
      columnDefs={ columnDefs(actionCellRenderer) }
      data={ sitePlanListData }
      ref={ ref }
      handleRowClick={ handleRowClick }
    />
  );
};

export default UploadedFilesListContainer;
