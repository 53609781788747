import { Menu, Tooltip, UnstyledButton } from "@mantine/core";
import { FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router";

import { ReactComponent as CollapseArrowLeft } from "@/common/icons/Arrows/back-arrow.svg";
import { ReactComponent as CollapseArrowRight } from '@/common/icons/Arrows/collapse-arrow-right.svg';
import { ReactComponent as LockIcon } from '@/common/icons/lock.svg';
import { TestableComponent } from "@/common/types/testable-component";
import { convertToNavFormat } from "@/common/util/get-testid";
import { NavIconProps } from "@/core/navigation/types/navigation";
import { redirectWithinRestrataProductEcosystem } from "@/core/navigation/util/navigation";
import { Dispatch } from '@/core/store';
import { navigationIconMap } from "@/tenant-context/navigation/components/NavigationIcon/NavigationIcon.component";
import { useNavigationItemStyles } from "@/tenant-context/navigation/components/NavigationItem/NavigationItem.style";
import { NavigationSidebarItem } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";
import { NavigationSidebarContext } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.container";

type Props = {
  isLogo?: boolean;
  type?: "link" | "button" | "menu";
  isRedirectOnClick?: boolean;
  onClick?: () => void;

  item?: NavigationSidebarItem;
  Icon?: React.ComponentType<NavIconProps>;
  title?: ReactNode;

  isWithTooltip?: boolean;
  isOpenClose?: boolean;
  isHoverable?: boolean;
  isDisabled?: boolean;
} & TestableComponent;

const NavigationItem: FC<Props> = ({
  isLogo,
  title: manualTitle,
  type = 'link',
  isRedirectOnClick = true,
  item,
  Icon: BackupIcon,
  onClick,
  isWithTooltip = true,
  isOpenClose = false,
  isHoverable = true,
  children,
  isDisabled: forcedDisabled,
  dataTestId = 'navigation-item'
}) => {
  const {
    itemId,
    path,
    area,
    title: itemTitle,
    isNotReady,
    policies: itemPolicies
  } = item || {};

  const navigate = useNavigate();
  const location = useLocation();

  const title = manualTitle || itemTitle;

  const [isHovered, setIsHovered] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const {
    isExpanded,
    setIsExpanded,
    usersPolicies
  } = useContext(NavigationSidebarContext);

  const {
    commonData: {
      startLoading,
      loadingComplete
    }
  } = useDispatch<Dispatch>();

  // const isProductSubscribed = useMemo(()=> {
  //   if(!item || !subscribedProducts || subscribedProducts.length === 0){
  //     return false;
  //   }
  //
  //   return subscribedProducts.some(product => item.productName ?
  //     product.name === item.productName : true);
  // }, [subscribedProducts, item]);

  const isItemRestrictedByPermissions = useMemo(() => {
    if (!itemPolicies || !itemPolicies.length || !usersPolicies) {
      return false;
    }

    const isAllowedByPolicy = itemPolicies.some(
      (policy) => usersPolicies.items.some(
        (userPolicy) => userPolicy.policyName === policy
      )
    );

    return !isAllowedByPolicy;
  }, [itemPolicies, usersPolicies]);

  const DummyIcon = useCallback(
    () => null,
    []
  );

  const isDisabled = useMemo(() => {
    if (isOpenClose) {
      return false;
    }

    if (item?.isAlwaysEnabled) {
      return false;
    }

    return isNotReady || isItemRestrictedByPermissions || forcedDisabled;
  }, [forcedDisabled, isItemRestrictedByPermissions, isNotReady, isOpenClose, item?.isAlwaysEnabled]);

  const isSubMenuItemRestricted = useCallback((subMenuItem: NavigationSidebarItem): boolean => {
    if (!subMenuItem.policies || !subMenuItem.policies.length || !usersPolicies) {
      return false;
    }
    const isAllowedByPolicy = subMenuItem.policies.some(
      (policy) => usersPolicies.items.some(
        (userPolicy) => userPolicy.policyName === policy
      )
    );

    return !isAllowedByPolicy;
  }, [usersPolicies]);

  const { classes, cx } = useNavigationItemStyles({
    isExpanded,
    isDisabled,
    isHoverable: isHoverable && !isDisabled,
    isWithTooltip,
    isOpenClose
  });

  const handleRedirectClick = useCallback(() => {
    if (!area) {
      return;
    }

    if (isRedirectOnClick && item) {
      startLoading();
      redirectWithinRestrataProductEcosystem(item, navigate)
        .finally(() => loadingComplete());
      setIsExpanded(false);

    }
  }, [area, isRedirectOnClick, item, loadingComplete, navigate, setIsExpanded, startLoading]);

  const handleSubMenuRedirect = useCallback((subItem: NavigationSidebarItem) => {
    return () => {
      startLoading();
      redirectWithinRestrataProductEcosystem(subItem, navigate)
        .finally(() => loadingComplete());
      setIsExpanded(false);
    };
  }, [loadingComplete, navigate, setIsExpanded, startLoading]);

  const renderIconContents = (maybeLock = false) => {
    // Children override everything else
    if (children) {
      return children;
    }

    // Lock icon if item is disabled
    if (maybeLock && isDisabled) {
      return <LockIcon />;
    }

    const maybeActualIcon = itemId ? navigationIconMap[itemId] : null;

    // Actual icon if item is not disabled
    if (maybeActualIcon) {
      const state: 'disabled' | 'enabled' | 'active' = (function() {
        if (isDisabled) {
          return 'disabled';
        }

        if (isHovered) {
          return 'active';
        }

        return 'enabled';
      })();

      const ActualIcon = maybeActualIcon[state];

      return <ActualIcon />;
    }

    if (BackupIcon) {
      return <BackupIcon />;
    }

    return <DummyIcon />;
  };

  const renderSubmenuItems = () => (
    <Menu.Dropdown className={ classes.dropDownContent }>
      <div>
        { item?.type === 'menu' && item?.subMenuItems?.map((mainItem, index) => {
          return (
            <div key={ mainItem.sectionHeader }>
              { index !== 0 && <Menu.Divider /> }
              <Menu.Label>{ mainItem.sectionHeader }</Menu.Label>
              { mainItem.subItems.map((subItem) => {
                const isItemDisabled = isSubMenuItemRestricted(subItem);
                const MenuIcon = isItemDisabled ? navigationIconMap[subItem.itemId]?.disabled as FC | undefined :
                  navigationIconMap[subItem.itemId]?.enabled as FC | undefined;

                return (
                  <div key={ subItem.itemId } className={ cx({
                    [classes.subMenuDisabled]: isItemDisabled
                  }) }>
                    <Menu.Item
                      data-testid={ convertToNavFormat(dataTestId, item.title, subItem.title) }
                      disabled={ isItemDisabled }
                      icon={ MenuIcon && < MenuIcon /> }
                      onClick={ handleSubMenuRedirect(subItem) }>
                      { subItem.title }</Menu.Item>
                    { subItem.isDivided && <Menu.Divider /> }
                  </div>
                );
              }) }
            </div>
          );
        }) }
      </div>
    </Menu.Dropdown>
  );

  const renderIcon = (forceNotHovered = false, maybeLock = false) => {
    return (
      <div
        className={ cx({
          [classes.itemIcon]: !forceNotHovered,
          [classes.tooltipIcon]: forceNotHovered
        }) }
      >
        { renderIconContents(maybeLock) }
      </div>
    );
  };

  const renderTitle = (isExpandable = true) => (
    <span
      className={ cx({
        [classes.title]: isExpandable,
        [classes.tooltipLabel]: !isExpandable
      }) }
    >
      { title }
    </span>
  );

  const renderLink = () => (
    <a href={ path } className={ cx(classes.navLink, classes.item) }>
      { renderIcon(false, false) }
      { renderTitle() }
    </a>
  );

  const renderButton = () => (
    <UnstyledButton
      disabled={ isDisabled }
      onClick={ isRedirectOnClick ? handleRedirectClick : onClick }
      className={ cx(classes.navLink, classes.expandedItemContainer) }
    >
      <div className={ classes.item }>
        { renderIcon(false, false) }
        { renderTitle() }
      </div>
      { item?.type === 'menu' && !isMenuActive && isExpanded ? 
        <div className={ classes.menuSlideIcon }><CollapseArrowRight /></div> : item?.type === 'menu' && isMenuActive && isExpanded ? 
          <div className={ classes.menuSlideIcon }><CollapseArrowLeft /></div> : null }
      { isDisabled && isExpanded && renderIcon(true, true) }
    </UnstyledButton>
  );

  const renderTooltipLabel = () => (
    <div className={ classes.tooltipContainer }>
      { renderIcon(false, true) }
      { renderTitle(false) }
      <span className={ classes.tooltipDescription }>
        { isNotReady && "Coming soon" }
        { isItemRestrictedByPermissions ?  (
          <>
            <span>You do not have permissions</span>
            <span>to access this feature</span>
          </>) : "" }
          
        { !isNotReady && !isItemRestrictedByPermissions && item?.hintMessage }
      </span>
    </div>
  );

  const handleMouseEnter = useCallback(() => {
    if (isHoverable && !isDisabled && !isHovered) {
      setIsHovered(true);
    }
  }, [isHoverable, isDisabled, isHovered]);

  const handleMenuChange = useCallback((opened: boolean) => {
    setIsMenuActive(opened);
  }, []);

  const handleMouseLeave = useCallback((event) => {
    const targetElement = event.target.tagName.toLowerCase();
    if (isHoverable && !isDisabled && targetElement !== 'div') {
      setIsHovered(false);
    }
  }, [isHoverable, isDisabled]);

  const currentSelectedItem
   = item?.path === location.pathname;

  return item?.type === 'menu' ? (
    <div>
      <Menu
        width={ 260 }
        position="right-end"
        transition='slide-right'
        transitionDuration={ 300 }
        offset={ 0 }
        trigger={ isExpanded ? 'click' : 'hover' }
        zIndex={ -1 }
        onChange={ handleMenuChange }
      >
        <Menu.Target>
          <div className={ cx({
            [classes.menuItemActive]: isMenuActive
          }) }>
            { renderButton() }
          </div>
        </Menu.Target>
        <Menu.Dropdown className={ classes.dropDownBackground }>
        </Menu.Dropdown>
        { renderSubmenuItems() }
      </Menu>
    </div> )
    : (
      <Tooltip
        style={ { padding: 0 } }
        label={ renderTooltipLabel() }
        transition='fade'
        transitionDuration={ 100 }
        position='right'
        offset={ -2 }
        withArrow
        arrowSize={ 10 }
        disabled={ !isWithTooltip || isExpanded || currentSelectedItem }
      >
        <div
          onMouseOver={ handleMouseEnter }
          onMouseOut={ handleMouseLeave }
          onBlur={ handleMouseLeave }
          onFocus={ handleMouseEnter }
          className={ cx({
            [classes.item]: true,
            [classes.sidebarItemContainer]: true,
            [classes.logo]: isLogo,
            [classes.navSelected]: currentSelectedItem
          }) }
          data-testid={ dataTestId }
        >
          { type === "link" && renderLink() }
          { type === "button" && renderButton() }
        </div>
      </Tooltip>
    );
};

export default NavigationItem;
