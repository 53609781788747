import { Button } from "@mantine/core";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as EyeIcon } from '@/common/icons/eye.svg';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { RootStateWithLoading } from "@/core/store";
import { useProfileDocumentsTabStyles } from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsTab/ProfileDocumentsTab.style";
import { useProfilePersonalContactTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.style';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from '@/tenant-context/control-profile/components/ui/ProfileKeyValue';
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";
import ProfileLoadingOverlayComponent from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay/ProfileLoadingOverlay.component';
import ProfileTabActionButton from "@/tenant-context/control-profile/components/ui/ProfileTabActionButton";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { PaginatedResult, ProfileDocumentDetails } from "@/tenant-context/control-profile/types/profile";

type Props = {
  documents?: PaginatedResult<ProfileDocumentDetails> | null,
  onAddClick: () => void,
  onEditClick: (documentId: string) => void,
  onDeleteDocumentClick: (documentId: string) => void,
  isOwnProfile: () => boolean
}

const ProfileDocumentsTab: FC<Props> = ({
  documents,
  onAddClick,
  onDeleteDocumentClick,
  onEditClick,
  isOwnProfile
}) => {
  const { classes } = useProfileDocumentsTabStyles();
  // TODO refactor
  const { classes: { section, relative } } = useProfilePersonalContactTabStyles();
  const isAuthorizedToDelete = usePermission(ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS);
  const handleUnauthorizedAction = useUnauthorizedModal();
  const isDocumentLoading = useSelector((state: RootStateWithLoading) => state.loading.effects.profile.deleteDocument);

  const renderAddDocumentButton = useCallback(() => (
    <ProfileTabActionButton
      onClick={ onAddClick }
      requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS }
    >
      Add Document
    </ProfileTabActionButton>
  ), [isOwnProfile, onAddClick]);

  const gtc = useCallback((mc) => `repeat(${mc - 1}, 1fr) 3fr 0.5fr 0.5fr`, []);

  if (!documents) {
    return <span>Loading...</span>;
  }

  return (
    <GenericProfileTab
      heading="Documents"
      renderAction={ renderAddDocumentButton }
      className={ relative }
    >
      <ProfileLoadingOverlayComponent
        isLoading={ isDocumentLoading }
      />
      <section className={ section }>
        { !!documents.items.length && (
          <ProfileBlock
            mode='legend-header'
          >
            <ProfileKeyValueBlock
              maxColumns={ 6 }
              gtc={ gtc }
            >
              <ProfileKeyValue
                mode='legend-header'
                name={ 'DOCUMENT' }
                value={ [
                  'TYPE',
                  'NUMBER',
                  'EXPIRY DATE',
                  'DOCUMENTS',
                  'COMMENT'
                ] }
              />
            </ProfileKeyValueBlock>
          </ProfileBlock>
        ) }

        { documents.items.map((document) => (
          <ProfileBlock key={ document._id } element='li'>
            <ProfileKeyValueBlock
              maxColumns={ 6 }
              gtc={ gtc }
            >
              <ProfileKeyValue
                firstItem="regular"
                name={ document.name }
                value={ [
                  document.documentType,
                  document.number,
                  (new Date(document.expiryDate)).toLocaleDateString('en-GB', { day: 'numeric' ,month: "long" , year: 'numeric' }),
                  document.documents,
                  document.comments
                ] }
              />

              <Button
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ (isOwnProfile() || isAuthorizedToDelete) ? () => onDeleteDocumentClick(document._id)
                  : handleUnauthorizedAction }
                className={ (isOwnProfile() || isAuthorizedToDelete) ? classes.btn : classes.unauthorizedDeleteBtn }
              >
                <TrashIcon />
              </Button>

              <Button
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => onEditClick(document._id) }
                className={ classes.btn }
              >
                <EyeIcon />
              </Button>
            </ProfileKeyValueBlock>
          </ProfileBlock>
        )) }
      </section>
    </GenericProfileTab>
  );
};

export default ProfileDocumentsTab;
