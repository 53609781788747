/* eslint-disable react/jsx-props-no-spreading */
import { Button, Textarea, TextInput } from "@mantine/core";
import { FC } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import { Building } from "@/tenant-context/control-location-management/types/ManageLocations.types";

type Props = {
  formControls:   UseFormReturn<FieldValues, unknown>
  onSubmit: (data: FieldValues) => void
  activeBuilding?: Building
  onClose: () => void
  isAuthorizedToEdit: boolean
}
const AddBuildingFormComponent:FC<Props> = ({
  formControls,
  onSubmit,
  activeBuilding,
  onClose,
  isAuthorizedToEdit
}) => {
  const  { register, handleSubmit, formState: { isValid, errors } } = formControls;
  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <div>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <div className={ classes.formRow }>
          <TextInput
            disabled={ !isAuthorizedToEdit }
            className={ classes.formItem }
            defaultValue={ activeBuilding?.name || '' }
            required={ true }
            label={ 'Name' }
            error={ errors?.name?.message }
            { ...register('name', addErrorMessages('Name', {
              required: true
            })) }
          />
          <TextInput
            disabled={ !isAuthorizedToEdit }
            className={ classes.formItem }
            defaultValue={ activeBuilding?.code || '' }
            required={ true }
            label={ 'Code' }
            error={ errors?.code?.message }
            { ...register('code', addErrorMessages('Code', {
              required: true
            })) }
          />
        </div>
        <div className={ classes.formRow }>
          <TextInput
            disabled={ !isAuthorizedToEdit }
            className={ classes.formItem }
            defaultValue={ activeBuilding?.geoPoint?.lat || '' }
            required={ true }
            label={ 'Latitude' }
            error={ errors?.geoPoint?.lat?.message }
            { ...register('geoPoint.lat', addErrorMessages('Latitude', {
              required: true,
              pattern: {
                value: /^[-]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
                message: "This input is number only."
              }
            })) }
          />
          <TextInput
            disabled={ !isAuthorizedToEdit }
            className={ classes.formItem }
            defaultValue={ activeBuilding?.geoPoint?.lon || '' }
            required={ true }
            label={ 'Longitude' }
            error={ errors?.geoPoint?.lon?.message }
            { ...register('geoPoint.lon', addErrorMessages('Longitude', {
              required: true,
              pattern: {
                value: /^[-]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
                message: "This input is number only."
              }
            })) }
          />
        </div>
        <div className={ classes.formRow }>
          <Textarea
            disabled={ !isAuthorizedToEdit }
            className={ classes.formItem }
            defaultValue={ activeBuilding?.note || '' }
            label={ 'Notes' }
            error={ errors?.note?.message }
            minRows={ 4 }
            { ...register('note', addErrorMessages('Notes', {
              required: false,
              maxLength: 1024
            })) }
          />
        </div>
        <div className={ classes.buttonContainer }>
          <Button size="md" variant="outline" onClick={ onClose }>Cancel</Button>
          <Button
            type={ 'submit' }
            size="md"
            disabled={ !isValid || !isAuthorizedToEdit }
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddBuildingFormComponent;
