/* eslint-disable react/jsx-key */
import React, { FC, ReactNode, useCallback } from "react";

import usePermission from '@/common/hooks/usePermission';
import { RequiredPolicies } from '@/common/types/permission-control';
import { useProfileBlockStyles } from "@/tenant-context/control-profile/components/ui/ProfileBlock/ProfileBlock.style";
import { ProfileAddress } from "@/tenant-context/control-profile/types/profile";

type Props = {
  heading?: string,
  renderAction?: () => ReactNode,
  element?: string,
  mode?: 'legend-header',
  padding?: 'l' | 'm',
  requiredPolicies?: RequiredPolicies | RequiredPolicies[],
  handleAddressClick?: (address: ProfileAddress) => void
  address?: ProfileAddress,
  customClass?: string
};

const ProfileBlock: FC<Props> = ({
  children,
  heading,
  renderAction,
  element,
  mode,
  padding = 'l',
  requiredPolicies,
  handleAddressClick,
  address,
  customClass
}) => {
  const isLegendHeader = mode === 'legend-header';
  const { classes, cx } = useProfileBlockStyles({
    isLegendHeader,
    padding
  });

  const isAuthorized = usePermission(requiredPolicies);
  const shouldRenderComponent = useCallback(() => {
    return !requiredPolicies ? true : isAuthorized;
  }, [requiredPolicies, isAuthorized]);

  return (
    shouldRenderComponent() ? React.createElement(
      element || 'div',
      { className: cx(classes.block, customClass) },
      heading && (
        <div className={ classes.blockHeading }>
          { heading }
        </div>
      ),

      <div className={ classes.contentBlock }>
        { /* eslint-disable-next-line react/jsx-no-bind */ }
        <button onClick={ () => handleAddressClick && address ? handleAddressClick(address) : undefined }
          className={ classes.contentButton }>
          { children }
        </button>
      </div>,

      <div>
        { renderAction?.() }
      </div>
    ) : null
  );
};

export default ProfileBlock;
