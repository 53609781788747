import { createStyles } from "@mantine/core";

type Params = {
  backgroundColor?: string;
  padding?: string;
}

export const usePopupStyles = createStyles((theme, {
  backgroundColor,
  padding
}: Params) => ({
  popup: {
    '& > .mapboxgl-popup-content': {
      backgroundColor: backgroundColor ?? theme.colors.neutral[7],
      padding: getPadding(padding),
      borderRadius: '1px',

      '& > .mapboxgl-popup-close-button': {
        color: theme.white,
        fontSize: 12
      }
    },

    '& > .mapboxgl-popup-tip': {
      display: 'none'
    }
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.s,
    color: theme.white
  },

  drawerCloseButton: {
    float: 'right',
    '> svg': {
      width: '20px',
      height: '20px'
    }
  },

  connectingLine: {
    position: 'absolute',
    height: `20px`,
    width: '2px',
    backgroundColor: backgroundColor ?? theme.colors.neutral[7],
    bottom: `-20px`,
    left: '0px'
  }
}));

const getPadding = (padding: string | undefined) => {
  switch (padding) {
  case 'sm':
    return 8;
  case 'md':
    return 16;
  case undefined:
    return 16;
  default:
    return padding;
  }
};
