import { closeAllModals } from "@mantine/modals";
import { createModel } from "@rematch/core";

import { ReferenceData, ReferenceType } from "@/common/types/reference-data";
import { TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { showNotification } from "@/common/util/notification";
import { getReferenceData } from "@/common/util/reference-data";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { getActivityLogForCase } from "@/tenant-context/employee-app-action-response-center/api/activity-log";
import { assignCaseOwner } from "@/tenant-context/employee-app-action-response-center/api/case-owner";
import { closeCase } from "@/tenant-context/employee-app-action-response-center/api/ea-arc";
import { ClientActivityLog } from "@/tenant-context/employee-app-action-response-center/types/activity-log";
import { CloseCasePayload } from "@/tenant-context/employee-app-action-response-center/types/ea-arc";
import { getDelta, parseLogData } from "@/tenant-context/employee-app-action-response-center/util/activity-log";

type EaArcState = {
  isOpen: boolean,
  caseId?: string,
  caseTid?: string,
  activityLog?: ClientActivityLog[],
  whatHappened?: ReferenceData[]
};

const eaArcDataModel = {
  name: 'eaArc',
  state: {
    isOpen: false
  } as EaArcState,
  reducers: {
    SET_ACTIVITY_LOG(state: EaArcState, payload: ClientActivityLog[]): EaArcState {
      return {
        ...state,
        activityLog: payload
      };
    },
    SET_IS_OPEN(state: EaArcState, payload: boolean): EaArcState {
      return {
        ...state,
        isOpen: payload
      };
    },

    SET_CASE_ID(state: EaArcState, payload: string | undefined): EaArcState {
      return {
        ...state,
        caseId: payload
      };
    },

    SET_CASE_TID(state: EaArcState, payload: string | undefined): EaArcState {
      return {
        ...state,
        caseTid: payload
      };
    },
    SET_WHAT_HAPPENED(state: EaArcState, payload: ReferenceData[]): EaArcState {
      return {
        ...state,
        whatHappened: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    openEaArc(): void {
      dispatch.eaArc.SET_IS_OPEN(true);
      dispatch.locationGraph.disableGraph();
    },

    closeEaArc(): void {
      dispatch.eaArc.SET_IS_OPEN(false);
      dispatch.eaArc.SET_CASE_ID(undefined);
      dispatch.eaArc.SET_CASE_TID(undefined);
    },

    async assignCaseOwner(
      _: void,
      state: RootState
    ): Promise<void> {
      const {
        eaArc: { caseId }
      } = state;
      const tenantToken = browserDatabase.getItem<string>(TENANT_TOKEN_KEY);

      if (!tenantToken || !caseId) {
        return;
      }

      try {
        const decodedToken = JSON.parse(atob(tenantToken.split('.')[1]));
        const { profileId } = decodedToken;

        await assignCaseOwner(
          caseId,
          profileId
        );

        showNotification({
          message: 'You have been assigned to this case.',
          color: 'info',
          autoClose: 5000
        });
      } catch (err) {
        // no-op
      }
    },

    async closeCurrentCase(
      payload: CloseCasePayload,
      state: RootState
    ): Promise<void> {
      const {
        userProfile: {
          lwStatus
        }
      } = state;

      try {
        await closeCase(payload);
        dispatch.eaArc.closeEaArc();
        closeAllModals();

        const interval = setInterval(async () => {
          const status = await dispatch.userProfile.refreshUserProfileLwStatus();

          if (lwStatus !== status) {
            clearInterval(interval);
          }
        }, 250);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while closing the case.',
            color: 'error'
          });
        }
      }
    },

    async loadActivityLog(
      _: void,
      state: RootState
    ): Promise<void> {
      const {
        eaArc: { caseTid }
      } = state;

      if (!caseTid) {
        return;
      }

      try {
        const activityLog = await getActivityLogForCase(caseTid);

        const transformedLog: ClientActivityLog[] = activityLog.map((log) => {
          const { newValues, oldValues } = log;

          const newValuesObject = parseLogData(newValues);
          const oldValuesObject = parseLogData(oldValues);
          const delta = getDelta(oldValuesObject, newValuesObject);

          return {
            log,
            newValuesObject,
            oldValuesObject,
            delta
          };
        });

        dispatch.eaArc.SET_ACTIVITY_LOG(transformedLog);
      } catch (e) {
        console.error(e);
      }
    },

    async getWhatHappenedReferenceData(referenceDataType: ReferenceType, _state: RootState): Promise<void> {
      const referenceData = await getReferenceData(referenceDataType);

      dispatch.eaArc.SET_WHAT_HAPPENED(
        referenceData?.items ?? []
      );

    }
  })
};

export const eaArc = createModel<RootModel>()(eaArcDataModel);
