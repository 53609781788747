/* eslint-disable react/boolean-prop-naming */
import { CellClickedEvent, ColDef, GridOptions, RowClassParams } from "ag-grid-community";
import { IsExternalFilterPresentParams, RowHeightParams } from "ag-grid-community/dist/lib/entities/iCallbackParams";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useMemo } from "react";

import { agGridDefaultColDef } from "@/common/components/AgGrid/ag-grid.config";
import { useDataGridStyles } from "@/common/components/AgGrid/DataGrid.styles";
import { ReactComponent as ExpandIcon } from "@/common/icons/Arrows/down-arrow-20-20.svg";
import { ReactComponent as CollapseIcon } from "@/common/icons/Arrows/up-arrow.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

type Props<T> = {
  id: string,
  data: Array<T>,
  columnDefs: Array<ColDef>,
  defaultColDef?: ColDef,
  rowClass?: string,
  isInInvertedTheme?: boolean,
  pagination: boolean,
  paginationPageSize: number,
  animateRows: boolean,
  suppressHorizontalScroll: boolean,
  suppressRowClickSelection: boolean,
  suppressDragLeaveHidesColumns: boolean,
  suppressCellFocus: boolean,
  domLayout: 'normal' | 'autoHeight' | 'print',
  gridOptions: GridOptions<T>,
  isWithExpandableRows: boolean,
  expandedRows: Map<number, boolean>,
  onRowExpandChanged: (rowIndex: number) => void,
  getExpandedRowHeight?: (params: RowHeightParams<T>) => number,
  isExternalFilterPresent?: (params: IsExternalFilterPresentParams<T>) => boolean,
  doesExternalFilterPass?: (node: RowNode<T>) => boolean,
  onCellClicked?: (event: CellClickedEvent<T>) => void,
  onRowClicked?: (event: RowClickedEvent<T>) => void,
}

const DataGridComponent: ForwardRefRenderFunction<AgGridReact, Props<Record<string, unknown>>> = ({
  id,
  data,
  columnDefs,
  rowClass,
  isInInvertedTheme,
  defaultColDef,
  pagination,
  paginationPageSize,
  animateRows,
  suppressHorizontalScroll,
  suppressRowClickSelection,
  suppressDragLeaveHidesColumns,
  suppressCellFocus,
  domLayout,
  gridOptions,
  isWithExpandableRows,
  expandedRows,
  onRowExpandChanged,
  getExpandedRowHeight,
  isExternalFilterPresent,
  doesExternalFilterPass,
  onCellClicked,
  onRowClicked
}, ref) => {

  const { classes } = useDataGridStyles(
    { hasData: data?.length > 0, searchInputPosition: undefined }
  );

  const getRowHeight = useCallback((params: RowHeightParams) => {
    return expandedRows.get(params.node.rowIndex as number) ? (getExpandedRowHeight?.(params) || 72) + 16 : 72;
  }, [expandedRows, getExpandedRowHeight]);

  const getRowStyles = useCallback((params: RowClassParams) => {
    return {
      maxHeight: expandedRows.get(params.rowIndex as number)
        ? (getExpandedRowHeight?.(params) || 72)
        : gridOptions?.rowStyle?.maxHeight ?? 56,
      backgroundColor: expandedRows.get(params.rowIndex as number) ?
        genericColorPalette.neutral[8] : genericColorPalette.neutral[7],
      boxSizing: 'border-box',
      overflow: 'hidden'
    };
  }, [ expandedRows, getExpandedRowHeight, gridOptions?.rowStyle?.maxHeight ]);

  const expandCellRenderer = useCallback((params: Record<string, string | number>): ReactNode => {
    const isRowExpanded = expandedRows?.get(params.rowIndex as number) || false;
    return (
      // eslint-disable-next-line react/jsx-no-bind
      <button className={ classes.expandButton } onClick={ () => onRowExpandChanged?.(params.rowIndex as number) }>
        { isRowExpanded ? <CollapseIcon/> : <ExpandIcon/> }
      </button>
    );
  }, [ expandedRows, classes.expandButton, onRowExpandChanged ]);

  const mappedColDefs: Array<ColDef> = useMemo(() => {
    if (!isWithExpandableRows) {
      return columnDefs;
    }

    return [
      ...columnDefs,
      {
        field: 'expand',
        headerName: '',
        width: 55,
        flex: 0,
        cellRenderer: expandCellRenderer
      }
    ];
  }, [ columnDefs, expandCellRenderer, isWithExpandableRows ]);

  return (
    <div id={ id } className={ `ag-theme-alpine-dark ag-theme-rt ${ isInInvertedTheme ? 'ag-inverted-theme-rt': '' }` }>
      <AgGridReact
        ref={ ref }
        defaultColDef={ {
          ...agGridDefaultColDef,
          ...defaultColDef
        } }
        rowData={ data }
        rowClass={ rowClass }
        columnDefs={ mappedColDefs }
        pagination={ pagination }
        paginationPageSize={ paginationPageSize }
        animateRows={ animateRows }
        suppressRowClickSelection={ suppressRowClickSelection }
        suppressHorizontalScroll={ suppressHorizontalScroll }
        suppressDragLeaveHidesColumns={ suppressDragLeaveHidesColumns }
        suppressCellFocus={ suppressCellFocus }
        domLayout={ domLayout }
        gridOptions={ {
          ...gridOptions
        } }
        isExternalFilterPresent={ isExternalFilterPresent }
        doesExternalFilterPass={ doesExternalFilterPass }
        getRowHeight={ getRowHeight }
        getRowStyle={ getRowStyles }
        onCellClicked={ onCellClicked }
        onRowClicked={ onRowClicked }
      />
    </div>
  );
};

export default forwardRef(DataGridComponent) as <T>(
  props: Props<T> & React.RefAttributes<AgGridReact>
) => JSX.Element;
