import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { MAX_RANK, MIN_RANK } from "@/tenant-context/core/config/ranking";

export enum TimeSliderType {
  hoursSlider = "Hours",
  daysSlider = "Days"
}

export type RankingSettingsState = {
  isShowOnlyHighestRanksForPeople: boolean,
  disabledAdapters: string[],
  probabilityThresholdMax: number,
  probabilityThresholdMin: number,
  probabilityThresholdPercentageUpperEnd: number,
  probabilityThresholdPercentageLowerEnd: number,
  isApplyProbabilityThreshold: boolean,
  timeSliderValueHours: number,
  timeTravelTargetEpoch: number,
  isGlobe: boolean,
  timeSliderType:TimeSliderType
}

const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_SECOND = 1000;

const rankingSettingsModel = ({
  name: 'rankingSettings',
  state: {
    isShowOnlyHighestRanksForPeople: true,
    isApplyProbabilityThreshold: false,
    probabilityThresholdPercentageUpperEnd: 100,
    probabilityThresholdPercentageLowerEnd: 0,
    probabilityThresholdMax: MAX_RANK,
    probabilityThresholdMin: MIN_RANK,
    disabledAdapters: [] as string[],
    timeSliderValueHours: 0,
    timeTravelTargetEpoch: 0,
    isGlobe: false,
    timeSliderType:TimeSliderType.daysSlider
  },
  reducers: {
    SET_IS_GLOBE(
      state: RankingSettingsState,
      isGlobe: boolean
    ) {
      return {
        ...state,
        isGlobe
      };
    },

    TOGGLE_APPLY_PROBABILITY_THRESHOLD(
      state: RankingSettingsState,
      isApply: boolean
    ) {
      return {
        ...state,
        isApplyProbabilityThreshold: isApply
      };
    },
    SET_TIME_SLIDER_TYPE(
      state: RankingSettingsState,
      timeSliderType : TimeSliderType
    ) {
      return {
        ...state,
        timeSliderValueHours: 0,
        timeTravelTargetEpoch: 0,
        timeSliderType: timeSliderType
      };
    },

    SET_TIME_SLIDER_VALUE(
      state: RankingSettingsState,
      { deltaHours, targetEpoch }: { deltaHours: number, targetEpoch: number }
    ) {

      return {
        ...state,
        timeSliderValueHours: deltaHours,
        timeTravelTargetEpoch: targetEpoch
      };
    },

    SET_ONLY_SHOW_HIGHEST_RANKS_FOR_PEOPLE(
      state: RankingSettingsState,
      show: boolean
    ) {
      return {
        ...state,
        isShowOnlyHighestRanksForPeople: show
      };
    },

    DISABLE_DATA_SOURCE(
      state: RankingSettingsState,
      payload: string
    ) {
      const {
        disabledAdapters
      } = state;

      const updatedDisabledDataSources = Array.from(new Set([
        ...disabledAdapters,
        payload
      ]));

      return {
        ...state,
        disabledAdapters: updatedDisabledDataSources
      };
    },

    ENABLE_DATA_SOURCE(
      state: RankingSettingsState,
      payload: string
    ) {
      const {
        disabledAdapters: disabledDataSources
      } = state;

      const updatedDisabledDataSources = disabledDataSources.filter(
        (source) => source !== payload
      );

      return {
        ...state,
        disabledAdapters: updatedDisabledDataSources
      };
    },

    RECALCULATE_MINIMUM_PROBABILITY_THRESHOLD(
      state: RankingSettingsState,
      percentage: number[]
    ) {
      const thresholdMin = (MAX_RANK * (percentage[0] / 100));
      const thresholdMax =  (MAX_RANK * (percentage[1] / 100));

      return {
        ...state,
        isApplyProbabilityThreshold:true,
        probabilityThresholdMin: thresholdMin,
        probabilityThresholdMax:thresholdMax,
        probabilityThresholdPercentageUpperEnd: percentage[1],
        probabilityThresholdPercentageLowerEnd: percentage[0]
      };
    },

    RESET_RANKING_SETTING_STATE( state: RankingSettingsState){
      return {
        ...state,
        isApplyProbabilityThreshold:false,
        probabilityThresholdPercentage: 100,
        probabilityThresholdPercentageUpperEnd: 100,
        probabilityThresholdPercentageLowerEnd: 0,
        probabilityThresholdMax: MAX_RANK,
        probabilityThresholdMin: MIN_RANK,
        timeSliderType:TimeSliderType.daysSlider,
        disabledAdapters: [] as string[],
        isGlobe: false
      };
    }
  },

  effects: (dispatch: Dispatch) => ({
    toggleShowOnlyHighestRanks(
      show: boolean
    ): void {
      dispatch.peopleLocations.unsubscribeFromPeopleLocationData();

      dispatch.rankingSettings.SET_ONLY_SHOW_HIGHEST_RANKS_FOR_PEOPLE(show);

      dispatch.peopleLocations.subscribeToPeopleLocationData();

      dispatch.peopleBreadcrumbs.UNSAFE_SET_IS_BREADCRUMB_LAYER_ENABLED(!show);
    },

    timeTravelTo(
      deltaHours: number
    ): void {
      const deltaMs = deltaHours
        * MINUTES_IN_HOUR
        * SECONDS_IN_MINUTE
        * MILLISECONDS_IN_SECOND;

      const timeTravelTargetEpoch = deltaMs === 0
        ? 0
        : Date.now() + deltaMs;

      dispatch.rankingSettings.SET_TIME_SLIDER_VALUE({
        deltaHours: deltaHours,
        targetEpoch: timeTravelTargetEpoch
      });

      dispatch.locationGraph.setTimeDomain(timeTravelTargetEpoch);

      dispatch.peopleLocations.unsubscribeFromPeopleLocationData();
      dispatch.peopleLocations.subscribeToPeopleLocationData();
    },

    resetRankingSettingsFilters(_: void, state: RootState):void {
      const { isShowOnlyHighestRanksForPeople,
        timeSliderValueHours,
        timeTravelTargetEpoch } = state.rankingSettings;

      if(!isShowOnlyHighestRanksForPeople){
        dispatch.rankingSettings.toggleShowOnlyHighestRanks(true);
      }
      if(!(timeSliderValueHours === 0 && timeTravelTargetEpoch === 0)){
        dispatch.rankingSettings.timeTravelTo(0);
      }
      dispatch.rankingSettings.RESET_RANKING_SETTING_STATE();
      dispatch.rankingSettings.RECALCULATE_MINIMUM_PROBABILITY_THRESHOLD([0,100]);
    }
  })
});

export const rankingSettings = createModel<RootModel>()(
  rankingSettingsModel
);