/* eslint-disable no-magic-numbers,max-lines */
import { IndoorMap, IndoorMapGeoJSON, MapboxMapWithIndoor } from '@f/map-gl-indoor';
import default_layers from '@f/map-gl-indoor/style/default_layers.json';
import { useMantineTheme } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useMap } from 'react-map-gl';
import { useDispatch } from 'react-redux';

import { Dispatch } from '@/core/store';
import { getIndoorMapLayers } from "@/tenant-context/plugin-indoors/layers/IndoorsMapLayer/layers";

type Props = {
  indoorsMapData: IndoorMapGeoJSON
}

const IndoorsMapLayer: FC<Props> = ({
  indoorsMapData
}) => {
  const map = (useMap().current?.getMap()) as MapboxMapWithIndoor | undefined;
  const [isAdded, setIsAdded] = useState(false);
  const theme = useMantineTheme();

  const {
    indoor: {
      SET_LEVEL,
      SET_IS_SITE_LOADED,
      SET_CURRENT_SITE_ID,
      fetchIndoorPeopleFromGlobalPeople
    },
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    if (!map || !map.indoor || isAdded) {
      return;
    }

    const indoorsMap = IndoorMap.fromGeojson(indoorsMapData, {
      siteId: 'Dubai Office', // ToDo: need to set the real value here
      beforeLayerId: 'r__peopleLocationSnapshotLayer',
      // Hide conflicting layers (POI in this case)
      layersToHide: [
        'poi-label'
      ],
      layers: [
        ...default_layers,
        ...getIndoorMapLayers(theme)
      ]
    });

    map.indoor.addMap(indoorsMap);

    const handleLevelChange = ({ level }: { level: number }) => {
      SET_LEVEL(level);
    };

    map.on('indoor.level.changed', handleLevelChange);
    map.on('indoor.map.loaded', (ev: { indoorMap: IndoorMap }) => {
      SET_LEVEL(ev.indoorMap.defaultLevel);
      SET_IS_SITE_LOADED(true);
      SET_CURRENT_SITE_ID(ev.indoorMap.siteId);
      // DISABLE_LAYER_TYPE([ToggleableLayerType.People]);
      fetchIndoorPeopleFromGlobalPeople();
    });
    map.on('indoor.map.unloaded', () => {
      SET_LEVEL(undefined);
      SET_IS_SITE_LOADED(false);
      SET_CURRENT_SITE_ID(undefined);
      // ENABLE_LAYER_TYPE([ToggleableLayerType.People]);
      fetchIndoorPeopleFromGlobalPeople();
    });

    setIsAdded(true);
  }, [
    map,
    indoorsMapData,
    isAdded,
    SET_LEVEL,
    SET_IS_SITE_LOADED,
    SET_CURRENT_SITE_ID,
    theme,
    DISABLE_LAYER_TYPE,
    ENABLE_LAYER_TYPE,
    fetchIndoorPeopleFromGlobalPeople
  ]);

  return null;
};

export default IndoorsMapLayer;
