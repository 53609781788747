import { Carousel } from "@mantine/carousel";
import { Button, Drawer, Loader, LoadingOverlay } from "@mantine/core";
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import Spinner from "@/common/components/Spinner";
import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import usePermission from '@/common/hooks/usePermission';
import { ReactComponent as RightIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as TravellersIcon } from "@/common/icons/travel.svg";
import { Products } from "@/common/types/products";
import { TestableComponent } from "@/common/types/testable-component";
import { Dispatch, RootState } from "@/core/store";
import {
  TRAVELLERS_SEARCH_DRAWERS,
  TRAVELLERS_SEARCH_SUB_SLIDES
} from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import {
  useTravellersSearchContext
} from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import {
  useTravelSearchControlStyles
} from "@/tenant-context/control-travellers-search/controls/TravellersSearchControl/TravellersSearchControl.styles";
import CountryDetailViewDrawer from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer";
import TravellersByCountryDrawer from "@/tenant-context/control-travellers-search/drawers/TravellersByCountryDrawer";
import TravellersMiniProfileDrawer
  from "@/tenant-context/control-travellers-search/drawers/TravellersMiniProfileDrawer";
import TravellersSearchControlsDrawer
  from "@/tenant-context/control-travellers-search/drawers/TravellersSearchControlsDrawer";

import { TravelSearchPolicies } from "../../config/TravellersSearch.policies";

const TravellersSearchControl: FC<TestableComponent> = ({ dataTestId }) => {
  const isTravellersByCountryDrawerOpen = useSelector((state: RootState) =>
    state.drawer?.currentlyOpenRightDrawerId === TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_CONTROL);
  const subscribedProducts = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts);

  const {
    currentlyOpenedDrawer,
    currentlyOpenedSlide,
    setCurrentlyOpenedDrawer,
    travellersSearchForm,
    setSubDrawerCarousel,
    resetContext,
    isLoading,
    searchTravellers
  } = useTravellersSearchContext();

  const {
    travellersSearch: {
      RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE
    },
    dataOptions: {
      DISABLE_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  const { classes } = useTravelSearchControlStyles();

  const isDrawerOpen = isTravellersByCountryDrawerOpen
    && currentlyOpenedDrawer === TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_SUB_DRAWER;

  const isSubscribedToTravel = useMemo(() => {
    return subscribedProducts?.some(product => product.name === Products.TRAVEL);
  }, [subscribedProducts]);

  const isAuthorised = usePermission([TravelSearchPolicies.TRAVEL_SEARCH_POLICY]);

  const subDrawerTitle = (<div className={ classes.drawerTitle }>
    Travellers by country
  </div>);

  const subDrawerStyles = useCallback(() => ({
    drawer: {
      margin: '0 calc(255px) 0 0'
    }
  }), []);

  const handleSearchTravellers = useCallback(() => {
    DISABLE_LAYER_TYPE([
      ToggleableLayerType.TravelCountriesView,
      ToggleableLayerType.TravelCountryDetailsView,
      ToggleableLayerType.CountryRiskLevel
    ]);
    searchTravellers();
  }, [ DISABLE_LAYER_TYPE, searchTravellers ]);

  const renderButton = useCallback(({ onClick }) => (
    <ButtonControl
      className={ classes.travelButton }
      onClick={ onClick }
      label="Travellers"
      data-testid={ dataTestId }
    >
      <TravellersIcon/>
    </ButtonControl>
  ), [classes.travelButton, dataTestId]);

  const handleCloseClick = useCallback(() => {
    RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE();
    resetContext();
    setCurrentlyOpenedDrawer(undefined);
    travellersSearchForm.reset();
  }, [RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE, resetContext, setCurrentlyOpenedDrawer, travellersSearchForm]);

  const onSubDrawerClose = useCallback(() => {
    setCurrentlyOpenedDrawer(undefined);
  }, [ setCurrentlyOpenedDrawer ]);

  if (!isSubscribedToTravel || !isAuthorised) {
    return null;
  }

  return (
    <>
      <DrawerControl
        id={ TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_CONTROL }
        title="Travellers"
        size="255px"
        zIndex={ 999 }
        shadow="-4px 0px 10px 0px rgba(0, 0, 0, 0.25)"
        renderButton={ renderButton }
        onCloseClick={ handleCloseClick }
        footer={ <Button
          fullWidth
          rightIcon={ isLoading ? <Loader size="xl" variant="oval" /> :<RightIcon/> }
          onClick={ handleSearchTravellers }
        >
          Search
        </Button> }
        explainer={ 'Track and monitor your active and upcoming travellers and potential travel risks.' }
      >
        <TravellersSearchControlsDrawer/>
      </DrawerControl>

      <Drawer
        id={ TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_SUB_DRAWER }
        position={ 'right' }
        opened={ isDrawerOpen }
        onClose={ onSubDrawerClose }
        withCloseButton={ false }
        title={ currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_BY_COUNTRY_SLIDE
          ? subDrawerTitle : null }
        styles={ subDrawerStyles }
      >
        <LoadingOverlay
          visible={ isLoading }
          overlayBlur={ 2 }
          loader={ <div className={ classes.loader }>
            <Spinner/>
            <div>Loading data...</div>
          </div> }
        />

        <Carousel
          getEmblaApi={ setSubDrawerCarousel }
          draggable={ false }
          withControls={ false }
          withKeyboardEvents={ false }
          withIndicators={ false }
          slideSize={ '328px' }
          slideGap={ 0 }
          align={ 'start' }
          speed={ 50 }
          /* eslint-disable-next-line react/jsx-no-bind */
          onSlideChange={ () => {
            // Not a good practice to call document.getElementById in React. But in this case there's no way to get a ref
            // to the drawer parent element. Hence, did this, prolly need to refactor later. Not an issue for now
            const drawerElem = document.getElementById(`${TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_SUB_DRAWER}-body`)?.parentElement;
            drawerElem?.scrollTo(0, 0);
          } }
        >
          <Carousel.Slide>
            <TravellersByCountryDrawer/>
          </Carousel.Slide>
          <Carousel.Slide>
            { (currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.COUNTRY_DETAIL_VIEW_SLIDE
              || currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_MINI_PROFILE_SLIDE)
              ? <CountryDetailViewDrawer/> : null }
          </Carousel.Slide>
          <Carousel.Slide>
            { currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_MINI_PROFILE_SLIDE
              ? <TravellersMiniProfileDrawer/> : null }
          </Carousel.Slide>
        </Carousel>
      </Drawer>
    </>
  );
};

export default TravellersSearchControl;
