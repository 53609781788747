import { FC } from "react";

import useSite from "@/tenant-context/plugin-indoors/hooks/useSite";

import IndoorsSiteLayer from './IndoorsSiteLayer.component';

const IndoorsSiteLayerContainer: FC = () => {
  const site = useSite();

  return (
    <IndoorsSiteLayer
      site={ site }
    />
  );
};

export default IndoorsSiteLayerContainer;