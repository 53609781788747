import type { Map } from "mapbox-gl";
import { createContext, FC, useMemo, useState } from "react";

export type MiniMapContextType = {
  miniMap: Map | null,
  setMiniMap: (map: Map) => void
}

export const MiniMapContext = createContext<MiniMapContextType>(
  {} as MiniMapContextType
);

const MiniMapProvider: FC = ({ children }) => {
  const [miniMap, setMiniMap] = useState<Map | null>(null);

  const contextValue = useMemo(
    () => ({ miniMap, setMiniMap }),
    [miniMap, setMiniMap]
  );

  return (
    <MiniMapContext.Provider value={ contextValue }>
      { children }
    </MiniMapContext.Provider>
  );
};

export default MiniMapProvider;