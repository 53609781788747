import { closeAllModals } from "@mantine/modals";
import { createContext, FC } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import { CompanyDetailsModalFormData } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/CompanyDetailsModal/CompanyDetailsModal.component";
import { ProfileListItem } from "@/tenant-context/control-profile/types/profile-list";

type CompanyDetailsModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn,
  managerProfiles?: ProfileListItem[],
};

export const CompanyDetailsModalContext = createContext<CompanyDetailsModalContextType>(
  {} as CompanyDetailsModalContextType
);

export const CompanyDetailsModalContextProvider: FC = ({
  children
}) => {
  const formControls = useForm();

  const {
    profile: {
      updateCompanyDetails
    },
    userProfile: {
      reloadMiniProfileData
    }
  } = useDispatch<Dispatch>();
  
  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as CompanyDetailsModalFormData;

    const success = await updateCompanyDetails(data);
    
    if(success){
      closeAllModals();
      reloadMiniProfileData();
    }

  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <CompanyDetailsModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls
    }) }>
      { children }
    </CompanyDetailsModalContext.Provider>
  );
};
