import { Point, Position } from "geojson";

const ANTIMERIDIAN = 180;
const LATTITUDE_OFFSET = 0.05;
const TOPBAR_OFFSET = 60;
const POPUP_OFFSET = 450;
const X_POPUP_OFFSET = 250;
export const getPopupOrDrawerPosition = (
  mapBound:  mapboxgl.LngLatBounds | undefined,
  pointPostion: Point,
  panelPosition: mapboxgl.LngLat | undefined = undefined,
  isDrawer = false
): Position => {
  const pointLongtitude = pointPostion.coordinates[0];
  const pointLatitude = pointPostion.coordinates[1];
 
  const eastBound = mapBound?.getEast() ?? 0;
  const westBound = mapBound?.getWest() ?? 0;
  const northBound = mapBound?.getNorth() ?? 0;
  const southBound = mapBound?.getSouth() ?? 0;

  if(eastBound === 0 && westBound === 0){
    return [pointLongtitude, pointLatitude] as Position;
  }
  const diffLattidude  = ((northBound - southBound) / 2) ;
  
  if(isDrawer && panelPosition){
    // 0.05 LATTITUDE_OFFSET added to get the curve of line 
    return [panelPosition.lng, 
      southBound + diffLattidude + LATTITUDE_OFFSET] as Position;
  }

  //calculate gap between risk alert point and East view port bound
  const diffEast = getDiffEast(pointLongtitude,eastBound);
  // calculate gap between risk alert point and West view port bound
  const diffWest = getDiffWest(pointLongtitude, westBound);
  
  //finding the view port's middle lattitude
  

  // when popup should be show between risk point and East bound
  if(diffEast > diffWest){
    // eslint-disable-next-line no-magic-numbers
    const distanceFromPopUp = (diffEast / 4);
    if(pointLongtitude > 0){
      if((pointLongtitude + distanceFromPopUp) > ANTIMERIDIAN){
        return [(pointLongtitude + distanceFromPopUp), southBound + diffLattidude] as Position;
      }

      return [pointLongtitude + distanceFromPopUp, southBound + diffLattidude] as Position;
    } else {
      return [pointLongtitude + distanceFromPopUp, southBound + diffLattidude] as Position;
    }
  } else {
    // when popup should be show between risk point and West bound
    // eslint-disable-next-line no-magic-numbers
    const distanceFromPopUp = diffWest / 4;
    if(pointLongtitude > 0){
      return [pointLongtitude - distanceFromPopUp, southBound + diffLattidude] as Position;
    } else {
      if(pointLongtitude - distanceFromPopUp < (-1 * ANTIMERIDIAN)){
        return [pointLongtitude - distanceFromPopUp, southBound + diffLattidude] as Position;
      } else {
        return [pointLongtitude - distanceFromPopUp, southBound + diffLattidude] as Position;
      }
    }
  }
};

//calculate gap between risk alert point and East view port bound
const getDiffEast = (pointLongtitude:number, eastBound:number) => {
  // when risk alert point longtitude is > 0
  if(pointLongtitude > 0){
    if(eastBound > 0 && eastBound > ANTIMERIDIAN){
      // when eastbound is greater the 180 degree line (This happens when 180 line resides left to the eastbound)
      // mapox always gives 180+ value in this scenario
      return (eastBound % ANTIMERIDIAN) + (ANTIMERIDIAN - pointLongtitude);
    } else if(pointLongtitude < eastBound){ 
      // when pointLongtitude < eastBound
      return eastBound - pointLongtitude;
    } else if(pointLongtitude > eastBound && eastBound < 0){
      // when eastbound is < 0 and zero degree line just left to the east bound
      return (ANTIMERIDIAN - pointLongtitude) + (ANTIMERIDIAN - (-1*eastBound));
    } else if(pointLongtitude > eastBound  && eastBound > 0){
      // when 180 line shown twice in the viewport
      // this scenario can be eliminated by limiting the map zoom level
      return (ANTIMERIDIAN-pointLongtitude) + ANTIMERIDIAN + eastBound;
    } 
  } else {
    if(pointLongtitude < eastBound && eastBound > 0){
      // when risk alert point is less than zero and east bound is greater than zero
      return eastBound + (-1* pointLongtitude);
    } else if(pointLongtitude < eastBound && eastBound < 0){
      // when both risk point and eastbound is less than zero
      return (-1* pointLongtitude) - (-1* eastBound);
    }
  }

  return 0;
};

const getDiffWest = (pointLongtitude:number, westBound:number) => {
  if(pointLongtitude > 0){
    if(westBound < 0 && westBound < (-1*ANTIMERIDIAN)){
      //when westbound is closer to 180 degree line
      //In this case westbound is less than -180
      return pointLongtitude - (-1*(westBound % ANTIMERIDIAN));
    } else if(pointLongtitude > westBound && westBound > 0){
      //when west bound is greater than zero
      return pointLongtitude - westBound;
    } else if(pointLongtitude > westBound && westBound < 0){
      //when west bound is less than zero
      return pointLongtitude + (-1*westBound);
    } else if(pointLongtitude < westBound && westBound > 0){
      // when 180 line shown twice in the viewport
      // this scenario can be eliminated by limiting the map zoom level
      return pointLongtitude + ANTIMERIDIAN + ANTIMERIDIAN - westBound;
    }
  } else {
    if(pointLongtitude > westBound && westBound < 0){
      // when risk alert point is less than zero and west bound is less than zero
      return (-1 * westBound) - (-1 * pointLongtitude);
    } else if(pointLongtitude < westBound && westBound > 0){
      // when west bound is greater than zero and longtitude 
      return ANTIMERIDIAN-(-1*pointLongtitude) + (ANTIMERIDIAN - westBound);
    } else if(pointLongtitude < westBound && westBound < 0){
      // when west bound is less than zero risk alert point is less than west bound
      return ANTIMERIDIAN - (-1 * pointLongtitude) + ANTIMERIDIAN + (-1 * westBound);
    }
  }

  return 0;
};

export const getPopupPositionByXYCoords = (pointX:number, pointY:number) => {

  // check popup should show on left to the risk point or right to the risk point
  const xAxisOffset = pointX > (window.screen.width - pointX)
    ? pointX - X_POPUP_OFFSET
    : pointX + X_POPUP_OFFSET;
  
  // check popup should show on top the risk point or bottom to the risk point
  const screenHight = window.screen.height;
  if( pointY > (screenHight - pointY)){
    const yAxisOffset = (pointY - (pointY / 2) + TOPBAR_OFFSET);
    return [xAxisOffset, yAxisOffset];
  } else{ 
    const yAxisOffset = pointY + (screenHight - pointY)/ 2;
    if(yAxisOffset + POPUP_OFFSET > screenHight){
      return [xAxisOffset, (yAxisOffset - POPUP_OFFSET/2)];
    }

    return [xAxisOffset, yAxisOffset];
  }
};
