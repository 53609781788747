import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as OfflineIcon } from '@/common/icons/cloud-offline.svg';
import { ReactComponent as OnlineIcon } from '@/common/icons/cloud-online.svg';
import { Dispatch, RootState } from "@/core/store";
import { getCountryNameByCode } from "@/tenant-context/common/util/country-to-iso-map";
import { LocationAddress } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import {
  SITE_INFO_CONTROL_DRAWER_ID
} from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import { navigateToSitePage } from "@/tenant-context/control-site/util/util";
import { useSiteLocationPopupStyles } from "@/tenant-context/visualisation-site/components/SiteLocationPopup/SiteLocationPopup.styles";
import { policiesToNavigateSite } from '@/tenant-context/visualisation-site/config/site-location-layer.config';
import { SitePeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

import CritLocationControlComponent from "./CritLocationControl.component";

const CritLocationControl: FC = () => {
  const openDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);
  const selectedSite = useSelector((state: RootState) => state.commonData?.selectedSite);
  const locationZonesData = useSelector((state: RootState) => state.manageLocations?.locationZonesData);
  const locationBuildingData = useSelector((state: RootState) => state.manageLocations?.locationBuildingData);

  const {
    siteLocations: {
      processSiteConnectivity
    },
    manageLocations:{
      getLocationZonesData,
      getBuildingList,
      SET_LOCATION_ZONES_DATA,
      SET_LOCATION_BUILDING_DATA
    }
  } = useDispatch<Dispatch>();

  const isSitesAuthorized = usePermission({ policyNames: policiesToNavigateSite });
  const { classes: popupClasses } = useSiteLocationPopupStyles();
  const unauthorizedAction = useUnauthorizedModal();
  const activeSite = useMemo(() => {
    // eslint-disable-next-line fp/no-let
    let activeSiteData: SitePeopleCount = {
      code: '',
      isMusterActive: false,
      musterCount: 0,
      total: 0,
      visitorsNext24Hrs: 0,
      visitorsOnSite: 0,
      countryName: '',
      address: '',
      description: '',
      siteContactId: '',
      ...selectedSite

    };

    locationsPeopleCount.countries.forEach(
      (country) => {
        return country.sites.forEach((site) => {
          if (site.code === selectedSite?.code) {
            activeSiteData = { ...site, ...selectedSite };
          } else {
            return true;
          }
        });
      }
    );

    return activeSiteData;
  }, [locationsPeopleCount, selectedSite]);

  const siteAddressString = useMemo(() => {
    const { firstLine, secondLine, thirdLine, city } = activeSite.address as LocationAddress;
    const address = `${firstLine || ''} ${secondLine || ''} ${thirdLine || ''}`.trim() + `${city ? `, ${city}` : ''}`;
    return address.trim();
  }, [activeSite.address]);
    
  const musterCount = activeSite.musterCount ?? 0;
  const isMuster = activeSite.isMusterActive ?? false;
  const peopleCount = activeSite.total ?? 0;
  const siteName = activeSite.name ?? '';
  const siteAddress = siteAddressString;
  const countryCode = (activeSite?.address as LocationAddress).country;
  const siteCountry = countryCode ? getCountryNameByCode(countryCode) || '' : '';
  const siteImgUrl = activeSite?.imageUrl ?? '';
  const siteLocType = activeSite?.locationType ?? '';
  const siteId = activeSite?.id;
  const subCategory = activeSite?.subCategory;
  const navigateToSite = useCallback(() => {
    if (!isSitesAuthorized) {
      unauthorizedAction();
      return;
    }

    if (!selectedSite?.code) {
      return;
    }

    navigateToSitePage(selectedSite.code, 'Site');
  }, [isSitesAuthorized, selectedSite, unauthorizedAction]);


  const handleSiteStatus = useCallback((locationId: string) => {
    const siteStatus = processSiteConnectivity(locationId);
    if (siteStatus.status === 'Online') {
      return (<div className={ popupClasses.siteStatusContainer }><OnlineIcon /> { siteStatus.status }</div>);
    } else {
      return (<div className={ popupClasses.siteStatusContainer }><OfflineIcon />{ `${siteStatus.status} ${siteStatus.duration}` }</div>);
    }
  }, [processSiteConnectivity, popupClasses.siteStatusContainer]);

  useEffect(()=>{
    if(selectedSite?.id && selectedSite.id !== "all"){

      //clear state
      if(!openDrawerId){
        SET_LOCATION_ZONES_DATA(undefined);
        SET_LOCATION_BUILDING_DATA(undefined);
        return;
      }

      if(openDrawerId === SITE_INFO_CONTROL_DRAWER_ID){
        getLocationZonesData({
          gridParams:{
            page: 0,
            size: 5,
            additionalParams: {
              locationCategory: selectedSite.subCategory
            }
          },
          locationId: selectedSite.id
        });
        getBuildingList({
          gridParams:{
            page: 0,
            size: 5
          },
          locationId: selectedSite.id
        });
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, openDrawerId]);

  if (openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID) {
    return null;
  }

  return (
    <CritLocationControlComponent
      zonesCount={ locationZonesData?.totalItems || 0 }
      buildingCount={ locationBuildingData?.totalItems || 0 }
      peopleCount={ peopleCount }
      musterCount={ musterCount }
      isMuster={ isMuster }
      navigateToSite={ navigateToSite }
      isSitesAuthorized={ isSitesAuthorized }
      siteName={ siteName }
      siteAddress={ siteAddress }
      siteImageUrl={ siteImgUrl }
      siteLocationType={ siteLocType }
      siteId={ siteId }
      onSiteStatus={ handleSiteStatus }
      subCategory={ subCategory }
      siteCountry={ siteCountry }
    />
  );
};

export default CritLocationControl;
