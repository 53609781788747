import { closeModal, openModal } from "@mantine/modals";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "@/common/util/format/date";
import { Dispatch, RootState } from "@/core/store";
import { changeArcPersonStatus } from "@/tenant-context/control-action-response-center/api/risk-alert";
import {
  ImpactStatusChangeModalComponent
} from "@/tenant-context/control-action-response-center/components/ImpactTab/components/ImpactStatusChangeModal";
import ImpactTabComponent from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.component";
import {
  ImpactPeopleGridData,
  ImpactPeopleGridDataList
} from "@/tenant-context/control-action-response-center/components/ImpactTab/types/ImpactTab.types";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { getTime } from "@/tenant-context/control-profile/util/time-convector";
import { handleAdapterSourceDisplay, handleLocationTypeDisplay } from "@/tenant-context/visualisation-people/util/utils";

import { useImpactTabStyles } from "./ImpactTab.styles";

const ImpactTab: FC = () => {
  const [ searchString, setSearchString ] = useState('');
  const [ expandedRows, setExpandedRows ] = useState<Map<number, boolean>>(new Map());

  const { arcCase, rawImpactPeople, handleRowExpand } = useContext(ArcContext);
  const tenantId = useSelector((state: RootState) => state.commonData.tenantId);
  const selectedImpactStatus = useSelector((state: RootState) => state.arc.selectedImpactStatus);

  const [isProfileOpened, setIsProfileOpened] = useState(false);

  const {
    userProfile:{
      SET_CURRENT_USER_ID,
      SET_CURRENT_USER_PROFILE_ID
    }
  } = useDispatch<Dispatch>();

  const { classes } = useImpactTabStyles();

  const {
    arc: {
      SET_SELECTED_IMPACT_STATUS
    }
  } = useDispatch<Dispatch>();

  const rowData: ImpactPeopleGridDataList = useMemo(() => {
    if (!rawImpactPeople || rawImpactPeople.length === 0) {
      return [];
    }

    return rawImpactPeople
      .filter((impactItem) =>
        selectedImpactStatus === undefined || impactItem.profileStatus === selectedImpactStatus)
      .map((person) => ({
        name:  `${person.fistName} ${person.lastName}`,
        nameExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            { person.personDetails?.company }
          </span>
          { person.personDetails?.jobTitle }
        </div>,
        personId: person.personId,
        personType: person.personType || "N/A",
        personTypeExpanded: '',
        locationType: person.locationType ? handleLocationTypeDisplay(person.locationType) : "N/A",
        locationTypeExpanded:  <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            { person.topRank?.location.type }
          </span>
          <span className={ classes.accented + " " + classes.underline }>
            Confirmed
          </span>
          <span className={ classes.dimmed }>
            DATA VIA:
          </span>
          { handleAdapterSourceDisplay(person.topRank?.adapterSource || "N/A") }
        </div>,
        location: person.impactLocation || "N/A",
        locationExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            LAST KNOWN LOCATION
          </span>
          Via { handleAdapterSourceDisplay(person.topRank?.adapterSource || "N/A") }
          <span className={ classes.accented  }>
            { person.geoLocation?.place_name }<br/>
            { person.geoPoint.lon } - { person.geoPoint.lat }
          </span>
          <span className={ classes.dimmed }>
            SINCE
          </span>
          <span className={ classes.underline }>
            { getTime(person.topRank?.endTime, true) } AGO
          </span>
          <span className={ classes.dimmed }>
            { formatDate(new Date(person.topRank?.endTime || new Date())) }
          </span>
        </div>,
        lastContacted: '22 Jun 2023 7:00 UTC',
        lastContactedExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            CHANNEL
          </span>
          Notification
        </div>,
        response: 'Yes',
        responseExpanded: <div className={ classes.textWrap }>
          <span className={ classes.dimmed }>
            DELIVERED
          </span>
          <span className={ classes.underline }>
            Email
          </span>
          <span className={ classes.dimmed }>
            22nd Jun 2023 - 16:30 UTC
          </span>
        </div>,
        safetyStatus: person.profileStatus,
        geoPoint: person.geoPoint
      } as ImpactPeopleGridData))
      .filter((person) => person.name.toLowerCase().includes(searchString.toLowerCase()));
  }, [ rawImpactPeople, selectedImpactStatus, searchString, classes.accented,
    classes.dimmed,classes.textWrap, classes.underline ]);

  const handleStatusFilterChange = useCallback((status: ArcImpactStatus | 'ALL') => {
    SET_SELECTED_IMPACT_STATUS(status === 'ALL' ? undefined : status);
  }, [ SET_SELECTED_IMPACT_STATUS ]);

  const handleSearchStringChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  }, [ setSearchString ]);

  const handleExpandRowChange = useCallback((expandedRowsMap: Map<number, boolean>) => {
    const expandedRow = Array.from(expandedRowsMap.entries()).filter(([, isExpanded]) => isExpanded);

    if (expandedRow.length > 0) {
      handleRowExpand(rowData[expandedRow[0][0]].personId).then(() => {
        setExpandedRows(expandedRowsMap);
      });
    } else {
      setExpandedRows(expandedRowsMap);
    }
  }, [ handleRowExpand, rowData ]);

  const handleManualStatusChange = useCallback(async (
    personId: string,
    status: ArcImpactStatus,
    notes: string
  ) => {
    if (!arcCase || !personId) {
      return;
    }

    await changeArcPersonStatus(
      personId,
      notes,
      arcCase.caseId,
      status,
      tenantId
    );

    closeModal('arcUpdateSafetyStatusModal');
  }, [ arcCase, tenantId ]);

  const handleManualStatusChangeRequest = useCallback((
    personId: string,
    personName: string,
    personStatus: ArcImpactStatus
  ) => {
    openModal({
      modalId: 'arcUpdateSafetyStatusModal',
      title: 'Update safety status',
      children: (
        <ImpactStatusChangeModalComponent
          personName={ personName }
          status={ personStatus }
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={ (updatedPersonStatus: ArcImpactStatus, notes: string) =>
            handleManualStatusChange(personId, updatedPersonStatus, notes) }
          // eslint-disable-next-line react/jsx-no-bind
          onCancel={ () => {
            closeModal('arcUpdateSafetyStatusModal');
          } }
        />
      )
    });
  }, [ handleManualStatusChange ]);

  const handleNavigateToPersonProfileRequested = useCallback((personId: string) => {
    SET_CURRENT_USER_ID(personId);
    SET_CURRENT_USER_PROFILE_ID(personId);
    setIsProfileOpened(true);
  }, [SET_CURRENT_USER_ID, SET_CURRENT_USER_PROFILE_ID]);

  return (
    <ImpactTabComponent
      rowData={ rowData }
      expandedRows={ expandedRows }
      selectedImpactStatus={ selectedImpactStatus }
      onSearchStringChange={ handleSearchStringChange }
      onStatusFilterChange={ handleStatusFilterChange }
      onRowExpandChanged={ handleExpandRowChange }
      onManualStatusChangeRequest={ handleManualStatusChangeRequest }
      onNavigateToPersonProfileRequested={ handleNavigateToPersonProfileRequested }
      handleRowExpand = { handleRowExpand }
      isProfileOpened = { isProfileOpened }
      setIsProfileOpened = { setIsProfileOpened }
    />
  );
};

export default ImpactTab;
