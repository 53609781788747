import { createStyles } from "@mantine/core";

export const useDataOptionsControlStyles = createStyles((theme) => ({
  divider: {
    marginTop: 32,
    marginBottom: 32
  },

  toggleList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },

  listItem: {
    display: 'flex'
  },

  itemDescription: {
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },

  listItemRightSpan: {
    all: 'unset',
    display: 'flex',
    alignItems: 'center'
  },

  navigationButton: {
    marginLeft: 20
  },

  switchLabel: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'fit-content'
  },

  mainToggle: {
    height: 58,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -24,
    marginRight: -24,
    marginBottom: 32,
    padding: '0 24px',
    backgroundColor: theme.colors.neutral[8],

    '& > *': {
      flex: 1
    }
  }
}));
