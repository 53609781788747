import { createStyles } from "@mantine/core";

export const useBasemapLayerToggleControlStyles = createStyles((theme) => ({
  divider: {
    marginTop: 32,
    marginBottom: 32
  },
  itemIcon: {
    marginRight: 4
  },
  li: {
    '& > *': {
      width: '100%',

      '& > :nth-of-type(2)': {
        width: '100%'
      }
    }
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  listItemText: {
    display: 'block',
    width: '100%',
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.regular
  }
}));
