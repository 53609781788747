import { ActionIcon, Image, Switch, Text } from "@mantine/core";
import { FC } from "react";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { ReactComponent as ForwardIcon } from "@/common/icons/Arrows/chevron-forward.svg";
import { ReactComponent as AssetLocationIcon } from "@/common/icons/DataOptionControlIcons/asset-locations-list-icon.svg";
import { ReactComponent as busStationIcon } from "@/common/icons/DataOptionControlIcons/Bus.svg";
import { ReactComponent as BusinessAccommodationIcon } from "@/common/icons/DataOptionControlIcons/Business Accommodation.svg";
import { ReactComponent as carHiresIcon } from "@/common/icons/DataOptionControlIcons/CarHire.svg";
import { ReactComponent as embarkationIcon } from "@/common/icons/DataOptionControlIcons/EmbarkDisembark.svg";
import { ReactComponent as harbourIcon } from "@/common/icons/DataOptionControlIcons/Harbour.svg";
import { ReactComponent as HeliportIcon } from "@/common/icons/DataOptionControlIcons/Heliport.svg";
import { ReactComponent as railStationIcon } from "@/common/icons/DataOptionControlIcons/Rail.svg";
import { ReactComponent as GlobeLight } from "@/common/icons/globe-light.svg";
import { ReactComponent as LocationLabelLayer } from "@/common/icons/location-label-layer.svg";
import { LayerType } from "@/tenant-context/core/controls/DataOptionsControl/DataOptionsControl.component";

type Props = {
  classes: Record<string, string>,
  disabledLayers: string[],
  onLayerToggle: React.ChangeEventHandler<HTMLInputElement>,
}

export const AssetLocationLayers: FC<Props> = ({
  classes,
  disabledLayers,
  onLayerToggle
}) => {

  const assetLocationLayerTypes: LayerType[] = [
    { label: 'Customer General Accommodations', type: ToggleableLayerType.CustomerGeneralAccommodations, Icon: BusinessAccommodationIcon },
    { label: 'Customer Heliports', type: ToggleableLayerType.CustomerHeliports, Icon: HeliportIcon },
    { label: 'Customer Harbours', type: ToggleableLayerType.CustomerHarbors, Icon: harbourIcon },
    { label: 'Customer Rail Stations', type: ToggleableLayerType.CustomerRailStations, Icon: railStationIcon },
    { label: 'Customer Bus Stations', type: ToggleableLayerType.CustomerBusStations, Icon: busStationIcon },
    { label: 'Customer Car Hires', type: ToggleableLayerType.CustomerCarHires, Icon: carHiresIcon },
    { label: 'Customer Embarkation Points', type: ToggleableLayerType.CustomerEmbarkationPoints, Icon: embarkationIcon },
    { label: 'All Common Locations', type: ToggleableLayerType.AllCommonLocations, Icon: GlobeLight },
    { label: 'Common Regions', type: ToggleableLayerType.CommonRegions, Icon: LocationLabelLayer }
  ];

  return (
    <div>
      <div className={ classes.mainToggle }>
        <li className={ classes.listItem }>
          <label className={ classes.switchLabel } htmlFor={ `doc-switch-all-asset-locations` }>
            <div className={ classes.itemDescription }>
              <AssetLocationIcon />

              <Text size="lg" weight="700">
                Asset Locations
              </Text>
            </div>

            <Switch
              id={ `doc-switch-asset-locations` }
              onChange={ onLayerToggle }
              checked={ !disabledLayers.includes(ToggleableLayerType.AssetLocations) }
              value={ ToggleableLayerType.AssetLocations }
            />
          </label>
        </li>
      </div>
      <ul className={ classes.toggleList }>
        { assetLocationLayerTypes.map(({
          type,
          label,
          Icon,
          image,
          isNavigable
        }) => (
          <li className={ classes.listItem } key={ type }>
            <label className={ classes.switchLabel } htmlFor={ `doc-switch-${type}` }>
              <div className={ classes.itemDescription }>
                { (Icon && (
                  <Icon
                    className={ type }
                    width="24"
                    height="35"
                    preserveAspectRatio=""
                  />
                )) ?? null }

                { (image && (
                  <Image
                    src={ image }
                    width="24px"
                    height="35px"
                  />
                )) ?? null }

                <span>
                  { label }
                </span>
              </div>

              { isNavigable && (
                <div className={ classes.listItemRightSpan }>
                  <Text>
                    On
                  </Text>
                  <ActionIcon className={ classes.navigationButton }>
                    <ForwardIcon />
                  </ActionIcon>
                </div>
              ) }

              { !isNavigable && <Switch
                id={ `doc-switch-${type}` }
                onChange={ onLayerToggle }
                checked={ !disabledLayers.includes(type) }
                value={ type }
              /> }
            </label>
          </li>
        )) }
      </ul>
    </div>
  );

};
