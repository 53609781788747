import { ActionIcon, Switch, Text } from "@mantine/core";
import React, { FC } from "react";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { ReactComponent as ForwardIcon } from "@/common/icons/Arrows/chevron-forward.svg";
import { ReactComponent as SiteMarker } from "@/common/icons/DataOptionControlIcons/lm-site.svg";
import { ReactComponent as PersonMarker } from "@/common/icons/DataOptionControlIcons/person-layer.svg";
import { LayerType } from "@/tenant-context/core/controls/DataOptionsControl/DataOptionsControl.component";

type Props = {
  classes: Record<string, string>,
  disabledLayers: string[],
  onLayerToggle: React.ChangeEventHandler<HTMLInputElement>,
  onPageSwitch?: () => void
}

export const MainLayers: FC<Props> = ({
  classes,
  disabledLayers,
  onLayerToggle,
  onPageSwitch
}) => {
  const mainLayers: LayerType[] = [
    {
      label: 'People',
      type: ToggleableLayerType.People,
      Icon: PersonMarker
    },
    {
      label: 'Locations',
      type: ToggleableLayerType.Locations,
      Icon: SiteMarker
    }
    // {
    //   label: 'Business Locations',
    //   type: ToggleableLayerType.AssetLocations,
    //   Icon: AssetLocationMarker,
    //   isNavigable: true
    // },
    // {
    //   label: 'Airports',
    //   type: ToggleableLayerType.Airports,
    //   Icon: AirportMarker
    // },
    // {
    //   label: 'Hotels',
    //   type: ToggleableLayerType.Hotels,
    //   Icon: HotelMarker
    // },
    // {
    //   label: 'Country Risk Level',
    //   type: ToggleableLayerType.CountryRiskLevel,
    //   Icon: ICMRiskIcon
    // }
  ];

  return (
    <div>
      <ul className={ classes.toggleList }>
        { mainLayers.map(({
          type,
          label,
          Icon,
          isNavigable
        }) => (
          <li className={ classes.listItem } key={ type }>
            <label className={ classes.switchLabel } htmlFor={ `doc-switch-${type}` }>
              <div className={ classes.itemDescription }>
                { (Icon && (
                  <Icon
                    className={ type }
                    width="24"
                    height="35"
                    preserveAspectRatio=""
                  />
                )) ?? null }

                <Text size="md">
                  { label }
                </Text>
              </div>

              { isNavigable && (
                <button
                  className={ classes.listItemRightSpan }
                  onClick={ onPageSwitch }
                >
                  <Text size="md">
                    { !disabledLayers.includes(type) ? 'On' : 'Off' }
                  </Text>
                  <ActionIcon className={ classes.navigationButton }>
                    <ForwardIcon />
                  </ActionIcon>
                </button>
              ) }

              { !isNavigable && <Switch
                id={ `doc-switch-${type}` }
                onChange={ onLayerToggle }
                checked={ !disabledLayers.includes(type) }
                value={ type }
              /> }
            </label>
          </li>
        )) }
      </ul>
    </div>
  );
};
