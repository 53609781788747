import { FC, useContext } from "react";

import RestrataLogo from "@/core/navigation/icons/Restrata.icon";
import { ReactComponent as RestrataTitle } from "@/core/navigation/icons/restrata-logo-title.svg";
import { contextSwitchItem } from "@/platform-context/navigation/components/NavigationSidebar/NavigationSidebar.config";
import { NavigationSidebarContext } from "@/platform-context/navigation/components/NavigationSidebar/NavigationSidebar.container";
import { useNavigationSidebarStyles } from "@/platform-context/navigation/components/NavigationSidebar/NavigationSidebar.style";
import NavigationItem from "@/tenant-context/navigation/components/NavigationItem";


const NavigationSidebar: FC = () => {
  const {
    isExpanded
  } = useContext(NavigationSidebarContext);

  const { classes } = useNavigationSidebarStyles(isExpanded);
  return (
    <div className={ classes.root }>
      <div className={ classes.topBlock }>
        <NavigationItem
          isLogo
          Icon={ RestrataLogo }
          isWithTooltip={ false }
          title={ <RestrataTitle /> }
          isHoverable={ false }
        />

        <NavigationItem
          type="button"
          isRedirectOnClick
          item={ contextSwitchItem }
        />
      </div>
    </div>
  );
};

export default NavigationSidebar;
