import { FC, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { showNotification } from "@/common/util/notification";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";

import { LocationInfoContext } from "../../../context/LocationInfo.context";
import AddLocationsFooterComponent from "./AddLocationsFooter.component";

const AddLocationsFooter: FC = () => {
  const {
    manageLocations: {
      addLocation,
      SET_LOCATION_SITE_PIC
    }
  } = useDispatch<Dispatch>();

  const {
    enteredLocationDetails,
    geoJsonFile,
    isFooterVisible
  } = useSelector((state: RootState) => state.manageLocations);

  const { isNewLocation, isAuthorizedToEdit, isAllowedToUseSaveLocationButton } = useContext(LocationInfoContext);

  const navigate = useNavigate();

  const handleNavigateToGlobal = useCallback(
    () => {
      navigate(RouterConfig.routes.locationManagement.locationsList);
    },
    [navigate]
  );

  const handleAddLocation = useCallback(async ()=> {

    const results = await addLocation();

    if(results?.tid){
      showNotification({
        message: `Location successfully ${isNewLocation ? "added" : "updated"}`,
        color: 'success',
        title: "Success"
      });

      SET_LOCATION_SITE_PIC(null);

      if(isNewLocation){
        navigate( RouterConfig.routes.locationManagement.addLocations(results.tid + `?category=${results.subCategoryName}`));
      }
    }

  }, [SET_LOCATION_SITE_PIC, addLocation, isNewLocation, navigate]);

  return (
    <AddLocationsFooterComponent
      onAddLocation={ handleAddLocation }
      onNavigateToGlobal={ handleNavigateToGlobal }
      enteredLocationDetails={ enteredLocationDetails }
      isAttachedValidGeoJsonFile={ !!geoJsonFile }
      isFooterVisible={ isFooterVisible }
      isAuthorizedToEdit = { isAuthorizedToEdit }
      isAllowedToUseSaveLocationButton={ isAllowedToUseSaveLocationButton }
    />
  );
};

export default AddLocationsFooter;
