import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";


export const useImpactTabStyles = createStyles((theme: MantineTheme) => ({
  container:{
    padding: '0 12px'
  },
  cellWrapText:{
    height: 'auto'
  },
  rowWrapper:{
    alignItems: 'start !important'
  },

  nameCellWrapper: {
    all: 'unset',
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    '& > *:not(:last-child)': {
      padding: 5,
      marginRight: 10
    }
  },

  filterWrapper: {
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  dropdownFilterWrapper: {
    display: 'flex',

    '& > *:not(:last-child)': {
      marginRight: 20
    }
  },

  expandIcon: {
    all: 'unset',
    position: 'absolute',
    top: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },

  expandedRow: {
    whiteSpace: 'pre-wrap',
    lineHeight: '150%'
  },

  statusIndicator: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    marginRight: 8,
    display: 'block'
  },
  statusChangeButton:{
    display: 'flex',
    alignItems: 'center'
  },

  textWrap:{
    display: "flex",
    flexDirection: 'column',
    fontSize: 12
  },

  dimmed:{
    color: theme.colors.neutral[5]
  },
  accented:{
    color: theme.other.semantic.accessibility.outer,
    fontSize: 14
  },
  underline:{
    borderBottom: `0.5px solid ${theme.colors.neutral[6]}`
  },
  impactTable: {
    '& .ag-cell-value':{
      height: "100%",
      fontSize: 16
    }
  },
  alignRight:{
    justifyContent: "center"
  },
  statusIndicatorBg: {
    '&_NOT_SAFE': {
      backgroundColor: `${ theme.colors.risk[5] } !important`
    },
    '&_NO_RESPONSE': {
      backgroundColor: `${ theme.colors.risk[2] } !important`,
      color: `${ theme.colors.neutral[8] } !important`
    },
    '&_SAFE': {
      backgroundColor: `${ theme.colors.risk[1] } !important`
    },
    '&_OTHER': {
      backgroundColor: `${ theme.colors.info[0] } !important`
    }
  }
}));
