import { Button, Tabs } from "@mantine/core";
import { Tooltip } from "@mantine/core";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { FC, useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePermission from "@/common/hooks/usePermission";
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { ReactComponent as PlusIcon } from "@/common/icons/plus.svg";
import { Dispatch, RootState } from "@/core/store";
import AddLocationsMap from '@/tenant-context/control-location-management/components/AddLocations/LocationDetailsTab/LocationDetailsMap';
import {
  LocationManagementPoliciesConfig
} from "@/tenant-context/control-location-management/config/location-management.policies";
import { LocationManagementContextProvider } from '@/tenant-context/control-location-management/context/LocationManagement.context';

import { LocationInfoContext, TabProps } from "../../context/LocationInfo.context";
import { useAddLocationsStyles } from "./AddLocations.styles";
import AddLocationsFooter from "./AddLocationsFooter";
import AddLocationsHeader from "./AddLocationsHeader";
import SelectedLocationTab from "./SelectedLocationTab.component";


const AddLocations: FC = () => {

  const {
    drawControl,
    selectedDrawing,
    handleTabChange,
    activeTab,
    tabs,
    isAddBuildingFormOpened,
    handleOpenAddAndEditBuildingForm,
    isAddBuildingButtonVisible,
    buildingView,
    handlePrepareAddFloorForm,
    isLocationExists
  } = useContext(LocationInfoContext);

  const { classes } = useAddLocationsStyles();

  const { isMapOpen } = useSelector((state:RootState) => state.manageLocations);

  const {
    manageLocations: {
      RESET_LOCATIONS_STATE
    },
    mapSearch: {
      SET_ACTIVE_LOCATIONS
    }
  } = useDispatch<Dispatch>();

  const isAuthorizedToEdit = usePermission(
    LocationManagementPoliciesConfig.ADD_LOCATIONS_FULL_ACCESS
  );

  const interceptTabChange = useCallback((event) => {
    const selectedTab = tabs.find((tab) => tab.value === event);
    if (selectedTab?.isAuthorized) {
      handleTabChange(event);
    }
  }, [tabs, handleTabChange]);

  const handleUnauthorizedAction = useUnauthorizedModal();

  const renderTab = (tab: TabProps, key: number) => {
    if(!tab.isAuthorized && !isLocationExists){
      return  <Tooltip  withinPortal
        label={ "You have to create a location first to get access to this tab." }><Tabs.Tab
          value={ tab.value }
          key={ key }
          className={ !tab.isAuthorized ? classes.unauthorizedTab : undefined }
        >
          { tab.label }
        </Tabs.Tab>
      </Tooltip>;
    }

    return (
      <Tabs.Tab
        value={ tab.value }
        key={ key }
        onClick={ !tab.isAuthorized ? handleUnauthorizedAction : undefined }
        className={ !tab.isAuthorized ? classes.unauthorizedTab : undefined }
      >
        { tab.label }
      </Tabs.Tab>
    );
  };

  // Clearing
  useEffect(() => {

    return () => {
      RESET_LOCATIONS_STATE();
      SET_ACTIVE_LOCATIONS(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationManagementContextProvider>
      <ParentSize>
        { ({ height }) => <div style={ { minHeight: height, height: '100%' } } className={ classes.locationsContainer }>
          <AddLocationsHeader/>

          <Tabs
            className={ classes.addLocationTabs }
            value={ activeTab }
            onTabChange={ interceptTabChange }
          >
            <Tabs.List>
              { tabs.map((tab, index) => (
                renderTab(tab, index)
              )) }
            </Tabs.List>
          </Tabs>

          <div className={ classes.locationPage }>
            <div className={ classes.tabInner }>
              { isAddBuildingButtonVisible() && !isAddBuildingFormOpened
               && <div className={ classes.addBuildingBtnWrap }>
                 <Button
                   leftIcon={ <PlusIcon/> }
                   className={ classes.addBuildingButton }
                   disabled={ isAddBuildingFormOpened || !isAuthorizedToEdit }
                   onClick={ buildingView ? handleOpenAddAndEditBuildingForm : handlePrepareAddFloorForm }
                 >{ buildingView ? 'Add building' : 'Add floor' }
                 </Button>
               </div> }
              <SelectedLocationTab activeTab={ activeTab } />
            </div>
            <div style={ { display: isMapOpen ? "inline-block" : "none" } } className={ classes.mapOuterContainer }>
              <div className={ classes.mapPickContainer }>
                <AddLocationsMap
                  activeTab={ activeTab }
                  selectedDrawing={ selectedDrawing }
                  drawControl={ drawControl }/>
              </div>
            </div>
          </div>
          <AddLocationsFooter/>
        </div> }
      </ParentSize>
    </LocationManagementContextProvider>
  );
};

export default AddLocations;
