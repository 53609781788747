import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";

import MiniProfile from "./MiniProfile.component";

const { routes: { bigProfile: bigProfilePath } } = RouterConfig;
const bigProfilePathWithoutParams = bigProfilePath().slice(0, bigProfilePath().indexOf('/:'));

export enum MiniProfileTabs {
  Profile = "Profile",
  Visits = "Visits"
}

type Props= {
  isStandAlone?: boolean
}

const MiniProfileContainer: FC<Props> = ({
  isStandAlone = false
}) => {
  const [activeTab, setActiveTab] = useState<MiniProfileTabs>(MiniProfileTabs.Profile);

  const currentMiniProfile = useSelector((state: RootState) => state.userProfile.currentUserProfile);

  const location = useLocation();
  const navigate = useNavigate();

  const isInBigProfileRoute = location.pathname.startsWith(
    bigProfilePathWithoutParams
  );

  const {
    userProfile:{
      RESET_CURRENT_USER_MINI_PROFILE,
      getMiniProfileData,
      refreshUserProfileLwStatus
    },
    profile: {
      closeBigProfile,
      hideBigProfile
    },
    eaArc: {
      closeEaArc
    },
    drawer: {
      closeRightDrawer
    },
    visitorManagement: {
      fetchVisitsForUser,
      CLEAR_USER_VISITS_INFO
    }
  } = useDispatch<Dispatch>();

  const userId = useSelector((state: RootState) => state.userProfile.id);
  const profileTid = useSelector((state: RootState) => state.userProfile.profileTid);
  const isEaArcOpen = useSelector((state: RootState) => state.eaArc.isOpen);
  const userVisitsInfo = useSelector((state: RootState) => state.visitorManagement.userVisitsInfo);

  useEffect(() => {
    CLEAR_USER_VISITS_INFO();
    setActiveTab(MiniProfileTabs.Profile);
    
    if (!userId) {
      return;
    }

    getMiniProfileData(userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMiniProfileData, userId]);

  useEffect(
    () => {
      if (!userId) {
        return;
      }

      const interval = setInterval(() => {
        refreshUserProfileLwStatus();
      }, 2000);

      return () => clearInterval(interval);
    }
    , [refreshUserProfileLwStatus, userId]
  );

  useEffect(()=>{
    return () => {
      RESET_CURRENT_USER_MINI_PROFILE();
      if(!isStandAlone){
        closeRightDrawer();
      }
      CLEAR_USER_VISITS_INFO();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBigProfileOpen = useSelector((state: RootState) => state.profile.isOpen);

  const onCloseClick = useCallback(() => {
    // If we are in big profile route, we need to navigate to profile list
    if (isInBigProfileRoute) {
      navigate(-1);

      // Ensure profile states are cleaned up
      hideBigProfile();
      RESET_CURRENT_USER_MINI_PROFILE();
      return;
    }

    // If big profile is expanded (not in route) - collapse it
    if (isBigProfileOpen) {
      hideBigProfile();

    // If EA ARC is expanded - collapse it
    } else if (isEaArcOpen) {
      closeEaArc();

    // If only mini profile is open - close it
    } else {
      RESET_CURRENT_USER_MINI_PROFILE();
      closeBigProfile();
    }
  }, [
    closeEaArc,
    isEaArcOpen,
    RESET_CURRENT_USER_MINI_PROFILE,
    closeBigProfile,
    hideBigProfile,
    isBigProfileOpen,
    isInBigProfileRoute,
    navigate
  ]);

  const onTabChange = useCallback((tab: MiniProfileTabs)=>{
    if(tab === MiniProfileTabs.Visits){
      if(profileTid){
        fetchVisitsForUser(profileTid);
      }
    }
    setActiveTab(tab);
  }, [fetchVisitsForUser, profileTid]);

  const handleOpenBigProfile = useCallback(() => {
    if (!userId) {
      return;
    }

    if(!isStandAlone){
      closeRightDrawer();
    }
    navigate(RouterConfig.routes.bigProfile(userId));

  }, [userId, isStandAlone, navigate, closeRightDrawer]);

  return (
    <MiniProfile
      profileData={ currentMiniProfile }
      onCloseClick={ onCloseClick }
      isBigProfileOpen={ isBigProfileOpen }
      isInFullscreenOverlay={ isBigProfileOpen || isEaArcOpen }
      onTabChange={ onTabChange }
      activeTab = { activeTab }
      userVisitsInfo = { userVisitsInfo }
      isStandAlone={ isStandAlone }
      openBigProfile={ handleOpenBigProfile }
    />
  );
};

export default MiniProfileContainer;
