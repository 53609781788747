/* eslint-disable no-magic-numbers */
import { MantineTheme } from "@mantine/styles/lib/theme";

import { extractCountryCode, getCountryIsoCode } from '@/tenant-context/common/util/country-to-iso-map';
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import {
  BSOCExternalData,
  Crime,
  GenericRiskAlertResponse,
  HighImpactExplosiveActivity,
  IDFUAVActivity,
  IED,
  ProtestsAndGatherings,
  RisklineV3ExternalData,
  SAF,
  SecurityOperations,
  Sigact
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import { RiskAlertEvent, RiskAlertExternalData } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

// Return background color based on risk level
export const getRiskLevelColor = (level: string, theme: MantineTheme): string => {
  const levels: Record<string, string> = {
    '2': theme.colors.risk[1],
    '3': theme.colors.risk[2],
    '4': theme.colors.risk[3],
    '5': theme.colors.risk[4]
  };
  const defaultValue = theme.colors.risk[0];
  return levels[level] ?? defaultValue;
};

export const getSigactId = (sigactToFormat: string): string => {
  const sigact = sigactToFormat.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

  if (sigact in Crime)  {
    return Sigact.Crime;
  } else if (
    sigact in SAF
  ){
    return Sigact.SAF;
  } else if (
    sigact in IED
  ){
    return Sigact.IED;
  } else if (
    sigact in HighImpactExplosiveActivity
  ){
    return Sigact.HighImpactExplosiveActivity;
  } else if (
    sigact in IDFUAVActivity
  ){
    return Sigact.IDFUAVActivity;
  } else if (
    sigact in SecurityOperations
  ){
    return Sigact.SecurityOperations;
  } else if (
    sigact in ProtestsAndGatherings
  ){
    return Sigact.ProtestsAndGatherings;
  }

  return sigact;
};

export const getRiskProviderForAlert = (
  alert: GenericRiskAlertResponse
): string => {
  const providerMap: Record<string, string> = {
    'riskline-alert-connector': 'Riskline',
    'riskline-alert-connector-service': 'Riskline',
    'bsoc-alert-connector-service': 'BSOC',
    'riskline-v4-alert-connector-service': 'Riskline v4',
    'data-minr-connector-service': 'DataMinr',
    'max-security-connector-service': 'MaxSecurity'
  };

  return providerMap[alert.request.connectorSource] ?? undefined;
};

const mapRiskAlertToBSOCAlert = (
  impactEngineRiskAlert: GenericRiskAlertResponse<BSOCExternalData>
): RiskAlertEvent<BSOCExternalData> => {
  const { request, peopleCount, assetCount, impactStatus } = impactEngineRiskAlert;
  const { sigact, critical_incident } = request.externalData;
  const formattedSigact = getSigactId(sigact);

  return {
    json: {
      meta: {
        company_id: request.tenantId,
        geojson: {
          type: "Feature",
          geometry: {
            type: 'Point',
            coordinates: [
              request.geometry.lon,
              request.geometry.lat
            ]
          },
          properties: {},
          id: 'BSOC'
        },
        language: 'en_US'
      },
      alert: {
        advice: '',
        category: { id: formattedSigact, name: formattedSigact },
        countries: [{
          iso_code: 'IRQ', // TODO: get from request
          name: 'Iraq'
        }],
        location_explanation: '',
        risk_level: { id: critical_incident ? '5' : '4', name: critical_incident ? 'Extreme' : 'High' },
        id: request.ext_id,
        is_breaking: false,
        is_notice: false,
        parent_id: '',
        title: request.title,
        text: request.text,
        regions: [],
        start_date: request.start_date,
        end_date: request.end_date
      },
      externalData: { ...request?.externalData, sigact: formattedSigact } as BSOCExternalData
    },
    meta: {
      createdTime: impactEngineRiskAlert.meta.createdTime,
      updatedTime: impactEngineRiskAlert.meta.updatedTime
    },
    source: request.source,
    tid: request.ext_id,
    isImpacted: impactStatus === 'CLOSED' ? false : peopleCount > 0 || assetCount > 0
  };
};

const mapRiskAlertToRisklineV3Alert = (
  impactEngineRiskAlert: GenericRiskAlertResponse<RisklineV3ExternalData>
): RiskAlertEvent<RiskAlertExternalData> => {
  const {
    request: {
      geometry,
      externalData: {
        meta,
        risklineMeta,
        alert
      },
      source
    },
    peopleCount,
    assetCount,
    impactStatus,
    tid
  } = impactEngineRiskAlert;

  return {
    json: {
      meta: {
        ...risklineMeta,
        geojson: {
          ...risklineMeta.geojson,
          geometry: {
            type: 'Point',
            coordinates: [
              geometry.lon,
              geometry.lat
            ]
          }
        }
      },
      alert: alert
    },
    meta,
    source,
    tid,
    isImpacted: impactStatus === 'CLOSED'? false : peopleCount > 0 || assetCount > 0
  };
};

const mapRiskAlertToDataMinrAlert = (
  impactEngineRiskAlert: GenericRiskAlertResponse<DataMinrExternalData>
): RiskAlertEvent<DataMinrExternalData> => {
  const { request, peopleCount, assetCount, impactStatus } = impactEngineRiskAlert;
  const {
    tenantId,
    geometry,
    ext_id,
    text,
    title,
    start_date,
    end_date,
    externalData: {
      alertType: {
        name: alertTypeName
      },
      eventLocation: {
        name: locationName
      },
      watchlistsMatchedByType
    },
    source
  } = request;

  const riskLevelMap: Record<string, { id: string, name: string }> = {
    'Alert': { id: '3', name: 'Medium' },
    'Urgent': { id: '4', name: 'High' },
    'Flash': { id: '5', name: 'Extreme' }
  };

  return {
    json: {
      meta: {
        company_id: tenantId,
        geojson: {
          type: "Feature",
          geometry: {
            type: 'Point',
            coordinates: [
              geometry.lon,
              geometry.lat
            ]
          },
          properties: {},
          id: 'DataMinr'
        },
        language: 'en_US'
      },
      alert: {
        id: ext_id,
        text: text,
        title: title,
        advice: '',
        category: { id: watchlistsMatchedByType?.[0].id, name: watchlistsMatchedByType?.[0].name }, // TODO: Clarify this
        countries: [{
          iso_code: getCountryIsoCode(locationName) || extractCountryCode(locationName) || '', // TODO: We missing this in the response
          name: locationName
        }],
        location_explanation: locationName,
        risk_level: riskLevelMap[alertTypeName],
        is_breaking: false,
        is_notice: false,
        parent_id: '',
        regions: [],
        start_date: start_date,
        end_date: end_date // TODO: We don't have a end date in dataminr
      },
      externalData: { ...request.externalData } as DataMinrExternalData
    },
    meta: {
      createdTime: impactEngineRiskAlert.meta.createdTime,
      updatedTime: impactEngineRiskAlert.meta.updatedTime
    },
    source: source,
    tid: ext_id,
    isImpacted: impactStatus === 'CLOSED' ? false : peopleCount > 0 || assetCount > 0
  };
};

const mapRiskAlertToMaxSecurityAlert = (
  impactEngineRiskAlert: GenericRiskAlertResponse<MaxSecurityExternalData>
): RiskAlertEvent<MaxSecurityExternalData> => {
  const { request, peopleCount, assetCount, impactStatus } = impactEngineRiskAlert;
  const {
    tenantId,
    geometry,
    ext_id,
    text,
    title,
    start_date,
    end_date,
    externalData: {
      incident_type,
      primary_region,
      incident_risk_level
    },
    source
  } = request;

  // todo: get correct levels mapping and remove hard coded one
  const riskLevelMap: Record<string, { id: string, name: string }> = {
    'Low': { id: '1', name: 'Low' },
    'Moderate': { id: '2', name: 'Moderate' },
    'Medium': { id: '3', name: 'Medium' },
    'High': { id: '4', name: 'High' },
    'Extreme': { id: '5', name: 'Extreme' }
  };

  return {
    json: {
      meta: {
        company_id: tenantId,
        geojson: {
          type: "Feature",
          geometry: {
            type: 'Point',
            coordinates: [
              geometry.lon,
              geometry.lat
            ]
          },
          properties: {},
          id: 'MaxSecurity'
        },
        language: 'en_US'
      },
      alert: {
        id: ext_id,
        text: text,
        title: title,
        advice: '',
        category: { id: incident_type.replace(' ', ''), name: incident_type },
        countries: [{
          iso_code: getCountryIsoCode(primary_region) || '',
          name: primary_region
        }],
        location_explanation: primary_region,
        risk_level: riskLevelMap[incident_risk_level] || { id: '3', name: 'Medium' },
        is_breaking: false,
        is_notice: false,
        parent_id: '',
        regions: [],
        start_date: start_date,
        end_date: end_date
      },
      externalData: { ...request.externalData } as MaxSecurityExternalData
    },
    meta: {
      createdTime: impactEngineRiskAlert.meta.createdTime,
      updatedTime: impactEngineRiskAlert.meta.updatedTime
    },
    source: source,
    tid: ext_id,
    isImpacted: impactStatus === 'CLOSED' ? false : peopleCount > 0 || assetCount > 0
  };
};

export const mapImpactEngineRiskAlertToRiskAlertEvent = (
  impactEngineRiskAlert: GenericRiskAlertResponse
): RiskAlertEvent<RiskConnectorExternalData> | undefined => {

  const riskProvider = getRiskProviderForAlert(impactEngineRiskAlert);

  switch (riskProvider) {
  case 'BSOC':
    return mapRiskAlertToBSOCAlert(impactEngineRiskAlert as GenericRiskAlertResponse<BSOCExternalData>);
  case 'Riskline':
    return mapRiskAlertToRisklineV3Alert(impactEngineRiskAlert as GenericRiskAlertResponse<RisklineV3ExternalData>);
  case 'DataMinr':
    return mapRiskAlertToDataMinrAlert(impactEngineRiskAlert as GenericRiskAlertResponse<DataMinrExternalData>);
  case 'MaxSecurity':
    return mapRiskAlertToMaxSecurityAlert(impactEngineRiskAlert as GenericRiskAlertResponse<MaxSecurityExternalData>);
  default: // Neglecting other data providers
    console.info('Unknown data provider for risk alert event: ', riskProvider, impactEngineRiskAlert.request.connectorSource);
    return undefined;
  }
};
