import mapboxgl from "mapbox-gl";
import { createContext, FC, useCallback,useEffect, useRef, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import usePermission from "@/common/hooks/usePermission";
import ensureDeserialized from "@/common/util/ensure-deserialized";
import { Dispatch, RootState } from "@/core/store";
import useMapPopup from "@/tenant-context/common/hooks/useMapPopup";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import { getClickedOnFeatures as getFeaturesInteractedWith } from "@/tenant-context/common/util/map-click";

import { RiskAlertPolicies } from "../configs/RiskAlert.policies";
import { RiskAlertEvent } from "../types/risk-alerts";
import { getPopupOrDrawerPosition, getPopupPositionByXYCoords } from "../util/getPopupOffset";

type MapboxHoverEvent = mapboxgl.MapMouseEvent
  & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; }
  & mapboxgl.EventData;

export type RiskContextType = {
  isRiskLocationPopupShown: boolean,
  setIsRiskLocationPopupShown: React.Dispatch<React.SetStateAction<boolean>>,
  riskLocationPopupCoordinates: PopupCoordinates | undefined,
  setRiskLocationPopupCoordinates: React.Dispatch<React.SetStateAction<PopupCoordinates | undefined>>,
  handleRiskLocationHover: (evt: MapboxHoverEvent, layerIds: Array<string>) => void,
  handleRiskLocationClick: (evt: MapboxHoverEvent, layerIds: Array<string>) => void,
  handleRiskLocationHoverLeave: () => void,
  hoveredOnRiskLocation: RiskAlertEvent | undefined,
  enablePopup: (event: RiskAlertEvent, pointerX:number, pointerY:number) => void,
  handleMouseEnterPopup: () => void,
  handleMouseLeavePopup:() => void,
  calculatePopupOffset:() => void
};

export const RiskContext = createContext<RiskContextType>({} as RiskContextType);
export const RiskContextProvider: FC = ({
  children
}) => {
  const [hoveredOnRiskLocation, setHoveredOnRiskLocation] = useState<RiskAlertEvent>();
  const {
    riskAlerts: {
      subscribeToRiskAlerts,
      enableRiskAlertDrawer
    },
    riskDetails:{
      TOGGLE_POPUP_LINE
    }
  } = useDispatch<Dispatch>();

  const riskProviders = useSelector((state: RootState) => state.commonData.riskProviders);
  const currentlyOpenRightDrawerId = useSelector((state:RootState) => state.drawer.currentlyOpenRightDrawerId);
  const isRiskImpactCircleEnabled = useSelector((state: RootState) => state.riskDetails.isRiskImpactCircleEnabled);
  const currentRiskAlert = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  const popupTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const currentAlertId = useRef<string | null>(null);
  const riskAlertPopupDelayInMilliseconds = 1000;
  const { current: map } = useMap();
  const RIGHT_PANEL_OFFSET_IN_PIXELS = 300;

  const isRiskAlertStreamPermissionAvailable = usePermission(RiskAlertPolicies.RISK_ALERT_POLICY);

  useEffect(() => {
    if (!riskProviders?.length || !isRiskAlertStreamPermissionAvailable) {
      return;
    }

    subscribeToRiskAlerts();
  }, [ isRiskAlertStreamPermissionAvailable, riskProviders, subscribeToRiskAlerts ]);

  const {
    isPopupShown: isRiskLocationPopupShown,
    setIsPopupShown: setIsRiskLocationPopupShown,
    popupCoordinates: riskLocationPopupCoordinates,
    setPopupCoordinates: setRiskLocationPopupCoordinates
  } = useMapPopup("Risk");

  const enablePopup = useCallback(
    (event:RiskAlertEvent, pointerX:number, pointerY:number) => {
      //assign the hovered risk alert data in order to access from component level
      setHoveredOnRiskLocation(event);
      //const popupPosition = getPopupPosition(map?.getBounds(), json.meta.geojson.geometry);
      const popupPositionXY = getPopupPositionByXYCoords(pointerX, pointerY);
      const mappedLngLat = map?.unproject(new mapboxgl.Point(
        popupPositionXY[0],
        popupPositionXY[1]
      ));

      // setting up the popup cordinates
      setRiskLocationPopupCoordinates({
        latitude: mappedLngLat?.lat ?? 0,
        longitude:mappedLngLat?.lng ?? 0
      });
      setIsRiskLocationPopupShown(true);
      // setTimeout(() => {    //PopupLine should be drawn after the popup is shown
      //   TOGGLE_POPUP_LINE(true);
      // // eslint-disable-next-line no-magic-numbers
      // }, riskAlertPopupDelayInMilliseconds/3);
    },
    [
      map,
      setRiskLocationPopupCoordinates,
      setIsRiskLocationPopupShown
      // TOGGLE_POPUP_LINE
    ]
  );

  const handleRiskLocationHover = useCallback(
    (
      evt: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; } & mapboxgl.EventData,
      layerIds: Array<string>
    ) => {
      if(popupTimer && popupTimer.current){  //if there is a existing ongoing callback for mouse enter. it should be cleared
        clearTimeout(popupTimer.current);
      }

      if(currentlyOpenRightDrawerId === "risk-events") { // if right risk event panel already opened, should not show the popup
        return;
      }

      // adding settimeout to disappear popup smoothly
      popupTimer.current = setTimeout(() => {
        const { layerFeatures } = getFeaturesInteractedWith(
          evt,
          layerIds
        );

        if (!layerFeatures.length) {
          return;
        }

        const [hoveredLayerFeature] = layerFeatures;

        if (!hoveredLayerFeature) {
          return;
        }

        // consider only the risk alert layer features
        if (layerIds.includes(hoveredLayerFeature.layer.id)) {
          const { properties } = hoveredLayerFeature;

          const { json } = ensureDeserialized(
            hoveredLayerFeature.properties as RiskAlertEvent,
            ['json']
          );

          if(currentAlertId.current === json.alert.id){
            return;
          }

          currentAlertId.current = json.alert.id;
          const { meta } = ensureDeserialized(
            hoveredLayerFeature.properties as RiskAlertEvent,
            ['meta']
          );

          popupTimer.current = null;
          setIsRiskLocationPopupShown(false);

          //trigger the logic to show up the
          enablePopup({ ...properties, meta, json  } as RiskAlertEvent, evt.point.x, evt.point.y);
        }
      }, riskAlertPopupDelayInMilliseconds);
    },
    [currentlyOpenRightDrawerId, enablePopup, setIsRiskLocationPopupShown]
  );

  const handleRiskLocationHoverLeave = useCallback(
    () => {
      if(popupTimer && popupTimer.current){
        clearTimeout(popupTimer.current);
      }
      popupTimer.current =  setTimeout(() =>{
        TOGGLE_POPUP_LINE(false);
        setHoveredOnRiskLocation(undefined);
        setRiskLocationPopupCoordinates(undefined);
        currentAlertId.current = null;
        setIsRiskLocationPopupShown(false);
      },riskAlertPopupDelayInMilliseconds / 3);
    },
    [TOGGLE_POPUP_LINE, setIsRiskLocationPopupShown, setRiskLocationPopupCoordinates]
  );

  const handleRiskLocationClick = useCallback(
    (
      evt: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; } & mapboxgl.EventData,
      layerIds: Array<string>
    ) => {
      const { layerFeatures: [{ properties }] } = getFeaturesInteractedWith(evt, layerIds);
      const riskAlertEvent =  properties as RiskAlertEvent;
      const { json } = ensureDeserialized(
        riskAlertEvent,
        ['json']
      );
      const { meta } = ensureDeserialized(
        riskAlertEvent,
        ['meta']
      );

      handleRiskLocationHoverLeave();
      enableRiskAlertDrawer({ ...riskAlertEvent, json, meta });
    },
    [enableRiskAlertDrawer, handleRiskLocationHoverLeave]
  );
  /*  calculate the offset between drawer/popup and line-end dynamically. In order to persist the line ,
      when zooming or dragging the map, drawer's end line is drawn based on the browser's X, Y points */
  const calculatePopupOffset = useCallback(() => {
    if( currentRiskAlert && isRiskImpactCircleEnabled){
      // relevent coordinates of X, Y points are mapped using unproject function
      const mappedLngLat = map?.unproject(new mapboxgl.Point(
        window.screen.width - RIGHT_PANEL_OFFSET_IN_PIXELS,
        window.screen.height/2 - RIGHT_PANEL_OFFSET_IN_PIXELS
      ));
      const popupPosition = getPopupOrDrawerPosition(
        map?.getBounds(),
        currentRiskAlert?.json.meta.geojson.geometry,
        mappedLngLat,
        true
      );

      // setting up the drawer / popup cordinates
      setRiskLocationPopupCoordinates({
        latitude: popupPosition[1],
        longitude:popupPosition[0]
      });
    }
  },[currentRiskAlert, isRiskImpactCircleEnabled, map, setRiskLocationPopupCoordinates]);

  const handleMouseEnterPopup = useCallback(() => {
    if(popupTimer && popupTimer.current){
      clearTimeout(popupTimer.current);
    }
  },[]);

  const handleMouseLeavePopup = useCallback(() => {
    popupTimer.current = setTimeout(() => {
      setHoveredOnRiskLocation(undefined);
      setRiskLocationPopupCoordinates(undefined);
      currentAlertId.current = null;
      TOGGLE_POPUP_LINE(false);
      setIsRiskLocationPopupShown(false);
    }, riskAlertPopupDelayInMilliseconds);
  },[TOGGLE_POPUP_LINE, setIsRiskLocationPopupShown, setRiskLocationPopupCoordinates]);

  return (
    <RiskContext.Provider value={ useContextValue({
      isRiskLocationPopupShown,
      setIsRiskLocationPopupShown,
      riskLocationPopupCoordinates,
      setRiskLocationPopupCoordinates,
      handleRiskLocationHover,
      handleRiskLocationClick,
      handleRiskLocationHoverLeave,
      hoveredOnRiskLocation,
      enablePopup,
      handleMouseEnterPopup,
      handleMouseLeavePopup,
      calculatePopupOffset
    }) } >
      { children }
    </RiskContext.Provider>
  );
};
