import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useRiskEventsProviderSelectionStyles = createStyles((theme) => ({

  description: {
    fontFamily: theme.fontFamily,
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular,
    lineHeight: theme.lineHeight
  },

  separator: {
    marginTop: '32px',
    marginBottom: '32px',
    borderTopColor: theme.colors.neutral[6]
  },

  impactCalculationChkBox: {
    marginTop: theme.spacing.xl,
    [`.${CSS_KEY}-Checkbox-body`]: {
      [`.${CSS_KEY}-Checkbox-label`]: {
        display: 'flex'
      },
      alignItems: 'flex-start'
    }
  },

  riskProviderDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  riskProviderName: {
    fontFamily:theme.fontFamily,
    fontSize:theme.fontSizes.md,
    fontWeight:theme.other.fw.regular
  },

  riskAlertCount:{
    fontFamily: theme.fontFamily,
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular
  },

  riskProviderSwitch:{
    height: 16
  },

  riskProvider: {
    cursor: 'pointer'
  },

  riskProviderIcon: {
    color:theme.white,
    paddingRight: theme.spacing.md,
    height: 24,
    width: 24 + theme.spacing.md
  },

  riskEventsTitleNBackArrow: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  riskEventTypes:{
    marginTop: '20px',
    display: 'flex',
    cursor: 'pointer',
    alignItems:'center',
    justifyContent:'space-between',
    flexDirection:'row',
    flexWrap:'wrap'
  },

  riskAlertTypeItem: {
    fontFamily:theme.fontFamily,
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.md
  },

  riskEventTypeIcon:{
    width:'40px',
    paddingRight:'2px'
  },

  riskEventTypeDetails:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center'
  },

  noFeedText:{
    fontSize:theme.fontSizes.md
  }
}));
