import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { PaginatedResult, ProfileGeneral } from "@/tenant-context/control-profile/types/profile";
import {
  getCurrentUsersPermissionsPolicies
} from "@/tenant-context/navigation/api/navigation";
import { UserPolicy } from "@/tenant-context/navigation/types/user";

import NavigationSidebar from './NavigationSidebar.component';

type NavigationSidebarContextValue = {
  isExpanded: boolean;
  setIsExpanded: (isNavExpanded: boolean) => void;
  usersPolicies: PaginatedResult<UserPolicy> | undefined;
  profileDetails: ProfileGeneral | undefined;
}

export const NavigationSidebarContext = createContext<NavigationSidebarContextValue>(
  {} as NavigationSidebarContextValue
);

export const NavigationSidebarContextProvider: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [usersPolicies, setUsersPolicies] = useState<PaginatedResult<UserPolicy>>();
  const [profileDetails, setProfileDetails] = useState<ProfileGeneral>();

  const loggedUserInfo = useSelector(({
    profile:{
      loggedUser
    }
  }: RootState) => loggedUser);

  const {
    commonData: {
      SET_IS_NAVIGATION_EXPANDED
    }
  } = useDispatch<Dispatch>();

  const handleNavigationExpand = useCallback((isNavExpanded: boolean) => {
    setIsExpanded(isNavExpanded);
    SET_IS_NAVIGATION_EXPANDED(isNavExpanded);
  }, [ SET_IS_NAVIGATION_EXPANDED ]);

  useEffect(() => {
    getCurrentUsersPermissionsPolicies().then(
      (response) => response && setUsersPolicies(response)
    );
  }, []);

  useEffect(()=>{
    setProfileDetails(loggedUserInfo);
  }, [loggedUserInfo]);

  return (
    <NavigationSidebarContext.Provider value={ {
      isExpanded,
      setIsExpanded: handleNavigationExpand,
      usersPolicies,
      profileDetails
    } }>
      <NavigationSidebar/>
    </NavigationSidebarContext.Provider>
  );
};

export default NavigationSidebarContextProvider;
