import { Button } from "@mantine/core";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";

import { TestableComponent } from "@/common/types/testable-component";
import { Dispatch } from "@/core/store";

const RiskTimelineTriggerControl: FC<TestableComponent> = ({ dataTestId = "bottom-items-show-risk-timeline" }) => {
  const {
    drawer: {
      openBottomDrawer
    }
  } = useDispatch<Dispatch>();

  const openRiskTimelineDrawer = useCallback(() => openBottomDrawer('risk-timeline-graph-control'), [openBottomDrawer]);

  return (
    <Button
      size={ 'md' }
      variant={ 'default' }
      ml={ 'sm' }
      onClick={ openRiskTimelineDrawer }
      data-testid={ dataTestId }
    >
      Show Risk Timeline
    </Button>
  );

};

export default RiskTimelineTriggerControl;
