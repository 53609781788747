import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const usePeopleLocationPopupStyles = createStyles(((theme) => ({
  popupContainer: {
    minHeight: '410px',
    width: '385px',
    fontFamily: theme.fontFamily,
    backgroundColor: theme.colors.neutral[8]
  },
  popupHeader: {
    display: 'flex',
    maxHeight: '164px'
  },
  personImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    height: '164px',
    backgroundColor: theme.colors.neutral[8],
    fontSize: '48px'
  },
  personNameTitle: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[5],
    marginBottom: 4
  },
  personName: {
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.md,
    lineHeight: theme.other.lh.s,
    color: theme.white
  },
  probabilityWrapper: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: 'center',
    backgroundColor: theme.colors.blue[6],
    height: '84px',
    borderRight: `8px solid #6eb1ef`
  },
  probabilityTitle: {
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.headings.sizes.h3.fontSize,
    fontWeight: theme.other.fw.bold,
    color: theme.colors.neutral[0],
    marginBottom: 4
  },
  probabilityValue: {
    fontSize: theme.headings.sizes.h3.fontSize,
    lineHeight: theme.other.lh.s,
    fontWeight: theme.other.fw.bold,
    color: theme.colors.neutral[0]
  },
  contentWrapper: {
    padding: '0 16px 16px 16px'
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '28px'
  },
  contentColumn: {
    width: '120px'
  },
  contentValue: {
    display: 'flex',
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[0],
    fontWeight: theme.other.fw.regular,
    marginTop: '10px'
  },
  contentTitle: {
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[5],
    fontWeight: theme.other.fw.regular
  },
  dataSourceIcon: {
    marginRight: '10px'
  },
  dataSource: {
    flexDirection: 'column',
    gap: '6px'
  },
  travellerPopupcontainer: {
    padding: '24px'
  },
  personDetailWrapper: {
    height: '76px',
    display: 'flex'
  },
  personPic: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    width: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    overflow: "hidden",
    '& img':{
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    lineHeight: '1.3em'
  },
  profileHeader: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h4.fontSize,
    marginTop: '5px'
  },
  profileDesignation: {
    fontSize: theme.fontSizes.sm,
    marginTop: '8px'
  },
  profileCompany: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5]
  },
  travelTimeUtc: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5],
    marginTop: '6px'
  },
  personInfoContainer: {
    marginTop: '35px'
  },
  personInfo: {
    display: 'flex',
    margin: '16px 0px'
  },
  infoItem: {
    flex: 1
  },
  personInfoHeader: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  lastKnownloc: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.sm
  },
  personInfoData: {
    fontSize: theme.fontSizes.sm
  },
  sourceInfo: {
    fontSize: theme.fontSizes.xs
  },
  personLocationInfo: {
    display: 'flex',
    marginTop: '16px',
    alignItems: 'flex-start'
  },
  timeDivider: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '6px'
  },
  locationIconContainer: {
    width: '40px'
  },
  locationInfoBody: {
    color: theme.other.semantic.accessibility.outer,
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: '100%'
  },
  locationtimer: {
    color: theme.white
  },
  travelInfoContainer: {
    backgroundColor: theme.colors.neutral[9],
    padding: '16px',
    marginTop: '16px'
  },
  iteneraryInfo: {
    backgroundColor: theme.colors.neutral[8],
    padding: '16px',
    marginTop: '24px',
    display: 'flex',
    gap: '12px'
  },
  otherTravelInfo: {
    backgroundColor: theme.colors.neutral[8],
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    marginTop: '24px'
  },
  iteneraryLocation: {
    flex: 1,
    lineHeight: theme.other.lh.s
  },
  flag: {
    width: 16,
    height: 16
  },
  iteneraryArrow: {
    alignSelf: 'center',
    svg: {
      height: '24px',
      width: '13px'
    },
    path: {
      fill: theme.colors.neutral[5]
    }
  },
  locName: {
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold,
    color: theme.white
  },
  progress: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.colors.neutral[7],
    [`& .${CSS_KEY}-Progress-bar`]: {
      backgroundColor: theme.other.semantic.accessibility.outer
    }
  },
  standardMarginTop: {
    marginTop: '24px'
  },
  alarmBlockContainer: {
    marginTop: '24px',
    section: {
      div: {
        marginBottom: '0px'
      }
    }
  },
  addressFont: {
    fontSize: theme.fontSizes.xs
  },
  otherLocationContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  otherItineraryLocation: {
    justifyContent: 'space-between'
  },
  loaderContainer: {
    padding: '24px',
    [`.${CSS_KEY}-Skeleton-visible`]: {
      '&:after': {
        backgroundColor: theme.colors.neutral[6]

      },
      '&:before': {
        backgroundColor: theme.colors.neutral[9]
      }
    }
  },
  personDetailLoad: {
    display: 'flex'
  },
  personPicLoad: {
    width: '75px',
    height: '76px'
  },
  profileInfoLoad: {
    marginLeft: '10px',
    height: '76px',
    display: 'flex',
    flex: 1
  },
  personInfoLoad: {
    marginTop: '35px',
    display: 'flex',
    gap: '10px'
  },
  infoItemLoad: {
    flex: 1,
    height: '80px'
  },
  locationInfoLoad: {
    height: '108px',
    marginTop: '24px'
  },
  locationTimerLoad: {
    marginTop: '16px',
    height: '55px'
  },
  popupLoader: {
    position: 'absolute',
    top: '38%',
    left: '47%'
  },
  copyCordinates: {
    cursor: 'pointer',
    display: 'flex',
    gap: '6px',
    marginTop: '12px',
    color: theme.colors.neutral[4],
    path: {
      fill: theme.colors.neutral[6]
    }
  },
  copiedCordinates: {
    cursor: 'pointer',
    display: 'flex',
    gap: '6px',
    marginTop: '12px',
    color: '#6BA5FC',
    path: {
      fill: '#6BA5FC'
    }
  }
})));
