import { ActionIcon } from "@mantine/core";
import { FC, useCallback, useContext } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import { ReactComponent as LogoutIcon } from "@/common/icons/exit.svg";
import { RouterConfig } from "@/core/components/Router/Router.config";
import {
  useNavigationUserDetailsItemStyles
} from "@/core/navigation/components/NavigationUserDetailsItem/NavigationUserDetailsItem.styles";
import { useAuthContext } from "@/tenant-context/core/context/Auth.context";
import NavigationItem from "@/tenant-context/navigation/components/NavigationItem";
import { NavigationItemId, ProductArea } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";
import { NavigationSidebarContext } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.container";

const NavigationUserDetailsItem: FC = () => {
  const {
    userDetails,
    profileDetails
  } = useContext(NavigationSidebarContext);

  const {
    logout
  } = useAuthContext();

  const { classes } = useNavigationUserDetailsItemStyles();

  const handleLogout = useCallback(($event) => {
    $event.stopPropagation();
    logout();
  }, [ logout ]);

  if (!userDetails) {
    return null;
  }

  const personName = userDetails ? userDetails.name + " " + userDetails.surName : "";

  const itemTitle = (
    <div className={ classes.item }>
      <span>{ userDetails.name } { userDetails.surName }</span>
      <ActionIcon onClick={ handleLogout }>
        <LogoutIcon />
      </ActionIcon>
    </div>
  );

  return (
    <NavigationItem
      isWithTooltip={ false }
      title={ itemTitle }
      item={ {
        title: `${userDetails.name} ${userDetails.surName}`,
        path: RouterConfig.routes.bigProfile(userDetails.tenantPersonProfileId),
        itemId: NavigationItemId.MyProfile,
        area: ProductArea.Global
      } }
      type="button"
      isRedirectOnClick
    >
      <PersonAvatar
        width="25px"
        height="25px"
        personName={ personName }
        personAvatarUrl={ profileDetails?.profilePictureThumbnailUrl }
      />

    </NavigationItem>
  );
};

export default NavigationUserDetailsItem;
