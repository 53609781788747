import { createModel } from "@rematch/core";
import { FeatureCollection } from "geojson";

import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel } from "@/core/store";
import { getSiteGeoJson } from "@/tenant-context/control-site/api/site";

type SiteState = {
  geoJson?: FeatureCollection
}

const siteDataModel = {
  name: 'site',
  state: {
    geoJson: undefined
  } as SiteState,
  reducers: {
    SET_GEO_JSON: (state: SiteState, geoJson: SiteState['geoJson']) => ({
      ...state,
      geoJson
    })
  },
  effects: (dispatch: Dispatch) =>({
    async setSiteGeoJson(siteId: string): Promise<void> {
      dispatch.site.SET_GEO_JSON(undefined);
      const res = await getSiteGeoJson(siteId);

      if(res && res.length > 0) {
        try {
          const geoJson = JSON.parse(res[0].geoJson);
          dispatch.site.SET_GEO_JSON(geoJson);
        } catch (err) {
          console.error(err);
          showNotification({
            title: 'Error',
            message: 'The GeoJson received is invalid. Please check the format.',
            color: 'error'
          });
        }

      } else {
        return;
      }


    }
  })
};

export const site = createModel<RootModel>()(siteDataModel);
