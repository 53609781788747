import { openModal } from "@mantine/modals";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Dispatch, RootState } from "@/core/store";
import ProfileDocumentsModal from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal";
import ProfileDocumentsTab from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsTab/ProfileDocumentsTab.component";
import { ReferenceType } from "@/tenant-context/control-profile/types/profile";

const ProfileDocumentsTabContainer: FC = () => {
  const {
    profile: {
      loadDocuments,
      deleteDocument,
      getProfileReferenceData,
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  const params = useParams();

  const documents = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ profile: { documents } }: RootState) => documents
  );

  const handleDeleteDocumentClick = useCallback((documentId: string) => {
    deleteDocument(documentId);
  }, [deleteDocument]);

  useEffect(() => {
    loadDocuments();
    getProfileReferenceData(ReferenceType.DOCUMENT_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDocuments]);

  const handleAddClick = useCallback(() => {
    openModal({
      title: `Add Document`,
      size: 688,
      children: (
        <ProfileDocumentsModal
          mode="add"
          currentUserId={ params.userId }
        />
      ),
      closeOnClickOutside: false
    });
  }, [params.userId]);

  const handleEditClick = useCallback((documentId: string) => {
    openModal({
      title: `Edit Document`,
      size: 688,
      children: (
        <ProfileDocumentsModal
          mode="edit"
          editableDocumentId={ documentId }
          currentUserId={ params.userId }
        />
      ),
      closeOnClickOutside: false
    });
  }, [params.userId]);

  const handleOwnProfileCheck = useCallback((): boolean => {
    return isLoggedInProfileId(params.userId);
  }, [isLoggedInProfileId, params.userId]);


  return (
    <ProfileDocumentsTab
      documents={ documents }
      onAddClick={ handleAddClick }
      onEditClick={ handleEditClick }
      onDeleteDocumentClick={ handleDeleteDocumentClick }
      isOwnProfile={ handleOwnProfileCheck }
    />
  );
};

export default ProfileDocumentsTabContainer;
