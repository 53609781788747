import { Divider, List, Switch } from '@mantine/core';
import React, { FC, useCallback } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as LayerToggleIcon } from '@/common/icons/layer-toggle.svg';
import { ReactComponent as LocationLayerIcon } from '@/common/icons/location-label-layer.svg';
import { ReactComponent as ICMRiskIcon } from "@/common/icons/risk-icm.svg";
import { ReactComponent as RoadLayerIcon } from '@/common/icons/road-layer.svg';
import { ReactComponent as SatelliteIcon } from '@/common/icons/satellite.svg';
import { TestableComponent } from '@/common/types/testable-component';
import {
  useBasemapLayerToggleControlStyles
} from '@/tenant-context/core/controls/BasemapLayerToggleControl/BasemapLayerToggleControl.styles';

export const SATELLITE_IMAGERY_SWITCH = 'SATELLITE_IMAGERY_SWITCH';
export const ROAD_LAYERS_SWITCH = 'ROAD_LAYERS_SWITCH';
export const LOCATION_LABELS_SWITCH = 'LOCATION_LABELS_SWITCH';
export const COUNTRY_RISK_LEVEL_SWITCH = 'COUNTRY_RISK_LEVEL_SWITCH';

export enum SwitchType {
  satelliteImagery = 'SATELLITE_IMAGERY_SWITCH',
  roadLayers = 'ROAD_LAYERS_SWITCH',
  locationLabels = 'LOCATION_LABELS_SWITCH',
  countryRiskLevel = 'COUNTRY_RISK_LEVEL_SWITCH'
}

type Props = {
  onSatelliteImagerySwitchToggle: React.ChangeEventHandler<HTMLInputElement>,
  onRoadLayerSwitchToggle: React.ChangeEventHandler<HTMLInputElement>,
  onLocationLabelsSwitchToggle: React.ChangeEventHandler<HTMLInputElement>,
  onCountryRiskLevelSwitchToggle: React.ChangeEventHandler<HTMLInputElement>,
  isSatelliteChecked: boolean,
  isRoadLayersChecked: boolean,
  isLocationLabelsChecked: boolean
  isCountryRiskLevelChecked: boolean
} & TestableComponent;

const BasemapLayerToggleControl: FC<Props> = ({
  onSatelliteImagerySwitchToggle,
  onRoadLayerSwitchToggle,
  onLocationLabelsSwitchToggle,
  onCountryRiskLevelSwitchToggle,
  isSatelliteChecked,
  isRoadLayersChecked,
  isLocationLabelsChecked,
  isCountryRiskLevelChecked,
  dataTestId = "right-menu-layers"
}) => {
  const renderTestDrawerButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    <ButtonControl label='Layers' onClick={ onClick }
      data-testid={ dataTestId }
    >
      <LayerToggleIcon />
    </ButtonControl>
  ), [dataTestId]);

  const { classes } = useBasemapLayerToggleControlStyles();

  return (
    <DrawerControl
      id={ 'basemap-layer-control' }
      title={ 'Toggle basemap layers' }
      renderButton={ renderTestDrawerButton }
      explainer={ 'Select and deselect the layers data you wish to visualise on the map.' }
    >
      <div>
        <List
          spacing="xl"
          size="sm"
          center
        >
          <List.Item
            icon={ <SatelliteIcon className={ classes.itemIcon }/> }
            className={ classes.li }
          >
            <div className={ classes.listItem }>
              <span className={ classes.listItemText }>
                Satellite imagery
              </span>
              <Switch
                onChange={ onSatelliteImagerySwitchToggle }
                checked={ isSatelliteChecked }
              />
            </div>
          </List.Item>
          <List.Item
            icon={ <RoadLayerIcon className={ classes.itemIcon }/> }
            className={ classes.li }
          >
            <div className={ classes.listItem }>
              <span className={ classes.listItemText }>
                Road layers
              </span>
              <Switch
                onChange={ onRoadLayerSwitchToggle }
                checked={ isRoadLayersChecked }
              />
            </div>
          </List.Item>
          <List.Item
            icon={ <LocationLayerIcon className={ classes.itemIcon }/> }
            className={ classes.li }
          >
            <div className={ classes.listItem }>
              <span className={ classes.listItemText }>
                Location labels
              </span>
              <Switch
                onChange={ onLocationLabelsSwitchToggle }
                checked={ isLocationLabelsChecked }
              />
            </div>
          </List.Item>
          <List.Item
            icon={ <ICMRiskIcon className={ classes.itemIcon }/> }
            className={ classes.li }
          >
            <div className={ classes.listItem }>
              <span className={ classes.listItemText }>
                Riskline Country Risk Level
              </span>
              <Switch
                onChange={ onCountryRiskLevelSwitchToggle }
                checked={ isCountryRiskLevelChecked }
              />
            </div>
          </List.Item>
        </List>

        <Divider
          className={ classes.divider }
          // eslint-disable-next-line react/jsx-no-bind
          sx={ (theme) => ({
            // eslint-disable-next-line no-magic-numbers
            borderTopColor: theme.colors.neutral[6]
          }) }
        />
      </div>
    </DrawerControl>
  );
};

export default BasemapLayerToggleControl;
