import { createModel } from "@rematch/core";

import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { createPlaybookActionForCase, getPlaybookActionsForCase, PlaybookSource } from "@/tenant-context/employee-app-action-response-center/api/playbook";
import { PlaybookAction } from "@/tenant-context/employee-app-action-response-center/types/playbook";

type PlaybookState = {
  actions: PlaybookAction[]
};

const playbookDataModel = {
  name: 'playbook',
  state: {
    actions: []
  } as PlaybookState,
  reducers: {
    SET_ACTIONS(state: PlaybookState, payload: PlaybookAction[]): PlaybookState {
      return {
        ...state,
        actions: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async loadActions(source: PlaybookSource, state: RootState): Promise<void> {
      const caseId = getCaseId(source, state);

      try {
        if (!caseId) {
          throw new Error('No caseId');
        }

        const actions = await getPlaybookActionsForCase(caseId, source);
        dispatch.playbook.SET_ACTIONS(actions);
      } catch (err) {
        showNotification({
          title: 'Error',
          message: 'Failed to load playbook actions',
          color: 'error'
        });

        console.error(err);
      }
    },

    async createAction(
      payload: {
        actionText: string
        source: PlaybookSource
      },
      state: RootState
    ): Promise<boolean> {
      const { actionText, source } = payload;
      const caseId = getCaseId(source, state);

      try {
        if (!caseId) {
          throw new Error('No caseId');
        }

        await createPlaybookActionForCase(actionText, caseId, source);
        dispatch.playbook.loadActions(source);

        return true;
      } catch (e) {
        showNotification({
          title: 'Error',
          message: 'Failed to create playbook action',
          color: 'error'
        });

        console.error(e);
      }

      return false;
    }
  })
};

export const playbook = createModel<RootModel>()(playbookDataModel);

const getCaseId = (source: PlaybookSource, state: RootState): string | undefined => {
  return source === 'ARC' ? state.arc.currentCaseId : state.eaArc.caseId;
};
