import { Layer } from "mapbox-gl";
import React, {  FC, useCallback, useContext, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { Dispatch, RootState } from "@/core/store";
import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";

import BasemapLayerToggleControl, {
  COUNTRY_RISK_LEVEL_SWITCH,
  LOCATION_LABELS_SWITCH,
  ROAD_LAYERS_SWITCH,
  SATELLITE_IMAGERY_SWITCH,
  SwitchType
} from './BasemapLayerToggleControl.component';

const BasemapLayerToggleControlContainer: FC = () => {
  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  const map = useMap().current;

  const [isSatellite, setSatellite] = useState(false);
  const [isRoads, setRoads] = useState(true);
  const [isLabels, setLabels] = useState(true);
  const [isCountryRiskLevel, setCountryRiskLevel] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [styleUpdateDependency, setStyleUpdateDependency] = useState(0);

  const { customIcons } = useContext(BigMapContext);
  const mapUrl = useSelector((state: RootState) => state.devtools.mapUrl);

  // Ensures we don't interact with APIs that do not exist before map loads
  useEffect(() => {
    if (!map) {
      return;
    }

    map.once('load', () => setIsMapLoaded(true));
  }, [map]);

  // Makes `layers` visible or invisible
  const updateLayersVisibility = useCallback((layers: Layer[], isVisible: boolean) => {
    if (!map) {
      return;
    }

    const mapboxMap = map.getMap();

    layers.forEach((layer) => {
      mapboxMap.setLayoutProperty(
        layer.id,
        'visibility',
        isVisible ? 'visible' : 'none'
      );
    });
  }, [map]);

  // Synchronises toggle state with map state
  const pushLayerStateToMap = useCallback(() => {
    if (!map || !isMapLoaded) {
      return;
    }

    const { layers = [] } = map.getStyle();

    const labelLayers = layers.filter(
      ({ type, id }) => type === 'symbol'
        && !id.startsWith('r__')
        && !id.startsWith('siteLocationSnapshot')
    );

    const roadLayers = layers?.filter(
      (layer: Layer) => layer['source-layer'] === 'road'
    );

    updateLayersVisibility(labelLayers, isLabels);
    updateLayersVisibility(roadLayers, isRoads);
  }, [isLabels, isMapLoaded, isRoads, map, updateLayersVisibility]);

  // On satellite imagery toggle - set appropriate base style
  useEffect(() => {
    if (!map) {
      return;
    }

    // Ensures that when the style is loaded disabled toggles are applied
    map.once('styledata', () => {
      setStyleUpdateDependency((x) => x + 1);

      // On style change, update custom icons in the map since whenever the map style changes, mapbox removes
      // all custom icons from the map, and we need to add them again.
      customIcons.current.forEach((customIcon) => {
        const { name, icon } = customIcon;
        if (map.hasImage(name)) {
          map.updateImage(name, icon);
        } else {
          map.addImage(name, icon);
        }
      });
    });

    if (isSatellite) {
      map.getMap().setStyle('mapbox://styles/mapbox/satellite-streets-v11');
    } else {
      map.getMap().setStyle(mapUrl);
    }
  }, [customIcons, isSatellite, map, mapUrl]);

  // When roads and labels are toggled - push update to the map state
  useEffect(() => {
    pushLayerStateToMap();
  }, [isRoads, isLabels, pushLayerStateToMap, styleUpdateDependency]);

  //Handler for country risk level
  useEffect(()=>{
    if(isCountryRiskLevel){
      ENABLE_LAYER_TYPE([ToggleableLayerType.CountryRiskLevel]);
    }else{
      DISABLE_LAYER_TYPE([ToggleableLayerType.CountryRiskLevel]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountryRiskLevel]);


  // Handlers for switches
  const handleSwitchToggle = useCallback((switchId: SwitchType, event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    ({
      [SATELLITE_IMAGERY_SWITCH]: setSatellite,
      [ROAD_LAYERS_SWITCH]: setRoads,
      [LOCATION_LABELS_SWITCH]: setLabels,
      [COUNTRY_RISK_LEVEL_SWITCH]: setCountryRiskLevel
    })[switchId](checked);
  }, []);

  const handleSatelliteImagerySwitchToggle: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => handleSwitchToggle(SwitchType.satelliteImagery, event),
    [handleSwitchToggle]
  );

  const handleRoadLayerSwitchToggle: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => handleSwitchToggle(SwitchType.roadLayers, event),
    [handleSwitchToggle]
  );

  const handleLocationLabelsSwitchToggle: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => handleSwitchToggle(SwitchType.locationLabels, event),
    [handleSwitchToggle]
  );

  const handleCountryRiskLevelSwitchToggle: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => handleSwitchToggle(SwitchType.countryRiskLevel, event),
    [handleSwitchToggle]
  );

  return (
    <BasemapLayerToggleControl
      onSatelliteImagerySwitchToggle={ handleSatelliteImagerySwitchToggle }
      onRoadLayerSwitchToggle={ handleRoadLayerSwitchToggle }
      onLocationLabelsSwitchToggle={ handleLocationLabelsSwitchToggle }
      onCountryRiskLevelSwitchToggle={ handleCountryRiskLevelSwitchToggle }
      isCountryRiskLevelChecked = { isCountryRiskLevel }
      isLocationLabelsChecked={ isLabels }
      isRoadLayersChecked={ isRoads }
      isSatelliteChecked={ isSatellite }
    />
  );
};

export default BasemapLayerToggleControlContainer;
