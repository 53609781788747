import { createModel } from "@rematch/core";
import { ViewState } from 'react-map-gl';

import { RootModel } from "@/core/store";
import { GeoPolygon, ViewportBbox } from "@/tenant-context/control-draw/types/draw";

export type BigMapState = {
  currentGeoFences: Array<GeoPolygon>,
  currentViewPort: ViewportBbox
  currentViewState: ViewState | undefined;
  currentBigMapPopup: PopupType | undefined;
}

export type PopupType = "None" | "MapLocation" |  "Risk" | "PersonLocation";

export const PopupPrecedence: Record<PopupType, number> = {
  "None": 0,
  "MapLocation": 1,
  "Risk": 2,
  "PersonLocation": 3
};

export const bigMap = createModel<RootModel>()({
  state: {
    currentGeoFences: [],
    currentViewPort: [],
    currentViewState: undefined,
    currentBigMapPopup: undefined
  } as BigMapState,
  reducers: {
    setCurrentGeoFences(state: BigMapState, payload: Array<GeoPolygon>) {
      return {
        ...state,
        currentGeoFences: payload
      };
    },

    setCurrentViewPort(state: BigMapState, payload: ViewportBbox) {
      return {
        ...state,
        currentViewPort: payload
      };
    },

    setCurrentViewState(state: BigMapState, payload: ViewState) {
      return {
        ...state,
        currentViewState: payload
      };
    },

    setCurrentBigMapPopup(state: BigMapState, payload: PopupType) {
      const precededPopup = payload === "None" ? payload :
        PopupPrecedence[payload] > PopupPrecedence[state.currentBigMapPopup || "None"]
          ? payload : state.currentBigMapPopup;

      return {
        ...state,
        currentBigMapPopup: precededPopup
      };
    }
  }
});
