
import { AutocompleteComponentStyle } from "@/core/styles/mantine/components/AutocompleteComponent.style";
import { BreadcrumbsComponentStyle } from "@/core/styles/mantine/components/BreadcrumbsComponent.style";
import { ButtonComponentStyle } from "@/core/styles/mantine/components/ButtonComponent.style";
import { CalendarStyle } from "@/core/styles/mantine/components/Calendar.style";
import { CheckboxComponentStyle } from "@/core/styles/mantine/components/CheckboxComponent.style";
import { DividerStyle } from "@/core/styles/mantine/components/Divider";
import { DrawerComponentStyle } from "@/core/styles/mantine/components/DrawerComponent.style";
import { InputStyle, InputWrapperComponentStyle } from "@/core/styles/mantine/components/Input.style";
import { ModalComponentStyle } from "@/core/styles/mantine/components/Modal.style";
import { MultiSelectComponentStyle } from "@/core/styles/mantine/components/MultiSelectComponent.style";
import { PaginationComponentStyle } from "@/core/styles/mantine/components/PaginationComponent.style";
import { ScrollAreaStyle } from "@/core/styles/mantine/components/ScrollArea.style";
import { SelectComponentStyle } from "@/core/styles/mantine/components/SelectComponentStyle";
import { SliderComponentStyle } from "@/core/styles/mantine/components/SliderComponent.style";
import { StepperComponentStyle } from "@/core/styles/mantine/components/StepperComponent.style";
import { SwitchComponentStyle } from "@/core/styles/mantine/components/SwitchComponent.style";
import { TabComponentStyle } from "@/core/styles/mantine/components/TabsComponent.style";
import { TextInputStyle } from "@/core/styles/mantine/components/TextInput.style";
import { TimelineStyle } from "@/core/styles/mantine/components/Timeline.style";
import { TooltipComponentStyle } from "@/core/styles/mantine/components/Tooltip";
import { ThemeComponent } from "@/core/styles/mantine/types";

import { AccordionComponentStyle } from "./AccordionComponent.style";
import { ActionIconComponentStyle } from "./ActionIconComponent.style";
import { AvatarComponentStyle } from "./AvatarComponent.style";
import { NotificationComponentStyle } from "./Notification.style";
import { RadioComponentStyle } from "./RadioComponent.styles";
import { RangeSliderComponentStyle } from "./RangeSliderComponent.style";
import { TextareaStyle } from "./Textarea.style";
import { TransferListStyle } from "./TransferList.style";

export const componentOverrides: Record<string, ThemeComponent> = {
  Accordion: AccordionComponentStyle,
  ActionIcon: ActionIconComponentStyle,
  Avatar: AvatarComponentStyle,
  Button: ButtonComponentStyle,
  Breadcrumbs: BreadcrumbsComponentStyle,
  Switch: SwitchComponentStyle,
  Checkbox: CheckboxComponentStyle,
  Select: SelectComponentStyle,
  MultiSelect: MultiSelectComponentStyle,
  Notification: NotificationComponentStyle,
  Autocomplete: AutocompleteComponentStyle,
  Slider: SliderComponentStyle,
  RangeSlider: RangeSliderComponentStyle,
  TextInput: TextInputStyle,
  Modal: ModalComponentStyle,
  Tabs: TabComponentStyle,
  ScrollArea: ScrollAreaStyle,
  InputWrapper: InputWrapperComponentStyle,
  Input: InputStyle,
  DatePicker: CalendarStyle,
  Tooltip: TooltipComponentStyle,
  Divider: DividerStyle,
  Pagination: PaginationComponentStyle,
  Drawer: DrawerComponentStyle,
  Radio: RadioComponentStyle,
  Stepper: StepperComponentStyle,
  Timeline: TimelineStyle,
  TransferList: TransferListStyle,
  Textarea: TextareaStyle
};
