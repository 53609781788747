import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { Dispatch, RootState } from "@/core/store";
import { TimeSliderType } from "@/tenant-context/control-ranking-settings/store/control-ranking-settings/control-ranking-settings.model";

import RankingSettings, { DAYS_DELTA_MAX, DAYSSLIDER,HOURS_DELTA_MAX,HOURSLIDER } from './RankingSettings.component';

const SLIDER_UPDATE_DEBOUNCE_INTERVAL = 200;
const SLIDER_MIDDLE = 50;
const SLIDER_MAX_VALUE = 50;
const HOURS_IN_DAY = 24;
const RankingSettingsContainer: FC = () => {
  const {
    disabledAdapters,
    isShowOnlyHighestRanksForPeople,
    timeSliderValueHours,
    probabilityThresholdPercentageUpperEnd,
    probabilityThresholdPercentageLowerEnd,
    timeSliderType
  } = useSelector((state: RootState) => state.rankingSettings);

  const usedAdapters = useSelector((state: RootState) => state.peopleLocations.usedAdapters);

  const {
    rankingSettings: {
      DISABLE_DATA_SOURCE,
      ENABLE_DATA_SOURCE,
      RECALCULATE_MINIMUM_PROBABILITY_THRESHOLD,
      SET_TIME_SLIDER_TYPE,
      timeTravelTo,
      toggleShowOnlyHighestRanks,
      resetRankingSettingsFilters
    }
  } = useDispatch<Dispatch>();

  const handleAdapterToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: {
        checked,
        value: adapterId
      }
    } = event;

    if (checked) {
      ENABLE_DATA_SOURCE(adapterId);
    } else {
      DISABLE_DATA_SOURCE(adapterId);
    }
  }, [DISABLE_DATA_SOURCE, ENABLE_DATA_SOURCE]);

  const debouncedTimeTravelTo = useDebouncedCallback(
    timeTravelTo,
    SLIDER_UPDATE_DEBOUNCE_INTERVAL
  );

  const debouncedRecalculateMinimumProbabilityThreshold = useDebouncedCallback(
    RECALCULATE_MINIMUM_PROBABILITY_THRESHOLD,
    SLIDER_UPDATE_DEBOUNCE_INTERVAL
  );

  const handleIsShowOnlyHighestRanksForPeopleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const isShowOnlyHighest = event.currentTarget.checked;

    toggleShowOnlyHighestRanks(isShowOnlyHighest);
  }, [toggleShowOnlyHighestRanks]);

  const calculateTimeSliderValue = useCallback(
    (value: number, sliderMode:string) => {
      /**
       * in: 0 -> 50
       * out: 0 -> 14
       *
       * in: 0 -> -14
       * out: 0 -> -50
       *
       * val = 25
       * res = 7 = (25 / 50) * 14
       */
      const sliderValue = value - SLIDER_MIDDLE;
      const sliderResult = (sliderValue / SLIDER_MAX_VALUE) * 
        (sliderMode === HOURSLIDER ? HOURS_DELTA_MAX : DAYS_DELTA_MAX);

      return Math.round(sliderResult);
    },
    []
  );

  const handleTimeSliderValueChange = useCallback((sliderValue: number) => {
    const interpolatedSliderValue = calculateTimeSliderValue(sliderValue,timeSliderType);
    debouncedTimeTravelTo(timeSliderType === DAYSSLIDER ? 
      HOURS_IN_DAY * interpolatedSliderValue:
      interpolatedSliderValue);
  }, [calculateTimeSliderValue, timeSliderType, debouncedTimeTravelTo]);

  const timeSliderValuePercentage = useMemo(
    /**
     * Days                         Hours
     * in: -7 -> +7                 in: -12 -> + 12
     * out: 0 -> 100                out: 0 -> 100
     *
     * in: -7                       in:-12
     * out: 0                       out: 0
     *
     * in: 0                        in: 0
     * out: 50                      out: 50
     *
     * in: +7                       in: +12
     * out: 100                     out: 100
     */

    () =>  timeSliderType === HOURSLIDER
      ? ((timeSliderValueHours + HOURS_DELTA_MAX) / (HOURS_DELTA_MAX * 2) * 100)
      : (((timeSliderValueHours / HOURS_IN_DAY) + DAYS_DELTA_MAX) / (DAYS_DELTA_MAX * 2) * 100),
    [timeSliderType, timeSliderValueHours]
  );

  const resetFilters = useCallback(() => {
    resetRankingSettingsFilters();
  },[resetRankingSettingsFilters]);

  const handleTimeSelectionModeChange = useCallback((value: TimeSliderType | null) => {
    if (!value) {
      return;
    }
    SET_TIME_SLIDER_TYPE(value);
    debouncedTimeTravelTo(0);
  }, [SET_TIME_SLIDER_TYPE, debouncedTimeTravelTo]);

  return (
    <RankingSettings
      isShowOnlyHighestRanksForPeople={ isShowOnlyHighestRanksForPeople }
      onShowOnlyHighestRanksForPeopleChange={ handleIsShowOnlyHighestRanksForPeopleChange }
      usedAdapters={ usedAdapters }
      disabledAdapters={ disabledAdapters }
      onAdapterToggle={ handleAdapterToggle }
      onProbabilityThresholdChange={ debouncedRecalculateMinimumProbabilityThreshold }
      onTimeSliderValueChange={ handleTimeSliderValueChange }
      calculateTimeSliderValue={ calculateTimeSliderValue }
      timeSliderValuePercentage={ timeSliderValuePercentage }
      probabilityThresholdPercentageUpperEnd={ probabilityThresholdPercentageUpperEnd }
      probabilityThresholdPercentageLowerEnd={ probabilityThresholdPercentageLowerEnd }
      onClickResetFilters={ resetFilters }
      timeSelectionMode = { timeSliderType }
      onChangeTimeSelectionMode = { handleTimeSelectionModeChange }
    />
  );
};

export default RankingSettingsContainer;