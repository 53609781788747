import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatMonth } from "@/common/util/format/date";
import { Dispatch, RootState } from "@/core/store";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";
import { handleTravelStatus } from "@/tenant-context/visualisation-people/util/utils";

import MiniProfileLocationInformationComponent from "./MiniProfileLocationInformation.component";

type Props = {
  profileData?: MiniProfileDatails
}

const MiniProfileLocationInformation: FC<Props> = ({ profileData }) => {

  const {
    userProfile: {
      getUserTravelItenerary,
      SET_ITINERARY_SHOWN_ON_MAP
    },
    locationGraph: {
      enableGraphForPerson,
      disableGraph
    },
    reverseGeocoding: {
      getReverseGeoLocation
    }
  } = useDispatch<Dispatch>();

  const { userTravelItenerary, travelCorrelationId } = useSelector((state: RootState) => state.userProfile);

  const isItineararyOnMap = useSelector((state: RootState) => state.userProfile.isTravelItineraryShownOnMap);

  const {
    clickedPersonEvent,
    hoveredOnPerson
  } = useContext(PeopleContext);

  const [reverseGeoLoc, setReverseGeoLoc] = useState('');

  useEffect(() => {
    if (travelCorrelationId) {
      getUserTravelItenerary(travelCorrelationId);
    }
  }, [getUserTravelItenerary, travelCorrelationId]);

  useEffect(() => {
    if (clickedPersonEvent && clickedPersonEvent?.adapterSource !== 'travel-book-adapter-service') {
      const location = getReverseGeoLocation({
        lat: clickedPersonEvent.location.point.lat, lon: clickedPersonEvent.location.point.lon
      });

      location.then(loc => {
        setReverseGeoLoc(loc.place_name);
      }).catch(() => {
        setReverseGeoLoc('We currently have no location data for this individual');
      });
    }
  }, [clickedPersonEvent, getReverseGeoLocation]);

  const handleCalculateTripProgress = useCallback((startDate: number, endDate: number): { progress: number, status: 'past' | 'present' | 'future' } => {
    const currentUtcInMilis = Date.now();
    if (currentUtcInMilis > startDate &&
          currentUtcInMilis < endDate) {
      const progress = currentUtcInMilis - startDate;
      const totaltrip = endDate - startDate;
      return {
        progress: Math.trunc((progress / totaltrip) * 100),
        status: 'present'
      };
    } else if (currentUtcInMilis < startDate && currentUtcInMilis < endDate) {
      return {
        progress: 0,
        status: 'future'
      };
    } else if (currentUtcInMilis > startDate && currentUtcInMilis > endDate) {
      return {
        progress: 0,
        status: 'past'
      };
    } else {
      return { progress: 0, status: 'past' };
    }
  }, []);

  const handleActiveStepper = useCallback((): number => {
    const currentUtcInMilis = Date.now();
    const currentItenerary = userTravelItenerary?.findIndex(itenerary => currentUtcInMilis > itenerary.start_date
      && currentUtcInMilis < itenerary.end_date);

    if (currentItenerary !== undefined && currentItenerary >= 0) {
      return currentItenerary + 1;
    } else if (userTravelItenerary !== undefined && userTravelItenerary?.length > 0 &&
      currentUtcInMilis > userTravelItenerary[userTravelItenerary.length - 1].end_date
    ) {
      return userTravelItenerary?.length;
    }else {
      return 0;
    }
  }, [userTravelItenerary]);

  const handleCurrentTravelStatus = useCallback((): {status: string | null,
    travelTime: string, currentLocation: string} => {
    const currentUtcInMilis = Date.now();
    const currentItinerary = userTravelItenerary
      ?.find(itinerary => currentUtcInMilis > itinerary.start_date && currentUtcInMilis < itinerary.end_date);

    if (currentItinerary) {
      const fromDate = new Date(currentItinerary.start_date);
      const endDate = new Date(currentItinerary.end_date);
      return {
        status: handleTravelStatus(currentItinerary.type, currentItinerary.start_date, currentItinerary.end_date),
        travelTime: `Between ${fromDate.getUTCHours()}:${fromDate.getUTCMinutes().toString().padStart(2, '0')} UTC - ${endDate.getUTCHours()}:${endDate.getUTCMinutes().toString().padStart(2, '0') } UTC`,
        currentLocation: currentItinerary.origin_name
      };
    } else if (userTravelItenerary && currentUtcInMilis >
      userTravelItenerary[userTravelItenerary?.length - 1].end_date) {

      const fromDate = new Date(userTravelItenerary[userTravelItenerary?.length - 1]?.start_date);
      const endDate = new Date(userTravelItenerary[userTravelItenerary?.length - 1]?.end_date);

      return {
        status: 'Completed',
        travelTime: `Between ${fromDate.getUTCDate()} ${formatMonth(fromDate?.getUTCMonth())} ${fromDate?.getUTCFullYear()} - ${endDate.getUTCDate()} ${formatMonth(endDate?.getUTCMonth())} ${endDate?.getUTCFullYear()} UTC`,
        currentLocation: userTravelItenerary[userTravelItenerary?.length - 1].destination_name || '-'
      };
    } else {
      const transitLocation = userTravelItenerary?.find((itinerary, index) => {
        if (index < userTravelItenerary.length) {
          return currentUtcInMilis > itinerary.end_date
            && currentUtcInMilis < userTravelItenerary[index + 1].start_date;
        }

        return false;
      });

      if (transitLocation) {
        return {
          status: '-', travelTime: '-', currentLocation: transitLocation.destination_name || '-'
        };
      } else {
        return { status: '-', travelTime: '-', currentLocation: '-' };
      }
    }
  }, [userTravelItenerary]);

  const handleTravelItineraryOnMap = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        disableGraph();
      } else {
        if (profileData?.profileId) {
          enableGraphForPerson(profileData?.profileId);
        }
      }
      SET_ITINERARY_SHOWN_ON_MAP(!isItineararyOnMap);
    },
    [SET_ITINERARY_SHOWN_ON_MAP, disableGraph, enableGraphForPerson, isItineararyOnMap, profileData?.profileId]
  );

  return (
    <MiniProfileLocationInformationComponent
      clickedPersonEvent={ clickedPersonEvent }
      hoveredOnPerson={ hoveredOnPerson }
      travelIteneraryInfo={ userTravelItenerary }
      onCalculateTripProgress={ handleCalculateTripProgress }
      onActiveStepper={ handleActiveStepper }
      onCurrentTravelStatus={ handleCurrentTravelStatus }
      handleTravelItineraryOnMap={ handleTravelItineraryOnMap }
      isItineararyOnMap={ isItineararyOnMap || false }
      profileData={ profileData }
      reverseGeoLoc={ reverseGeoLoc }
    />

  );
};

export default MiniProfileLocationInformation;
