import { Tooltip } from "@mantine/core";
import { FC, useCallback, useContext } from "react";

import { convertToNavFormat } from "@/common/util/get-testid";
import NavigationUserDetailsItem from "@/core/navigation/components/NavigationUserDetailsItem";
import OpenNavIcon from "@/core/navigation/icons/OpenNav.icon";
import RestrataLogo from "@/core/navigation/icons/Restrata.icon";
import { ReactComponent as RestrataTitle } from "@/core/navigation/icons/restrata-logo-title.svg";
import NavigationItem from "@/tenant-context/navigation/components/NavigationItem";
import { navigationSidebarBottomItems, navigationSidebarItems } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";
import { NavigationSidebarContext } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.container";
import { useNavigationSidebarStyles } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.style";
import NavigationTenantDetailsItem from "@/tenant-context/navigation/components/NavigationTenantDetailsItem/NavigationTenantDetailsItem.component";


const dataTestId = "right-navigation";

const NavigationSidebar: FC = () => {
  const {
    isExpanded,
    setIsExpanded
  } = useContext(NavigationSidebarContext);

  const handleExpandCollapseClick = useCallback(
    () => {
      setIsExpanded(!isExpanded);
    },
    [ isExpanded, setIsExpanded ]
  );

  const { classes } = useNavigationSidebarStyles(isExpanded);

  return (
    <div className={ classes.root }>
      <div className={ classes.menuFixedBlock }>
        <NavigationItem
          isLogo
          Icon={ RestrataLogo }
          isWithTooltip={ false }
          title={ <RestrataTitle /> }
          isHoverable={ false }
        />

        <NavigationItem
          type="button"
          title="Close"
          isOpenClose
          isWithTooltip={ false }
          Icon={ OpenNavIcon }
          isRedirectOnClick={ false }
          onClick={ handleExpandCollapseClick }
          dataTestId={ `${dataTestId}-expand-button` }
        />
      </div>
      <div className={ classes.scrollableContent }>
        <Tooltip.Group openDelay={ 100 } >
          <ul>
            <div className={ classes.navItemContainer }>
              <div>
                { navigationSidebarItems.map((item) => (
                  <li key={ item.itemId }>
                    <NavigationItem
                      type="button"
                      isRedirectOnClick={ item.type !== 'menu' }
                      item={ item }
                      data-testid={ convertToNavFormat(dataTestId, item.title) }
                    />
                  </li>
                )) }
              </div>
              <div>
                { navigationSidebarBottomItems.map((item) => (
                  <li key={ item.itemId }>
                    <NavigationItem
                      type="button"
                      isRedirectOnClick={ item.type !== 'menu' }
                      item={ item }
                      data-testid={ convertToNavFormat(`${dataTestId}-bottom`, item.title) }
                    />
                  </li>
                )) }
              </div>
            </div>
          </ul>
        </Tooltip.Group>
      </div>

      <div className={ classes.menuFixedBlock }>
        <ul className={ classes.bottomEmphasized }>
          <li>
            <NavigationTenantDetailsItem />
          </li>

          <li>
            <NavigationUserDetailsItem />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavigationSidebar;
