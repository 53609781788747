/* eslint-disable import/extensions */
import { FC } from "react";
import { MapProvider as MapboxMapProvider } from "react-map-gl";

import TenantRefContext from "@/platform-context/core/components/TenantRefContextProvider";
import { AppInitContextProvider } from "@/tenant-context/core/context/AppInit.context";
import { BigMapProvider } from "@/tenant-context/core/context/BigMap.context";
import MiniMapProvider from "@/tenant-context/core/controls/MiniMapControl/MiniMap.context";


const TenantContext: FC = ({ children }) => {
  return (
    <MiniMapProvider>
      <MapboxMapProvider>
        <AppInitContextProvider>
          <BigMapProvider>
            <TenantRefContext>
              { children }
            </TenantRefContext>
          </BigMapProvider>
        </AppInitContextProvider>
      </MapboxMapProvider>
    </MiniMapProvider>
  );
};

export default TenantContext;
