import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from '@/common/components/ToggleableLayer/ToggleableLayer.config';
import { Dispatch, RootState } from "@/core/store";

import DataOptionsControl from "./DataOptionsControl.component";

const DataOptionsControlContainer: FC = () => {

  const [isInSubPage, setIsInSubPage] = useState(false);

  const disabledLayers = useSelector((state: RootState) => state.dataOptions.disabledLayerTypes);

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  // Handlers for switches
  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        checked,
        value: layerType
      } = event.currentTarget;

      if (!Object.keys(ToggleableLayerType).includes(layerType)) {
        // Omit non-registered layer type interactions
        return;
      }

      if (checked) {
        if(layerType === ToggleableLayerType.AllCommonLocations){
          ENABLE_LAYER_TYPE([
            ToggleableLayerType.Hotels,
            ToggleableLayerType.Airports,
            ToggleableLayerType.CommonRegions,
            ToggleableLayerType.AllCommonLocations
          ]);
        } else{
          ENABLE_LAYER_TYPE([layerType as ToggleableLayerType]);
        }
     
      } else {
        if(layerType === ToggleableLayerType.AllCommonLocations){
          DISABLE_LAYER_TYPE([
            ToggleableLayerType.Hotels,
            ToggleableLayerType.Airports,
            ToggleableLayerType.CommonRegions,
            ToggleableLayerType.AllCommonLocations  ]);
        } else{
          DISABLE_LAYER_TYPE([layerType as ToggleableLayerType]);
        }
       
      }
    },
    [DISABLE_LAYER_TYPE, ENABLE_LAYER_TYPE]
  );

  const handlePageSwitch = useCallback(() => setIsInSubPage(!isInSubPage), [ isInSubPage ]);

  return (
    <DataOptionsControl
      onLayerToggle={ handleSwitchToggle }
      disabledLayers={ disabledLayers }
      isInSubPage={ isInSubPage }
      onPageSwitch={ handlePageSwitch }
    />
  );
};

export default DataOptionsControlContainer;
