import { FeatureCollection } from 'geojson';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MarkerDragEvent } from "react-map-gl";
import { useDispatch } from "react-redux";

import { isValidLatitude, isValidLongitude } from "@/common/util/geo";
import { Dispatch } from "@/core/store";
import { LocationTab } from "@/tenant-context/control-location-management/context/LocationInfo.context";
import { LocationManagementContext } from '@/tenant-context/control-location-management/context/LocationManagement.context';
import DraggableMarker from '@/tenant-context/control-location-management/layers/DragableMarker';

type Props = {
  activeTab?: LocationTab;
}
const DraggableMarkerLayer: FC<Props> = ({ activeTab }) => {
  const [currentLocation, setCurrentLocation] = useState<FeatureCollection | undefined>();
  const [isDraggedLocation, setIsDraggedLocation] = useState(false);
  const [coordinates, setCoordinates] = useState<{ lat: number, lng: number }>();

  const {
    currentlySelectedLocation,
    externallySetCoordinates
  } = useContext(LocationManagementContext);

  const {
    manageLocations: {
      setLatitudeLongitudeInLocationDetails,
      setLatitudeLongitudeInActiveBuilding,
      SET_ACTIVE_MARKER_LOCATION
    }
  } = useDispatch<Dispatch>();

  const handleMarkerDragStart = useCallback(() => {
    if (!isDraggedLocation) {
      setIsDraggedLocation(true);
    }
  }, [isDraggedLocation]);

  const handleMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setCoordinates({
      lng: event.lngLat.lng,
      lat: event.lngLat.lat
    });
  }, []);

  const handleMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
    if (activeTab === LocationTab.LocationDetails || activeTab === LocationTab.AdditionalDetails) {
      setLatitudeLongitudeInLocationDetails({
        lng: event.lngLat.lng,
        lat: event.lngLat.lat,
        isReverseGeocodingEnabled: true
      });
    }
    if (activeTab === LocationTab.Buildings_Floors) {
      setLatitudeLongitudeInActiveBuilding({
        lng: event.lngLat.lng,
        lat: event.lngLat.lat
      });
    }
  }, [activeTab, setLatitudeLongitudeInActiveBuilding, setLatitudeLongitudeInLocationDetails]);

  useEffect(() => {
    if (!currentlySelectedLocation) {
      return;
    }

    setCurrentLocation(currentlySelectedLocation);
  }, [currentlySelectedLocation]);

  useEffect(() => {
    if (!externallySetCoordinates || !isValidLatitude(externallySetCoordinates.lat)
      || !isValidLongitude(externallySetCoordinates.lng)) {
      return;
    }

    setIsDraggedLocation(true);

    setCoordinates(externallySetCoordinates);
    if (activeTab === LocationTab.LocationDetails || activeTab === LocationTab.AdditionalDetails) {
      setLatitudeLongitudeInLocationDetails({
        ...externallySetCoordinates,
        isReverseGeocodingEnabled: true
      });
    }
    if (activeTab === LocationTab.Buildings_Floors) {
      setLatitudeLongitudeInActiveBuilding(externallySetCoordinates);
    }
    if (activeTab === LocationTab.Zones) {
      SET_ACTIVE_MARKER_LOCATION(externallySetCoordinates);
    }
  }, [SET_ACTIVE_MARKER_LOCATION,
    activeTab,
    externallySetCoordinates,
    setLatitudeLongitudeInActiveBuilding,
    setLatitudeLongitudeInLocationDetails]);

  useEffect(() => {
    if (!currentLocation) {
      return;
    }

    setIsDraggedLocation(false);

    const coords = {
      lat: currentLocation.features[0].properties?.coordinates.latitude,
      lng: currentLocation.features[0].properties?.coordinates.longitude
    };

    setCoordinates(coords);
    if (activeTab === LocationTab.LocationDetails || activeTab === LocationTab.AdditionalDetails) {
      setLatitudeLongitudeInLocationDetails({
        ...coords,
        isReverseGeocodingEnabled: false
      });
    }
    if (activeTab === LocationTab.Buildings_Floors) {
      setLatitudeLongitudeInActiveBuilding(coords);
    }
    if (activeTab === LocationTab.Zones) {
      SET_ACTIVE_MARKER_LOCATION(coords);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, currentLocation, setLatitudeLongitudeInActiveBuilding, setLatitudeLongitudeInLocationDetails]);

  const currentLocationName = useMemo(
    () => currentLocation?.features?.[0]?.properties?.name,
    [currentLocation?.features]
  );

  const currentLocationAddress = useMemo(
    () => currentLocation?.features?.[0]?.properties?.full_address,
    [currentLocation?.features]
  );

  return (
    <DraggableMarker
      onMarkerDragStart={ handleMarkerDragStart }
      onMarkerDrag={ handleMarkerDrag }
      onMarkerDragEnd={ handleMarkerDragEnd }
      currentLocation={ currentLocation }
      isDraggedLocation={ isDraggedLocation }
      currentLocationName={ currentLocationName }
      currentLocationAddress={ currentLocationAddress }
      dragMarkerCoordinates={ coordinates }
    />
  );
};

export default DraggableMarkerLayer;
