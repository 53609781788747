import { FileWithPath } from "@mantine/dropzone";
import bbox from "@turf/bbox";
import { lineString } from "@turf/turf";
import { useCallback, useContext, useEffect, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootState } from "@/core/store";
import SitePlanTab
  from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/SitePlanTab.component";

import { LocationInfoContext } from "../../../context/LocationInfo.context";

const SitePlanTabContainer = () => {
  const [uploadedFile, setUploadedFile] = useState<FileWithPath | null>(null);
  const [uploadedFileError, setUploadedFileError] = useState<FileRejection | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const currentSitePlanGeoJSON = useSelector((state: RootState) => state.manageLocations?.currentSitePlanGeoJson);
  const isSitePlanLoading = useSelector((state: RootState) => state.manageLocations?.isSitePlanLoading);
  const sitePlanListData = useSelector((state: RootState) => state.manageLocations?.sitePlanList);
  const { handleDrawingToolSelect, drawControl  } = useContext(LocationInfoContext);

  const { AddLocationMap: map } = useMap();

  const {
    manageLocations: {
      uploadGEOJSONFileToLocation,
      SET_CURRENT_SITE_PLAN_GEO_JSON

    }
  } = useDispatch<Dispatch>();

  const parseUploadedFile = useCallback((file: FileWithPath, callback) => {
    const fr = new FileReader();
    fr.onload = () => callback(null, fr.result);
    fr.onerror = (err) => callback(err);
    fr.readAsText(file);
  }, []);

  const onDrawCancelButtonClick = useCallback(() => {
    setUploadedFile(null);
    setUploadedFileError(null);
    setIsDrawing(false);
    drawControl.current?.deleteAll();
    SET_CURRENT_SITE_PLAN_GEO_JSON(undefined);
  }, [SET_CURRENT_SITE_PLAN_GEO_JSON, drawControl]);

  const onUploadCancelButtonClick = useCallback(() => {
    setUploadedFile(null);
    setUploadedFileError(null);
  }, []);

  const onDropFileUpload = useCallback((file: FileWithPath[]) => {
    setUploadedFileError(null);
    setUploadedFile(file[0] as File);
  }, []);

  const onRejectFileUpload = useCallback((file: FileRejection[]) => {
    setUploadedFile(null);
    setUploadedFileError(file[0]);
  }, []);

  const handleSaveUploadedFile = useCallback(() => {
    if (uploadedFile) {
      const fileName = uploadedFile?.name.slice(0, uploadedFile?.name.lastIndexOf('.'));
      const fileType = uploadedFile?.name.substring(uploadedFile?.name.lastIndexOf('.') + 1);
      const fileSize = (uploadedFile?.size / 1024).toFixed(1) + 'KB';

      parseUploadedFile(uploadedFile, (err: string, res: string) => {
        if (err) {
          handleError({ message: err, name: 'File Reader Error' });
        } else {
          uploadGEOJSONFileToLocation({
            fileName,
            fileType,
            fileSize,
            geoJson: res
          })
            .then(() => setUploadedFile(null));
        }
      });
    }
    if (currentSitePlanGeoJSON && !uploadedFile) {
      uploadGEOJSONFileToLocation(
        {
          fileName: 'Manually created',
          fileType: 'json',
          fileSize: 'no data',
          geoJson: JSON.stringify(currentSitePlanGeoJSON)
        }
      )
        .then(() => drawControl.current?.deleteAll())
        .then(() => setIsDrawing(false));
    }
  }, [currentSitePlanGeoJSON, drawControl, parseUploadedFile, uploadGEOJSONFileToLocation, uploadedFile]);

  useEffect(() => {
    if (uploadedFile) {
      parseUploadedFile(uploadedFile, (err: string, res: string) => {
        if (err) {
          handleError({ message: err, name: 'File Reader Error' });
        } else {
          SET_CURRENT_SITE_PLAN_GEO_JSON(JSON.parse(res));
          const line = lineString(JSON.parse(res)?.geometry?.coordinates[0]);
          const bbox_ = bbox(line);
          if (map && bbox_) {
            map?.fitBounds(bbox_ as [number, number, number, number], { padding: 100 });
          }
        }
      });
    }

    return () => {
      if (sitePlanListData?.length === 0 || !sitePlanListData) {
        SET_CURRENT_SITE_PLAN_GEO_JSON(undefined);
        setUploadedFile(null);
        setIsDrawing(false);
      }
    };
  }, [SET_CURRENT_SITE_PLAN_GEO_JSON,
    map,
    parseUploadedFile,
    sitePlanListData,
    uploadGEOJSONFileToLocation,
    uploadedFile
  ]);

  return (
    <SitePlanTab
      handleSaveUploadedFile={ handleSaveUploadedFile }
      uploadedFile={ uploadedFile }
      uploadedFileError={ uploadedFileError }
      handleDrawingToolSelect={ handleDrawingToolSelect }
      isDrawing={ isDrawing }
      setIsDrawing={ setIsDrawing }
      currentSitePlanGeoJSON={ currentSitePlanGeoJSON }
      isLoading={ isSitePlanLoading }
      onDrawCancelButtonClick={ onDrawCancelButtonClick }
      onUploadCancelButtonClick={ onUploadCancelButtonClick }
      onDropFileUpload={ onDropFileUpload }
      onRejectFileUpload={ onRejectFileUpload }
    />
  );
};

export default SitePlanTabContainer;
