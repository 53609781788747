import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { formatNumberToDecimalPoints } from "@/tenant-context/common/util/data-standardize";
import { ArcCaseEvent } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";

import RiskImpactPopup from './RiskImpactPopup.component';

type Props = {
  arcCaseEvent?:ArcCaseEvent
};

const RiskImpactPopupContainer: FC<Props> = ({
  arcCaseEvent
}) => {
  const riskImpactData = useSelector((state: RootState) => state.riskDetails.currentRiskAlertImpactEvent);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);

  const isRiskImpactPopupShown = currentBigMapPopup === "Risk"
    || arcCaseEvent !== undefined ;

  const { bigMapZoomLevel } = useContext(BigMapContext);
  const { impactZoneMap: map } = useMap();

  // const zoomLevel = !arcCaseEvent ? bigMapZoomLevel : map?.getZoom();
  const impactPosition = !arcCaseEvent ? riskImpactData?.request.geometry : arcCaseEvent.geometry;
  const impactRadius = formatNumberToDecimalPoints(((!arcCaseEvent
    ? riskImpactData?.calculation.appliedTo
    : arcCaseEvent.calculation.appliedTo) || 0), 3);
  const assetsCount = !arcCaseEvent ? riskImpactData?.assetCount : arcCaseEvent.totalAssetCount;
  const peopleCount = !arcCaseEvent ? riskImpactData?.peopleCount : arcCaseEvent.totalPeopleCount;

  const [currentMapZoom, setCurrentMapZoom] = useState(0);
  useEffect(() => {
    if (!map) {
      return;
    }
    map.on('zoom', (_) => {
      setCurrentMapZoom(map.getZoom());
    });

    return () => {
      map.off('zoom', () => {
        setCurrentMapZoom(map.getZoom());
      });
    };
  }, [map]);

  const getPopUpOffset = useCallback(
    () => {
      if(!impactRadius || !impactPosition){
        return;
      }

      // Pop up offset should be dynamic and it should always just above the drawn impact cicle
      // Mapping meters to pixels at given zoom level

      // 78271.484 is m/px ratio through equator (Latitude 0) when zool level is 0 (reference https://docs.mapbox.com/help/glossary/zoom-level/)
      // appliedTo is multiplied by 1000 to get the distance in meters
      // 1 / cos(phi), uses to obtain the correct meter to pixel ratio for any latitude

      const zoomLevel = arcCaseEvent ? currentMapZoom : bigMapZoomLevel;
      // eslint-disable-next-line no-magic-numbers
      return(impactRadius * 1000) /
       // eslint-disable-next-line no-magic-numbers
       (78271.484 / 2 ** zoomLevel) / Math.cos(impactPosition.lat * Math.PI / 180);
    },
    [impactRadius, impactPosition, bigMapZoomLevel, arcCaseEvent, currentMapZoom]
  );

  return (
    <RiskImpactPopup
      isShown={ isRiskImpactPopupShown }
      assetCount = { assetsCount }
      impactRadius = { impactRadius }
      peopleCount = { peopleCount }
      impactPositionData = {  impactPosition }
      popUpOffset = { getPopUpOffset() }
    />
  );
};

export default RiskImpactPopupContainer;
