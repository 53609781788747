/* eslint-disable no-magic-numbers */
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const getIndoorMapLayers = (theme: MantineTheme) => ([
  // Zone polygons
  {
    "filter": [
      "filter-==",
      "indoor",
      "zone"
    ],
    "id": "indoor-zones-custom",
    "type": "fill",
    "source": "indoor",
    "paint": {
      "fill-color": theme.colors.neutral[8],
      "fill-opacity": 1
    }
  },
  // Muster Zone polygons
  {
    "filter": [
      "filter-==",
      "indoor",
      "musterZone"
    ],
    "id": "indoor-musterZone",
    "type": "fill",
    "source": "indoor",
    "paint": {
      "fill-color": theme.colors.risk[0],
      "fill-opacity": 1
    }
  },
  // Outline layer
  {
    'id': 'indoor-polygon-outline',
    'type': 'line',
    'source': "indoor",
    "filter": [
      "any",
      [
        "filter-==",
        "indoor",
        "zone"
      ]
    ],
    'paint': {
      'line-color': theme.colors.neutral[5],
      'line-width': 1
    }
  },
  // Entry points
  {
    "filter": [
      "filter-==",
      "indoor",
      "entry"
    ],
    "id": "indoor-entry-custom",
    "type": "fill",
    "source": "indoor",
    "paint": {
      "fill-color": theme.colors.blue[6],
      "fill-opacity": 1
    }
  },
  // Exit points
  {
    "filter": [
      "filter-==",
      "indoor",
      "exit"
    ],
    "id": "indoor-exit-custom",
    "type": "fill",
    "source": "indoor",
    "paint": {
      "fill-color": theme.colors.error[0],
      "fill-opacity": 1
    }
  },
  // Texts
  {
    "interactive": true,
    "minzoom": 19,
    "filter": [
      "any",
      // Removed texts for zones for now since the designs don't have 'em yet
      // [
      //   "filter-==",
      //   "indoor",
      //   "zone"
      // ],
      [
        "filter-==",
        "indoor",
        "enter/exit"
      ],
      [
        "filter-==",
        "indoor",
        "musterZone"
      ],
      [
        "filter-==",
        "indoor",
        "entry"
      ],
      [
        "filter-==",
        "indoor",
        "exit"
      ]
    ],
    "layout": {
      "text-line-height": 1.5,
      "text-size": 12,
      "text-allow-overlap": true,
      "text-ignore-placement": false,
      "text-max-angle": 38,
      "symbol-placement": "point",
      "text-padding": 2,
      "visibility": "visible",
      "text-rotation-alignment": "viewport",
      "text-anchor": "center",
      "text-field": "{name}",
      "text-letter-spacing": 0.2
    },
    "type": "symbol",
    "source": "indoor",
    "id": "poi-indoor-text-ref",
    "paint": {
      "text-color": theme.white
    }
  }
]);
