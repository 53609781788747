import { FC, useCallback, useContext, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import AddFloorFormComponent
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/AddFloorForm/AddFloorForm.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-management/context/LocationInfo.context";
import {
  CreateFloorRequest
} from "@/tenant-context/control-location-management/types/ManageLocations.types";

const AddFloorForm: FC = () => {
  const { manageLocations: {
    postCreateFloor,
    SET_ACTIVE_FLOOR
  } } = useDispatch<Dispatch>();

  const { setIsAddFloorFormOpened } = useContext(LocationInfoContext);

  const activeFloor = useSelector((state: RootState) => state.manageLocations?.activeFloor);

  const formControls = useForm({ mode: 'onChange' });

  const handleFormClear = useCallback(() => {
    SET_ACTIVE_FLOOR(undefined);
    formControls.reset();
    setIsAddFloorFormOpened(false);
  }, [SET_ACTIVE_FLOOR, formControls, setIsAddFloorFormOpened]);

  const handleFormSubmit = useCallback((data: FieldValues) => {
    postCreateFloor({
      floor: {
        ...data,
        name: data.name.trim().toUpperCase(),
        code: data.code.trim().toUpperCase()
      } as CreateFloorRequest,
      floorId: activeFloor?.floorTid || ''
    }).then(() => handleFormClear());
  }, [activeFloor?.floorTid, handleFormClear, postCreateFloor]);

  useEffect(() => {
    return () => {
      handleFormClear();
    };
  }, [handleFormClear]);

  return (
    <AddFloorFormComponent
      onSubmit={ handleFormSubmit }
      formControls={ formControls }
      onFormClear={ handleFormClear }
    />
  );
};

export default AddFloorForm;
