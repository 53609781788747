
export const TransferListStyle = {
  defaultProps: {
    className: 'mantine-transferList-override'
  },
  styles: () => ({
    transferListItem: {
      padding: '8px !important'
    },
    transferListControl:{
      height: 48
    }
  })
};