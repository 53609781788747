import ReactDOM from 'react-dom';

import App from '@/core/components/App';

import '@/core/styles/reset.css';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
