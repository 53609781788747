import { createModel } from "@rematch/core";
import { FeatureCollection, GeoJsonProperties, Point } from "geojson";

import { geoTimeZones } from "@/common/config/geoTimeZones";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { MapboxGeocodingProperties } from "@/tenant-context/common/types/mapbox-geocoding";
import { manageLocationsDefaultState } from "@/tenant-context/control-location-management/store/ManageLocations.model";
import { GeoTimeZone } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import { getLocationById, getSearchSuggestions } from "@/tenant-context/core/api/mapSearch";
import { SearchSuggestion } from "@/tenant-context/core/types/map-search.types";

type MapSearch = {
  suggestions?: SearchSuggestion[]
  activeLocation?: FeatureCollection<Point>
}

const mapSearchModel = {
  name: "mapSearch",
  state: {
    suggestions: undefined,
    activeLocation: undefined
    // foundLocation: undefined
  } as MapSearch,
  reducers: {
    SET_SUGGESTIONS: (state: MapSearch, suggestions: SearchSuggestion[]) => ({
      ...state,
      suggestions
    }),
    SET_ACTIVE_LOCATIONS: (state: MapSearch, activeLocation?: FeatureCollection<Point>) => ({
      state,
      activeLocation
    })
  },

  effects: (dispatch: Dispatch) => ({

    async loadSuggestions(
      options: {
        location: string,
        service: 'map-service' | 'lookup-service'
      }
    ): Promise<void> {

      const suggestions = await getSearchSuggestions(options.location, options.service || 'map-service');

      dispatch.mapSearch.SET_SUGGESTIONS(suggestions.suggestions);

    },

    async loadFoundLocation(
      options: {
        mapboxId: string,
        service: 'map-service' | 'lookup-service'
      },
      state: RootState
    ): Promise<void> {

      const {
        manageLocations: {
          enteredLocationDetails
        }
      } = state;

      const foundLocation = await getLocationById(options.mapboxId, options.service);

      const lat = foundLocation.features[0].geometry.coordinates[1];
      const lon = foundLocation.features[0].geometry.coordinates[0];

      // Setting up the timezone for the location
      const timeZone = await dispatch.reverseGeocoding.getLocationTimezone({
        lon,
        lat
      });

      if (timeZone && timeZone.timeZone) {
        const timezoneObj = geoTimeZones.find((tz) => tz.timeZone === timeZone.timeZone);
        const mappedTimeZone: GeoTimeZone = {
          ...timeZone,
          value: timeZone.value ?? timezoneObj?.value
        };

        foundLocation.features[0].properties = {
          ...foundLocation.features[0].properties,
          timezone: mappedTimeZone
        } as GeoJsonProperties;
      }

      const { features } = foundLocation;

      const [feature] = features;
      const { geometry, properties } = feature;

      const { coordinates } = geometry;
      // Timezone is setted in the map search modal
      const { name,  address, context, timezone } =
          properties as MapboxGeocodingProperties & { timezone: GeoTimeZone };
      const { country , place, postcode } = context;
      const resolvedAddress = address ? address : name;

      // Resetting the entered location details
      dispatch.manageLocations.SET_ENTERED_LOCATION_DETAILS({
        ...enteredLocationDetails,
        lat: coordinates[1].toFixed(6),
        lon: coordinates[0].toFixed(6),
        isoCountryCode: country?.country_code || manageLocationsDefaultState.enteredLocationDetails.isoCountryCode,
        timezones: timezone || manageLocationsDefaultState.enteredLocationDetails.timezones,
        address01: resolvedAddress || manageLocationsDefaultState.enteredLocationDetails.address01,
        address02: manageLocationsDefaultState.enteredLocationDetails.address02,
        city: place?.name || manageLocationsDefaultState.enteredLocationDetails.city,
        countyRegion: manageLocationsDefaultState.enteredLocationDetails.countyRegion,
        postalCode: postcode?.name || manageLocationsDefaultState.enteredLocationDetails.postalCode
      });

      dispatch.mapSearch.SET_ACTIVE_LOCATIONS(foundLocation);
    }
  })
};

export const mapSearch = createModel<RootModel>()(mapSearchModel);
