import { Skeleton } from "@mantine/core";
import { FC, RefObject } from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import {
  usePeopleLocationPopupStyles
} from "@/tenant-context/visualisation-people/components/PeopleLocationPopup/PeopleLocationPopup.styles";
import { PersonPopupAssetRankingEvent, PopupPositionInfo } from "@/tenant-context/visualisation-people/types/people.types";

import PeopleTravelPopup from "./PeopleTravelPopup/PeopleTravelPopup.container";
import { StandardPersonPopup } from "./StandardPersonPopup.component";


type Props = {
  popupCoordinates?: PopupCoordinates,
  assetSummary?: PersonPopupAssetRankingEvent
  isShown: boolean,
  popupRef: RefObject<HTMLDivElement>,
  onMouseLeave:  () => void
  onMouseEnter: () => void,
  popupPosition?: PopupPositionInfo
}

export const PeopleLocationPopup: FC<Props> = ({
  assetSummary,
  popupCoordinates,
  isShown,
  popupRef,
  onMouseEnter,
  onMouseLeave,
  popupPosition
}) => {
  const { classes: popupClasses } = usePeopleLocationPopupStyles();

  if (!isShown) {
    return null;
  }

  return (
    <Popup
      longitude={ popupCoordinates?.longitude ?? 0 }
      latitude={ popupCoordinates?.latitude ?? 0 }
      padding="0"
      closeButton={ false }
      offset={ 20 }
      popupPosition={ popupPosition }
    >
      <div
        ref={ popupRef }
        onMouseEnter={ onMouseEnter }
        onMouseLeave={ onMouseLeave }
        className={ popupClasses.popupContainer }
      >
        { !assetSummary ? <div className={ popupClasses.loaderContainer }>
          <div className={ popupClasses.personDetailLoad }>
            <div className={ popupClasses.personPicLoad }>
              <Skeleton height={ 76 } />
            </div>
            <div className={ popupClasses.profileInfoLoad } >
              <Skeleton height={ 76 } />
            </div>
          </div>
          <div className={ popupClasses.personInfoLoad }>
            <div className={ popupClasses.infoItemLoad }>
              <Skeleton height={ 80 } />
            </div>
            <div className={ popupClasses.infoItemLoad }>
              <Skeleton height={ 80 } />
            </div>
            <div className={ popupClasses.infoItemLoad }>
              <Skeleton height={ 80 } />
            </div>
          </div>
          <div className={ popupClasses.locationInfoLoad }>
            <Skeleton height={ 108 } />
          </div>
          <div className={ popupClasses.locationTimerLoad }>
            <Skeleton height={ 55 } />
          </div>
        </div> : assetSummary.adapterSource === 'travel-book-adapter-service' ?
          <PeopleTravelPopup assetSummary={ assetSummary } /> :
          <StandardPersonPopup assetSummary={ assetSummary } /> }

      </div>
    </Popup>
  );
};

export default PeopleLocationPopup;
