export const handleLocationTypeDisplay = (locationEnum: string): string => {
  switch (locationEnum) {
  case "COUNTRY":
    return "Other - Country";
  case "CITY":
    return "Other - City";
  case "HOME":
    return "Home Address";
  case "WORK":
    return "Work Location";
  case "REQUEST_FLIGHT":
  case "REQUEST_TAXI":
  case "REQUEST_HOTEL":
  case "BOOKED_FLIGHT":
  case "BOOKED_RAIL":
  case "BOOKED_HOTEL":
  case "BOOKED_TAXI":
  case "BOOKED_CAR_HIRE":
  case "PLANNED_VISIT":
  case "PLANNED_JOURNEY":
  case "MANAGED_VISIT":
  case "MANAGED_JOURNEY":
    return "Travel Itinerary";
  case "ACCESS_CONTROL_SYSTEM":
  case "PERSON_CONTROL_SYSTEM":
    return "Access Control";
  case "MUSTER_CONTROL_SYSTEM":
    return "Muster Control";
  case "MOBILE_APP_GPS_CHECK_IN":
    return "Check In (Restrata APP)";
  case "MOBILE_APP_GPS":
    return "GPS Location (Restrata APP)";
  default:
    return locationEnum;
  }
};
