import { environment } from '@/core/config/env';
import { Location } from "@/tenant-context/common/types/location";
import { getOutsystemsToken } from '@/tenant-context/navigation/api/navigation';

export const locationsCount = (subCategory = '', locationName = '', locations: Location[] = []) => locations.filter(
  (location) => (location.subCategory === subCategory && location?.parentList && location.parentList.find(
    (parentListItem) => parentListItem.code === locationName
  ))
).length;

export const navigateToSitePage = async (siteCode: string, application: 'Musters' | 'Site') => {
  const { access_token: osToken } = await getOutsystemsToken();

  const url = `${environment.outSystemsGlobalDashboardUrl}?` +
    `Application=${application}|${siteCode}&` +
    `Token=${osToken}&` +
    'OriginApp=GLOBAL-MAP&' +
    `OriginDomain=${environment.unifiedExperienceUrl}/`;

  window.open(url, "_blank")?.focus();
};
