import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { redirectWithinRestrataProductEcosystem } from "@/core/navigation/util/navigation";
import { Dispatch } from "@/core/store";
import { NavigationItemId, ProductArea } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";

import NotFoundComponent from "./NotFound.component";


const NotFoundContainer: FC = () => {
  const {
    commonData: {
      startLoading,
      loadingComplete
    }
  } = useDispatch<Dispatch>();

  const navigate = useNavigate();

  const navigateToCrossTenant = useCallback(() => {
    const item = {
      area: ProductArea.UnifiedExperience,
      unifiedExperienceApplication: 'landing-page',
      title: 'Back to CrossTenant',
      path: '',
      itemId: NavigationItemId.CrossTenant
    };

    startLoading();
    redirectWithinRestrataProductEcosystem(item, navigate)
      .finally(() => loadingComplete());
  }, [loadingComplete, navigate, startLoading]);

  useEffect(()=>{
    navigateToCrossTenant();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NotFoundComponent />;
};

export default NotFoundContainer;
