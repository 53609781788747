import { Location } from "@/tenant-context/common/types/location";
import { LocationAddress } from "@/tenant-context/control-location-management/types/ManageLocations.types";

export type SiteLocationSnapshot = Location & {
  // TS technical
  __subject: 'site',
  musterCount: number,
  description: string,
  peopleCount: number,
}

export type SiteLocationSnapshotProperties = Omit<SiteLocationSnapshot, "geoPoint">

export type CountrySiteCount = {
  isoCountryCode: string,
  latitude: number,
  longitude: number,
  count: number
}

export type  LocationsPeopleCount = {
  countries: CountryPeopleCount[],
  personInitials: string
  total: number
  siteTotal: number
  countryTotal: number
  musters: number
  visitors: number
  visitorsNext24Hrs: number
  _type: string
}

export type CountryPeopleCount = {
  code: string,
  sites: SitePeopleCount[]
  siteTotal: number,
  countryTotal: number,
  total: number
  musters: number
  visitors: number
  visitorsNext24Hrs: number
}

export type SitePeopleCount = {
  code: string,
  isMusterActive: boolean
  musterCount: number
  total: number
  visitorsNext24Hrs: number
  visitorsOnSite: number
  countryName: string
  address: string | LocationAddress
  description: string
  siteContactId?: string
  name?: string
  imageUrl?: string
  locationType?: string
  id?: string,
  subCategory?: string
}

export type Address = {
  firstLine?: string
  secondLine?: string
  thirdLine?: string
  postalCode?: string
  region?: string
  city?: string
  country?: string
}

export type ConnectedSiteStatus = {
  lastHeartbeatTimestamp: number,
  _type: string,
  locationId: string
}

export enum SiteConnectivityStatus {
  Online = "Online",
  Offline = "Offline"
}

export enum ConnectedSiteStreamType {
  ConnectedSiteHeartbeat = "ConnectedSiteHeartbeat"
}