import { RiskAlertLayerType } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

const BASE_ZOOM_LEVEL = 3;

// expression for filter the given risk category
const getFilter = (id:string) => {
  return   ['==',
    ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
    id
  ];
};

const getDataMinrWatchlistFilter = (activeWatchlist: Array<string>) => {
  // Mapbox filter to check if the watchlist id is in the active watchlist
  return [
    'in',
    ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
    ['literal', activeWatchlist]
  ];
};

// expression for get the icon name for given risk category name
const getIconName = (iconName:string) => {
  return ['concat',
    'RiskAlertIcon_',
    ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
    iconName
  ];
};

const getMaxSecurityIconName = () => {
  return ['concat',
    'RiskAlertIcon_MaxSecurity_',
    ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
    ['case',
      ['==', ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]], 'Extreme'],
      '_White',
      ''
    ]
  ];
};

export const RiskAlertLayers = (dataminrActiveWatchList: Array<string> = []): Array<RiskAlertLayerType> => {

  return [
    // BSOC Layers
    {
      id: 'r__risk-alert-BSOC-SAF',
      type: 'symbol',
      filter: [
        getFilter('SAF')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('SAF'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('SAF')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-BSOC-Crime',
      type: 'symbol',
      filter: [
        getFilter('Crime')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Crime'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Crime')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-BSOC-IED',
      type: 'symbol',
      filter: [
        getFilter('IED')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('IED'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('IED')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-BSOC-HighImpactExplosiveActivity',
      type: 'symbol',
      filter: [
        getFilter('HighImpactExplosiveActivity')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('HighImpactExplosiveActivity'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('HighImpactExplosiveActivity')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-BSOC-IDFUAVActivity',
      type: 'symbol',
      filter: [
        getFilter('IDFUAVActivity')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('IDFUAVActivity'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('IDFUAVActivity')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-BSOC-SecurityOperations',
      type: 'symbol',
      filter: [
        getFilter('SecurityOperations')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('SecurityOperations'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('SecurityOperations')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-BSOC-ProtestsAndGatherings',
      type: 'symbol',
      filter: [
        getFilter('ProtestsAndGatherings')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('ProtestsAndGatherings'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('ProtestsAndGatherings')
        ],
        'icon-size': 0.25
      }
    },

    // Riskline Layers
    {
      id: 'r__risk-alert-political',
      type: 'symbol',
      filter: [
        getFilter('1')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Political'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Political')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-conflict-and-terrorism',
      type: 'symbol',
      filter: [
        getFilter('2')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Conflict'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Conflict')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-unrest',
      type: 'symbol',
      filter: [
        getFilter('3')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Unrest'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Unrest')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-crime',
      type: 'symbol',
      filter: [
        getFilter('4')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Crime'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Crime')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-natural',
      type: 'symbol',
      filter: [
        getFilter('5')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Natural'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Natural')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-medical',
      type: 'symbol',
      filter: [
        getFilter('6')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          [
            'case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('Medical'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('Medical')
        ],
        'icon-size': 0.25
      }
    },
    {
      id: 'r__risk-alert-travel-safety',
      type: 'symbol',
      filter: [
        getFilter('7')
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('TravelSafety'),
            getIconName('Base')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('TravelSafety')
        ],
        'icon-size': 0.25
      }
    },

    // Dataminr Layers
    {
      id: 'r__risk-alert-dataminr-generic',
      type: 'symbol',
      filter: [
        getDataMinrWatchlistFilter(dataminrActiveWatchList)
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('DataMinrBase'),
            getIconName('DataMinrBase')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('DataMinrBase')
        ],
        'icon-size': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            0.25,
            0.125
          ],
          BASE_ZOOM_LEVEL,
          0.25
        ]
      }
    },

    // MAX Security Layers
    // MAX security base layer
    {
      id: 'r__risk-alert-max-security-generic',
      type: 'symbol',
      filter: [
        ['==',
          ['get', 'id', ['get', 'geojson', ['get', 'meta', ['get', 'json']]]],
          'MaxSecurity'
        ]
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            getIconName('TriangleBase'),
            getIconName('TriangleBase')
          ],
          BASE_ZOOM_LEVEL,
          getIconName('TriangleBase')
        ],
        'icon-size': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            0.25,
            0.125
          ],
          BASE_ZOOM_LEVEL,
          0.25
        ]
      }
    },
    // MAX security risk category layer
    {
      id: 'r__risk-alert-max-security-incident-type',
      type: 'symbol',
      filter: [
        ['==',
          ['get', 'id', ['get', 'geojson', ['get', 'meta', ['get', 'json']]]],
          'MaxSecurity'
        ]
      ],
      layout: {
        'icon-image': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            getMaxSecurityIconName(),
            getMaxSecurityIconName()
          ],
          BASE_ZOOM_LEVEL,
          getMaxSecurityIconName()
        ],
        'icon-size': [
          'step',
          ['zoom'],
          ['case',
            ['==', ['get', 'isImpacted'], true],
            0.3,
            0.13
          ],
          BASE_ZOOM_LEVEL,
          0.3
        ]
        // 'icon-size': 0.3
      }
    },



    // icon to indicate impacted risk alerts
    {
      id: 'r__risk-impact-alert-hovered',
      type: 'symbol',
      filter: [
        [ '==', ['get', 'isImpacted'], true ]
      ],
      layout: {
        'icon-image': 'RiskAlertIcon_RiskAlertImpacted',
        'icon-size': 0.3,
        // setting the offset for the impacted icon
        // eslint-disable-next-line no-magic-numbers
        'icon-offset':[25, -35]
      }
    }
  ];
};



