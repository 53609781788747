import { Tabs } from "@mantine/core";
import { FC, useContext } from "react";

import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import EditFloorForm
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/EditFloorForm";
import FloorUpload
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/FloorUpload";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-management/context/LocationInfo.context";

type Props = {
  setIsExpanded: (isExpanded: boolean) => void
}

const EditFloor: FC<Props> = ({
  setIsExpanded
}) => {

  const { classes } = useBuildingsAndFloorsStyles();
  const { isAuthorizedToEdit } = useContext(LocationInfoContext);

  return (
    <Tabs className={ classes.editFormTabs } defaultValue="details">
      <Tabs.List>
        <Tabs.Tab value="details">
          Details
        </Tabs.Tab>
        <Tabs.Tab disabled={ !isAuthorizedToEdit } value="upload">
          Upload Floor Plan
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="details">
        <EditFloorForm setIsExpanded={ setIsExpanded }/>
      </Tabs.Panel>

      <Tabs.Panel value="upload">
        <FloorUpload setIsExpanded={ setIsExpanded }/>
      </Tabs.Panel>
    </Tabs>
  );
};

export default EditFloor;
