import { getFormattedTimeDifference } from "@/common/util/format/date";

export const getTime = (time = Date.now(), includeTimeData = false) => {
  const timeDiff = getFormattedTimeDifference(time, Date.now());
  
  // eslint-disable-next-line fp/no-let
  let timeConstruct = "";

  if(!includeTimeData){
    if(!timeDiff.days.value){
      timeConstruct = timeDiff.hours.value + timeDiff.hours.label + ' ' + timeDiff.minutes.value + timeDiff.minutes.label;
    } else{
      timeConstruct = timeDiff.days.value + timeDiff.days.label;
    }
  } else{
    if(timeDiff.days.value){
      timeConstruct += timeDiff.days.value + " " + timeDiff.days.label + " ";
    }
    if(timeDiff.hours.value){
      timeConstruct += timeDiff.hours.value + " " + timeDiff.hours.label + " ";
    }
    timeConstruct += timeDiff.minutes.value + " " + timeDiff.minutes.label;
  }

  return timeConstruct;
};
