export const LocationManagementPoliciesConfig = {
  ADD_LOCATIONS_FULL_ACCESS: {
    policyNames: [
      'Tenant_System_Tenant-Location-RO_FullAccess'
    ]
  },
  LOCATION_MANAGEMENT_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_System_Tenant-Location-RO_FullAccess',
      'Tenant_System_Tenant-Location-RO_ReadOnly'
    ]
  },
  LOCATION_CATEGORY_FULL_ACCESS: {
    policyNames: ['Tenant_System_Tenant-LocationCategory_FullAccess']
  },
  LOCATION_BULK_UPLOAD: {
    policyNames: ['Tenant_System_Tenant-Location-RO_FullAccess']
  }
};
