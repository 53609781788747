/* eslint-disable no-param-reassign */
import { FeatureCollection } from "geojson";
import { useEffect, useState } from "react";

import { getSite } from "@/tenant-context/plugin-indoors/api/site";

export default function useSite() {
  const [site, setSite] = useState<FeatureCollection>();

  useEffect(() => {
    (async () => {
      const fetchedSite = await getSite();

      setSite(fetchedSite);
    })();
  }, []);

  return site;
}
