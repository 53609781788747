import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { ILocation } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { GeneratedConfigKey, SiteBuildVersions, SiteConfigItem, SiteConfigListType, SiteConfigRequest } from "../types/siteConfiguration.types";

export const getSiteConfigurations = (
  tenantId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<SiteConfigListType>>(
  `location-management-service/v2/connected-sites/configurations?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const generateTokenKey = (
  tenantId: string,
  siteId: string
) => callApi<GeneratedConfigKey>(
  `/managed/token?grant_type=sync_token&access_type=offline`,
  {
    headers: {
      'X-TenantId': tenantId,
      'x-SiteId': siteId,
      'Content-Type': 'application/json;'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const getExcludedTenantSites = (
  tenantId: string
) => callApi<Array<ILocation>>(
  `location-management-service/v2/connected-sites?excludeConfigured=true`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const getSiteConfigurationById = (configId: string, tenantId: string) => callApi<SiteConfigItem>(
  `location-management-service/v2/connected-sites/configurations/${configId}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const postSiteConfiguration =
  (siteConfigDetails: SiteConfigRequest, tenantId: string) => callApi<{ status: string }>(
    `location-management-service/v2/connected-sites/configurations`,
    {
      method: 'post',
      body: JSON.stringify(
        siteConfigDetails
      ),
      withTenantId: false,
      headers: {
        'X-TenantId': tenantId
      }
    }
  );

export const updateSiteConfiguration =
  (siteConfigDetails: SiteConfigRequest, tenantId: string) => callApi<{ status: string }>(
    `location-management-service/v2/connected-sites/configurations/${siteConfigDetails.tid}`,
    {
      method: 'put',
      body: JSON.stringify(
        siteConfigDetails
      ),
      withTenantId: true,
      headers: {
        'X-TenantId': tenantId,
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json'
      }
    }
  );

export const deleteSiteConfiguration = (siteConfigurationId: string, tenantId: string) => callApi(
  `location-management-service/v2/connected-sites/configurations/${siteConfigurationId}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;'
    },
    withTenantId: false,
    method: 'delete'
  }
);

export const deploySiteConfiguration = (
  siteConfigurationId: string, 
  version: {version: string}, 
  tenantId: string
) => callApi(
  `location-management-service/v2/connected-sites/configurations/${siteConfigurationId}/dispatches`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;'
    },
    withTenantId: false,
    method: 'post',
    body: JSON.stringify(
      version
    )
  }
);

export const getSiteVersions = () => callApi<Array<SiteBuildVersions>>(
  `location-management-service/v2/connected-sites/versions`,
  {
    headers: {
      'X-TenantId': 'default',
      'Content-Type': 'application/json;'
    },
    withTenantId: false,
    method: 'get'
  }
);

