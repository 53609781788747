import { createStyles, MantineTheme } from "@mantine/core";

export const useSitePlanStyles = createStyles((theme: MantineTheme) => ({
  link:{
    color: theme.colors.link[0]
  },
  sitePlanContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.colors.neutral[9]
  },
  sitePlanText: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '16px 0'
  },
  sitePlanOptionContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px 0'
  },
  sitePlanDrawOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed #DFE2E6',
    minHeight: '150px'
  },
  sitePlanTextSecondary: {
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.regular
  },
  drawIconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '32px',
    minWidth: '120px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: '16px',
    '& > *:not(:last-child)': {
      marginRight: 24
    }
  },
  errorMessage: {
    color: theme.colors.error[0],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular,
    marginTop: 8
  },
  uploadedFileMessage: {
    color: theme.colors.success[0],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular,
    marginTop: 8
  }
}));
