import { FC } from "react";

import { ReactComponent as RestrataLogo } from "@/core/navigation/icons/Restrata_Logo.svg";
import { NavIconProps } from "@/core/navigation/types/navigation";

const RestrataIcon: FC<NavIconProps> = () => {
  return (
    <RestrataLogo />
  );
};

export default RestrataIcon;
