import { Button, LoadingOverlay } from "@mantine/core";
import { ParentSize } from "@visx/responsive";
import { ColDef } from "ag-grid-community";
import { forwardRef, ForwardRefRenderFunction } from "react";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide/ServerDataGrid.container";
import { ReactComponent as Arrow } from "@/common/icons/Arrows/right-arrow.svg";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import LiveFeedButton from "@/tenant-context/control-live-feed/components/LiveFeedButton";
import { useLocationsListStyles } from "@/tenant-context/control-location-management/components/LocationList/LocationsList.styles";
import { UploadedLocationStatusGrid } from "@/tenant-context/control-location-management/types/ManageLocations.types";

type Props = {
  columnDefs: ColDef[];
  handleGoBackLocationList: () => void;
  handleDownloadErrorReport: () => void;
  status?: UploadedLocationStatusGrid
  isLoading: boolean
  id?: string;
  getData: (payload: {
    gridParams: GridParams;
  }) => Promise<UploadedLocationStatusGrid>;
};
const UnProcessedLocationsListComponent: ForwardRefRenderFunction<
  DataGrid,
  Props
> = (
  { columnDefs, handleGoBackLocationList, status, id, isLoading, getData, handleDownloadErrorReport },
  ref
) => {
  const { classes } = useLocationsListStyles();

  return (
    <ParentSize>
      { ({ height }) => (
        <div style={ { minHeight: height } } className={ classes.container }>
          <LoadingOverlay visible={ isLoading } overlayBlur={ 2 } />
          <div className={ classes.headerWrapper }>
            <h1 className={ classes.headerText }>Location Management</h1>
            <LiveFeedButton />
          </div>
          <div className={ classes.content }>
            <button
              role="link"
              tabIndex={ 0 }
              onClick={ handleGoBackLocationList }
              className={ classes.link }
            >
              <Arrow />
              Back
            </button>
            <div className={ classes.unProcessesLocationsHeader }>
              <div>
                <div className={ classes.headerText }>Unprocessed Data</div>
                { status && <div className={ classes.description }>
                  Last upload ({ status?.filename },
                  { ' ' + (status?.createdTime && new Date(status?.createdTime).toUTCString()) }). Following { status?.totalItems } records were not processed.
                </div> }
              </div>
              <Button onClick={ handleDownloadErrorReport }>
                Download Full Report
              </Button>
            </div>

            <div className="ag-theme-alpine-dark ag-theme-rt">
              <ServerDataGrid
                id={ "tenantAccessCards-grid" }
                columnDefs={ columnDefs }
                ref={ ref }
                getData={ getData }
                search={ true }
                additionalGridParams={ { id } }
              />
            </div>
          </div>
        </div>
      ) }
    </ParentSize>
  );
};

export default forwardRef(UnProcessedLocationsListComponent) as (
  props: Props & React.RefAttributes<DataGrid>
) => JSX.Element;
