import { useCallback, useRef } from "react";

import useLayerListener from "./useLayerListener";
const defaultDelayInMilliSeconds = 225;

export default function useLayerTimer(
  layerIdOrIds: string | string[],
  mouseEnterCallback: (evt: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData) => void,
  mouseLeaveCallback:() => void,
  delayInMilliSeconds = defaultDelayInMilliSeconds
) {
  const popupTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useLayerListener(
    'mouseenter',
    layerIdOrIds,
    useCallback((evt) => {
      if(popupTimer && popupTimer.current){
        clearTimeout(popupTimer.current);
      }
      popupTimer.current = setTimeout(() => {
        mouseEnterCallback(evt as mapboxgl.MapMouseEvent & {
          features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData);
      }, delayInMilliSeconds);

    },[delayInMilliSeconds, mouseEnterCallback])
  );

  useLayerListener(
    'mouseleave',
    layerIdOrIds,
    useCallback(() => {
      if(popupTimer && popupTimer.current){
        clearTimeout(popupTimer.current);
      }

      setTimeout(() => {
        mouseLeaveCallback();
      }, delayInMilliSeconds);

    },[delayInMilliSeconds, mouseLeaveCallback])
  );
}
