/* eslint-disable fp/no-let */
import { createUrl } from '@/common/util/api';
import { TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { showNotification } from "@/common/util/notification";
import { ENV, Environment } from "@/core/config/env";

export type SubscriptionEventHandler<E> = (event: E) => void;
export type SSEOptions = {
  // Flag to identify if the stream should be created with auth token. Default is true
  isWithAuth?: boolean;
  // Function to validate if the stream should be retried or not. You can use this to check if the stream is receiving
  // any data or not by checking the event data or the isDataReceived flag
  retryValidation?: (event: MessageEvent | null, isDataReceived: boolean) => boolean;
  // Interval in which the stream should be retried. Default is 3000ms
  retryInterval?: number;
}

const isInDevMode = (ENV === Environment.Dev ||  ENV === Environment.Qa);

export default function createServerSentEventStream<E>(
  url: string,
  onEvent: SubscriptionEventHandler<E>,
  options?: SSEOptions
) {
  const isWithAuth = options?.isWithAuth ?? true;
  const tenantToken = browserDatabase.getItem(TENANT_TOKEN_KEY);
  const authSegment = isWithAuth ? `${url.includes('?') ? '&' : '?'}access_token=${tenantToken}` : '';
  const mappedUrl = url.concat(authSegment);

  // Flag to identify if any data was received from the stream other than HB
  let isDataReceived = false;
  let retryTimeout: NodeJS.Timer;
  let isOnRetry = false;

  const stream = new EventSource(
    createUrl(mappedUrl).toString()
  );

  const retryHandler = (event: MessageEvent | null, isDataReceivedByStream: boolean) => {

    if (options?.retryValidation && options.retryValidation(event, isDataReceivedByStream)) {
      isOnRetry = true;
      retryTimeout = setInterval(() => {
        // Clear the current stream and create a new one.
        // This is done to avoid multiple streams being created in case of multiple retries

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        stream.removeEventListener('message', () => {});
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        stream.removeEventListener('error', () => {});
        stream.close();
        clearInterval(retryTimeout);

        createServerSentEventStream(url, onEvent, options);
      }, options.retryInterval || 3000);
    } else {
      isOnRetry = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    }
  };

  // Event listener to handle the events received from the stream. This is the main data handler
  stream.addEventListener('message', (ev) => {
    const event = JSON.parse(ev.data);

    if (event._type === 'HB') {
      // Ignore heartbeat events
      return;
    }

    if (!isDataReceived) {
      isDataReceived = true;
    }

    onEvent(event);
  });

  // Retry connection if it was closed or no response other than 'HB' was received
  stream.onmessage = (ev) => {
    const isHeartbeatEvent = JSON.parse(ev.data)._type === 'HB';

    if (!isOnRetry) {
      retryHandler(ev, isDataReceived ? true : isHeartbeatEvent);
    }
  };

  stream.onerror = (err) => {
    console.error('SSE Error', err);
    retryHandler(null, isDataReceived);

    showNotification({
      message: isInDevMode ? 'SSE Stream error. Check console for details' : 'Stream connection issue. Please refresh the page or contact support.',
      color: 'error'
    });
  };

  return stream;
}
