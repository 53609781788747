import { Global } from '@mantine/core';
import {
  FC
} from 'react';

import LoaderScreen from '@/core/components/SSOIntegration/LoaderScreen';
import { globalStyles } from '@/core/styles/global-styles';

import ProviderHaven from '../ProviderHaven/ProviderHaven.component';

import './App.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const App: FC = () => {
  return (
    <ProviderHaven>
      <Global styles={ globalStyles } />
      <LoaderScreen isGlobal/>
    </ProviderHaven>
  );
};

export default App;
