/* eslint-disable no-magic-numbers */
import { useMantineTheme } from "@mantine/core";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { GeoJSONSource } from "mapbox-gl";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CircleLayer, GeoJSONSourceRaw, useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { TRAVELLERS_SEARCH_DRAWERS } from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";
import {
  PERSON_RED_ALERT_FILTER,
  PERSON_YELLOW_ALERT_FILTER
} from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";
import { RiskAlertFeatureCollection } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

import { MiniMapOptions } from "../../types/mini-map.types";
import { Minimap } from "./MiniMap";
import { MiniMapContext, MiniMapContextType } from "./MiniMap.context";

const MiniMapControl = ({
  id = 'mapbox-minimap',
  width = 320,
  height = 180,
  center = [0, 0],
  zoomLevelFixed = false,
  zoomLevelOffset = -30,
  lineColor = '#FFFFFF',
  lineWidth = 0.5,
  lineOpacity = 1,
  fillColor = 'transparent',
  fillOpacity = 0,
  dragPan = false,
  scrollZoom = false,
  boxZoom = false,
  dragRotate = false,
  keyboard = false,
  doubleClickZoom = false,
  touchZoomRotate = false,
  minimized = false,
  toggleDisplay = true,
  collapsedWidth = 20,
  collapsedHeight = 20,
  togglePosition = 'bottomleft',
  showText = 'Show Minimap',
  hideText = 'Hide Minimap',
  bounds,
  maxBounds
}: MiniMapOptions) => {

  const mapUrl = useSelector((state: RootState) => state.devtools.mapUrl);
  const { setMiniMap } = useContext(MiniMapContext) as MiniMapContextType;
  const [miniMapEl, setMiniMapEl] = useState<HTMLDivElement>();
  const theme = useMantineTheme();
  const { current: map } = useMap();
  const { isBigMapZoomedIn } = useContext(BigMapContext);
  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.geoData);
  const peopleGeoData = useSelector((state: RootState) => state.peopleLocations.geoData);
  const isTravelSearchDrawerOpen = useSelector((state: RootState) =>
    state.drawer?.currentlyOpenRightDrawerId === TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_CONTROL);
  const isCountryWiseTravellersDataAvailable = useSelector((state: RootState) =>
    state.travellersSearch.countryWiseTravellers.length > 0);

  const locationGeoData = useSelector(({
    assetLocations: {
      geoData: assetGeoData
    }
  }: RootState) => assetGeoData);
  const { miniMap } = useContext(MiniMapContext);
  const controlContainerEl = document.getElementById('bottom-left-control-container');
  const minimap = useMemo(() => new Minimap({
    id,
    center,
    togglePosition,
    width,
    height,
    style: mapUrl,
    bounds,
    maxBounds,
    zoomLevelFixed,
    zoomLevelOffset,
    lineColor,
    lineWidth,
    lineOpacity,
    fillColor,
    fillOpacity,
    dragPan,
    scrollZoom,
    boxZoom,
    dragRotate,
    keyboard,
    doubleClickZoom,
    touchZoomRotate,
    minimized,
    toggleDisplay,
    collapsedWidth,
    collapsedHeight,
    showText,
    hideText
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  useEffect(() => {
    minimap?.getMap()?.setStyle(mapUrl);
  }, [mapUrl, minimap]);


  const onAddMinimapLoneWorkerAlarmLayer = useCallback((
    loneWorkerAlarmData: FeatureCollection<Geometry, GeoJsonProperties>
  ) => {

    const loneWorkerAlarmSource: GeoJSONSourceRaw = {
      type: 'geojson',
      data: loneWorkerAlarmData
    };

    const minimapLoneWorkerAlarmLayers: CircleLayer[] = [
      {
        id: 'mini_map_lone_worker_extreme_alarm_layer',
        source: 'mini_map_lone_worker_alarm_source',
        type: 'circle',
        paint: {
          'circle-color': theme.other.semantic.risk.extreme,
          "circle-radius": 4
        },
        filter: PERSON_RED_ALERT_FILTER
      },
      {
        id: 'mini_map_lone_worker_medium_alarm_layer',
        source: 'mini_map_lone_worker_alarm_source',
        type: 'circle',
        paint: {
          'circle-color': theme.other.semantic.risk.medium,
          "circle-radius": 4
        },
        filter: PERSON_YELLOW_ALERT_FILTER
      }
    ];

    if (!miniMap?.getSource('mini_map_lone_worker_alarm_source')) {
      miniMap?.addSource('mini_map_lone_worker_alarm_source', loneWorkerAlarmSource);
      minimapLoneWorkerAlarmLayers.map((layer) => miniMap?.addLayer(layer));
    }
  }, [
    miniMap, theme.other.semantic.risk.medium, theme.other.semantic.risk.extreme
  ]);

  const onAddMinimapRiskAlertLayer = useCallback((
    riskGeoData: RiskAlertFeatureCollection & FeatureCollection<Geometry, GeoJsonProperties>
  ) => {

    const riskAlertSource: GeoJSONSourceRaw = {
      type: 'geojson',
      data: riskGeoData
    };

    const minimapRiskImpactlayer: CircleLayer = {
      id: 'mini_map_impact_alert_layer',
      source: 'mini_map_impact_alert_source',
      type: 'circle',
      filter: ['==', ['get', 'isImpacted'], true],
      paint: {
        'circle-color': [
          'match',
          ['get', 'id', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
          // 1 -> lowest, 5 -> highest
          '5', theme.other.semantic.risk.extreme,
          '4', theme.other.semantic.risk.high,
          '3', theme.other.semantic.risk.medium,
          '2', theme.other.semantic.risk.moderate,
          '1', theme.other.semantic.risk.low,
          theme.other.semantic.risk.extreme
        ],
        "circle-radius": 4
      }
    };

    if (!miniMap?.getSource('mini_map_impact_alert_source')) {
      miniMap?.addSource('mini_map_impact_alert_source', riskAlertSource);
      miniMap?.addLayer(minimapRiskImpactlayer);
    }
  }, [
    miniMap,
    theme.other.semantic.risk.extreme,
    theme.other.semantic.risk.high,
    theme.other.semantic.risk.low,
    theme.other.semantic.risk.medium,
    theme.other.semantic.risk.moderate
  ]);

  const onAddMinimapAssetLocationLayer = useCallback((
    assetLocationGeoData: FeatureCollection<Geometry, GeoJsonProperties>
  ) => {

    const source: GeoJSONSourceRaw = {
      type: 'geojson',
      data: assetLocationGeoData
    };

    const layer: CircleLayer = {
      id: 'mini_map_data_layer',
      source: 'mini_map_data_source',
      type: 'circle',
      paint: {
        'circle-color': '#2bbfec',
        "circle-radius": 6
      }
    };

    if (!miniMap?.getSource('mini_map_data_source')) {
      miniMap?.addSource('mini_map_data_source', source);
      miniMap?.addLayer(layer);
    }
  }, [miniMap]);

  useEffect(() => {
    // Checking whether the mainMap available, controlContainerElement available and isMapZoomed than global zoom level
    // before creating the miniMap
    if (!map || !controlContainerEl || !isBigMapZoomedIn) {
      return;
    }

    if (!miniMapEl) {
      // Creating the miniMap elem
      const miniMapElement = minimap.onAdd(map.getMap(), controlContainerEl, false);
      setMiniMapEl(miniMapElement);
      setMiniMap(minimap.getMap());
    } else {
      const isTravellersSearchIsActive = isTravelSearchDrawerOpen && isCountryWiseTravellersDataAvailable;
      // display minimap only if the big map is zoomed in and travellers search is not active
      if (isBigMapZoomedIn && !isTravellersSearchIsActive) {
        miniMapEl.style.display = 'block';
      } else {
        miniMapEl.style.display = 'none';
      }
    }

    return () => {
      if (!miniMapEl) {
        return;
      }

      miniMapEl.style.display = 'none';
    };
  }, [map, minimap, controlContainerEl, isBigMapZoomedIn, miniMapEl, setMiniMap,
    isTravelSearchDrawerOpen, isCountryWiseTravellersDataAvailable]);

  useEffect(() => {
    if (!minimap) {
      return;
    }
    (minimap?.getMap()?.getSource('mini_map_lone_worker_alarm_source') as GeoJSONSource)?.setData(peopleGeoData);
    /* eslint-disable-next-line */
  }, [peopleGeoData]);

  useEffect(() => {
    if (minimap.getMap()) {
      setMiniMap(minimap.getMap());
    }

    if (!miniMap) {
      return;
    }

    // Adding layers to the minimap
    miniMap.on('load', () => {
      onAddMinimapRiskAlertLayer(riskAlertsGeoData);
      onAddMinimapAssetLocationLayer(locationGeoData);
      onAddMinimapLoneWorkerAlarmLayer(peopleGeoData);
    });

  }, [
    setMiniMap,
    minimap,
    miniMap,
    onAddMinimapRiskAlertLayer,
    onAddMinimapAssetLocationLayer,
    onAddMinimapLoneWorkerAlarmLayer,
    riskAlertsGeoData,
    locationGeoData,
    peopleGeoData
  ]);

  return null;
};

export default MiniMapControl;
