import { closeAllModals } from "@mantine/modals";
import { createContext, FC } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import { DistinguishingFeaturesModalFormData } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/DistinguishingFeaturesModal/DistinguishingFeaturesModal.component";

type DistinguishingFeaturesModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn
};

export const DistinguishingFeaturesModalContext = createContext<DistinguishingFeaturesModalContextType>(
  {} as DistinguishingFeaturesModalContextType
);

export const DistinguishingFeaturesModalContextProvider: FC = ({
  children
}) => {
  const formControls = useForm();

  const {
    profile: {
      updateDistinguishingFeatures
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as DistinguishingFeaturesModalFormData;

    const success = await updateDistinguishingFeatures(data);
    if(success){
      closeAllModals();
    }
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <DistinguishingFeaturesModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls
    }) }>
      { children }
    </DistinguishingFeaturesModalContext.Provider>
  );
};