import { createStyles } from "@mantine/core";
import { FC, useContext } from "react";

import { NavIconProps } from "@/core/navigation/types/navigation";
import { NavigationSidebarContext } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.container";

const BAR_OFFSET = 40;

const useOpenNavIconStyles = createStyles((theme, isClose: boolean | undefined) => ({
  svg: {
    overflow: 'hidden !important'
  },

  bar: {
    transitionProperty: 'transform',
    transitionDuration: '0.3s',
    transformOrigin: 'center',
    stroke: 'white',
    strokeWidth: '10%'
  },

  topBar: {
    transform: `translateX(-${ isClose ? 0 : BAR_OFFSET }%) ${ isClose ? 'rotate(45deg)' : '' }`
  },

  bottomBar: {
    transform: `translateX(${ isClose ? 0 : BAR_OFFSET }%) ${ isClose ? 'rotate(-45deg)' : '' }`
  }
}));

const OpenNavIcon: FC<NavIconProps> = () => {
  const {
    isExpanded
  } = useContext(NavigationSidebarContext);

  const {
    classes,
    cx
  } = useOpenNavIconStyles(isExpanded);

  return (
    <div >
      <svg width={ 17 } height={ 16 } className={ classes.svg }>
        <line x1="50%" y1="-10%" x2="50%" y2="110%" className={ cx(classes.bar, classes.topBar) } />

        <line x1="50%" y1="-10%" x2="50%" y2="110%" className={ cx(classes.bar, classes.bottomBar) } />
      </svg>
    </div>
  );
};

export default OpenNavIcon;
