import { FC, useContext } from "react";
import { MapboxEvent, ViewStateChangeEvent } from "react-map-gl";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import useSpinningGlobe from "@/core/hooks/useSpinningGlobe";
import { Dispatch, RootState, store } from "@/core/store";
import { MILLIS_IN_SECOND } from "@/tenant-context/common/util/constants";
import { getBoundingBox } from "@/tenant-context/common/util/map";
import { ViewportBbox } from "@/tenant-context/control-draw/types/draw";
import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";

import BigMapComponent from './BigMap.component';

import './BigMap.scss';

const BigMapContainer: FC = () => {
  const isGlobe = useSelector((state: RootState) => state.rankingSettings.isGlobe);
  const isArcOpen = useSelector((state: RootState) => state.arc.isArcOpen);
  const isProfileOpen = useSelector((state: RootState) => state.profile.isOpen);
  const isMassCommsOpen = useSelector((state: RootState) => state.massComms.massCommsNavigation.isOpen);
  const isEaArcOpen = useSelector((state: RootState) => state.eaArc.isOpen);

  useSpinningGlobe();

  const {
    bigMap: {
      setCurrentViewPort,
      setCurrentViewState
    }
  } = useDispatch<Dispatch>();

  const mapUrl = useSelector((state: RootState) => state.devtools.mapUrl);

  const { isBigMapZoomedIn } = useContext(BigMapContext);

  const updateBindingBox = useDebouncedCallback((e: MapboxEvent<undefined> | ViewStateChangeEvent) => {
    const currentMap = e.target;
    const boundingBox: ViewportBbox = getBoundingBox(currentMap);

    setCurrentViewPort(boundingBox);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setCurrentViewState(e.viewState);

    const { bigMap: { currentGeoFences } } = store.getState();
    // Neglect the fetch asset API call if there's any geo-fences available
    if (currentGeoFences.length > 0) {
      return;
    }
  }, MILLIS_IN_SECOND / 2);

  return (
    <BigMapComponent
      // Own state & handlers
      onLoad={ updateBindingBox }
      onMove={ updateBindingBox }
      isZoomedIn={ isBigMapZoomedIn }
      isGlobe={ isGlobe }
      isFullscreenOverlayOpen={ isArcOpen || isProfileOpen || isMassCommsOpen || isEaArcOpen }
      mapUrl={ mapUrl }
      isArcOpen={ isArcOpen }
      isMassCommsOpen={ isMassCommsOpen }
    />
  );
};

export default BigMapContainer;
