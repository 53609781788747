import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { CriticalLocationLayerType } from "@/tenant-context/visualisation-customer-locations/types/customer-location.types";

export const CriticalLocationLayerConfig: Array<CriticalLocationLayerType> = [
  {
    id: `r__criticalLocationSource-airports`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Airport'],
    layout: {
      'icon-image': 'static-location-airport-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Airports,
    fieldName: 'Airport'
  },
  {
    id: `r__criticalLocationSource-rail-stations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Rail Station'],
    layout: {
      'icon-image': 'static-location-rail-station-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.RailwayStations,
    fieldName: 'Rail Station'
  },
  {
    id: `r__criticalLocationSource-last-mile-sites`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Last Mile Site'],
    layout: {
      'icon-image': 'last-mile-site-marker',
      'icon-size': 0.25,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    toggleableLayerType: ToggleableLayerType.Locations,
    fieldName: 'Last Mile Site'
  },
  {
    id: `r__criticalLocationSource-ports`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Port'],
    layout: {
      'icon-image': 'static-location-harbour-marker',
      'icon-size': 0.25,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    toggleableLayerType: ToggleableLayerType.CustomerHarbors,
    fieldName: 'Port'
  },
  {
    id: `r__criticalLocationSource-police-stations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Police'],
    layout: {
      'icon-image': 'static-location-police-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Police,
    fieldName: 'Police'
  },
  {
    id: `r__criticalLocationSource-suppliers`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Supplier'],
    layout: {
      'icon-image': 'static-location-infrastructure-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Suppliers,
    fieldName: 'Supplier'
  },
  {
    id: `r__criticalLocationSource-transportation`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Transportation Hub'],
    layout: {
      'icon-image': 'static-location-transportation-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Transportation,
    fieldName: 'Transportation Hub'
  },
  {
    id: `r__criticalLocationSource-business-accomodations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Business Accommodation'],
    layout: {
      'icon-image': 'static-location-business-accommodation-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.BusinessAccommodation,
    fieldName: 'Business Accommodation'
  },
  {
    id: `r__criticalLocationSource-bus-stations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Bus Station'],
    layout: {
      'icon-image': 'static-location-business-accommodation-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.BusStations,
    fieldName: 'Bus Station'
  },
  {
    id: `r__criticalLocationSource-car-hires`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Car Hire'],
    layout: {
      'icon-image': 'static-location-car-hires-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.CarHires,
    fieldName: 'Car Hire'
  },
  {
    id: `r__criticalLocationSource-camps`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Camp'],
    layout: {
      'icon-image': 'static-location-camp-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Camps,
    fieldName: 'Camp'
  },
  {
    id: `r__criticalLocationSource-embassy`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Embassy'],
    layout: {
      'icon-image': 'static-location-embassy-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Embassy,
    fieldName: 'Embassy'
  },
  {
    id: `r__criticalLocationSource-evacuation-centres`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Evacuation Centre'],
    layout: {
      'icon-image': 'static-location-evacuation-point-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.EvacuationCenters,
    fieldName: 'Evacuation Centre'
  },
  {
    id: `r__criticalLocationSource-hotels`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Hotel'],
    layout: {
      'icon-image': 'static-location-hotel-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Hotels,
    fieldName: 'Hotel'
  },
  {
    id: `r__criticalLocationSource-hospitals`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Hospital'],
    layout: {
      'icon-image': 'static-location-hospital-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Hospitals,
    fieldName: 'Hospital'
  },
  {
    id: `r__criticalLocationSource-offices`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Office'],
    layout: {
      'icon-image': 'static-location-office-marker',
      'icon-size': 0.25
    },
    toggleableLayerType: ToggleableLayerType.Offices,
    fieldName: 'Office'
  },
  {
    id: `r__criticalLocationSource-custom-sites`,
    type: 'symbol',
    filter: ['none',
      ['==', 'subCategory', 'Airport'],
      ['==', 'subCategory', 'Rail Station'],
      ['==', 'subCategory', 'Last Mile Site'],
      ['==', 'subCategory', 'Port'],
      ['==', 'subCategory', 'Police'],
      ['==', 'subCategory', 'Supplier'],
      ['==', 'subCategory', 'Transportation Hub'],
      ['==', 'subCategory', 'Business Accommodation'],
      ['==', 'subCategory', 'Bus Station'],
      ['==', 'subCategory', 'Car Hire'],
      ['==', 'subCategory', 'Camp'],
      ['==', 'subCategory', 'Embassy'],
      ['==', 'subCategory', 'Evacuation Centre'],
      ['==', 'subCategory', 'Hotel'],
      ['==', 'subCategory', 'Hospital'],
      ['==', 'subCategory', 'Office']],
    layout: {
      'icon-image': 'last-mile-site-marker',
      'icon-size': 0.25,
      'text-allow-overlap': true,
      'icon-allow-overlap': true
    },
    toggleableLayerType: ToggleableLayerType.CustomSites,
    fieldName: 'Custom Site'
  }
];
