import { Badge,Divider } from "@mantine/core";
import { FC } from "react";

import DrawerControl from "@/common/components/DrawerControl";
import { ReactComponent as ImagePlaceholderIcon } from "@/common/icons/image-solid.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import { LocationAddress } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import CountBox from "@/tenant-context/control-site/components/CountBox";
import SiteContact from "@/tenant-context/control-site/components/SiteContact";
import { useSiteInfoControlStyles } from "@/tenant-context/control-site/controls/SiteInfoControl/styles";
import MasterTab from "@/tenant-context/visualisation-site/components/MusterTab";


export const SITE_INFO_CONTROL_DRAWER_ID = 'site-info-control';

type Props = {
  zonesCount: number
  buildingCount: number
  peopleCount: number
  musterCount: number
  isMuster: boolean
  navigateToSite: () => void
  isSitesAuthorized: boolean
  siteName?: string
  siteAddress?: string | LocationAddress
  siteCountryName?: string
  siteImageUrl?: string
  siteLocationType?: string
  siteId?: string,
  onSiteStatus: (locationId: string) => void,
  subCategory?: string,
  siteCountry: string
}

const CritLocationControlComponent: FC<Props> = ({
  zonesCount = 0,
  buildingCount = 0,
  musterCount = 0,
  isMuster,
  siteName,
  siteAddress,
  siteImageUrl,
  siteLocationType,
  siteId,
  onSiteStatus,
  subCategory,
  siteCountry
}) => {
  const { classes } = useSiteInfoControlStyles();
  return (
    <DrawerControl
      id={ SITE_INFO_CONTROL_DRAWER_ID }
      size={ 'lg' }
      drawerPosition="right"
    >
      <div className={ classes.content }>
        <div className={ classes.mainContent }>
          <div className={ classes.siteDescriptionContainer }>
            <div className={ classes.drawerImgContainer }>
              { siteImageUrl ? <img src={ siteImageUrl } alt="site" /> : <div className={ classes.imgPlaceHolderContainer }>
                <ImagePlaceholderIcon />
              </div> } 
            </div>
            <div className={ classes.siteDescription }>
              <Badge className={ classes.locationTypeBadge }>{ siteLocationType }</Badge>
              { subCategory === 'Last Mile Site' ? onSiteStatus(siteId as string) : null }
              <div className={ classes.siteName }>
                { siteName }
              </div>
              <Divider
                className={ classes.nameDivider }
                color={ genericColorPalette.blue[6] as CustomColors }
                size={ 2 }
              />
              <div className={ classes.siteLocation }>
                { siteAddress }
              </div>
              <div className={ classes.countryName }>
                { siteCountry }
              </div>
            </div>
          </div>
          <div className={ classes.blockTitle }>SITE CONTACT</div>
          <Divider/>
          <SiteContact/>
          <div className={ classes.blockTitle }>SITE DETAILS</div>
          <CountBox
            zonesCount={ zonesCount }
            buildingCount={ buildingCount }
          />
          { isMuster && <MasterTab musterCount={ musterCount }/> }
        </div>
        { /* temporary */ }
        { /* <Button
          className={ isSitesAuthorized ? classes.manageButton : classes.unauthorizedButton }
          fullWidth
          onClick={ navigateToSite }
          disabled={ (siteLocationType !== 'Connected Site - Basic' && siteLocationType !== 'Connected Site - Advance') }
        >
          Manage Site
        </Button> */ }
      </div>
    </DrawerControl>
  );
};

export default CritLocationControlComponent;
