import { FC } from "react";

import {
  useSitePlanOptionStyles
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/SitePlanOption/styles";

type Props = {
title: string,
}
const SitePlanOptionComponent:FC<Props> = ({ title, children }) => {

  const { classes } = useSitePlanOptionStyles();
  return (
    <div className={ classes.sitePlanOptionContainer }>
      <h4 className={ classes.sitePlanOptionTitle }>{ title }</h4>
      <div className={ classes.sitePlanChildrenWrapper }>
        { children }
      </div>
    </div>

  );
};

export default SitePlanOptionComponent;
