/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/boolean-prop-naming */
import { LoadingOverlay, TextInput } from "@mantine/core";
import { CellClickedEvent, ColDef, GridOptions, RowClassParams, RowSelectedEvent, RowStyle } from "ag-grid-community";
import { IsExternalFilterPresentParams, RowHeightParams } from "ag-grid-community/dist/lib/entities/iCallbackParams";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import { RowClickedEvent, SortChangedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useMemo } from "react";

import { agGridDefaultColDef } from "@/common/components/AgGrid/ag-grid.config";
import { useDataGridStyles } from "@/common/components/AgGrid/DataGrid.styles";
import PaginationBlock from "@/common/components/PaginationBlock/PaginationBlock.component";
import Spinner from '@/common/components/Spinner';
import { ReactComponent as ExpandIcon } from "@/common/icons/Arrows/down-arrow-20-20.svg";
import { ReactComponent as CollapseIcon } from "@/common/icons/Arrows/up-arrow.svg";
import { ReactComponent as CloseIcon } from '@/common/icons/close.svg';
import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';
import { GenericStringKeyObject } from "@/common/util/interface";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

type Props<T> = {
  id: string,
  data: Array<T>,
  columnDefs: Array<ColDef>,
  animateRows: boolean,
  suppressHorizontalScroll: boolean,
  suppressRowClickSelection: boolean,
  suppressDragLeaveHidesColumns: boolean,
  suppressCellFocus: boolean,
  domLayout: 'normal' | 'autoHeight' | 'print',
  gridOptions: GridOptions<T>,
  isWithExpandableRows: boolean,
  expandedRows: Map<number, boolean>,
  onRowExpandChanged: (rowIndex: number) => void,
  getExpandedRowHeight?: (params: RowHeightParams<T>) => number,
  isExternalFilterPresent?: (params: IsExternalFilterPresentParams<T>) => boolean,
  doesExternalFilterPass?: (node: RowNode<T>) => boolean,
  onCellClicked?: (event: CellClickedEvent<T>) => void,
  onRowClicked?: (event: RowClickedEvent<T>) => void,
  onRowSelected?: (e: RowSelectedEvent<T>) => void
  showingFrom: number
  showingTo: number
  totalRecords: number
  activePage: number
  setActivePage: (value: number) => void
  totalPages: number
  boundaries?: number
  itemsPerPage: string
  setItemsPerPage: (value: string) => void,
  cellAutoHeight: boolean,
  onSortChanged: (params: SortChangedEvent<any>) => void,
  search: boolean,
  handleSearchPhraseChange: (value: string) => void;
  searchInputPosition?: 'left' | 'right'
  headerContent?: () => ReactNode
  overlayNoRowsTemplate?: string
  loading: boolean,
  rowSelection?: 'single' | 'multiple',
  searchInputRef: React.RefObject<HTMLInputElement>,
  getCustomRowStyle?: (params: GenericStringKeyObject) => RowStyle | undefined,
  isResponsivePagination?: boolean,
  suppressRowHoverHighlight?: boolean
}

const DataGridComponent: ForwardRefRenderFunction<AgGridReact, Props<Record<string, unknown>>> = ({
  id,
  data,
  columnDefs,
  animateRows,
  suppressHorizontalScroll,
  suppressRowClickSelection,
  suppressDragLeaveHidesColumns,
  suppressCellFocus,
  domLayout,
  gridOptions,
  isWithExpandableRows,
  expandedRows,
  onRowExpandChanged,
  onRowSelected,
  getExpandedRowHeight,
  isExternalFilterPresent,
  doesExternalFilterPass,
  onCellClicked,
  onRowClicked,
  showingFrom,
  showingTo,
  totalRecords,
  activePage,
  setActivePage,
  totalPages,
  boundaries,
  itemsPerPage,
  setItemsPerPage,
  cellAutoHeight,
  onSortChanged,
  search,
  handleSearchPhraseChange,
  searchInputPosition,
  headerContent,
  overlayNoRowsTemplate,
  loading,
  rowSelection = 'single',
  searchInputRef,
  getCustomRowStyle,
  isResponsivePagination,
  suppressRowHoverHighlight
}, ref) => {

  const { classes } = useDataGridStyles(
    {
      hasData: data?.length > 0,
      searchInputPosition: searchInputPosition
    }
  );

  const getRowHeight = useCallback((params: RowHeightParams) => {
    return expandedRows.get(params.node.rowIndex as number) ? (getExpandedRowHeight?.(params) || 72) + 16 : 72;
  }, [expandedRows, getExpandedRowHeight]);

  const getRowStyles = useCallback((params: RowClassParams) => {
    const customRowStyles = getCustomRowStyle?.(params.data) || {};

    return {
      maxHeight: expandedRows.get(params.rowIndex as number) ? (getExpandedRowHeight?.(params) || 72) : 56,
      backgroundColor: expandedRows.get(params.rowIndex as number) ?
        genericColorPalette.neutral[8] : genericColorPalette.neutral[7],
      boxSizing: 'border-box',
      overflow: 'hidden',
      cursor: onRowClicked ? 'pointer' : 'default',
      ...customRowStyles
    };
  }, [getCustomRowStyle, expandedRows, getExpandedRowHeight, onRowClicked]);

  const expandCellRenderer = useCallback((params: Record<string, string | number>): ReactNode => {
    const isRowExpanded = expandedRows?.get(params.rowIndex as number) || false;
    return (
      // eslint-disable-next-line react/jsx-no-bind
      <button className={ classes.expandButton } onClick={ () => onRowExpandChanged?.(params.rowIndex as number) }>
        { isRowExpanded ? <CollapseIcon/> : <ExpandIcon/> }
      </button>
    );
  }, [ expandedRows, classes.expandButton, onRowExpandChanged ]);

  const mappedColDefs: Array<ColDef> = useMemo(() => {
    if (!isWithExpandableRows) {
      return columnDefs;
    }

    return [
      ...columnDefs,
      {
        field: 'expand',
        headerName: '',
        width: 55,
        flex: 0,
        cellRenderer: expandCellRenderer
      }
    ];
  }, [ columnDefs, expandCellRenderer, isWithExpandableRows ]);

  const handleClear = useCallback(()=>{
    if(searchInputRef && searchInputRef.current){
      // eslint-disable-next-line no-param-reassign
      searchInputRef.current.value = "";
    }
    handleSearchPhraseChange("");
  }, [handleSearchPhraseChange, searchInputRef]);

  return (
    <div id={ id } className={ `ag-theme-alpine-dark ag-theme-rt ${classes.root}` }>
      <div className={ classes.gridHeader }>
        { search && <div className={ classes.searchWrap }>
          <TextInput
            ref = { searchInputRef }
            className={ classes.searchInput }
            icon={ <SearchIcon/> }
            size="lg"
            placeholder="Search"
            // eslint-disable-next-line react/jsx-no-bind
            onChange={ (evt) =>  handleSearchPhraseChange(evt.target.value) }
            // eslint-disable-next-line react/jsx-no-bind
            rightSection={ <CloseIcon className={ classes.clearSearch } onClick={ handleClear }/> }
          />
        </div> }
        { headerContent && <div className={ search ? classes.headerWrap : classes.flex }>{ headerContent() }</div> }
      </div>
      <div className={ classes.relative }>
        <LoadingOverlay
          visible={ loading }
          overlayBlur={ 2 }
          loader={ <div className={ classes.loader }>
            <Spinner/>
            <div>Searching...</div>
          </div> }
        />

        <AgGridReact
          ref={ ref }
          defaultColDef={ agGridDefaultColDef }
          rowData={ data }
          columnDefs={ mappedColDefs }
          paginationPageSize={ +itemsPerPage }
          animateRows={ animateRows }
          suppressRowClickSelection={ suppressRowClickSelection }
          suppressHorizontalScroll={ suppressHorizontalScroll }
          suppressDragLeaveHidesColumns={ suppressDragLeaveHidesColumns }
          suppressCellFocus={ suppressCellFocus }
          domLayout={ domLayout }
          gridOptions={ {
            ...gridOptions
          } }
          isExternalFilterPresent={ isExternalFilterPresent }
          doesExternalFilterPass={ doesExternalFilterPass }
          getRowHeight={ cellAutoHeight ? undefined : getRowHeight }
          getRowStyle={ cellAutoHeight ? undefined : getRowStyles }
          onCellClicked={ onCellClicked }
          onRowClicked={ onRowClicked }
          rowClass={ !isWithExpandableRows ? classes.rowWrap : '' }
          onSortChanged={ onSortChanged }
          rowStyle={ { backgroundColor: genericColorPalette.neutral[9] } }
          serverSideInfiniteScroll={ false }
          overlayNoRowsTemplate={ overlayNoRowsTemplate }
          rowSelection={ rowSelection }
          onRowSelected={ onRowSelected }
          suppressRowHoverHighlight={ suppressRowHoverHighlight }
        />

      </div>

      <PaginationBlock
        totalRecords={ totalRecords }
        itemsPerPage={ itemsPerPage }
        setItemsPerPage={ setItemsPerPage }
        showingFrom={ showingFrom }
        showingTo={ showingTo }
        activePage={ activePage }
        boundaries={ boundaries }
        setActivePage={ setActivePage }
        totalPages={ totalPages }
        isResponsive={ isResponsivePagination }
      />
    </div>
  );
};

export default forwardRef(DataGridComponent) as <T>(
  props: Props<T> & React.RefAttributes<AgGridReact>
) => JSX.Element;
