import { createStyles, MantineTheme } from "@mantine/core";

export const useRiskAlertPopupStyles = createStyles((theme: MantineTheme) => ({

  riskImpactPopup:{
    color: theme.white,

    '& > .mapboxgl-popup-content': {
      background: theme.colors.neutral[8],
      padding: '16px 8px',

      '& > .mapboxgl-popup-close-button': {
        color: theme.white
      }
    },
    '& > .mapboxgl-popup-tip': {
      border: 'none'
    }
  },

  popupContainer: {
    fontFamily: theme.fontFamily,
    backgroundColor: theme.colors.neutral[8],
    width: '363px'
  },

  popupAlternativeContainer: {
    fontFamily: theme.fontFamily,
    backgroundColor: theme.colors.neutral[8],
    width: '363px',
    padding: '8px 16px'
  },

  timesAgoText:{
    fontFamily: theme.fontFamily,
    display: 'flex',
    marginTop: '-5px',
    paddingRight: '20px',
    justifyContent: 'flex-end',
    fontSize: theme.fontSizes.xs,
    div: {
      color: theme.colors.neutral[5]
    }
  },

  popupHeader: {
    backgroundColor: theme.colors.neutral[8],
    maxHeight: '200px',
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.neutral[5]
  },

  popupAlternativeHeader: {
    backgroundColor: theme.colors.neutral[8],
    color: theme.colors.neutral[4],
    maxHeight: '200px',
    display: 'flex',
    flexDirection: 'column'
  },

  headerTop: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  countryInfo: {
    fontSize: theme.fontSizes.md,
    marginTop: '10px',
    fontWeight: theme.other.fw.regular,
    lineHeight: '100%',
    color: theme.colors.neutral[0]
  },

  impactSectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '20px',
    marginTop: '25px'
  },

  impactCalculation: {
    textAlign: 'left',
    verticalAlign: 'middle',
    width: '50%'
  },

  occurence: {
    textAlign: 'left',
    verticalAlign: 'middle',
    width: '50%'
  },

  impactInfoTable: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    marginTop: '10px',
    width: '90%'
  },

  impactInfoTableRow: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    height: '35px',
    textAlign: 'center'
  },

  impactInfoTableData: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    width: '50%',
    verticalAlign: 'middle'
  },

  riskAlertSummarySection: {
    marginTop: '20px',
    paddingLeft: '20px'
  },

  riskAlertTitle:{
    fontSize: theme.fontSizes.md,
    color: theme.white,
    fontFamily: theme.fontFamily,
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.l,
    marginBottom: '32px',
    padding: 20
  },

  riskAlertSummary: {
    marginTop: '10px',
    paddingRight: '20px',
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[0],
    fontFamily: theme.fontFamily,
    fontWeight: theme.other.fw.regular
  },

  separator: {
    marginTop: '5px',
    left: '0%',
    top: '100%',
    width: '90%',
    background: theme.colors.neutral[6],
    opacity: 0.3
  },

  riskAlertProvider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '20px',
    marginTop: '8px',
    gap: '3px'

  },

  issuedBy: {
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily,
    fontStyle: 'italic'
  },

  riskAlertProviderName: {
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily,
    color: theme.white

  },

  riskAlerProviderIcon: {
    width: '16px',
    height: '16px'
  },

  responseBox:{
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%'
  },

  responseStatusText: {
    display: 'flex',
    gap: '10px',
    color: theme.colors.neutral[5],
    letterSpacing: '0.5px',
    marginTop: '20px',
    textAlign: 'left',
    paddingLeft: '20px',
    fontSize: theme.fontSizes.sm,
    fontFamily: theme.fontFamily
  },

  responseProgress: {
    color: theme.white,
    letterSpacing: '0.5px',
    marginTop: '8px',
    textAlign: 'left',
    paddingLeft: '20px',
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h6.fontSize,
    fontFamily: theme.fontFamily,
    paddingBottom: '20px',
    lineHeight: '1.2'
  },

  lastModifiedTxt: {
    paddingLeft: '20px',
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },

  lastModified: {
    paddingLeft: '20px',
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.regular,
    paddingBottom: '20px'
  },

  boldText: {
    fontWeight: theme.other.fw.bold
  },

  riskLevelIdText:{
    position: 'absolute',
    right: '50%',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.regular,
    left: '63%'
  },

  riskLevelIcon:{
    position: 'relative'
  },

  divider:{
    margin: '27px 16px 0',
    borderTopColor: theme.colors.neutral[8]
  },

  riskInfoPublishedOn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.neutral[3],
    fontSize: theme.fontSizes.xs
  },

  incidentTimeInfo: {
    fontFamily: theme.fontFamily,
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.neutral[4],
    fontSize: theme.fontSizes.xs
  },

  boldIncidentTimeInfo:{
    fontWeight: theme.other.fw.bold,
    color: theme.white
  },

  responseStatusContainer:{
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    backgroundColor: theme.colors.neutral[9],
    fontFamily: theme.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '16px',
    paddingTop: '8px'
  },

  responseMeta:{
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px'
  },

  responseMetaTxt:{
    paddingLeft: '20px',
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },

  responseMetaValue:{
    paddingLeft: '20px',
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.regular,
    paddingBottom: '20px'
  }
}));
