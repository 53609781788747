// Time constants
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
export const MILLIS_IN_SECOND = 1000;
export const MILLIS_IN_MINUTE = MILLIS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLIS_IN_HOUR = MILLIS_IN_MINUTE * MINUTES_IN_HOUR;
export const MILLIS_IN_DAY = MILLIS_IN_HOUR * HOURS_IN_DAY;

// Geo constants
export const MIN_LONG_BOUND = -180;
export const MIN_LAT_BOUND = -90;
export const MAX_LONG_BOUND = 180;
export const MAX_LAT_BOUND = 90;
export const WORLD_MAX_BOUNDS: [number, number, number, number] = [
  MIN_LONG_BOUND,
  MIN_LAT_BOUND,
  MAX_LONG_BOUND,
  MAX_LAT_BOUND
];
export const GLOBAL_LEVEL_ZOOM = 2;
export const DEFAULT_LONG_LAT_DECIMALS = 6;
export const INITIAL_MAP_VIEW = {
  latitude: 30,
  longitude: 30,
  zoom: 2
};
export const INITIAL_MAP_BOUNDS = {
  sw: {
    lng: -124.98046875000068,
    lat: -49.889429029590104
  },
  ne: {
    lng: 180.00000000000045,
    lat: 76.12383087146111
  }
};
