import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from '@/tenant-context/common/types/common';
import {
  getRelatedEventsMaxSecurityPage,
  getRelatedEventsPage
} from '@/tenant-context/control-risk-config/api/event-timeline';
import {
  GeneralizedRelatedEvent,
  RelatedEvent
} from '@/tenant-context/control-risk-config/types/risk';
import { generalizeRelatedEvents } from '@/tenant-context/control-risk-config/util/generalize-related-event';


export type EventTimelineModelState = {
  relatedEventsLazyLoadedList: GeneralizedRelatedEvent[]
  relatedEventsCount: number
  lastPageLoaded: number
  pageSize: number
  selectedEventAlertId?: string
  selectedRelatedEvent?: GeneralizedRelatedEvent
}

const eventTimeLineModel = {
  name: 'eventTimeLine',
  state: {
    relatedEvents: [],
    relatedEventsLazyLoadedList: [],
    relatedEventsCount: 0,
    lastPageLoaded: 0,
    pageSize: 4
  } as EventTimelineModelState,
  reducers: {
    SET_RELATED_EVENTS_LAZY_LOADED_LIST: (state: EventTimelineModelState, payload: GeneralizedRelatedEvent[]) => {
      return {
        ...state,
        relatedEventsLazyLoadedList: payload
      };
    },
    SET_RELATED_EVENTS_COUNT: (state: EventTimelineModelState, payload: number) => {
      return {
        ...state,
        relatedEventsCount: payload
      };
    },
    SET_LAST_PAGE_LOADED: (state: EventTimelineModelState, payload: number) => {
      return {
        ...state,
        lastPageLoaded: payload
      };
    },
    SET_PAGE_SIZE: (state: EventTimelineModelState, payload: number) => {
      return {
        ...state,
        pageSize: payload
      };
    },
    SET_SELECTED_EVENT_ALERT_ID: (state: EventTimelineModelState, payload?: string) => {
      return {
        ...state,
        selectedEventAlertId: payload
      };
    },
    SET_SELECTED_RELATED_EVENT: (state: EventTimelineModelState, payload?: GeneralizedRelatedEvent) => {
      return {
        ...state,
        selectedRelatedEvent: payload
      };
    },
    RESET_EVENT_TIMELINE: () => {
      return {
        relatedEvents: [],
        relatedEventsLazyLoadedList: [],
        relatedEventsCount: 0,
        lastPageLoaded: 0,
        pageSize: 4
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async loadRelatedEventsLazyLoadedList(
      options: { parentEventId: string | undefined, provider: 'dataminr' | 'max-security' } | undefined,
      state: RootState
    ): Promise<void> {
      if (!options) {
        dispatch.eventTimeLine.SET_RELATED_EVENTS_LAZY_LOADED_LIST([]);
        return;
      }

      const { parentEventId, provider } = options;

      const {
        commonData: {
          tenantId
        },
        eventTimeLine: {
          pageSize,
          relatedEventsCount,
          relatedEventsLazyLoadedList
        }
      } = state;

      try {
        const calculatedSize = relatedEventsLazyLoadedList.length + pageSize;
        const size = ((relatedEventsCount > 0) && (calculatedSize > relatedEventsCount)) ?
          relatedEventsCount : calculatedSize;

        const relatedEventsPaginated: PaginatedResult<RelatedEvent> = await (
          provider === 'dataminr' ?
            getRelatedEventsPage(tenantId, parentEventId || '', 0, size) :
            getRelatedEventsMaxSecurityPage(tenantId, parentEventId || '', 0, size));

        const relatedEvents = generalizeRelatedEvents(relatedEventsPaginated.items, provider);
        dispatch.eventTimeLine.SET_RELATED_EVENTS_LAZY_LOADED_LIST(relatedEvents);
        dispatch.eventTimeLine.SET_RELATED_EVENTS_COUNT(relatedEventsPaginated.totalItems);
      } catch (error) {
        console.error('Failed to load related events', error);
        dispatch.eventTimeLine.SET_RELATED_EVENTS_LAZY_LOADED_LIST([]);
      }
    }
  })
};

export const eventTimeLine = createModel<RootModel>()(
  eventTimeLineModel
);
