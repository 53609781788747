import { FC, useContext } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { RiskContext } from "@/tenant-context/visualisation-risk-alerts/context/Risk.context";

import RiskAlertPopup from './RiskAlertPopup.component';

const RiskAlertPopupContainer: FC = () => {
  const {
    hoveredOnRiskLocation,
    riskLocationPopupCoordinates,
    handleMouseEnterPopup,
    handleMouseLeavePopup
  } = useContext(RiskContext);

  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup); 
  
  return (
    <RiskAlertPopup
      isShown={ currentBigMapPopup === "Risk" }
      popupCoordinates = { riskLocationPopupCoordinates }
      riskAlertData = { hoveredOnRiskLocation }
      onMouseEnter = { handleMouseEnterPopup }
      onMouseLeave = {  handleMouseLeavePopup }
    />
  );
};

export default RiskAlertPopupContainer;