import { Button } from "@mantine/core";
import { ICellRendererParams } from "ag-grid-community";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { FC, ReactNode, useCallback, useContext, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useDispatch } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { ReactComponent as EditIcon } from '@/common/icons/edit.svg';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { DataGrid } from "@/common/types/ag-grid";
import { Dispatch } from "@/core/store";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import BuildingListTableComponent
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/BuildingListTable/BuildingListTable.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-management/context/LocationInfo.context";
import {
  LocationManagementContext
} from "@/tenant-context/control-location-management/context/LocationManagement.context";
import {
  BuildingListItem
} from "@/tenant-context/control-location-management/types/ManageLocations.types";

type Props = {
  setBuildingView: (val: boolean) => void
}
const BuildingListTable: FC<Props> = ({ setBuildingView }) => {

  const { classes } = useBuildingsAndFloorsStyles();
  const ref = useRef<DataGrid>(null);
  const { AddLocationMap: map } = useMap();

  const {
    setIsAddBuildingFormOpened,
    isAddBuildingFormOpened,
    isAuthorizedToEdit
  } = useContext(LocationInfoContext);

  const {
    setMarkerLocationExternally
  } = useContext(LocationManagementContext);

  const {
    manageLocations:{
      getBuildingList,
      deleteBuilding,
      SET_ACTIVE_BUILDING
    }
  } = useDispatch<Dispatch>();

  const handleBuildingEditClick = useCallback(async (data: BuildingListItem) => {
    setIsAddBuildingFormOpened(true);
    SET_ACTIVE_BUILDING({
      name: data?.name,
      code: data?.code,
      geoPoint: data?.geoPoint,
      note: data?.note,
      buildingTid: data?.buildingTid
    });
  }, [SET_ACTIVE_BUILDING, setIsAddBuildingFormOpened]);

  const onRowClicked = useCallback((e: RowClickedEvent<BuildingListItem>) => {
    if ((e?.event?.target as HTMLElement)?.getAttribute('col-id') === 'action' || !(e?.event?.target as HTMLElement)?.getAttribute('col-id')) {
      return;
    }
    if (e.data?.geoPoint?.lat && e.data?.geoPoint?.lon) {
      const lat = +e.data?.geoPoint?.lat;
      const lon = +e.data?.geoPoint?.lon;
      setMarkerLocationExternally(lat, lon);
      if (map) {
        map?.flyTo({ center: [lon, lat], zoom: 15 });
      }
    }
    SET_ACTIVE_BUILDING(e.data);
    setBuildingView(false);
  }, [SET_ACTIVE_BUILDING, map, setBuildingView, setMarkerLocationExternally]);

  const handleUnauthorizedAction = useUnauthorizedModal();
  const handleDeleteBuildingWithFloorsAction = useUnauthorizedModal(
    'Warning',
    { customMessage: 'You can\'t delete building with floors. Please delete floors first' }
  );


  const handleBuildingDelete = useCallback((building: BuildingListItem) => {
    if (!isAuthorizedToEdit) {
      handleUnauthorizedAction();
      return;
    }
    if (+building.floors !== 0) {
      handleDeleteBuildingWithFloorsAction();
    } else {
      openConfirmationModal({
        text: "Are you sure you want to remove this building?",
        onConfirm: async () => await deleteBuilding(building.buildingTid).then(() => ref.current?.refreshGrid()),
        title: "Confirm Removal"
      });
    }
  }, [deleteBuilding, handleDeleteBuildingWithFloorsAction, handleUnauthorizedAction, isAuthorizedToEdit]);

  const actionCellRenderer = useCallback((params: ICellRendererParams): ReactNode => {
    return (
      <>
        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={ () => handleBuildingEditClick(params.data) }
          className={ classes.btn }
        >
          <EditIcon/>
        </Button>
        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={ () => handleBuildingDelete(params.data) }
          className={ classes.btn  }
        >
          <TrashIcon/>
        </Button>
      </>
    );
  }, [classes.btn, handleBuildingDelete, handleBuildingEditClick]);

  useEffect(() => {
    if (!isAddBuildingFormOpened) {
      ref.current?.refreshGrid();
    }
  }, [isAddBuildingFormOpened, ref]);

  return (
    <BuildingListTableComponent
      getData={ getBuildingList }
      actionCellRenderer={ actionCellRenderer }
      onRowClicked={ onRowClicked }
      ref={ ref }
    />
  );
};

export default BuildingListTable;
