import { FC, ReactNode, useCallback, useContext } from "react";
import { Map } from "react-map-gl";
import { useSelector } from "react-redux";

import ControlContainer from "@/common/controls/ControlContainer";
import { Products } from "@/common/types/products";
import { ENV, Environment } from "@/core/config/env";
import { RootState } from "@/core/store";
import { INITIAL_MAP_VIEW } from "@/tenant-context/common/util/constants";
import DeveloperToolsControl from "@/tenant-context/control-developer-tools/controls/DeveloperToolsControl";
import { ReactComponent as RestrictedMapIcon } from '@/tenant-context/navigation/icons/map/map-noaccess.svg';

import { AuthContext } from "../../context/Auth.context";
import { useAuthenticatedMapStyles } from "./AuthenticatedMap.styles";

type Props = {
  requiredProduct?: Products,
  children: ReactNode
}

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

const AuthenticatedMap: FC<Props> = ({ requiredProduct, children }) => {

  const { isProductSubscribed } = useContext(AuthContext);
  const mapUrl = useSelector((state: RootState) => state.devtools.mapUrl);
  const isInDevMode = (ENV === Environment.Dev || ENV === Environment.Qa);

  const { classes } = useAuthenticatedMapStyles();


  const handleAuthenticationCheck = useCallback((): boolean => {
    if (!requiredProduct) {
      return true;
    } else {
      return isProductSubscribed(requiredProduct);
    }
  }, [isProductSubscribed, requiredProduct]);


  return (
    handleAuthenticationCheck() ?
      (<>{ children }</>) :
      <Map
        id="DisabledMap"
        mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
        minZoom={ 2 }
        dragPan={ false }
        scrollZoom={ false }
        doubleClickZoom={ false }
        touchZoomRotate={ false }
        keyboard={ false }
        initialViewState={ INITIAL_MAP_VIEW }
        mapStyle={ mapUrl }
        renderWorldCopies={ true }
        projection={ 'mercator' }
        fog={ {} }
        style={ { pointerEvents: 'none', opacity: 0.6 } }
      >
        <div className={ classes.lockContainer }>
          <div className={ classes.lockIconContainer }>
            <RestrictedMapIcon className={ classes.lockIcon }/>
          </div>
        </div>

        <ControlContainer
          containerId="mid-right-control-container"
          position="top-right"
          layout="vertical"
        >
          { isInDevMode && <DeveloperToolsControl/> }
        </ControlContainer>
      </Map>

  );
};

export default AuthenticatedMap;
