import { createStyles,MantineTheme } from "@mantine/core";

export const useBuildingsAndFloorsStyles = createStyles((theme: MantineTheme) => ({
  floorForm: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.neutral[9],
    width: '100%',
    padding: '12px 0'
  },
  addFloorForm: {
    marginBottom: '24px'
  },
  divider: {
    margin: '24px 0'
  },
  addNewFloorTitleContainer: {
    marginBottom: '12px'
  },
  formRow: {
    display: "flex",
    width: "100%",
    columnGap: '16px'
  },
  formIsDefaultSwitch: {
    marginTop: '16px'
  },
  addBuildingWrapper: {
    padding: '24px'
  },
  formItem: {
    width: "100%"
  },
  floorTitleContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr 1fr',
    alignItems: 'center',
    marginTop: '24px'
  },
  floorCellInnerContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr 0.5fr',
    alignItems: 'center'
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  floorTitle: {
    color: `theme.colors.neutral[9] !important`,
    fontSize: theme.fontSizes.sm,
    borderRight: `1px solid ${theme.colors.neutral[7]}`,
    marginRight: '16px',
    '&:last-of-type': {
      marginRight: '0',
      paddingRight: '12px'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: '24px',
    '& > *:not(:last-child)': {
      marginRight: 24
    },
    alignSelf: 'flex-end'
  },
  btn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    padding: 0,
    width: 'fit-content',
    cursor: 'pointer'
  },
  floorWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    marginBottom: '16px'
  },
  goBackButton: {
    all: 'unset',
    display: 'flex !important',
    color: `${theme.colors.royalBlue[0]} !important`,
    marginRight: '8px !important',
    fontSize: `${theme.fontSizes.lg}px !important`,
    '&:hover': {
      color: 'none !important'
    }
  },
  goBackButtonText: {
    marginLeft: '8px'
  },
  floorCellContainer: {
    display: 'flex',
    padding: '0 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '8px 0',
    width: '100%',
    backgroundColor: theme.colors.neutral[9],
    border: `1px solid ${theme.colors.neutral[6]}`
  },
  floorCellHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px'
  },
  expandedButton: {
    all: 'unset',
    cursor: 'pointer',
    flexGrow: 0
  },
  floorName: {
    flexGrow: 1
  },
  trash: {
    flexGrow: 0,
    marginRight: '2%',
    cursor: 'pointer'
  },
  errorMessage: {
    color: theme.colors.error[0],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular,
    marginTop: 8
  },
  uploadedFileMessage: {
    color: theme.colors.success[0],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular,
    marginTop: 8
  },
  optionContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px 0'
  },
  uploadFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px'
  },
  editFormTabs: {
    width: '100%'
  },
  floorCellHeaderButton: {
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px'
  },
  buildingInfoContainer: {
    marginBottom: 0
  }
}
));
