import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RisklineToggleableFilterType } from "@/common/components/ToggleableFilter/ToggleableFilter.config";
import { Dispatch, RootState } from "@/core/store";
import RiskLineEventsSettings
  from "@/tenant-context/control-risk-config/components/RiskLineEventsSettings/RiskLineEventsSettings.component";

const RiskLineEventsSettingsContainer: FC = () => {
  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );

  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelFilters
  );

  const isGlobalCategoryToggleOn = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.isGlobalCategoryToggleOnRiskline
  );
  const isOnlyImpactedAlerts = useSelector((state:RootState)=> state.riskAlerts.showOnlyImpactedRiskEvents);
  const {
    riskEventFilterDataOptions: {
      ENABLE_FILTER,
      DISABLE_FILTER,
      CHANGE_RANGE_FILTER,
      ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE,
      DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE
    },
    riskAlerts: {
      SET_SHOW_ONLY_IMPACTED_RISK_EVENTS
    }
  } = useDispatch<Dispatch>();

  const SCALE_FACTOR = 25;

  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        checked,
        value: filter
      } = event.currentTarget;

      if (!Object.keys(RisklineToggleableFilterType).includes(filter)) {
        // Omit non-registered layer type interactions
        return;
      }


      if (checked) {
        ENABLE_FILTER(RisklineToggleableFilterType[filter as unknown as keyof typeof RisklineToggleableFilterType]);
      } else {
        DISABLE_FILTER(RisklineToggleableFilterType[filter as unknown as keyof typeof RisklineToggleableFilterType]);
      }
    },
    [ENABLE_FILTER, DISABLE_FILTER]
  );

  const handleChangeRangeFilter = useCallback(
    (event:number []) => {
      const scaledRanges = event.map(r => (r/ SCALE_FACTOR)+1);
      CHANGE_RANGE_FILTER(scaledRanges);
    },
    [CHANGE_RANGE_FILTER]
  );

  const handleGlobalCategoryToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    if (checked) {
      ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE();
    } else {
      DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE();
    }
  },[ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE, DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE]);

  const handleImpactRiskEventsToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    SET_SHOW_ONLY_IMPACTED_RISK_EVENTS(checked);

  }, [SET_SHOW_ONLY_IMPACTED_RISK_EVENTS]);

  return (
    <RiskLineEventsSettings
      onCategoryToggle= { handleSwitchToggle }
      onChangeGlobalCategoryToggle={ handleGlobalCategoryToggle }
      isGlobalCategoryToggleOn = { isGlobalCategoryToggleOn }
      onChangeRangeFilter= { handleChangeRangeFilter }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
      riskLevelSliderUpperLimit= { (riskLevelFilters[1] -1) * SCALE_FACTOR }
      riskLevelSliderLowLimit= { (riskLevelFilters[0] - 1) * SCALE_FACTOR }
      onImpactRiskEventsToggleChange={ handleImpactRiskEventsToggle }
      isShowOnlyImpactedAlerts ={ isOnlyImpactedAlerts }
    />
  );
};

export default RiskLineEventsSettingsContainer;
