import { FC, useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import FloorListTableComponent
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/FloorListTable/FloorListTable.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-management/context/LocationInfo.context";


type Props = {
  setBuildingView: (val: boolean) => void
}
const FloorListTable: FC<Props> = ({ setBuildingView }) => {
  const floors = useSelector((state: RootState) => state.manageLocations?.floorListData);
  const activeBuilding = useSelector((state: RootState) => state.manageLocations?.activeBuilding);

  const { isAddFloorFormOpened } = useContext(LocationInfoContext);

  const {
    manageLocations:{
      getFloorsList,
      SET_ACTIVE_BUILDING
    }
  } = useDispatch<Dispatch>();

  const handleGoBackButtonClick = useCallback(() => {
    SET_ACTIVE_BUILDING(undefined);
    setBuildingView(true);
  }, [SET_ACTIVE_BUILDING, setBuildingView]);

  useEffect(() => {
    getFloorsList();
  }, [getFloorsList]);

  return (
    <FloorListTableComponent
      handleGoBackButtonClick={ handleGoBackButtonClick }
      floors={ floors?.items || [] }
      isAddFloorFormOpened={ isAddFloorFormOpened }
      activeBuilding={ activeBuilding }
    />
  );
};

export default FloorListTable;
