import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Params = {
  isMapZoomedIn: boolean,
  isDatesSelected: boolean
}

export const useRiskDateFilterTriggerControlStyles = createStyles((theme: MantineTheme, params: Params) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  controlButton: {
    width: params.isDatesSelected ? 260 : 'auto',
    fontSize: theme.fontSizes.xs
  },
  triggerContent: {
    display: "flex",
    alignItems: "center",

    '& > button': {
      color: theme.colors.error[0]
    }
  },
  liveBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "max-content",
    height: 30,
    backgroundColor: theme.colors.primary[3],
    color: theme.colors.navyBlue[0],
    borderRadius: 1,
    marginLeft: params.isMapZoomedIn ? 24 : 0,
    // marginRight: 12,
    padding: "0 16px",
    fontSize: 12,
    "& b":{
      marginRight: 8
    }
  }
}));
