import { Feature, FeatureCollection, LineString, Position } from "geojson";


export const createLinesBetweenPopupAndRiskPoint = (popupCoordinates:Position, alertPoint: Position):
FeatureCollection<LineString> => {
  const lines: Feature<LineString>[] = [
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [alertPoint[0], alertPoint[1]],
          [(popupCoordinates[0] + alertPoint[0]) / 2, alertPoint[1]],
          [popupCoordinates[0], popupCoordinates[1]]
        ]
      },
      properties: { }
    }];

  return { type:"FeatureCollection", features:lines };
};
