import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { RootState } from "@/core/store";

type Props = {
  type: ToggleableLayerType,
  children: ReactNode
};

/**
 * ! When using this to wrap Layers that are children of Source component
 * ! You MUST use `source` prop of the Layers anyways
 * ! Because this intermediate component breaks direct parent-child structure of Source -> Layer
 * ! And it becomes Source -> ToggleableLayer -> Layer
 */
const ToggleableLayer: FC<Props> = ({
  children,
  type
}) => {
  const disabledLayerTypes = useSelector(
    (state: RootState) => state.dataOptions.disabledLayerTypes
  );

  // TODO enhance performance
  // base enhanced performance on visibility property
  if (disabledLayerTypes.includes(type)) {
    return null;
  }

  return (
    <>{ children }</>
  );
};

export default ToggleableLayer;