import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { Dispatch, RootState } from "@/core/store";
import {
  useTravellersSearchContext
} from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import {
  TravellersSearchControlsDrawerComponent
} from "@/tenant-context/control-travellers-search/drawers/TravellersSearchControlsDrawer/TravellersSearchControlsDrawer.component";

import { TRAVELLERS_SEARCH_DRAWERS } from "../../config/travellers-search.config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const allToggleableLayers = Object.keys(ToggleableLayerType).map(key => ToggleableLayerType[key]);

const TravellersSearchControlsDrawer = () => {

  const {
    travellersSearchForm
  } = useTravellersSearchContext();

  const isMainDrawerOpen = useSelector((state: RootState) =>
    state.drawer?.currentlyOpenRightDrawerId === TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_CONTROL);

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {

    if (!isMainDrawerOpen) {
      return;
    }

    DISABLE_LAYER_TYPE([
      ToggleableLayerType.People
    ]);

    return () => {
      ENABLE_LAYER_TYPE([
        ...allToggleableLayers
      ]);

      DISABLE_LAYER_TYPE([
        ToggleableLayerType.TravelCountriesView,
        ToggleableLayerType.TravelCountryDetailsView,
        ToggleableLayerType.CountryRiskLevel
      ]);
    };
  }, [ ENABLE_LAYER_TYPE, DISABLE_LAYER_TYPE, isMainDrawerOpen ]);

  return (
    <TravellersSearchControlsDrawerComponent
      travellersSearchForm={ travellersSearchForm }
    />
  );
};

export default TravellersSearchControlsDrawer;
