import { Popover } from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import { FC } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import { formatDate } from "@/common/util/format/date";
import {
  useRiskDateFilterTriggerControlStyles
} from "@/tenant-context/control-risk-timeline/controls/RiskDateFilterTriggerControl/RiskDateFilterTriggerControl.styles";

type Props = {
  isMapZoomedIn: boolean,
  isPopupOpen: boolean,
  dateRange: [Date | null, Date | null],
  minDate: Date | undefined,
  maxDate: Date | undefined,
  onPopupChange: (open: boolean) => void,
  onDateRangeChange: (value: [Date, Date]) => void,
  onPopupOpenRequest: () => void,
  onDateFilterClearRequest: ($event: React.MouseEvent<HTMLButtonElement>) => void
} & TestableComponent;

const RiskDateFilterTriggerControl: FC<Props> = ({
  isMapZoomedIn,
  isPopupOpen,
  dateRange,
  minDate,
  maxDate,
  onPopupChange,
  onDateRangeChange,
  dataTestId = "bottom-items-date-and-time"
}) => {

  const { classes } = useRiskDateFilterTriggerControlStyles({
    isMapZoomedIn,
    isDatesSelected : dateRange[0] !== null && dateRange[1]  !== null
  });

  const isDateRangeSelected = dateRange[0] !== null && dateRange[1] !== null;

  return (
    <div className={ classes.wrapper }>
      <Popover
        opened={ isPopupOpen }
        onChange={ onPopupChange }
      >

        { /* Live Indicator */ }
        { !isDateRangeSelected && (
          <div className={ classes.liveBadge }
            data-testid={ dataTestId }
          ><b>LIVE</b> { formatDate(new Date(), true) }</div>
        ) }

        { /* <Popover.Target>
          <Button
            fullWidth
            compact
            variant={ isDateRangeSelected ? 'outline' : 'filled' }
            size={ 'md' }
            className={ classes.controlButton }
            onClick={ onPopupOpenRequest }
          >
            { isDateRangeSelected ?
              <div className={ classes.triggerContent }>
                From: { dateRange[0]?.toLocaleDateString() } - to - { dateRange[1]?.toLocaleDateString() }
                <CloseButton onClick={ onDateFilterClearRequest } />
              </div>
              : <CalendarIcon /> }
          </Button>
        </Popover.Target> */ }

        <Popover.Dropdown>
          <RangeCalendar 
            value={ dateRange }
            size="xs"
            minDate={ minDate }
            maxDate={ maxDate }
            onChange={ onDateRangeChange }
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );

};

export default RiskDateFilterTriggerControl;
