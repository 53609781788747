import { FeatureCollection, GeoJsonProperties, LineString, Position } from "geojson";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import { RootState } from "@/core/store";
import { RiskContext } from "@/tenant-context/visualisation-risk-alerts/context/Risk.context";
import { useRiskAlertIcons } from "@/tenant-context/visualisation-risk-alerts/hooks/useRiskAlertIcons";
import { createLinesBetweenPopupAndRiskPoint } from "@/tenant-context/visualisation-risk-alerts/util/getRiskAlertPopupLine";

import RiskAlertLayer from "./RiskAlertLayer.component";

const layerIds = [
  // Riskline Risk alerts
  'r__risk-alert-political',
  'r__risk-alert-conflict-and-terrorism',
  'r__risk-alert-unrest',
  'r__risk-alert-crime',
  'r__risk-alert-natural',
  'r__risk-alert-medical',
  'r__risk-alert-travel-safety',
  // BSOC risk alerts
  'r__risk-alert-BSOC-SAF',
  'r__risk-alert-BSOC-Crime',
  'r__risk-alert-BSOC-HighImpactExplosiveActivity',
  'r__risk-alert-BSOC-IDFUAVActivity',
  'r__risk-alert-BSOC-SecurityOperations',
  'r__risk-alert-BSOC-IED',
  'r__risk-alert-BSOC-ProtestsAndGatherings',

  // Dataminr Risk alerts
  'r__risk-alert-dataminr-generic',

  // Dataminr Risk alerts
  'r__risk-alert-max-security-generic',
  'r__risk-alert-max-security-incident-type',

  // Impact alerts
  'r__risk-impact-alert-hovered',
  'r__risk-impacthovericons-impacted-warning',
  'r__risk-impacthovericons',

  // Selected alert
  'r__risk-current-selected-risk-event',
  'r__risk-current-selected-risk-event-max-security-category',
  'r__risk-current-selected-risk-event-impacted-icon'
];

const RiskAlertLayerContainer: FC = () => {
  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.geoData);
  const currentRiskAlert = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  const isRiskImpactCircleEnabled = useSelector((state: RootState) => state.riskDetails.isRiskImpactCircleEnabled);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);
  const isPopupLineEnabled = useSelector((state: RootState) => state.riskDetails.popupLineEnabled) && currentBigMapPopup === "Risk";

  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );
  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelFilters
  );
  const dataMinrWatchlist = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.dataMinrWatchlist
  ).map((watchlist) => watchlist.id);

  const showOnlyImpactedRiskEvents = useSelector(
    (state: RootState) => state.riskAlerts.showOnlyImpactedRiskEvents
  );
  const riskLevelBSOCFilters = useSelector((state: RootState) => state.riskEventFilterDataOptions.riskLevelBSOCFilters);

  const [popupLine, setPopupLine] = useState<FeatureCollection<LineString, GeoJsonProperties>>();


  const {
    handleRiskLocationHover,
    handleRiskLocationHoverLeave,
    handleRiskLocationClick,
    hoveredOnRiskLocation,
    riskLocationPopupCoordinates,
    calculatePopupOffset
  } = useContext(RiskContext);

  useLayerListener(
    'click',
    layerIds,
    (evt) => {
      setPopupLine(undefined);
      handleRiskLocationClick(evt, layerIds);
    }
  );

  const { current: map } = useMap();
  useRiskAlertIcons(map);

  useLayerListener(
    'mouseenter',
    layerIds,
    () => {
      if(map){
        map.getCanvas().style.cursor = 'pointer';
      }
    }
  );
  useLayerListener(
    'mouseleave',
    layerIds,
    () => {
      if(map){
        map.getCanvas().style.cursor = 'default';
      }
    }
  );

  useEffect(() => {
    if(!isPopupLineEnabled){
      return;
    }

    const riskLocationCordinates = isRiskImpactCircleEnabled
      ? currentRiskAlert?.json.meta.geojson.geometry.coordinates
      : hoveredOnRiskLocation?.json.meta.geojson.geometry.coordinates;

    if(!riskLocationCordinates){
      return;
    }
    const lines = createLinesBetweenPopupAndRiskPoint(
        [riskLocationPopupCoordinates?.longitude,
          riskLocationPopupCoordinates?.latitude] as Position,
        riskLocationCordinates as Position
    );

    setPopupLine(lines);
  }, [currentRiskAlert?.json.meta.geojson.geometry.coordinates,
    hoveredOnRiskLocation?.json.meta.geojson.geometry.coordinates,
    isPopupLineEnabled, isRiskImpactCircleEnabled,
    riskLocationPopupCoordinates?.latitude,
    riskLocationPopupCoordinates?.longitude] );

  useEffect(() => {
    if (!isPopupLineEnabled) {
      return;
    }
    if(map){
      map.on(
        'move',
        calculatePopupOffset
      );
    }

    return () => {
      map?.off(
        'move',
        calculatePopupOffset
      );
    };
  }, [isPopupLineEnabled, calculatePopupOffset, map]);

  const BSOCRiskLevelFilter = useMemo(() => {
    const { nonCritical, critical } = riskLevelBSOCFilters;

    if (critical && nonCritical) {  // TODO: Refactor
      return 'true false';
      // I know - pretty dumb. But this is temporary until it implements true and false values
      // for the risk event critical data property :P
    } else if (critical) {
      return 'true';
    } else if (nonCritical) {
      return 'false';
    } else {
      return '';
    }
  }, [riskLevelBSOCFilters]);

  useLayerListener(
    'mouseenter',
    layerIds,
    (evt) =>  handleRiskLocationHover(evt, layerIds)
  );

  useLayerListener(
    'mouseleave',
    layerIds,
    handleRiskLocationHoverLeave
  );

  return (
    <RiskAlertLayer
      riskAlertsGeoData={ riskAlertsGeoData }
      riskLevelLowerLimit={ riskLevelFilters[0] }
      riskLevelUpperLimit = { riskLevelFilters[1] }
      disabledRiskFilters = {  disabledRiskFilterTypes }
      hoveredRiskLocation = { hoveredOnRiskLocation }
      selectedRiskEventLocation = { currentRiskAlert }
      isShowOnlyImpactedRiskEvents = { showOnlyImpactedRiskEvents }
      isRiskImpactCircleEnabled = { isRiskImpactCircleEnabled }
      lineToPopup = { popupLine }
      BSOCRiskLevelFilter = { BSOCRiskLevelFilter }
      dataMinrWatchlist={ dataMinrWatchlist }
    />
  );
};

export default RiskAlertLayerContainer;


