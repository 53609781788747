import { Button, LoadingOverlay } from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { Polygon } from "@turf/turf";
import { Feature } from "geojson";
import { FC } from "react";
import { FileRejection } from "react-dropzone";

import { ReactComponent as DownloadIcon } from "@/common/icons/downloadFile.svg";
import { DrawingTypes } from "@/tenant-context/control-draw/types/draw";
import SitePlanMapToolbarComponent
  from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/SitePlanMapToolbar";
import SitePlanOptionComponent
  from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/SitePlanOption";
import UploadedFilesList
  from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/compoments/UploadedFilesList";
import {
  AllowedSitePlanUploadFileTypes
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/SitePlanTab.config";
import {
  useSitePlanStyles
} from "@/tenant-context/control-location-management/components/AddLocations/SitePlanTab/styles";
import {
  MAX_SITE_PLAN_UPLOAD_SIZE
} from "@/tenant-context/control-location-management/config/location-management.config";

type Props = {
  uploadedFile: FileWithPath | null;
  handleSaveUploadedFile: () => void;
  uploadedFileError: FileRejection | null;
  handleDrawingToolSelect: (type: DrawingTypes) => void;
  setIsDrawing: (val: boolean) => void
  isDrawing: boolean,
  currentSitePlanGeoJSON?: Feature<Polygon>
  isLoading: boolean
  onDrawCancelButtonClick: () => void
  onUploadCancelButtonClick: () => void
  onDropFileUpload: (file: FileWithPath[]) => void
  onRejectFileUpload: (file: FileRejection[]) => void
}
const SitePlanTab: FC<Props> = ({
  uploadedFile,
  handleSaveUploadedFile,
  uploadedFileError,
  handleDrawingToolSelect,
  setIsDrawing,
  isDrawing,
  currentSitePlanGeoJSON,
  isLoading,
  onDrawCancelButtonClick,
  onUploadCancelButtonClick,
  onDropFileUpload,
  onRejectFileUpload
}) => {

  const { classes } = useSitePlanStyles();
  return (
    <div className={ classes.sitePlanContainer }>
      <LoadingOverlay visible={ isLoading } overlayBlur={ 2 }/>
      { !uploadedFile && (<SitePlanOptionComponent title="Draw a simple boundary for your location">
        { /* eslint-disable-next-line react/jsx-no-bind,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */ }
        <div onClick={ () => setIsDrawing(true) } className={ classes.sitePlanDrawOptionContainer }>
          Draw an outline around the location
          <SitePlanMapToolbarComponent handleDrawingToolSelect={ handleDrawingToolSelect }/>
        </div>
        <div className={ classes.buttonContainer }>
          <Button size="md" variant="outline" onClick={ onDrawCancelButtonClick }>Cancel</Button>
          <Button
            onClick={ handleSaveUploadedFile }
            size="md"
            disabled={ !currentSitePlanGeoJSON }
          >
            Confirm Boundary Outline
          </Button>
        </div>
      </SitePlanOptionComponent>) }
      { !isDrawing && <>
        <div className={ classes.sitePlanText }>- OR -</div>
        <SitePlanOptionComponent title="Upload a site plan">
          <Dropzone
            multiple={ false }
            style={ { background: 'none' } }
            onDrop={ onDropFileUpload }
            onReject={ onRejectFileUpload }
            accept={ AllowedSitePlanUploadFileTypes }
            maxSize={ MAX_SITE_PLAN_UPLOAD_SIZE }
          >
            <div className={ classes.sitePlanOptionContent }>
              <DownloadIcon/>
            &nbsp;
              <span>Drag & drop your file here or <u className={ classes.link }>browse to upload</u></span>
            &nbsp;
              <span> Maximum file size: 2mb</span>
              <span>Valid file types: JSON, GeoJSON</span>
              { uploadedFile?.name &&
              (<span className={ classes.uploadedFileMessage }>Uploaded file: { uploadedFile?.name }</span>) }
              { uploadedFileError?.errors &&
              (<span className={ classes.errorMessage }>Error: { uploadedFileError?.errors?.[0].message }</span>) }
            </div>
          </Dropzone>
          <div className={ classes.buttonContainer }>
            <Button size="md" variant="outline" onClick={ onUploadCancelButtonClick }>Cancel</Button>
            <Button
              onClick={ handleSaveUploadedFile }
              size="md"
              disabled={ !uploadedFile }
            >
              Upload File
            </Button>
          </div>
        </SitePlanOptionComponent>
      </> }
      <UploadedFilesList/>
    </div>
  );
};

export default SitePlanTab;
