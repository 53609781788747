import { MantineThemeOther } from "@mantine/core";

import { CustomColors } from "@/core/styles/mantine/palettes/types";

const UNDEFINED_COLOR = '#4b0082';
const genericColorPalette: {
  [K in CustomColors]: string[]
} = {
  primary: [
    '#0F3552',
    '#1C4563',
    '#496272',
    '#CAE8F6',
    '#172E40', // Added Extended Palette/Primary/Bluish Grey 3 here until designs have finalized the Extended Palette,
    '#061927', // This is neutral 10/neutral[0] color. Earlier added here since we can't add 11 colors into a palette in mantine.
    '#FD7521',  // Extended/Orange/Orange 8
    '#DE57021a', // Color used for mustering site/country, in the design file rgba(222, 87, 2, 0.1);
    '#FFBC1F' // Extended/Yellow/Yellow 10
  ],

  white: ['#FFFFFF'],
  black: ['#000000'],

  // * defined below
  dark: [],

  // mirrored by vars -
  neutral: [
    '#F8F9FA', // 0..10
    '#F1F2F5', // 1..20
    '#E9EBEF', // 2..30
    '#DFE2E6', // 3..40
    '#CDD2DB', // 4..50
    '#A1AABA', // 5..60
    '#576275', // 6..70
    '#182F40', // 7..80
    '#0D2232', // 8..90
    '#061927'  // 9..100
  ],

  neutralLightShades: [
    'rgba(255, 255, 255, 0%)',
    'rgba(255, 255, 255, 25%)',
    'rgba(255, 255, 255, 50%)',
    'rgba(255, 255, 255, 75%)',
    'rgba(255, 255, 255, 100%)'
  ],

  neutralDarkShades: [
    'rgba(0, 0, 0, 0%)',
    'rgba(0, 0, 0, 25%)',
    'rgba(0, 0, 0, 50%)',
    'rgba(0, 0, 0, 75%)',
    'rgba(0, 0, 0, 100%)'
  ],

  risk: [
    '#47815D',
    '#13AE87',
    '#FFC94A',
    '#DF6A1C',
    '#C72323',
    // ? What is this ?
    '#DC2020'
  ],

  navyBlue: ['#0F3552'],
  royalBlue: [
    '#0F62FE',
    '#85B0FE'
  ],
  turquoiseBlue: ['#CAE8F6'],
  skyBlue: ['#71A2FE'],
  dodgerBlue: ['#3699FF'],

  // TODO the light one needs to be HEX/6 instead of HEX/8
  // This is in format [base, light]
  info: ['#1081E8', '#1081E833'],
  success: ['#29823B', '#EAF3EB'],
  warning: ['#E9A100', '#FDF6E5', '#FFD337'],
  error: ['#DC2020', '#FCEAEA'],
  link: ["#6BA5FC"],

  // Single color based pallettes

  blue: [
    "#D6E5FF",
    "#C2D7FF",
    "#AECAFF",
    "#85B0FE",
    "#5D95FE",
    "#347BFE",
    "#0F62FE",
    "#0C4FCC",
    "#0F2851",
    "#091933"
  ],

  pink: [
    "#EBC1D3",
    "#DD92B3",
    "#D883A8",
    "#D3739D",
    "#CE6492",
    "#C95487",
    "#C4457C",
    "#BA3B72",
    "#AB3668",
    "#9B315F"
  ],

  purple: [
    '#F2ECFD',
    '#E6D9FC',
    '#D9C6FA',
    '#CBB3F9',
    '#BDA0F8',
    '#B08DF7', // According to the design file, this is A379F6. But since it's the same color as next, kept this
    '#A379F6',
    '#9666F4',
    '#8953F3',
    '#7B40F2'
  ],

  turquoise: [
    "#91F3DA",
    "#7EF1D4",
    "#6CEFCE",
    "#5AEDC8",
    "#47EBC2",
    "#90CBBF",
    "#23E7B6",
    "#18DCAB",
    "#16CA9D",
    "#13AE87"
  ],

  orange: [
    "#FBE7DB",
    "#F6D0B6",
    "#F4C4A4",
    "#F2B892",
    "#EFAC80",
    "#EDA16E",
    "#EB955C",
    "#E98949",
    "#E67D37",
    "#DF6A1C"
  ],

  yellow: [
    "#FFEDC2",
    "#FFE7AD",
    "#FFE099",
    "#FFE099",
    "#FFDA85",
    "#FFD470",
    "#FFC94A",
    "#FFC847",
    "#FFC233",
    "#FFBC1F"
  ],

  aqua: [
    "#A8DFF0",
    "#96D9ED",
    "#85D3EA",
    "#74CCE7",
    "#63C5E3",
    "#52BFE0",
    "#40B9DD",
    "#2FB2DA",
    "#25A8D0",
    "#229ABF"
  ],

  electricPink: [
    "#FFD6E3",
    "#FFC2D5",
    "#FFADC7",
    "#FF99B9",
    "#FF85AB",
    "#FF709D",
    "#FF5C8F",
    "#FF4782",
    "#FF3374",
    "#FF1F66"
  ],

  finn: [
    "#E0C8E5",
    "#D0ACD8",
    "#C090CB",
    "#B074BE",
    "#984EA6",
    "#8C4899",
    "#80418B",
    "#733B7D",
    "#66346F",
    "#582D60"
  ],

  green: [
    "#C1EBC9",
    "#A2E2AF",
    "#83D894",
    "#73D387",
    "#64CE79",
    "#54C96C",
    "#3BBA54",
    "#36AB4D",
    "#319B46",
    "#29823B"
  ],

  red: [
    "#F3BABA",
    "#F0A8A8",
    "#ED9797",
    "#EA8686",
    "#E77474",
    "#E36363",
    "#E05252",
    "#DD4040",
    "#DA2F2F",
    "#C72323"
  ],

  teal: [
    "#CEFFFF",
    "#A2FCFF",
    "#63F7FD",
    "#1CE7F4",
    "#00CBDA",
    "#03A0B5",
    "#0A8194",
    "#126778",
    "#145565",
    "#063946"
  ]
};

const semanticColorPalette: MantineThemeOther['semantic'] = {
  risk: {
    low: genericColorPalette.risk[0],
    moderate: genericColorPalette.risk[1],
    medium: genericColorPalette.risk[2],
    high: genericColorPalette.risk[3],
    extreme: genericColorPalette.risk[4]
  },

  label: {
    1: genericColorPalette.white[0],
    2: genericColorPalette.neutral[0],
    3: genericColorPalette.neutral[5],
    4: genericColorPalette.neutral[6]
  },

  button: {
    default: {
      default: genericColorPalette.blue[6],
      hover: genericColorPalette.blue[7],
      focused: genericColorPalette.blue[7],
      pressed: genericColorPalette.blue[4],
      disabled: `${genericColorPalette.neutral[6]}33`,
      disabledText: genericColorPalette.neutral[6]
    },
    filled: {
      default: genericColorPalette.teal[9],
      hover: genericColorPalette.teal[8],
      focused: genericColorPalette.teal[8],
      pressed: genericColorPalette.teal[5],
      disabled: `${genericColorPalette.neutral[6]}33`,
      disabledText: genericColorPalette.neutral[6]
    },
    outline: {
      default: genericColorPalette.blue[4],
      hover: genericColorPalette.blue[1],
      focused: genericColorPalette.blue[3],
      pressed: genericColorPalette.blue[5],
      disabled: `${genericColorPalette.neutral[6]}33`,
      disabledText: genericColorPalette.neutral[6]
    },
    white: {
      default: genericColorPalette.red[9],
      hover: genericColorPalette.red[7],
      focused: genericColorPalette.red[7],
      pressed: genericColorPalette.red[4],
      disabled: `${genericColorPalette.neutral[6]}33`,
      disabledText: genericColorPalette.neutral[6]
    },
    subtle: {
      default: genericColorPalette.blue[4],
      hover: genericColorPalette.blue[7],
      focused: genericColorPalette.blue[7],
      pressed: genericColorPalette.blue[2],
      disabled: `${genericColorPalette.neutral[6]}33`,
      disabledText: genericColorPalette.neutral[6]
    },
    light: {
      default: genericColorPalette.blue[4],
      hover: genericColorPalette.blue[1],
      focused: genericColorPalette.blue[3],
      pressed: genericColorPalette.blue[5],
      disabled: `${genericColorPalette.neutral[6]}33`,
      disabledText: genericColorPalette.neutral[6]
    }
  },


  accent: {
    1: genericColorPalette.blue[6],
    2: genericColorPalette.blue[4]
  },

  meta: {
    info: {
      light: genericColorPalette.info[1],
      base: genericColorPalette.info[0]
    },
    success: {
      light: genericColorPalette.success[1],
      base: genericColorPalette.success[0]
    },
    warning: {
      light: genericColorPalette.warning[1],
      base: genericColorPalette.warning[0]
    },
    error: {
      light: genericColorPalette.error[1],
      base: genericColorPalette.error[0]
    }
  },

  // * declared below
  separator: UNDEFINED_COLOR,

  navigation: {
    map: {
      0: genericColorPalette.neutral[7],
      4: genericColorPalette.white[0]
    },

    primary: {
      active: genericColorPalette.primary[1],
      disabled: UNDEFINED_COLOR,
      hover: UNDEFINED_COLOR,

      lvl: {
        1: genericColorPalette.navyBlue[0],
        2: UNDEFINED_COLOR
      }
    },

    secondary: {
      active: genericColorPalette.neutral[2],
      disabled: UNDEFINED_COLOR,
      hover: UNDEFINED_COLOR,

      lvl: {
        1: genericColorPalette.neutral[7],
        2: genericColorPalette.neutral[6]
      }
    }
  },

  interactive: {
    control: {
      enabled: {
        // * declared below
        bg: UNDEFINED_COLOR,
        detail: genericColorPalette.neutral[0]
      },

      disabled: {
        bg: genericColorPalette.neutral[3],
        detail: UNDEFINED_COLOR
      },

      hover: {
        bg: UNDEFINED_COLOR,
        detail: UNDEFINED_COLOR
      }
    },

    input: {
      active: {
        bg: genericColorPalette.neutral[7],
        border: UNDEFINED_COLOR
      },
      inactive: {
        // * declared below
        bg: UNDEFINED_COLOR,
        border: UNDEFINED_COLOR
      }
    }
  },

  accessibility: {
    inner: '#101213',
    outer: '#FFD337'
  },

  icon: {
    general: {
      main: genericColorPalette.white[0],
      secondary: genericColorPalette.neutral[6],
      hover: genericColorPalette.blue[4],
      disabled: genericColorPalette.neutral[6],
      transparent: 'transparent',
      inactive: genericColorPalette.neutral[4],
      activeDisabled: genericColorPalette.neutral[7],
      onDisabled: genericColorPalette.neutralLightShades[2]
    },
    button: {
      primary: genericColorPalette.neutralLightShades[2],
      secondary: genericColorPalette.neutralLightShades[2],
      tertiary: genericColorPalette.neutralLightShades[2]
    }
  },

  text: {
    primary: genericColorPalette.white[0],
    secondary: genericColorPalette.neutral[5],
    tertiary: genericColorPalette.neutral[6],
    disabled: genericColorPalette.neutral[5],
    textOnBrand: genericColorPalette.white[0],
    textOnInvert: genericColorPalette.neutral[5],
    alert: {
      normal: genericColorPalette.neutral[5],
      information: genericColorPalette.blue[4],
      success: genericColorPalette.green[5],
      caution: genericColorPalette.orange[6],
      error: genericColorPalette.red[5]
    },
    button: {
      primary: genericColorPalette.neutralLightShades[4],
      secondary: genericColorPalette.neutralLightShades[4],
      tertiary: genericColorPalette.neutralLightShades[4],
      disabled: genericColorPalette.neutral[5]
    }
  },

  border: {
    general: {
      dark: genericColorPalette.neutral[4],
      light: genericColorPalette.neutral[5],
      lighter: genericColorPalette.neutral[6],
      lightest: genericColorPalette.neutral[7],
      accent: [genericColorPalette.blue[6], genericColorPalette.blue[4]]
    },
    alert: {
      normal: genericColorPalette.neutral[6],
      information: genericColorPalette.blue[6],
      success: genericColorPalette.green[9],
      caution: genericColorPalette.yellow[9],
      error: genericColorPalette.red[9]
    }
  },

  surfaceBackground: {
    primary: genericColorPalette.neutral[9],
    secondary: genericColorPalette.neutral[8],
    tertiary: genericColorPalette.neutral[7],
    quaternary: genericColorPalette.neutral[7],
    contrast: genericColorPalette.neutral[8],
    none: 'transparent',
    disabled: `${genericColorPalette.neutral[6]}33`
  }
};

/**
 * Generic values that reference semantic values
 */
genericColorPalette.dark = [
  /** 0 */ semanticColorPalette.label[1], // text color
  UNDEFINED_COLOR, // TBD
  /** 2 */ semanticColorPalette.label[2], // control secondary text color
  /** 3 */ semanticColorPalette.label[3], // input placeholder text color
  /** 4 */ genericColorPalette.neutral[3], // control secondary bg color
  /** 5 */ semanticColorPalette.interactive.input.active.bg, // input primary bg color
  /** 6 */ genericColorPalette.neutral[7], // Input bg color
  /** 7 */ semanticColorPalette.navigation.primary.lvl[1], // default body colour
  UNDEFINED_COLOR, // TBD
  UNDEFINED_COLOR,  // TBD,
  '#182F40', //TBD
  '#102636'
];

/**
 * Semantic values that reference other semantic values
 * */
semanticColorPalette.separator = semanticColorPalette.label[4];
semanticColorPalette.interactive.control.enabled.bg = semanticColorPalette.accent[1];
semanticColorPalette.interactive.input.inactive.border = semanticColorPalette.separator;
semanticColorPalette.interactive.input.active.border = genericColorPalette.neutral[0];

export { semanticColorPalette, genericColorPalette };
