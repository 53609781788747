import { createModel } from "@rematch/core";

import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import {
  createSeverity,
  deleteSeverity,
  getSeveritiesList, getSeverity,
  updateSeverity
} from "@/platform-context/control-icm-management/api/severity";
import {
  createSeverityLevel, deleteSeverityLevel,
  getSeverityLevel,
  getSeverityLevelList, postSeverityLevelOrder, updateSeverityLevel
} from "@/platform-context/control-icm-management/api/severityLevels";
import {
  ActiveSeverity,
  CreateSeverity,
  PaginatedResult, Severity, SeverityLevel, SeverityLevelDeleteItem, SeverityLevelOrder, SortData
} from "@/platform-context/control-icm-management/types/severityTypes";

type SeverityState = {
  severities?: PaginatedResult<Severity>
  activeSeverity?: ActiveSeverity
  severityLevels?: PaginatedResult<SeverityLevel>
  activeSeverityLevel?: SeverityLevel
  isSeverityFormVisible: boolean
}

const severityModel = {
  name: 'severities',
  state: {
    severities: undefined,
    activeSeverity: undefined,
    severityLevels: undefined,
    activeSeverityLevel: undefined,
    isSeverityFormVisible: false
  } as SeverityState,
  reducers: {
    SET_SEVERITIES: (state: SeverityState, severities: SeverityState['severities']) => ({
      ...state,
      severities
    }),
    SET_ACTIVE_SEVERITY: (state: SeverityState, activeSeverity: SeverityState['activeSeverity']) => ({
      ...state,
      activeSeverity
    }),
    SET_SEVERITY_LEVELS: (state: SeverityState, severityLevels: SeverityState['severityLevels']) => ({
      ...state,
      severityLevels
    }),
    SET_ACTIVE_SEVERITY_LEVEL: (state: SeverityState, activeSeverityLevel: SeverityState['activeSeverityLevel']) => ({
      ...state,
      activeSeverityLevel
    }),
    SET_IS_SEVERITY_FORM_VISIBLE: (state: SeverityState, isSeverityFormVisible: SeverityState['isSeverityFormVisible']) => ({
      ...state,
      isSeverityFormVisible
    })
  },
  effects: (dispatch: Dispatch) => ({
    async addNewSeverity(severity: CreateSeverity): Promise<ActiveSeverity | undefined> {
      if (!severity) {
        return;
      }
      const res = await createSeverity(severity).catch(handleError);
      if (res) {
        handleSuccess();
        dispatch.severities.SET_ACTIVE_SEVERITY(res);
        return res;
      }
    },
    async getSeverity(id: string): Promise<ActiveSeverity | undefined> {
      if (!id) {
        return;
      }
      const res = await getSeverity(id);
      dispatch.severities.SET_ACTIVE_SEVERITY(res);
      return res;
    },
    async getAllSeverities(
      meta?: {
        page?: number,
        size?: number,
        sort?: SortData
        search?: string
      }
    ): Promise<void> {
      const res = await getSeveritiesList(
        meta?.page,
        meta?.size,
        meta?.sort,
        meta?.search
      ).catch(handleError);

      if (res) {
        dispatch.severities.SET_SEVERITIES(res);
      }
    },
    async deleteSeverity(id: string): Promise<void> {
      if (!id) {
        return;
      }
      await deleteSeverity(id).catch(handleError);
      dispatch.severities.SET_ACTIVE_SEVERITY(undefined);
    },
    async updateSeverity(data: { severity: CreateSeverity, id: string }): Promise<void> {
      if (!data.id) {
        return;
      }
      const res = await updateSeverity(data.severity, data.id).catch(handleError);
      if (res) {
        handleSuccess();
        dispatch.severities.SET_ACTIVE_SEVERITY(res);
      }
    },
    async getAllSeverityLevels(_: void, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }

      const res = await getSeverityLevelList(activeSeverity?._id).catch(handleError);
      if (res) {
        dispatch.severities.SET_SEVERITY_LEVELS(res);
      }
    },
    async createSeverityLevel(severityLevel: SeverityLevel, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }
      const res = await createSeverityLevel(activeSeverity?._id, severityLevel)
        .catch(handleError);

      if (res) {
        handleSuccess();
        dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(undefined);
        dispatch.severities.getAllSeverityLevels();
      }
    },
    async getSeverityLevel(id: string, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id || !id) {
        return;
      }
      const res = await getSeverityLevel(activeSeverity?._id, id).catch(handleError);
      if (res) {
        dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(res);
      }
    },

    async updateSeverityLevel(severityLevel: SeverityLevel, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity,
          activeSeverityLevel
        }
      } = state;

      if (!activeSeverity?._id || !activeSeverityLevel?._id) {
        return;
      }
      const res = await updateSeverityLevel(activeSeverity?._id, activeSeverityLevel?._id, severityLevel)
        .catch(handleError);

      if (res) {
        dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(res);
        dispatch.severities.getAllSeverityLevels();
      }
    },

    async updateSeverityLevelOrder(severityLevelOrder: SeverityLevelOrder, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }
      await postSeverityLevelOrder(activeSeverity?._id, severityLevelOrder).catch(handleError);
      dispatch.severities.getAllSeverityLevels();
    },

    async deleteSeverityLevel(SeverityLevelItem: SeverityLevelDeleteItem, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }
      await deleteSeverityLevel(activeSeverity?._id, SeverityLevelItem).catch(handleError);
      dispatch.severities.getAllSeverityLevels();
    }
  })
};

export const severities = createModel<RootModel>()(severityModel);
