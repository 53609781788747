import { FC } from "react";
import { Popup } from "react-map-gl";

import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import {
  BSOCPopupContent
} from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/BSOCPopupContent.component";
import {
  DataMinrPopupContent
} from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/DataMinrPopupContent.component";
import {
  MaxSecurityPopupContent
} from '@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/MaxSecurityPopupContent.component';
import {
  useRiskAlertPopupStyles
} from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RiskAlertPopup.styles";
import {
  RisklinePopupContent
} from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RisklinePopupContent.component";
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  isShown: boolean
  popupCoordinates?: PopupCoordinates,
  riskAlertData?: RiskAlertEvent,
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export const RiskAlertPopup: FC<Props> = ({
  isShown,
  popupCoordinates,
  riskAlertData,
  onMouseEnter,
  onMouseLeave
}) => {

  const { classes } = useRiskAlertPopupStyles();

  if (!isShown || !popupCoordinates) {
    return null;
  }

  const { latitude, longitude } = popupCoordinates;
  if (riskAlertData) {
    if (riskAlertData.source === "riskline") {
      return (
        <Popup
          longitude={ longitude }
          latitude={ latitude }
          anchor="center"
          closeButton={ false }
          className={ classes.riskImpactPopup }
        >
          <RisklinePopupContent
            classes={ classes }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            meta={ riskAlertData?.meta }
            json={ riskAlertData?.json }
            riskAlertData={ riskAlertData }
            isImpacted={ riskAlertData.isImpacted }
          />
        </Popup>
      );
    } else if (riskAlertData.source === "dataminr") {

      return (
        <Popup
          longitude={ longitude }
          latitude={ latitude }
          anchor="center"
          closeButton={ false }
          className={ classes.riskImpactPopup }
        >
          <DataMinrPopupContent
            classes={ classes }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            meta={ riskAlertData?.meta }
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            json={ riskAlertData?.json }
            riskAlertData={ riskAlertData as unknown as RiskAlertEvent<DataMinrExternalData> }
            isImpacted={ riskAlertData.isImpacted }
          />
        </Popup>
      );
    } else if (riskAlertData.source === "max-security") {

      return (
        <Popup
          longitude={ longitude }
          latitude={ latitude }
          anchor="center"
          closeButton={ false }
          className={ classes.riskImpactPopup }
        >
          <MaxSecurityPopupContent
            classes={ classes }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            meta={ riskAlertData?.meta }
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            json={ riskAlertData?.json }
            riskAlertData={ riskAlertData as unknown as RiskAlertEvent<MaxSecurityExternalData> }
            isImpacted={ riskAlertData.isImpacted }
          />
        </Popup>
      );
    } else {
      return (
        <Popup
          longitude={ longitude }
          latitude={ latitude }
          anchor="center"
          closeButton={ false }
          className={ classes.riskImpactPopup }
        >
          <BSOCPopupContent
            classes={ classes }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            meta={ riskAlertData?.meta }
            json={ riskAlertData?.json }
            riskAlertData={ riskAlertData }
          />
        </Popup>
      );
    }
  } else {
    return null;
  }
};

export default RiskAlertPopup;
