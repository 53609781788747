import { createModel } from "@rematch/core";

import {
  MaxSecurityToggleableFilterType,
  RiskBSOCToggleableFilterType,
  RisklineToggleableFilterType
} from "@/common/components/ToggleableFilter/ToggleableFilter.config";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { getDataminrWatchlist } from "@/tenant-context/core/api/riskProviders";
import { DataMinrWatchList } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";


export const MIN_RISK_LEVEL = 1;
export const MAX_RISK_LEVEL = 5;

export type RiskDataFilterOptionsState = {
  disabledRiskFilterTypes: string[],
  riskLevelFilters: number []
  isGlobalCategoryToggleOnRiskline: boolean
  isGlobalCategoryToggleOnBSOC: boolean
  isGlobalCategoryToggleOnDataMinr: boolean
  isGlobalCategoryToggleOnMaxSecurity: boolean
  riskLevelBSOCFilters: {
    nonCritical: boolean
    critical: boolean
  }
  dataMinrWatchlist: DataMinrWatchList[]
}

const riskEventFilterDataOptionsModal = {
  name: 'riskEventFilterDataOptions',
  state: {
    disabledRiskFilterTypes: [],
    riskLevelFilters: [MIN_RISK_LEVEL, MAX_RISK_LEVEL],
    isGlobalCategoryToggleOnRiskline: true,
    isGlobalCategoryToggleOnBSOC: true,
    isGlobalCategoryToggleOnDataMinr: true,
    isGlobalCategoryToggleOnMaxSecurity: true,
    riskLevelBSOCFilters: {
      nonCritical: true,
      critical: true
    },
    dataMinrWatchlist: []
  } as RiskDataFilterOptionsState,
  reducers: {
    ENABLE_FILTER(state: RiskDataFilterOptionsState, filterToEnable: string) {
      const { disabledRiskFilterTypes } = state;
      const updatedFilters = disabledRiskFilterTypes.filter(
        (filter) => filter !== filterToEnable
      );

      return {
        ...state,
        disabledRiskFilterTypes: updatedFilters,
        isGlobalCategoryToggleOn: true
      };
    },

    DISABLE_FILTER(state: RiskDataFilterOptionsState, filterToDisable: string) {
      const { disabledRiskFilterTypes } = state;

      const updatedFilters = Array.from(
        new Set([...disabledRiskFilterTypes, filterToDisable])
      );

      const isAllCategoriesDisabled = updatedFilters.length === Object.values(RisklineToggleableFilterType).length;

      return {
        ...state,
        isGlobalCategoryToggleOn: !isAllCategoriesDisabled,
        disabledRiskFilterTypes: updatedFilters
      };
    },

    ENABLE_BSOC_LEVEL_FILTER(state: RiskDataFilterOptionsState, filterToEnable: string) {
      const { riskLevelBSOCFilters } = state;

      return {
        ...state,
        riskLevelBSOCFilters: { ...riskLevelBSOCFilters, [filterToEnable]: true },
        isGlobalCategoryToggleOn: true
      };
    },

    DISABLE_BSOC_LEVEL_FILTER(state: RiskDataFilterOptionsState, filterToDisable: string) {
      const { riskLevelBSOCFilters } = state;

      return {
        ...state,
        riskLevelBSOCFilters: { ...riskLevelBSOCFilters, [filterToDisable]: false },
        isGlobalCategoryToggleOn: true
      };
    },

    CHANGE_RANGE_FILTER(state:RiskDataFilterOptionsState, newRiskLevelFilter:number []){
      return {
        ...state,
        riskLevelFilters:newRiskLevelFilter
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_RISKLINE(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnRiskline:true,
        disabledRiskFilterTypes:state.disabledRiskFilterTypes
          .filter((filter) => !(Object.values(RisklineToggleableFilterType) as Array<string>).includes(filter))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_RISKLINE(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnRiskline:false,
        disabledRiskFilterTypes: [...state.disabledRiskFilterTypes ,...Object.values(RisklineToggleableFilterType)]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_BSOC(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnBSOC:true,
        disabledRiskFilterTypes:
          state.disabledRiskFilterTypes.filter((filter) => (!(filter in RiskBSOCToggleableFilterType)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_BSOC(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnBSOC:false,
        disabledRiskFilterTypes: [...state.disabledRiskFilterTypes ,...Object.values(RiskBSOCToggleableFilterType)]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnDataMinr:true,
        disabledRiskFilterTypes:
          state.disabledRiskFilterTypes.filter((filter) =>
            (!(state.dataMinrWatchlist.findIndex(({ id }) => id.toString() === filter) > -1)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnDataMinr:false,
        disabledRiskFilterTypes: [
          ...state.disabledRiskFilterTypes,
          ...state.dataMinrWatchlist.map(({ id }) => id.toString())
        ]
      };
    },

    ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnMaxSecurity:true,
        disabledRiskFilterTypes: state.disabledRiskFilterTypes.filter((filterType) =>
          (!(Object.values(MaxSecurityToggleableFilterType) as string[]).includes(filterType)))
      };
    },

    DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY(state: RiskDataFilterOptionsState){
      return {
        ...state,
        isGlobalCategoryToggleOnMaxSecurity:false,
        disabledRiskFilterTypes: [
          ...state.disabledRiskFilterTypes,
          ...Object.values(MaxSecurityToggleableFilterType)
        ]
      };
    },

    SET_DATAMINR_WATCHLIST(state: RiskDataFilterOptionsState, watchlist: DataMinrWatchList[]){
      return {
        ...state,
        dataMinrWatchlist: watchlist
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async getDataminrWatchlist(_: void, state: RootState): Promise<void> {
      const { tenantId } = state.commonData;

      const watchlistResponse = await getDataminrWatchlist(tenantId);

      if (!watchlistResponse.watchlists) {
        return;
      }

      dispatch.riskEventFilterDataOptions.SET_DATAMINR_WATCHLIST(watchlistResponse.watchlists.TOPIC);
    }
  })
};

export const riskEventFilterDataOptions = createModel<RootModel>()(riskEventFilterDataOptionsModal);
