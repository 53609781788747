/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/boolean-prop-naming */
import {
  CellClickedEvent,
  ColDef,
  ColumnState,
  GridOptions,
  RowHeightParams,
  RowSelectedEvent,
  RowStyle
} from "ag-grid-community";
import { IsExternalFilterPresentParams } from "ag-grid-community/dist/lib/entities/iCallbackParams";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import { RowClickedEvent, SortChangedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { useDebouncedCallback } from "use-debounce";

import ServerDataGridComponent from "@/common/components/AgGrid/ServerSide/ServerDataGrid.component";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { GenericStringKeyObject } from "@/common/util/interface";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

type Props<T> = {
  id: string,
  columnDefs: Array<ColDef>,
  animateRows?: boolean,
  suppressHorizontalScroll?: boolean,
  suppressRowClickSelection?: boolean,
  suppressDragLeaveHidesColumns?: boolean,
  suppressCellFocus?: boolean,
  domLayout?: 'normal' | 'autoHeight' | 'print',
  gridOptions?: GridOptions<T>,
  isExternalFilterPresent?: (params: IsExternalFilterPresentParams<T>) => boolean,
  doesExternalFilterPass?: (node: RowNode<T>) => boolean,
  isWithExpandableRows?: boolean,
  getExpandedRowHeight?: (params: RowHeightParams<T>) => number,
  onExpandedRowsChange?: (expandedRows: Map<number, boolean>) => void,
  onCellClicked?: (event: CellClickedEvent<T>) => void,
  onRowClicked?: (event: RowClickedEvent<T>) => void,
  onRowSelected?: (e: RowSelectedEvent<T>) => void
  cellAutoHeight?: boolean,
  search?: boolean,
  searchInputPosition?: 'left' | 'right',
  headerContent?: () => React.ReactNode,
  additionalGridParams?: any,
  overlayNoRowsTemplate?: string,
  rowSelection?: 'single' | 'multiple',
  getData: (payload: {
    gridParams: GridParams,
    additionalParams?: any
  }) => Promise<any>;
  getCustomRowStyle?: (params: GenericStringKeyObject) => RowStyle | undefined
  isResponsivePagination?: boolean,
  suppressRowHoverHighlight?: boolean
}

const ServerDataGrid: ForwardRefRenderFunction<DataGrid, Props<Record<string, unknown>>> = ({
  id,
  columnDefs,
  animateRows = true,
  suppressHorizontalScroll = true,
  suppressRowClickSelection = true,
  suppressDragLeaveHidesColumns = true,
  suppressCellFocus = true,
  domLayout = 'autoHeight',
  gridOptions = {},
  isExternalFilterPresent,
  doesExternalFilterPass,
  isWithExpandableRows = false,
  onExpandedRowsChange,
  getExpandedRowHeight,
  onCellClicked,
  onRowClicked,
  onRowSelected,
  cellAutoHeight = false,
  getData,
  search = false,
  searchInputPosition = 'right',
  headerContent,
  additionalGridParams,
  overlayNoRowsTemplate,
  rowSelection = 'single',
  getCustomRowStyle,
  isResponsivePagination,
  suppressRowHoverHighlight
}, ref) => {

  const initialLoad = useRef(true);
  const gridRef = useRef<AgGridReact | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [ expandedRows, setExpandedRows ] = useState<Map<number, boolean>>(new Map());

  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState('10');
  const [sortedColumn, setSortedColumn] = useState<ColumnState | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState<PaginatedResult<any> | undefined>(undefined);
  const [loading ,setLoading] = useState<boolean>(false);

  const toggleLoading = useCallback(()=>{
    setLoading(prevState => !prevState);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useImperativeHandle(ref, () => ({
    refreshGrid() {
      toggleLoading();
      if(searchInputRef && searchInputRef.current){
        searchInputRef.current.value = "";
      }
      getData({ gridParams:{ page: 0, size: +itemsPerPage,
        additionalParams: additionalGridParams } })
        .then(response => {
          setData(response);
          setActivePage(0);
        }).catch(handleError).finally(toggleLoading);
    },
    setChecked(checkedIds: string[], idName: string) {
      gridRef.current?.api?.forEachNode((node) => {
        if (checkedIds.indexOf(node?.data?.[idName]) > -1) {
          node.setSelected(true);
        }
      });
    }
  }));

  useEffect(() => {
    onExpandedRowsChange?.(expandedRows);

    gridRef?.current?.api?.redrawRows();
    gridRef?.current?.api?.resetRowHeights();
  }, [ expandedRows, onExpandedRowsChange ]);

  const handleExpandRowChange = useCallback((rowIndex: number) => {
    const isRowExpanded = expandedRows.get(rowIndex) || false;

    setExpandedRows(new Map(expandedRows.set(rowIndex, !isRowExpanded)));
  }, [ expandedRows ]);

  const showingFrom = useMemo(() => {
    if (data?.totalPages) {
      const firstRow = (activePage + 1) * +itemsPerPage - +itemsPerPage + 1;
      return data?.totalPages < 1 ? 0 : firstRow;
    } else {
      return 0;
    }
  }, [activePage, itemsPerPage, data?.totalPages]);
   
  const showingTo = useMemo(() => {
    if (data?.totalPages) {
      const lastRow = (activePage + 1) * +itemsPerPage;
      return lastRow > data?.totalItems ? data?.totalItems : lastRow;
    } else {
      return 0;
    }
  }, [data?.totalPages, data?.totalItems, activePage, itemsPerPage]);

  const onSortChanged = useCallback((params: SortChangedEvent<any>): void => {
    const sorted = params.columnApi.getColumnState().find(column => column.sort !== null);
    setSortedColumn(sorted);
  }, []);

  const handleSearchPhraseChange = useDebouncedCallback((value: string): void => {
    setSearchQuery(value);
  }, 1000);

  useEffect(() => {
    const gridParams: GridParams = { page: activePage, size: +itemsPerPage };
    if(sortedColumn){
      gridParams.sort = {
        sort: sortedColumn.sort || "",
        colId: sortedColumn.colId
      };
    }
    if(searchQuery){
      gridParams.searchQuery = searchQuery;
    } 
    if(additionalGridParams){
      gridParams.additionalParams = additionalGridParams;
    }
    toggleLoading();
    getData({ gridParams }).then(response =>
      setData(response)).catch(handleError).finally(() => {
      initialLoad.current = false;
      toggleLoading();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    //prevent api from triggering twice in the initial load
    if(!initialLoad.current){
      if (activePage > 0) {
        setActivePage(0);
      } else {
        const gridParams: GridParams = { page: activePage, size: +itemsPerPage };
        if(sortedColumn){
          gridParams.sort = {
            sort: sortedColumn.sort || "",
            colId: sortedColumn.colId
          };      
        }
        if(searchQuery){
          gridParams.searchQuery = searchQuery;
        }  
        if(additionalGridParams){
          gridParams.additionalParams = additionalGridParams;
        }
        toggleLoading();
        getData({ gridParams }).then(response => setData(response)).catch(handleError).finally(toggleLoading);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, sortedColumn, searchQuery]);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [isExternalFilterPresent]);

  return (
    <ServerDataGridComponent
      id={ id }
      ref={ gridRef }
      searchInputRef={ searchInputRef }
      data={ data?.items || [] }
      columnDefs={ columnDefs }
      animateRows={ animateRows }
      suppressHorizontalScroll={ suppressHorizontalScroll }
      suppressRowClickSelection={ suppressRowClickSelection }
      suppressDragLeaveHidesColumns={ suppressDragLeaveHidesColumns }
      suppressCellFocus={ suppressCellFocus }
      domLayout={ domLayout }
      gridOptions={ gridOptions }
      isWithExpandableRows={ isWithExpandableRows }
      expandedRows={ expandedRows }
      onRowExpandChanged={ handleExpandRowChange }
      getExpandedRowHeight={ getExpandedRowHeight }
      isExternalFilterPresent={ isExternalFilterPresent }
      doesExternalFilterPass={ doesExternalFilterPass }
      onCellClicked={ onCellClicked }
      onRowClicked={ onRowClicked }
      cellAutoHeight={ cellAutoHeight }
      onSortChanged={ onSortChanged }
      search={ search }
      searchInputPosition={ searchInputPosition }
      handleSearchPhraseChange={ handleSearchPhraseChange }
      headerContent={ headerContent }
      overlayNoRowsTemplate={ overlayNoRowsTemplate }
      loading={ loading }
      rowSelection={ rowSelection }
      onRowSelected={ onRowSelected }
      getCustomRowStyle={ getCustomRowStyle }
      //pagination
      totalRecords={ data?.totalItems || 0 }
      itemsPerPage={ itemsPerPage }
      setItemsPerPage={ setItemsPerPage }
      showingFrom={ showingFrom }
      showingTo={ showingTo }
      activePage={ activePage }
      boundaries={ 2 }
      setActivePage={ setActivePage }
      totalPages={ data?.totalPages || 0 }
      isResponsivePagination={ isResponsivePagination }
      suppressRowHoverHighlight={ suppressRowHoverHighlight }
    />
  );
};

export default forwardRef(ServerDataGrid) as <T>(
  props: Props<T> & React.RefAttributes<DataGrid>
) => JSX.Element;
