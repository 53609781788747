/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */
import { ActionIcon, Button, Radio, Select, Switch, TextInput } from "@mantine/core";
import { FC, useCallback, useMemo } from "react";
import { Control, Controller, FieldValues, UseFormHandleSubmit, UseFormRegister } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import { ReactComponent as CloseIcon } from '@/common/icons/close.svg';
import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { unshiftDefaultItem } from '@/tenant-context/common/util/profile-reference-data';
import { ISelectOptions, LocationZoneFormOptionalFields, LocationZoneReferenceState, LocationZoneResponse } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";

import { useAddLocationsStyles } from "../../AddLocations.styles";

interface Props {
  register: UseFormRegister<FieldValues>,
  handleSubmit: UseFormHandleSubmit<FieldValues>,
  errors: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [x: string]: any;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>
  locationZoneReferenceData: LocationZoneReferenceState
  onLocationZoneSubmit: (fieldVals: FieldValues) => void
  fieldsVisibility: LocationZoneFormOptionalFields
  allNormalZones: ISelectOptions[],
  allProfileGroups: ISelectOptions[],
  allBuildingData: ISelectOptions[],
  allBuildingFloorsData: ISelectOptions[],
  mappedNormalZones: ISelectOptions[],
  excludedPeopleGroups:  ISelectOptions[],
  onMappedNormalZones: (value: string) => void,
  onExcludedPeopleGroups: (value: string) => void,
  onMappedNormalZoneRemoval: (index: number) => void,
  onExPeopleGroupRemoval: (index: number) => void,
  isAuthorizedToEdit: boolean,
  selectedLocationZone: LocationZoneResponse | undefined
}

const LocationZoneFormComponent: FC<Props> = ({ 
  control, register, handleSubmit, errors, locationZoneReferenceData, onLocationZoneSubmit,
  fieldsVisibility, allNormalZones, mappedNormalZones,allProfileGroups, allBuildingData, allBuildingFloorsData,
  excludedPeopleGroups, onMappedNormalZones, onExcludedPeopleGroups, onMappedNormalZoneRemoval, onExPeopleGroupRemoval,
  isAuthorizedToEdit, selectedLocationZone
}) => {
  const { classes } = useAddLocationsStyles();

  const gtc = useCallback(
    (mc) => `repeat(${mc - 1}, 1fr) 0.5fr`,
    []
  );

  const buildingData = useMemo(
    () => unshiftDefaultItem(allBuildingData),
    [allBuildingData]
  );

  const normalZones = useMemo(
    () =>{
      const zonesWithDefaultItem =  unshiftDefaultItem(allNormalZones);
      const mappedZones = new Set(mappedNormalZones.map(obj => obj.value));
      return zonesWithDefaultItem.filter(obj => !mappedZones.has(obj.value));
    },
    [allNormalZones, mappedNormalZones]
  );

  const profileGroups = useMemo(
    () => unshiftDefaultItem(allProfileGroups),
    [allProfileGroups]
  );

  const alternateAccessControlMode: string = useMemo(()=>{
    if(locationZoneReferenceData.accessControlMode.length === 0){
      return "";
    }
    const [firstAccessControlMode, secondAccessControlMode] = locationZoneReferenceData.accessControlMode;
    return selectedLocationZone?.accessControlMode ? 
      selectedLocationZone.accessControlMode === firstAccessControlMode.value ? secondAccessControlMode.value
        : firstAccessControlMode.value : "";
  }, [locationZoneReferenceData.accessControlMode, selectedLocationZone?.accessControlMode]);
  
  return (
    <form onSubmit={ handleSubmit(onLocationZoneSubmit) }>
      <div className={ classes.formMainContainer }>
        <div className={ classes.formItemContainer }>
          <div className={ classes.formHeaderWrapper }>
            <div className={ classes.formTitle }>Provide essential Zone details</div>
          </div>
          <div className={ classes.formBody }>
            <form>
              <div className={ classes.formSet }>
                <div className={ classes.formItemFullWidth }>
                  <div className={ classes.formItemHeader }>Name*</div>
                  <TextInput
                    disabled={ !isAuthorizedToEdit }
                    error={ errors?.name?.message }
                    { ...register(
                      'name',
                      addErrorMessages('Zone Name', {
                        required: true
                      })
                    ) }
                    maxLength={ 128 }/>
                </div>

                <div className={ classes.formItemFullWidth }>
                  <div className={ classes.formItemHeader }>Code*</div>
                  <TextInput
                    disabled={ !isAuthorizedToEdit }
                    error={ errors?.code?.message }
                    { ...register(
                      'code',
                      addErrorMessages('Zone Code', {
                        required: true
                      })
                    ) }
                    maxLength={ 16 }/>
                </div>
                <div className={ classes.formItem }>
                  <div className={ classes.formItemHeader }>Latitude*</div>
                  <TextInput
                    disabled={ !isAuthorizedToEdit }
                    { ...register(
                      'lat',
                      addErrorMessages('Latitude', {
                        required: true
                      })
                    ) }
                    error={ errors?.lat?.message }
                    type="number" maxLength={ 16 }/>
                </div>
                <div className={ classes.formItem }>
                  <div className={ classes.formItemHeader }>Longitude*</div>
                  <TextInput
                    disabled={ !isAuthorizedToEdit }
                    { ...register(
                      'lon',
                      addErrorMessages('Longitude', {
                        required: true
                      })
                    ) }
                    error={ errors?.lon?.message }
                    type="number" maxLength={ 16 }/>
                </div>
                <div className={ classes.formItem }>
                  <div className={ classes.formItemHeader }>Building</div>
                  <Controller
                    control={ control }
                    name="buildingTid"
                    // eslint-disable-next-line react/jsx-no-bind
                    render={ ({
                      field: { onChange, value }
                    }) => (
                      <Select
                        nothingFound={ "No buildings found" }
                        disabled={ !isAuthorizedToEdit }
                        searchable
                        errorProps={ {
                          style: {
                            marginTop: 5
                          }
                        } }
                        onChange={ onChange }
                        data={ buildingData }
                        value={ value }
                        rightSection={ value ?
                        // eslint-disable-next-line react/jsx-no-bind
                          <ActionIcon className={ classes.selectBtn } onClick={ () => {
                            onChange("");
                          } }>
                            <CloseIcon/>
                          </ActionIcon>

                          : undefined }
                      />
                    ) }
                  />
                </div>

                <div className={ classes.formItem }>
                  { fieldsVisibility.Floor && (
                    <>
                      <div className={ classes.formItemHeader }>Floor*</div>
                      <Controller
                        rules={ {
                          required: fieldsVisibility.Floor
                        } }
                        control={ control }
                        name="floorTid"
                        // eslint-disable-next-line react/jsx-no-bind
                        render={ ({
                          field: { onChange, value }
                        }) => (
                          <Select
                            clearable
                            disabled={ !isAuthorizedToEdit }
                            searchable
                            errorProps={ {
                              style: {
                                marginTop: 5
                              }
                            } }
                            onChange={ onChange }
                            data={ allBuildingFloorsData }
                            value={ value }
                            error={ errors.floorTid ? "Floor is required" : "" }
                            nothingFound={ "No floors found for this building" }
                          />
                        ) }
                      />
                    </>
                  ) }
                </div>
                <div className={ classes.formItem }>
                  <div className={ classes.formItemHeader }>Enable Max Capacity</div>
                  <Controller
                    control={ control }
                    name="maxCapacityEnabled"
                    // eslint-disable-next-line react/jsx-no-bind
                    render={ ({
                      field: { onChange, value }
                    }) => (
                      <Switch disabled={ !isAuthorizedToEdit } size="lg" onChange={ onChange } checked={ value }/>
                    ) }
                  />

                </div>
                <div className={ classes.formItem }>
                  { fieldsVisibility.MaxCapacity && (
                    <>
                      <div className={ classes.formItemHeader }>Max Capacity*</div>
                      <TextInput
                        disabled={ !isAuthorizedToEdit }
                        { ...register(
                          'maxCapacity',
                          addErrorMessages('Max Capacity', {
                            required: fieldsVisibility.MaxCapacity
                          })
                        ) }
                        error={ errors?.maxCapacity?.message }
                        type="number" maxLength={ 8 }/></>
                  ) }
                </div>
                <div className={ classes.formItemFullWidth }>
                  <Controller
                    control={ control }
                    name="zoneType"
                    // eslint-disable-next-line react/jsx-no-bind
                    render={ ({
                      field: { onChange, value }
                    }) => (
                      <Radio.Group
                        label="Zone Type*"
                        className={ classes.radioGroup }
                        value={ value }
                        onChange={ onChange }
                      >
                        { locationZoneReferenceData.zoneType.map((zoneType, index) => (
                          <Radio
                            disabled={ !isAuthorizedToEdit }
                            key={ "zoneType-" + index }
                            value={ zoneType.value } label={ zoneType.value }/>
                        )) }
                      </Radio.Group>
                    ) }
                  />
                </div>
                { fieldsVisibility.MusterZonePosition && (
                  <div className={ classes.formItemFullWidth }>
                    <Controller
                      control={ control }
                      name="musterPosition"
                      // eslint-disable-next-line react/jsx-no-bind
                      render={ ({
                        field: { onChange, value }
                      }) => (
                        <Radio.Group
                          label="Muster Zone Position"
                          className={ classes.radioGroup }
                          value={ value }
                          onChange={ onChange }
                        >
                          { locationZoneReferenceData.musterZonePosition.map((position, index) => (
                            <Radio disabled={ !isAuthorizedToEdit } key={ "position-" + index }
                              value={ position.value } label={ position.value }/>
                          )) }
                        </Radio.Group>
                      ) }
                    />
                  </div>
                ) }
                { fieldsVisibility.NormalZone && (
                  <div className={ classes.formItemFullWidth + " " + classes.mb3 }>
                    <div className={ classes.formItemHeader }>Normal Zone*</div>
                    <Controller
                      rules={ {
                        required: fieldsVisibility.NormalZone
                      } }
                      control={ control }
                      name="musterPlacedZone"
                      // eslint-disable-next-line react/jsx-no-bind
                      render={ ({
                        field: { onChange, value }
                      }) => (
                        <Select
                          disabled={ !isAuthorizedToEdit }
                          searchable
                          errorProps={ {
                            style: {
                              marginTop: 5
                            }
                          } }
                          onChange={ onChange }
                          data={ allNormalZones }
                          value={ value }
                          error={ errors.musterPlacedZone ? "Normal Zone is required" : "" }
                        />
                      ) }
                    />
                  </div>
                ) }
                { fieldsVisibility.ReleaseBehaviour && (
                  <div className={ classes.formItemFullWidth }>
                    <Controller
                      control={ control }
                      name="releaseBehavior"
                      // eslint-disable-next-line react/jsx-no-bind
                      render={ ({
                        field: { onChange, value }
                      }) => (
                        <Radio.Group
                          label="Release Behaviour"
                          className={ classes.radioGroup }
                          value={ value }
                          onChange={ onChange }
                        >
                          { locationZoneReferenceData.releaseBehaviour.map((behaviour, index) => (
                            <Radio disabled={ !isAuthorizedToEdit } key={ "behaviour-" + index }
                              value={ behaviour.value } label={ behaviour.value }/>
                          )) }
                        </Radio.Group>
                      ) }
                    />
                  </div>
                ) }

              </div>
            </form>
          </div>
        </div>
        { fieldsVisibility.MusterZonePosition && (
          <div className={ classes.formItemContainer }>
            <div className={ classes.formHeaderWrapper }>
              <div className={ classes.formTitle }>Mapped Normal Zones</div>
            </div>
            <div className={ classes.formBody }>
              <div className={ classes.formItemFullWidth }>
                <Select
                  disabled={ !isAuthorizedToEdit }
                  searchable
                  value={ "" }
                  data={ normalZones }
                  icon={ <SearchIcon /> } 
                  onChange={ onMappedNormalZones } />
              </div>
              <div className={ classes.formItemFullWidth }>
                <ProfileBlock
                  mode='legend-header'
                >
                  <ProfileKeyValueBlock
                    maxColumns={ 2 }
                    gtc={ gtc }
                  >
                    <ProfileKeyValue
                      mode='legend-header'
                      name={ 'Zones' }
                      value={ [
                        ''
                      ] }
                    />
                  </ProfileKeyValueBlock>
                </ProfileBlock>

                { mappedNormalZones.map((zone, index) => (
                  <ProfileBlock key={ "zone-" + index } element='li'>
                    <ProfileKeyValueBlock
                      maxColumns={ 2 }
                      // eslint-disable-next-line react/jsx-no-bind
                      gtc={ gtc }
                    >
                      <ProfileKeyValue
                        name={ zone.label }
                        value={ [
                          <div key={ "deleteZone-" + index } className={ classes.textRight }>
                            <Button
                              disabled={ !isAuthorizedToEdit }
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={ () => isAuthorizedToEdit ? onMappedNormalZoneRemoval(index) : undefined }
                              className={ classes.btn  }
                            >
                              <TrashIcon />
                            </Button>
                          </div>               
                        ] }
                      />
                    </ProfileKeyValueBlock>
                  </ProfileBlock>
                )) }
              </div>
            </div>
          </div>
        ) }
        <div className={ classes.formItemContainer }>
          <div className={ classes.formHeaderWrapper }>
            <div className={ classes.formTitle }>Access Control</div>
          </div>
          <div className={ classes.formBody }>
            <div className={ classes.formItemFullWidth }>
              <Controller
                control={ control }
                name="accessControlMode"
                // eslint-disable-next-line react/jsx-no-bind
                render={ ({
                  field: { onChange, value }
                }) => (
                  <Radio.Group
                    label="Access Control Mode"
                    className={ classes.radioGroup }
                    value={ value }
                    onChange={ onChange }
                  >
                    { locationZoneReferenceData.accessControlMode.map((mode, index) => (
                      <Radio disabled={ !isAuthorizedToEdit } key={ "mode-" + index }
                        value={ mode.value } label={ mode.value } />
                    )) }
                  </Radio.Group>
                ) }
              />
            </div>
            <div className={ classes.formHeaderWrapper }>
              <div className={ classes.formTitle +" " + classes.formLabel }>Access Control Exclusions</div>
            </div>
            <div className={ classes.formItemFullWidth }>
              <Select 
                disabled={ !isAuthorizedToEdit }
                searchable 
                value={ "" } 
                data={ profileGroups }
                icon={ <SearchIcon /> } 
                onChange={ onExcludedPeopleGroups } />
            </div>
            <div className={ classes.formItemFullWidth }>
              <ProfileBlock
                mode='legend-header'
              >
                <ProfileKeyValueBlock
                  maxColumns={ 3 }
                  gtc={ gtc }
                >
                  <ProfileKeyValue
                    mode='legend-header'
                    name={ 'Group Name' }
                    value={ [
                      'ACCESS LEVEL'
                    ] }
                  />
                </ProfileKeyValueBlock>
              </ProfileBlock>

              { excludedPeopleGroups.map((group, index) => (
                <ProfileBlock key={ "group-" + index } element='li'>
                  <ProfileKeyValueBlock
                    maxColumns={ 3 }
                    // eslint-disable-next-line react/jsx-no-bind
                    gtc={ gtc }
                  >
                    <ProfileKeyValue
                      name={ group.label }
                      value={ [
                        alternateAccessControlMode,
                        <div key={ "deleteGroup-" + index } className={ classes.textRight }>
                          <Button
                            disabled={ !isAuthorizedToEdit }
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={ () => isAuthorizedToEdit ? onExPeopleGroupRemoval(index) : undefined }
                            className={ classes.btn  }
                          >
                            <TrashIcon />
                          </Button>
                        </div>
                    
                      ] }
                    />
                  </ProfileKeyValueBlock>
                </ProfileBlock>
              )) }
            </div>
          </div>
        </div>
        <Button
          disabled={ !isAuthorizedToEdit }
          className={ classes.saveBtn }
          size='md'
          type={ 'submit' }
        >
          Save Changes
        </Button>
      </div>
    </form>
   
  );
};

export default LocationZoneFormComponent;
