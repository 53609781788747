import { createModel } from "@rematch/core";

import { NOT_FOUND_STATUS } from "@/common/util/api";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState } from "@/core/store";
import {
  queryTravellers,
  queryTravellersByCountry
} from "@/tenant-context/control-travellers-search/api/travellers-search.api";
import {
  CountryDetailData,
  CountrySummaryDataType,
  QueryTravellersByCountryRequestData,
  QueryTravellersRequestData
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";
import { countryBoundingBoxes } from "@/tenant-context/control-travellers-search/util/country-bounding-boxes";
import { calculateTripStatus } from "@/tenant-context/control-travellers-search/util/travellers-search.util";

type TravellersSearchModelState = {
  countryWiseTravellers: Array<CountrySummaryDataType>;
  selectedCountry: CountrySummaryDataType | undefined;
  countryDetailData: CountryDetailData | undefined;
}

const initialState: TravellersSearchModelState = {
  countryWiseTravellers: [],
  selectedCountry: undefined,
  countryDetailData: undefined
};

const TravellersSearchModel = {
  name: 'travellersSearch',
  state: initialState,
  reducers: {
    SET_TRAVEL_COUNTRY_WISE_TRAVELLERS(
      state: TravellersSearchModelState,
      payload: Array<CountrySummaryDataType>
    ) {
      return {
        ...state,
        countryWiseTravellers: payload
      };
    },

    SET_SELECTED_COUNTRY(
      state: TravellersSearchModelState,
      payload: CountrySummaryDataType | undefined
    ) {
      return {
        ...state,
        selectedCountry: payload
      };
    },

    SET_SELECTED_COUNTRY_DETAILS(
      state: TravellersSearchModelState,
      payload: CountryDetailData
    ) {
      return {
        ...state,
        countryDetailData: payload
      };
    },

    RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE(_state: TravellersSearchModelState) {
      return initialState;
    }
  },
  effects: (dispatch: Dispatch) => ({
    async fetchCountryWiseTravellers(
      requestData: QueryTravellersRequestData,
      _state: RootState
    ): Promise<Array<CountrySummaryDataType>> {
      const countryWiseTravellers = await queryTravellers(requestData)
        .catch((error) => {
          console.error(error);
          if (error.cause === NOT_FOUND_STATUS) {
            showNotification({
              message: 'No travellers found for the selected time range or filters.',
              color: 'warning'
            });

            return [];
          }

          showNotification({
            message: error.message || 'Failed to fetch country wise travellers.',
            color: 'error'
          });

          return [];
        });

      if (!countryWiseTravellers || !countryWiseTravellers.length) {
        dispatch.travellersSearch.SET_TRAVEL_COUNTRY_WISE_TRAVELLERS([]);
        return [];
      }

      // TODO - Remove the risk part when data is coming from BE
      const mappedCountryWiseTravellers: Array<CountrySummaryDataType> = countryWiseTravellers
        .filter((countryWiseTraveller) => countryWiseTraveller.countryISOCode)
        .map((countryWiseTraveller) => ({
          ...countryWiseTraveller,
          countryName: countryWiseTraveller.countryName ||
              countryBoundingBoxes?.[countryWiseTraveller.countryISOCode]?.[0] ||
              'Unknown'
        }));

      dispatch.travellersSearch.SET_TRAVEL_COUNTRY_WISE_TRAVELLERS(mappedCountryWiseTravellers);

      return mappedCountryWiseTravellers;
    },

    async fetchCountryDetailData(requestData: Omit<QueryTravellersByCountryRequestData, 'countryISOCode'>, state: RootState): Promise<void> {
      const selectedCountryCode = state.travellersSearch.selectedCountry?.countryISOCode;

      if (!selectedCountryCode) {
        console.error('No country selected or cannot find the country code');
        showNotification({
          message: 'No country selected or cannot find the country code.',
          color: 'error'
        });

        return;
      }

      const countryDetailData = await queryTravellersByCountry({
        ...requestData,
        countryISOCode: selectedCountryCode
      });

      // TODO - Remove the risk part when data is coming from BE
      dispatch.travellersSearch.SET_SELECTED_COUNTRY_DETAILS({
        ...countryDetailData,
        countryName: countryDetailData.countryName ||
            countryBoundingBoxes?.[countryDetailData.travellers[0].countryISOCode]?.[0] ||
            'Unknown',
        countryISOCode: selectedCountryCode,
        travellers: countryDetailData.travellers.map((traveller) => ({
          ...traveller,
          tripStatus: calculateTripStatus(traveller.tripDates.from, traveller.tripDates.to),
          countryName: traveller.countryName ||
              countryBoundingBoxes?.[traveller.countryISOCode]?.[0] ||
              'Unknown',
          countryOfResident: traveller.countryOfResident === 'Country is not defined' ? undefined : traveller.countryOfResident
        }))
      });
    }
  })
};

export const travellersSearch = createModel<RootModel>()(TravellersSearchModel);
