import { FC, ReactNode } from "react";

import { useButtonControlStyles } from "@/common/components/ButtonControl/ButtonControl.styles";

type Props = {
  children: ReactNode,
  className?: string,
  onClick?: () => void,
  label?: string,
  labelPosition?: 'left' | 'right',
  isUnauthorized?: boolean
}

const ButtonControl: FC<Props> = ({
  children,
  onClick,
  className = '',
  label = '',
  labelPosition = 'left',
  isUnauthorized
}) => {
  const { classes, cx } = useButtonControlStyles({ isLabelIncluded: label !== '', isUnauthorized: isUnauthorized || false });

  return (
    <button
      className={ cx(
        className,
        'mapboxgl-ctrl-group',
        'mapboxgl-ctrl',
        'mapboxgl-custom-ctrl',
        classes.wrapper,
        classes.btn,
        labelPosition === 'right' && 'rightLabel'
      ) }
      data-label={ label }
      onClick={ onClick }
    >
      { children }
    </button>
  );
};

export default ButtonControl;
