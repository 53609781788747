import { Button } from "@mantine/core";
import { FC } from "react";

import { EnteredLocationDetails } from "@/tenant-context/control-location-management/types/ManageLocations.types";

import { useAddLocationsStyles } from "../AddLocations.styles";

type Props = {
  onAddLocation: () => void,
  onNavigateToGlobal:  () => void,
  enteredLocationDetails: EnteredLocationDetails,
  isAttachedValidGeoJsonFile: boolean,
  isFooterVisible: boolean,
  isAuthorizedToEdit: boolean
  isAllowedToUseSaveLocationButton: boolean
}

const AddLocationsFooterComponent: FC<Props> = ({
  onAddLocation,
  onNavigateToGlobal,
  enteredLocationDetails,
  isFooterVisible,
  isAuthorizedToEdit,
  isAllowedToUseSaveLocationButton
}) => {

  const { classes } = useAddLocationsStyles();

  const isSaveDisabled = !(enteredLocationDetails.id && enteredLocationDetails.id.length > 0) ||
    !(enteredLocationDetails.isoCountryCode && enteredLocationDetails.isoCountryCode.length > 0) ||
    !(enteredLocationDetails.locationCategory && enteredLocationDetails.locationCategory.length > 0) ||
    !(enteredLocationDetails.address01 && enteredLocationDetails.address01.length > 0) ||
    !(enteredLocationDetails.lon && enteredLocationDetails.lon.length > 0) ||
    !(enteredLocationDetails.lat && enteredLocationDetails.lat.length > 0) ||
    !isAuthorizedToEdit || !isAllowedToUseSaveLocationButton;

  if(!isFooterVisible){
    return null;
  }

  return (
    <div className={ classes.footerContainer }>
      <div className={ classes.footerSegment }>
      </div>
      <div className={ classes.footerSegment }>
        <Button variant='outline' onClick={ onNavigateToGlobal }>Cancel</Button>
        <Button
          onClick={ isAuthorizedToEdit ? onAddLocation : undefined }
          /* eslint-disable-next-line react/jsx-no-bind */
          disabled={ isSaveDisabled }
        >
          Save Location Details
        </Button>
      </div>
    </div>
  );
};

export default AddLocationsFooterComponent;
