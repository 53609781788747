import type { FeatureCollection, Point } from "geojson";

import { callApi } from "@/common/util/api";
import { SearchSuggestions } from "@/tenant-context/core/types/map-search.types";



export const getSearchSuggestions = (
  location = '',
  service: 'map-service' | 'lookup-service' = 'map-service'
) => {

  return callApi<SearchSuggestions>(
    `/${ service }/v1/search-locations?location=${location}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      withTenantId: true
    }
  );
};


export const getLocationById = (
  mapboxId= '',
  service: 'map-service' | 'lookup-service' = 'map-service'
) => {

  return callApi<FeatureCollection<Point>>(
    `/${ service }/v1/get-location/${mapboxId}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      withTenantId: true
    }
  );
};
