import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RiskBSOCToggleableFilterType } from "@/common/components/ToggleableFilter/ToggleableFilter.config";
import { Dispatch, RootState } from "@/core/store";
import BSOCEventsSettings
  from "@/tenant-context/control-risk-config/components/BSOCEventsSettings/BSOCEventsSettings.component";

const BSOCEventsSettingsContainer: FC = () => {
  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );

  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelBSOCFilters
  );

  const isGlobalCategoryToggleOn = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.isGlobalCategoryToggleOnBSOC
  );
  const isOnlyImpactedAlerts = useSelector((state:RootState)=> state.riskAlerts.showOnlyImpactedRiskEvents);
  const {
    riskEventFilterDataOptions: {
      ENABLE_FILTER,
      DISABLE_FILTER,
      ENABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      DISABLE_GLOBAL_CATEGORY_FILTER_BSOC,
      ENABLE_BSOC_LEVEL_FILTER,
      DISABLE_BSOC_LEVEL_FILTER
    },
    riskAlerts: {
      SET_SHOW_ONLY_IMPACTED_RISK_EVENTS
    }
  } = useDispatch<Dispatch>();

  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        checked,
        value: filter
      } = event.currentTarget;

      if (!Object.keys(RiskBSOCToggleableFilterType).includes(filter)) {
        // Omit non-registered layer type interactions
        return;
      } 
    

      if (checked) {
        ENABLE_FILTER(RiskBSOCToggleableFilterType[filter as unknown as keyof typeof RiskBSOCToggleableFilterType]);
      } else {
        DISABLE_FILTER(RiskBSOCToggleableFilterType[filter as unknown as keyof typeof RiskBSOCToggleableFilterType]);
      }
    },
    [ENABLE_FILTER, DISABLE_FILTER]
  );

  const handleRiskLevelToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked,
      value: riskLevel
    } = event.currentTarget;

    if (checked) {
      ENABLE_BSOC_LEVEL_FILTER(riskLevel);
    } else {
      DISABLE_BSOC_LEVEL_FILTER(riskLevel);
    }
  },[ENABLE_BSOC_LEVEL_FILTER, DISABLE_BSOC_LEVEL_FILTER]);

  const handleGlobalCategoryToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    if (checked) {
      ENABLE_GLOBAL_CATEGORY_FILTER_BSOC();
    } else {
      DISABLE_GLOBAL_CATEGORY_FILTER_BSOC();
    }
  },[ENABLE_GLOBAL_CATEGORY_FILTER_BSOC, DISABLE_GLOBAL_CATEGORY_FILTER_BSOC]);

  const handleImpactRiskEventsToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    SET_SHOW_ONLY_IMPACTED_RISK_EVENTS(checked);

  }, [SET_SHOW_ONLY_IMPACTED_RISK_EVENTS]);
    
  return (
    <BSOCEventsSettings 
      onCategoryToggle= { handleSwitchToggle }
      onChangeGlobalCategoryToggle={ handleGlobalCategoryToggle }
      isGlobalCategoryToggleOn = { isGlobalCategoryToggleOn }      
      disabledRiskFilterTypes={ disabledRiskFilterTypes } 
      onImpactRiskEventsToggleChange={ handleImpactRiskEventsToggle }
      isShowOnlyImpactedAlerts ={ isOnlyImpactedAlerts }
      riskLevelFilters={ riskLevelFilters }
      onRiskLevelToggle={ handleRiskLevelToggle }
    />
  );
};

export default BSOCEventsSettingsContainer;
