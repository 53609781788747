import { FC, useCallback, useContext } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";

import PeopleLocationPopup from './PeopleLocationPopup.component';

const PeopleLocationPopupContainer: FC = () => {
  const {
    individualPersonPopupCoordinates,
    hoveredOnPerson,
    individualPersonPopupRef,
    handleMouseLeavePopup,
    handleMouseEnterPopup,
    popupPosition
  } = useContext(PeopleContext);

  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup); 

  const handleMouseEnter = useCallback(() => {
    handleMouseEnterPopup();
  },[handleMouseEnterPopup]);

  const handleMouseLeave = useCallback(() => {
    handleMouseLeavePopup();
  },[handleMouseLeavePopup]);

  return (
    <PeopleLocationPopup
      assetSummary={ hoveredOnPerson }
      onMouseEnter = { handleMouseEnter }
      onMouseLeave = {  handleMouseLeave }
      popupCoordinates={ individualPersonPopupCoordinates }
      isShown={ currentBigMapPopup === "PersonLocation" }
      popupRef={ individualPersonPopupRef }
      popupPosition={ popupPosition }
    />
  );
};

export default PeopleLocationPopupContainer;