import { Button } from "@mantine/core";
import { FC, useCallback } from "react";

import usePermission from '@/common/hooks/usePermission';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { RequiredPolicies } from '@/common/types/permission-control';
import { capitalizeWord } from "@/common/util/format/string";
import { useProfileGroupsTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfileGroupsTab/ProfileGroupsTab.style';
import { useProfilePersonalContactTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.style';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from '@/tenant-context/control-profile/components/ui/ProfileKeyValue';
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";
import ProfileTabActionButton from "@/tenant-context/control-profile/components/ui/ProfileTabActionButton";
import { PaginatedResult, ProfileGroup } from "@/tenant-context/control-profile/types/profile";

const formatServices = (concatenatedServices: string) => {
  const individualServices = concatenatedServices.split(',');
  const formattedServices = individualServices.map((svc) => {
    return svc.split('-').map(capitalizeWord).join(' ');
  });

  return formattedServices.join(', ');
};

type Props = {
  onAddClick: () => void,
  onUnauthorizedAction: () => void,
  confirmDelete: (documentId: string) => void,
  groups?: PaginatedResult<ProfileGroup> | null,
  requiredPolicies?: RequiredPolicies
}

const ProfileGroupsTab: FC<Props> = ({
  onAddClick,
  onUnauthorizedAction,
  confirmDelete,
  groups,
  requiredPolicies
}) => {
  const { classes } = useProfileGroupsTabStyles();
  // TODO refactor
  const { classes: { section } } = useProfilePersonalContactTabStyles();

  const isAuthorizedToEdit = usePermission( requiredPolicies );

  const renderAddDocumentButton = useCallback(() => (
    <ProfileTabActionButton
      onClick={ isAuthorizedToEdit ? onAddClick : onUnauthorizedAction }
      requiredPolicies={ requiredPolicies }
    >
      Add to Group
    </ProfileTabActionButton>
  ), [isAuthorizedToEdit, onAddClick, onUnauthorizedAction, requiredPolicies]);

  const gtc = useCallback((mc) => `repeat(${mc - 1}, 1fr) 3fr 0.2fr`, []);

  if (!groups) {
    return <span>Loading...</span>;
  }

  return (
    <GenericProfileTab
      heading="People Group"
      renderAction={ renderAddDocumentButton }
    >
      <section className={ section }>
        <ul>
          { !!groups.items.length && (
            <ProfileBlock
              mode='legend-header'
            >
              <ProfileKeyValueBlock
                maxColumns={ 3 }
                gtc={ gtc }
              >
                <ProfileKeyValue
                  mode='legend-header'
                  name={ 'MEMBER OF GROUP' }
                  value={ [
                    'NUMBER OF PEOPLE',
                    'CATEGORY'
                  ] }
                />
              </ProfileKeyValueBlock>
            </ProfileBlock>
          ) }

          { groups.items.map((group) => (
            <ProfileBlock key={ group._id } element='li'>
              <ProfileKeyValueBlock
                maxColumns={ 3 }
                gtc={ gtc }
              >
                <ProfileKeyValue
                  firstItem="regular"
                  name={ group.name }
                  value={ [
                    group.profileCount,
                    formatServices(group.applicableServices)
                  ] }
                />

                <Button
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ isAuthorizedToEdit ? () => confirmDelete(group._id) : onUnauthorizedAction }
                  className={ isAuthorizedToEdit ? classes.btn : classes.unauthorizedDeleteBtn }
                  styles={ {
                    label: {
                      float: 'right'
                    }
                  } }
                >
                  Remove Person
                  <TrashIcon />
                </Button>
              </ProfileKeyValueBlock>
            </ProfileBlock>
          )) }
        </ul>
      </section>
    </GenericProfileTab>
  );
};

export default ProfileGroupsTab;
