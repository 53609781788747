import { openModal } from "@mantine/modals";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { Dispatch, RootState } from "@/core/store";
import ProfilePeopleGroupsModal from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal";
import ProfileGroupsTab from "@/tenant-context/control-profile/components/tabs/ProfileGroupsTab/ProfileGroupsTab.component";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';

const ProfileGroupsTabContainer: FC = () => {
  const {
    profile: {
      loadGroups
    },
    groups: {
      removeProfileFromGroup
    }
  } = useDispatch<Dispatch>();

  const groups = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ profile: { groups } }: RootState) => groups
  );

  const handleUnauthorizedAction = useUnauthorizedModal();

  const handleRemoveFromGroupClick = useCallback((documentId: string) => {
    removeProfileFromGroup(documentId);
  }, [removeProfileFromGroup]);

  const confirmDelete = useCallback((documentId: string) => {
    openConfirmationModal({
      text: "Are you sure you want to remove the profile from this group?",
      onConfirm: () => handleRemoveFromGroupClick(documentId),
      title: "Confirm Removal"
    });
  }, [handleRemoveFromGroupClick]);
  
  const handleAddClick = useCallback(() => {
    openModal({
      title: 'Add to Group',
      children: (
        <ProfilePeopleGroupsModal />
      ),
      closeOnClickOutside: false
    });
  }, []);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  return (
    <ProfileGroupsTab
      groups={ groups }
      onAddClick={ handleAddClick }
      onUnauthorizedAction={ handleUnauthorizedAction }
      confirmDelete = { confirmDelete }
      requiredPolicies={ ProfileRequiredPoliciesConfig.GROUPS_FULL_ACCESS }
    />
  );
};

export default ProfileGroupsTabContainer;
