import { Bounds } from '@visx/brush/lib/types';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import truthy from '@/common/util/truthy';
import { Dispatch, RootState } from '@/core/store';
import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";
import { AssetRankingEventGroup } from '@/tenant-context/control-location-graph/util/groupBy';
import makeInfinite, { filterEventsForTimeDomain, generateEventForTime } from '@/tenant-context/control-location-graph/util/infiniteEvents';
import { PeopleContext } from '@/tenant-context/visualisation-people/context/People.context';

import LocationGraph from './LocationGraph.component';

const BRUSH_DEBOUNCE_INTERVAL = 150;

const LocationGraphContainer: FC = () => {
  const { enablePopupForRankingEvent } = useContext(PeopleContext);

  const {
    groupedAssetRankingEvents,
    startTime,
    endTime,
    timeDomainDelta,
    timeMiddle: timeDomainMiddle,
    timeScale
  } = useSelector((state: RootState) => state.locationGraph);

  const {
    locationGraph: {
      setTimeDomain
    }
  } = useDispatch<Dispatch>();

  const [brushDomain, setBrushDomain] = useState<Bounds | null>(null);
  const debouncedSetBrushDomain = useDebouncedCallback(
    setBrushDomain,
    BRUSH_DEBOUNCE_INTERVAL
  );

  // Add first and last events for each group
  // -> First event is the last event before time domain
  // -> Last event is the first event within or after time domain
  const brushData = useMemo(
    () => makeInfinite(
      groupedAssetRankingEvents,
      startTime,
      endTime
    ),
    [groupedAssetRankingEvents, endTime, startTime]
  );

  const chartUnfilteredData = useMemo(
    () => makeInfinite(
      groupedAssetRankingEvents,
      brushDomain?.x0 ?? startTime,
      brushDomain?.x1 ?? endTime
    ),
    [brushDomain?.x0, brushDomain?.x1, groupedAssetRankingEvents, endTime, startTime]
  );

  // For proper display, events should be filtered
  // Only the events within current time domain (set by brush)
  // Can be rendered in the main chart itself
  const chartData = useMemo(
    () => brushDomain && brushDomain.x0 && brushDomain.x1
      ? filterEventsForTimeDomain(
        chartUnfilteredData,
        brushDomain.x0,
        brushDomain.x1
      )
      : chartUnfilteredData,
    [brushDomain, chartUnfilteredData]
  );

  // Calculate glyphs (squares that are on the central line)
  // For the current time
  const now = useMemo(() => Date.now(), []);
  const glyphData: AssetRankingEventGroup[] = useMemo(
    () => chartData
      .map((group): AssetRankingEventGroup | null => {
        const eventForCurrentTime = generateEventForTime(
          group.events,
          now
        );

        if (!eventForCurrentTime) {
          return null;
        }

        return {
          ...group,
          events: [eventForCurrentTime]
        };
      })
      .filter(truthy),
    [chartData, now]
  );

  const handleGlyphClick = useCallback((event: AssetRankingEvent, serializedKey: string) => {
    console.log(`Event ${serializedKey}`, event);

    enablePopupForRankingEvent(event);
  }, [enablePopupForRankingEvent]);

  const handleTimeTravelForwardClick = useCallback(() => {
    setTimeDomain(timeDomainMiddle + (timeDomainDelta * 2));
  }, [setTimeDomain, timeDomainDelta, timeDomainMiddle]);

  const handleTimeTravelBackwardClick = useCallback(() => {
    setTimeDomain(timeDomainMiddle - (timeDomainDelta * 2));
  }, [setTimeDomain, timeDomainDelta, timeDomainMiddle]);

  return (
    <div style={ {
      height: 'calc(100% - 38px)',
      width: 'calc(100% - 4px)'
    } }>
      <ParentSize>
        { ({ width, height }) => (
          <LocationGraph
            timeScale={ timeScale }
            now={ now }
            brushData={ brushData }
            brushDomain={ brushDomain }
            setBrushDomain={ debouncedSetBrushDomain }
            chartData={ chartData }
            width={ width }
            height={ height }
            startTime={ startTime ?? 0 }
            endTime={ endTime ?? 0 }
            glyphData={ glyphData }
            onGlyphClick={ handleGlyphClick }
            onTimeTravelForwardClick={ handleTimeTravelForwardClick }
            onTimeTravelBackwardClick={ handleTimeTravelBackwardClick }
          />
        ) }
      </ParentSize>
    </div>
  );
};

export default LocationGraphContainer;
