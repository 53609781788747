/* eslint-disable react/jsx-props-no-spreading */
import { Select, TextInput } from "@mantine/core";
import { FC, useCallback, useContext, useMemo } from "react";
import { RegisterOptions } from "react-hook-form";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import { RootState } from "@/core/store";
import { unshiftDefaultItem } from '@/tenant-context/common/util/profile-reference-data';
import {
  MedicalDetailsModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/MedicalDetailsModal/context/MedicalDetailsModal.context";
import {
  useEmergencyContactModalContentStyles
} from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileMedical } from "@/tenant-context/control-profile/types/profile";

export type MedicalDetailsModalFormData = Pick<ProfileMedical,
  | 'bloodType'
  | 'height'
  | 'weight'
  | 'medicalConditionsList'
  | 'allergiesList'
  | 'medicationsList'
> & {
  dateOfBirth: Date
};

const MedicalDetailsModal: FC = () => {
  const { classes } = useEmergencyContactModalContentStyles();
  const {
    onSubmit,
    formControls: { register, setValue, formState: { errors, isDirty, isValid } }
  } = useContext(MedicalDetailsModalContext);

  const medicalDetails = useSelector((state: RootState) => state.profile.medicalDetails);
  const referenceData = useSelector((state: RootState) => state.profile.referenceData);

  const bloodType = useMemo(() => {
    return unshiftDefaultItem(referenceData?.bloodType?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const handleBloodTypeChange = useCallback((val) => {
    setValue('bloodType', val, { shouldDirty: true });
  }, [setValue]);

  if (typeof medicalDetails === 'undefined') {
    return (
      <p>Loading...</p>
    );
  }

  const renderTextInput = (
    label: string,
    id: keyof ProfileMedical,
    options: RegisterOptions
  ) => (
    <TextInput
      label={ label }
      defaultValue={ medicalDetails?.[id]?.toString() }
      error={ errors?.[id]?.message }
      { ...register(id, addErrorMessages(label, options)) }
    />
  );

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay/>
      <div className={ classes.grid }>
        <Select
          { ...register('bloodType', addErrorMessages('Blood Type', {})) }
          onChange={ handleBloodTypeChange }
          data={ bloodType ? bloodType : [] }
          label="Blood Type"
          defaultValue={ medicalDetails?.bloodType }
          error={ errors?.bloodType?.message }
        />

        <TextInput
          { ...register('height', {
            pattern: {
              value: /^([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)$/,
              message: "This input is number only."
            }
          }) }
          label="Height"
          defaultValue={ medicalDetails?.height?.toString() ?? '' }
          error={ errors?.height?.message }
        />

        <TextInput
          { ...register('weight', {
            pattern: {
              value: /^([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)$/,
              message: "This input is number only."
            }
          }) }
          label="Weight"
          defaultValue={ medicalDetails?.weight?.toString() ?? '' }
          error={ errors?.weight?.message }
        />

        { renderTextInput('Medical Conditions', 'medicalConditionsList', {}) }

        { renderTextInput('Allergies', 'allergiesList', {}) }

        { renderTextInput('Medications', 'medicationsList', {}) }
      </div>

      <ModalControlGroup
        primaryButtonDisabled={ !isValid || !isDirty }
      />
    </form>
  );
};

export default MedicalDetailsModal;
