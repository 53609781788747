import { FC, useCallback, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { showNotification } from "@/common/util/notification";
import { CategoryDTO } from "@/tenant-context/control-location-management/types/ManageLocations.types";

import AddCategoryComponent from "./AddCategory.component";

interface Props{
  categoryItem?: CategoryDTO,
  saveLocationCategory: (payload: {
    id: string;
    tid: string;
}) => void
}

const AddCategoryModal: FC<Props> = ({
  categoryItem,
  saveLocationCategory
}) => {

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(()=>{
    if(categoryItem){
      setValue("name", categoryItem.label);
    } else{
      setValue("name", "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryItem?.label]);

  const handleLocationCategoryEditSubmit = useCallback(async (fieldVals: FieldValues)=>{
    if(!fieldVals.name.trim()){
      showNotification({
        message: "Please enter a category name",
        color: "error"
      });

      return;
    }
    saveLocationCategory({
      id: fieldVals.name.trim(),
      tid: categoryItem?.tid || ""
    });
  }, [categoryItem?.tid, saveLocationCategory]);

  return (
    <AddCategoryComponent
      handleSubmit={ handleSubmit }
      onLocationCategoryEditSubmit={ handleLocationCategoryEditSubmit }
      errors={ errors } 
      register={ register } />
  );
};

export default AddCategoryModal;
