import { callApi } from "@/common/util/api";
import { TENANT_REFRESH_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { AuthTokenMsg } from "@/core/types/auth";

const tenantRefreshToken = browserDatabase.getItem<string>(TENANT_REFRESH_TOKEN_KEY);

export const refreshToken = (token?: string) => callApi<AuthTokenMsg>(
  `/managed/token?grant_type=refresh_token&access_type=offline`,
  {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token || tenantRefreshToken}`,
      'Content-Type': 'application/json'
    }
  }
);
