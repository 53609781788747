import { Feature } from "geojson";
import { EventData, MapLayerEventType } from "mapbox-gl";
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { MapLayerMouseEvent, useMap } from 'react-map-gl';
import { useDispatch, useSelector } from "react-redux";

import { isValidLatitude } from "@/common/util/geo";
import { Dispatch, RootState, store } from "@/core/store";
import { ViewportBbox } from "@/tenant-context/control-draw/types/draw";
import { getUpdatedGeofencePolygons } from "@/tenant-context/control-draw/util/get-updated-geofence-polygons";
import LocationDetailsMapComponent
  from "@/tenant-context/control-location-management/components/AddLocations/LocationDetailsTab/LocationDetailsMap/LocationDetailsMap.component";
import { LocationInfoContext, LocationTab } from "@/tenant-context/control-location-management/context/LocationInfo.context";
import {
  LocationManagementContext
} from '@/tenant-context/control-location-management/context/LocationManagement.context';

type Props = {
  drawControl?: React.MutableRefObject<MapboxDraw | undefined>
  selectedDrawing?: React.MutableRefObject<Feature | undefined>
  activeTab?: string;
}
export const LocationDetailsMapContainer: FC<Props> = ({
  drawControl,
  selectedDrawing,
  activeTab
}) => {

  const {
    manageLocations: {
      SET_CURRENT_SITE_PLAN,
      SET_CURRENT_SITE_PLAN_GEO_JSON
    }
  } = useDispatch<Dispatch>();

  const { setMarkerLocationExternally } = useContext(LocationManagementContext);
  const {
    isLocationExists
  } = useContext(LocationInfoContext);
  const mapBoxMap = useMap();

  const {
    currentSitePlanGeoJson, enteredLocationDetails, activeFloorPlan
  } = useSelector((state: RootState) => state.manageLocations);

  const handleClickOnMap = useCallback((event: MapLayerMouseEvent) => {
    if (!event?.lngLat) {
      return;
    }

    setMarkerLocationExternally(Number(event.lngLat.lat.toFixed(6)), Number(event.lngLat.lng.toFixed(6)));
  }, [setMarkerLocationExternally]);

  const onDrawingControlCreated = useCallback((control: MapboxDraw) => {
    if (drawControl) {
      // eslint-disable-next-line no-param-reassign
      drawControl.current = control;
    }
  }, [drawControl]);

  const onDrawingSelected = useCallback((evt: MapLayerEventType & EventData) => {
    const selectedFeature = evt.features[0];
    if (selectedDrawing) {
      if (!selectedFeature) {
        // eslint-disable-next-line no-param-reassign
        selectedDrawing.current = undefined;
      }
      // eslint-disable-next-line no-param-reassign
      selectedDrawing.current = selectedFeature;
    }
  }, [selectedDrawing]);

  const onDrawingUpdate = useCallback((evt: MapLayerEventType & EventData) => {
    const actionType = evt.type === 'draw.create' ? 'CREATE' : 'UPDATE';
    const polygonFeature = evt.features[0];

    const boundingBox: ViewportBbox = polygonFeature.geometry.type === 'LineString' ? polygonFeature.geometry.coordinates : polygonFeature.geometry.coordinates[0];

    const isPolygonOrLine = polygonFeature.geometry.type === 'Polygon' || polygonFeature.geometry.type === 'LineString';

    if (isPolygonOrLine) {
      const { manageLocations: { currentSitePlan } } = store.getState();

      const cloneGeoJson = Object.assign({}, polygonFeature);
      // eslint-disable-next-line fp/no-delete
      delete cloneGeoJson.id;
      SET_CURRENT_SITE_PLAN_GEO_JSON(cloneGeoJson);

      if (actionType === 'CREATE') {
        currentSitePlan.forEach((geoPolygon) => {
          if (geoPolygon?.id && drawControl) {
            drawControl.current?.delete(geoPolygon.id as string);
          }
        });

        const updatedSitePlans = getUpdatedGeofencePolygons(
          actionType,
          [],
          polygonFeature,
          boundingBox
        );

        SET_CURRENT_SITE_PLAN(updatedSitePlans);
      } else {
        const updatedSitePlans = getUpdatedGeofencePolygons(
          actionType,
          currentSitePlan,
          polygonFeature,
          boundingBox
        );

        SET_CURRENT_SITE_PLAN(updatedSitePlans);
      }
    }
  }, [SET_CURRENT_SITE_PLAN, SET_CURRENT_SITE_PLAN_GEO_JSON, drawControl]);

  const onDrawingDelete = useCallback((evt: MapLayerEventType & EventData) => {
    const isPolygon = evt.features[0].geometry.type === 'Polygon'; // Circle also is a polygon
    const polygonId = evt.features[0].id;

    if (!isPolygon) {
      return;
    }

    const { manageLocations: { currentSitePlan } } = store.getState();

    // Removing the deleted polygon from the list
    const polygons = currentSitePlan
      .filter((sitePlan) => sitePlan.id !== polygonId);

    SET_CURRENT_SITE_PLAN(polygons);
  }, [SET_CURRENT_SITE_PLAN]);

  const renderGeofence = useMemo(() => {
    if (activeFloorPlan && activeTab === LocationTab.Buildings_Floors) {
      return activeFloorPlan;
    } else {
      return currentSitePlanGeoJson;
    }
  }, [activeFloorPlan, activeTab, currentSitePlanGeoJson]);

  useEffect(() => {
    if (mapBoxMap && enteredLocationDetails
      && enteredLocationDetails.lon !== '' && enteredLocationDetails.lat !== ''
      && isValidLatitude(enteredLocationDetails.lat) && isValidLatitude(enteredLocationDetails.lon)) {
      mapBoxMap.AddLocationMap?.flyTo({
        center: [
          Number(enteredLocationDetails.lon),
          Number(enteredLocationDetails.lat)
        ],
        zoom: 14,
        speed: 1.8
      });
    }
  }, [ mapBoxMap, enteredLocationDetails ]);

  useEffect(() => {
    if (drawControl?.current) {
      drawControl.current.changeMode('draw_polygon');
    }
  }, [drawControl]);

  return (
    <LocationDetailsMapComponent
      onDrawingUpdate={ onDrawingUpdate }
      onDrawingDelete={ onDrawingDelete }
      onDrawingSelected={ onDrawingSelected }
      onDrawControlCreated={ onDrawingControlCreated }
      handleClickOnMap={ handleClickOnMap }
      activeTab={ activeTab }
      activeGeofence={ renderGeofence }
      enteredLocationDetails={ enteredLocationDetails }
      isLocationExists = { isLocationExists }
    />
  );
};

export default LocationDetailsMapContainer;
