export enum RisklineToggleableFilterType {
  political = '1',
  conflict = '2',
  demonstrations = '3',
  crime = '4',
  natural = '5',
  health = '6',
  travel = '7'
}


export enum RiskBSOCToggleableFilterType {
  Crime = 'Crime',
  SAF = 'SAF',
  SecurityOperations = 'SecurityOperations',
  IED = 'IED',
  HighImpactExplosiveActivity = 'HighImpactExplosiveActivity',
  IDFUAVActivity = 'IDFUAVActivity',
  ProtestsAndGatherings = 'ProtestsAndGatherings'
}

export enum MaxSecurityToggleableFilterType {
  ActiveShooter = 'ActiveShooter',
  AirStrike = 'AirStrike',
  ArbitraryDetention = 'ArbitraryDetention',
  ArmedConflict = 'ArmedConflict',
  CivilUnrest = 'CivilUnrest',
  Crime = 'Crime',
  Cyber = 'Cyber',
  Developing = 'Developing',
  Earthquake = 'Earthquake',
  Economics = 'Economics',
  Fire = 'Fire',
  Health = 'Health',
  Holiday = 'Holiday',
  KidnapAndRansom = 'KidnapAndRansom',
  LaborAction = 'LaborAction',
  LargeScaleEvents = 'LargeScaleEvents',
  MaritimeIncidents = 'MaritimeIncidents',
  MilitancyTerrorism = 'MilitancyTerrorism',
  OrganisedCrime = 'OrganisedCrime',
  Piracy = 'Piracy',
  Politics = 'Politics',
  Protest = 'Protest',
  Rocket = 'Rocket',
  SecurityOperations = 'SecurityOperations',
  SupplyChainDisruptions = 'SupplyChainDisruptions',
  Travel = 'Travel',
  Volcano = 'Volcano',
  Weather = 'Weather'
}
