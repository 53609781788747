import { createStyles, MantineTheme } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useRankingSettingsStyles = createStyles((theme:MantineTheme) => ({

  rankingSettingsContainer:{
    fontFamily:theme.fontFamily
  },
  settingBlock: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginTop:'30px',
    gap:'50px',
    marginBottom: 24,
    [`.${CSS_KEY}-Checkbox-root`]: {
      marginBottom: 12
    }
  },

  txtInfo: {
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular,
    color:theme.colors.neutral[0],
    lineHeight:theme.other.lh.l
  },

  ResetFiltersButton: {
    background: theme.colors.info[0],
    marginTop:50,
    span:{
      color:theme.white,
      fontSize:theme.fontSizes.md,
      fontWeight:theme.other.fw.regular
    }
  },

  thresholdTxt:{
    marginTop:'48px',
    marginBottom:'23px'
  },

  divider: {
    marginTop: '32px',
    borderTopColor: theme.colors.neutral[6]
  },

  dividerProbabilityThresold:{
    borderTopColor: theme.colors.neutral[6],
    marginTop:'50px'
  },

  dividerRankingData:{
    borderTopColor: theme.colors.neutral[6],
    marginTop:'36px'
  },

  dividerTimeAdjustment:{
    marginTop:'75px',
    borderTopColor: theme.colors.neutral[6]
  },

  probabilitySlider:{
    marginTop:'50px',
    marginBottom:'40px',
    [`.${CSS_KEY}-Slider-label`]:{
      color:theme.white,
      backgroundColor:theme.colors.neutral[7],
      fontSize:"11px" // reduced due to overlapping labels when thumbs are too close
    },
    [`.${CSS_KEY}-Slider-markWrapper`]:{
      '&:last-of-type':{
        [`.${CSS_KEY}-Slider-markLabel`]:{
          marginLeft:'-8px'
        }
      }
    }
  },

  timeAdjustRow:{
    //marginTop:'47px',
    display:'flex',
    marginBottom:'35px',
    justifyContent:'space-between'
  },

  timeSelector:{
    width:'30%'
  },

  slider:{
    marginTop:'60px'
  },

  RankingDataSource:{
    marginTop:'48px',

    h5:{
      marginBottom:'36px'
    }
  },
  dataOptionChkBox:{
    marginBottom:'18px',
    label:{
      color:theme.colors.neutral[0]
    }
  },

  thresholdBlock:{
    marginBottom:'20px'
  }

}));
