import type { MapMouseEvent } from "mapbox-gl";
import { useEffect } from "react";
import { useMap } from "react-map-gl";

import { getClickedOnFeatures } from "@/tenant-context/common/util/map-click";

export default function useLayerClickOutside(
  layerId: string,
  callback: () => void
) {
  const { current } = useMap();

  useEffect(() => {
    if (!current) {
      return;
    }

    const handleClickOutside = (event: MapMouseEvent) => {
      const {
        layerFeatures
      } = getClickedOnFeatures(event, layerId);

      if (!layerFeatures.length) {
        callback();
      }
    };

    current.on('click', handleClickOutside);

    return () => {
      current.off('click', handleClickOutside);
    };
  }, [callback, current, layerId]);
}