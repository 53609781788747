import { LoadingOverlay } from "@mantine/core";
import { FC } from "react";

import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import AddBuildingForm
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/AddBuildingForm";
import BuildingListTable
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/BuildingListTable";
import FloorListTable
  from "@/tenant-context/control-location-management/components/AddLocations/BuildingsAndFloorsTab/components/FloorListTable";

type Props = {
  isAddBuildingFormOpened: boolean
  isBuildingView: boolean
  setBuildingView: (val: boolean) => void
  isLoading: boolean
}
const BuildingsAndFloorsTabComponent: FC<Props> = ({
  isAddBuildingFormOpened,
  isBuildingView,
  setBuildingView,
  isLoading
}) => {
  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <div className={ classes.addBuildingWrapper }>
      <LoadingOverlay visible={ isLoading } overlayBlur={ 2 }/>
      { isBuildingView && isAddBuildingFormOpened && <AddBuildingForm/> }
      { isBuildingView
        ? <BuildingListTable setBuildingView={ setBuildingView }/>
        : <FloorListTable setBuildingView={ setBuildingView }/> }
    </div>
  );
};

export default BuildingsAndFloorsTabComponent;
