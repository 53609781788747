export interface FeedNotification {
    notificationId: string;
    tenantId: string;
    eventType: string;
    alertType: string;
    critically: string;
    status: string;
    eventTId: string;
    eventCaseId: string;
    eventTaskId: string;
    profileId: string | null;
    title: string;
    description: string;
    source: string;
    geometry: {lat: number, lon: number};
    eventStartedDateTime: number;
    openedDataTime: number;
    personProfile: {
        profilePictureUrl: string;
        profilePictureThumbnailUrl: string;
        firstName: string;
        lastName: string;
    };
    impactInfo: {
        peopleCount: number;
        assetCount: number;
        categoryId: string;
        categoryName: string;
        riskLevelId: string;
        riskLevelName: string;
    };
    caseOwnerPersonProfile: {
        profilePictureUrl: string;
        profilePictureThumbnailUrl: string;
        firstName: string;
        lastName: string;
        jobTitle: string;
    },
    caseOwnerProfileId: string;
    closedDataTime: number;
    isClosedInDisplayStack: boolean;
}

export enum NotificationEventType {
    EMERGENCY = 'EMERGENCY',
    GEOFENCE = 'GEOFENCE',
    IMPACT = 'IMPACT',
}


export enum NotificationAlertType {
    NONE = 'NONE',
    GEOFENCE_BREACH_ENTER = 'GEOFENCE_BREACH_ENTER',
    GEOFENCE_BREACH_EXIT = 'GEOFENCE_BREACH_EXIT',
    IMPACT = 'IMPACT',
    MISSED_CHECK_IN_EMERGENCY = 'MISSED_CHECK_IN_EMERGENCY',
    MISSED_END_TASK_EMERGENCY = 'MISSED_END_TASK_EMERGENCY',
    DURESS_CANCELLED_EMERGENCY = 'DURESS_CANCELLED_EMERGENCY',
    MANUAL_ACTIVATED_EMERGENCY = 'MANUAL_ACTIVATED_EMERGENCY',
}

export enum NotificationCritically {
    CRITICAL = 'CRITICAL',
    NON_CRITICAL = 'NON_CRITICAL',
}

export type ReverseGeoLocation = {
    place_name: string
}

export enum LiveFeedCasesTab {
    Latest = 'Latest',
    Older = 'Older',
    Closed = 'Closed'
}

export type NotificationContainerLayoutParams = {
    rightBorderToWindowGap: number;
}
