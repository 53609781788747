import { createStyles } from "@mantine/core";

type Props = {
  isDarkTheme?: boolean
}

export const useCountBoxStyles = createStyles((theme, props: Props) => ({

  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    borderTop: `1px solid ${theme.colors.neutral[6]}`,
    borderLeft: `1px solid ${theme.colors.neutral[6]}`,
    background: props?.isDarkTheme ? theme.colors.neutral[9] : 'inherit',
    height: '43px'
  },

  item: {
    borderBottom: `1px solid ${theme.colors.neutral[6]}`,
    borderRight: `1px solid ${theme.colors.neutral[6]}`
  },

  title:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    marginLeft: '16px'
  },

  icon:{
    marginRight: '8px'
  },

  count: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    marginRight: '16px',
    fontSize: theme.fontSizes.md,
    lineHeight: theme.other.lh.s,
    color: theme.white,
    fontWeight: theme.other.fw.bold
  }

}));
