import { createStyles } from "@mantine/core";

export const useNavigationSidebarStyles = createStyles((theme, isExpanded: boolean) => ({
  root: {
    height: "100%",
    width: `var(--navigation-sidebar-${isExpanded ? 'open' : 'closed'}-width)`,
    backgroundColor: theme.colors.neutral[8],
    borderRight: `1px solid ${theme.colors.neutral[6]}`,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    transition: 'width 0.3s ease-in-out',

    position: 'relative'
  },

  topBlock: {
    display: "flex",
    flexDirection: "column"
  },

  menuFixedBlock: {
    flex: '0 0 auto'
  },

  scrollableContent: {
    flex: 1,
    overflow: 'auto',
    '::-webkit-scrollbar:vertical': {
      width: '7px',
      padding: '7px 2px'
    },
    ul: {
      height: '100%'
    }
  },

  bottomBlock: {
    display: "flex",
    flexDirection: "column"
  },

  bottomEmphasized: {
    backgroundColor: theme.colors.primary[4],
    borderTop: `1px solid ${theme.colors.neutral[6]}`
  },

  navItemContainer: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    height: '100%'
  }
}));