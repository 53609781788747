import { createModel } from "@rematch/core";
import dayjs from "dayjs";

import { GridParams } from "@/common/types/ag-grid";
import { ReferenceData } from "@/common/types/reference-data";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { getReferenceData } from "@/tenant-context/control-profile/api/profile-list";
import { PaginatedResult, ReferenceType } from "@/tenant-context/control-profile/types/profile";
import {
  CreateNewVisitorCompany, CreateNewVisitorContact, CreateNewVisitorEmergencyContact, CreateNewVisitorGroup,
  CreateNewVisitorProfile,
  ExistingVisitorProfile,
  IVisitNote,
  IVisitorDetails,
  IVisitRequest,
  IVisitVehicle, NewVisitorGroupList,
  UserVisitsInfo,
  VisitorDetailsParams,
  VisitorFilters,
  VisitorRequestFilters,
  VisitRequestParams
} from "@/tenant-context/control-visitor-management/types/visitorManagement";

import {
  changeVisitRequestStatus,
  changeVisitStatus,
  deleteVisitNote,
  deleteVisitor,
  deleteVisitorRequest,
  deleteVisitVehicle,
  fetchVisitsForUser,
  getListOfExistingProfiles,
  getListOfVehiclesData,
  getListOfVisitors, getNewVisitorGroupList,
  getVisitNotesData,
  getVisitorDetails,
  getVisitorRequestData,
  getVisitorsData,
  getVisitRequest,
  getVisitVehiclesData,
  postAddVisitorRequest,
  postCreateNewVisitorCompany,
  postCreateNewVisitorContact, postCreateNewVisitorEmergencyContact,
  postCreateNewVisitorProfile, postCreateOrDeleteNewVisitorGroup,
  saveVisitNote,
  saveVisitRequest,
  saveVisitVehicle,
  updateVisitNote,
  updateVisitorDetails,
  updateVisitRequest,
  updateVisitVehicle
} from "../api/visitorManagement";
import { ExistingVisitor,Visitor, VisitorDetails, VisitorRequest } from "../types/visitorManagement";

type VisitorManagementState = {
  visitorRequestData?: PaginatedResult<VisitorRequest>,
  visitorVisitsData?: PaginatedResult<VisitorDetails>,
  visitorsData?: PaginatedResult<Visitor>,
  visitNotesData?: IVisitNote[],
  visitVehiclesData?: PaginatedResult<IVisitVehicle>,
  selectedVisitRequest?: IVisitRequest,
  selectedVisitNote?: IVisitNote,
  selectedVisitVehicle?: IVisitVehicle,
  existingProfiles?: PaginatedResult<ExistingVisitorProfile>,
  isExistingProfilesLoading: boolean,
  selectedVisitor?: IVisitorDetails,
  listOfAllVehicles: IVisitVehicle[],
  isListOfAllVehiclesLoading: boolean
  createNewVisitorProfile?: CreateNewVisitorProfile
  createNewVisitorCompany?: CreateNewVisitorCompany
  createNewVisitorContact?: CreateNewVisitorContact
  createNewVisitorEmergencyContact?: CreateNewVisitorEmergencyContact
  createNewVisitorGroup?: CreateNewVisitorGroup
  userVisitsInfo?: UserVisitsInfo
  createdNewVisitorId?: string
  newVisitorGroupList: NewVisitorGroupList[]
};

const visitorManagementsDataModel = {
  name: 'visitorManagement',
  state: {
    visitorRequestData: undefined,
    visitorVisitsData: undefined,
    visitorsData: undefined,
    visitNotesData: undefined,
    visitVehiclesData: undefined,
    siteList: undefined,
    selectedVisitRequest: undefined,
    selectedVisitNote: undefined,
    selectedVisitVehicle: undefined,
    existingProfiles: undefined,
    selectedVisitor: undefined,
    listOfAllVehicles: [],
    isListOfAllVehiclesLoading: false,
    isExistingProfilesLoading: false,
    userVisitsInfo: undefined,
    newVisitorGroupList: []
  } as VisitorManagementState,
  reducers: {
    SET_VISITOR_REQUEST: (state: VisitorManagementState, visitorRequestData: VisitorManagementState['visitorRequestData']) => ({
      ...state,
      visitorRequestData
    }),
    SET_VISITOR_VISITS: (state: VisitorManagementState, visitorVisitsData: VisitorManagementState['visitorVisitsData']) => ({
      ...state,
      visitorVisitsData
    }),
    SET_VISITORS: (state: VisitorManagementState, visitorsData: VisitorManagementState['visitorsData']) => ({
      ...state,
      visitorsData
    }),
    SET_VISIT_NOTES: (state: VisitorManagementState, visitNotesData: VisitorManagementState['visitNotesData']) => ({
      ...state,
      visitNotesData
    }),
    SET_VISIT_VEHICLES: (state: VisitorManagementState, visitVehiclesData: VisitorManagementState['visitVehiclesData']) => ({
      ...state,
      visitVehiclesData
    }),
    SET_SELECTED_VISIT_REQUEST: (state: VisitorManagementState, selectedVisitRequest: VisitorManagementState['selectedVisitRequest']) => ({
      ...state,
      selectedVisitRequest
    }),
    SET_SELECTED_VISIT_NOTE: (state: VisitorManagementState, selectedVisitNote: VisitorManagementState['selectedVisitNote']) => ({
      ...state,
      selectedVisitNote
    }),
    SET_SELECTED_VISIT_VEHICLE: (state: VisitorManagementState, selectedVisitVehicle: VisitorManagementState['selectedVisitVehicle']) => ({
      ...state,
      selectedVisitVehicle
    }),
    SET_EXISTING_VISITOR_PROFILES: (state: VisitorManagementState, existingProfiles: VisitorManagementState['existingProfiles']) => ({
      ...state,
      existingProfiles
    }),
    TOGGLE_EXISTING_VISITOR_PROFILES_LOAD: (state: VisitorManagementState, isExistingProfilesLoading: VisitorManagementState['isExistingProfilesLoading']) => ({
      ...state,
      isExistingProfilesLoading
    }),
    SET_SELECTED_VISITOR: (state: VisitorManagementState, selectedVisitor: VisitorManagementState['selectedVisitor']) => ({
      ...state,
      selectedVisitor
    }),
    SET_LIST_OF_ALL_VEHICLE: (state: VisitorManagementState, listOfAllVehicles: VisitorManagementState['listOfAllVehicles']) => ({
      ...state,
      listOfAllVehicles
    }),
    SET_IS_LIST_OF_ALL_VEHICLE_LOADING: (state: VisitorManagementState, isListOfAllVehiclesLoading: VisitorManagementState['isListOfAllVehiclesLoading']) => ({
      ...state,
      isListOfAllVehiclesLoading
    }),
    SET_CREATE_NEW_VISITOR_PROFILE: (state: VisitorManagementState, createNewVisitorProfile: VisitorManagementState['createNewVisitorProfile']) => ({
      ...state,
      createNewVisitorProfile
    }),
    SET_CREATE_NEW_VISITOR_COMPANY: (state: VisitorManagementState, createNewVisitorCompany: VisitorManagementState['createNewVisitorCompany']) => ({
      ...state,
      createNewVisitorCompany
    }),
    SET_CREATE_NEW_VISITOR_CONTACT: (state: VisitorManagementState, createNewVisitorContact: VisitorManagementState['createNewVisitorContact']) => ({
      ...state,
      createNewVisitorContact
    }),
    SET_CREATE_NEW_VISITOR_EMERGENCY_CONTACT: (state: VisitorManagementState, createNewVisitorEmergencyContact: VisitorManagementState['createNewVisitorEmergencyContact']) => ({
      ...state,
      createNewVisitorEmergencyContact
    }),
    SET_NEW_VISITOR_GROUP: (state: VisitorManagementState, createNewVisitorGroup: VisitorManagementState['createNewVisitorGroup']) => ({
      ...state,
      createNewVisitorGroup
    }),
    SET_CREATED_NEW_VISITOR_ID: (state: VisitorManagementState, createdNewVisitorId: VisitorManagementState['createdNewVisitorId']) => ({
      ...state,
      createdNewVisitorId
    }),
    SET_USER_VISITS_INFO: (state: VisitorManagementState, userVisitsInfo: VisitorManagementState['userVisitsInfo']) => ({
      ...state,
      userVisitsInfo
    }),
    CLEAR_USER_VISITS_INFO: (state: VisitorManagementState) => ({
      ...state,
      userVisitsInfo: undefined
    }),
    SET_NEW_VISITOR_GROUP_LIST: (state: VisitorManagementState, newVisitorGroupList: VisitorManagementState['newVisitorGroupList']) => ({
      ...state,
      newVisitorGroupList
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getVisitorRequestData(payload: {
        gridParams: GridParams,
      }, _state: RootState): Promise<PaginatedResult<VisitorRequest>> {

      // eslint-disable-next-line prefer-destructuring
      const additionalParams: VisitorRequestFilters = payload.gridParams.additionalParams;

      // eslint-disable-next-line fp/no-let
      let searchQuery = "";
      // eslint-disable-next-line fp/no-let
      let additionalFilterQuery = "";

      // eslint-disable-next-line fp/no-let
      let fromArrivalDateTime;
      // eslint-disable-next-line fp/no-let
      let toArrivalDateTime;
      // eslint-disable-next-line fp/no-let
      let fromDepartDateTime;
      // eslint-disable-next-line fp/no-let
      let toDepartDateTime;

      if(!additionalParams.site){
        return Promise.resolve({
          currentPage: 0,
          items: [],
          totalPages: 0,
          totalItems: 0
        });
      }

      const searchObj: Record<string, string> = {
        searchQuery: additionalParams.search,
        siteId: additionalParams.site,
        status: additionalParams.status
      };

      Object.keys(searchObj).forEach(function(key, index) {
        if(searchObj[key]){
          if(index !== 0 && searchQuery){
            searchQuery += " AND ";
          }

          if(key === "searchQuery"){
            searchQuery += `requestId LIKE %27%25${additionalParams.search}%25%27 OR requester LIKE %27%25${additionalParams.search}%25%27`;
          } else {
            searchQuery += `${key}='${searchObj[key]}'`;
          }
        }
      });

      if(additionalParams.arrivalDateFrom && additionalParams.arrivalTimeFrom){
        fromArrivalDateTime = dayjs(new Date(`${dayjs(additionalParams.arrivalDateFrom).format('YYYY-MM-DD')}T${additionalParams.arrivalTimeFrom}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&fromArrivalDateTime=${fromArrivalDateTime}`;
      }
      if(additionalParams.arrivalDateTo && additionalParams.arrivalTimeTo){
        toArrivalDateTime = dayjs(new Date(`${dayjs(additionalParams.arrivalDateTo).format('YYYY-MM-DD')}T${additionalParams.arrivalTimeTo}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&toArrivalDateTime=${toArrivalDateTime}`;
      }
      if(additionalParams.departureDateFrom && additionalParams.departureTimeFrom){
        fromDepartDateTime = dayjs(new Date(`${dayjs(additionalParams.departureDateFrom).format('YYYY-MM-DD')}T${additionalParams.departureTimeFrom}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&fromDepartDateTime=${fromDepartDateTime}`;
      }
      if(additionalParams.departureDateTo && additionalParams.departureTimeTo){
        toDepartDateTime = dayjs(new Date(`${dayjs(additionalParams.departureDateTo).format('YYYY-MM-DD')}T${additionalParams.departureTimeTo}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&toDepartDateTime=${toDepartDateTime}`;
      }

      if(additionalParams.timeZone){
        additionalFilterQuery += `&filteringTimeZone=${additionalParams.timeZone}`;
      }

      const visitorRequestData = await getVisitorRequestData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        searchQuery,
        additionalFilterQuery
      );

      dispatch.visitorManagement.SET_VISITOR_REQUEST(visitorRequestData);
      return visitorRequestData;
    },

    async getVisitorsData(payload: {
        gridParams: GridParams
      }, _state: RootState): Promise<PaginatedResult<Visitor>> {

      // eslint-disable-next-line prefer-destructuring
      const additionalParams: VisitorFilters = payload.gridParams.additionalParams;

      // eslint-disable-next-line fp/no-let
      let searchQuery = "";
      // eslint-disable-next-line fp/no-let
      let additionalFilterQuery = "";
      // eslint-disable-next-line fp/no-let
      let arrivalDateTimeFrom;
      // eslint-disable-next-line fp/no-let
      let arrivalDateTimeTo;
      // eslint-disable-next-line fp/no-let
      let departDateTimeFrom;
      // eslint-disable-next-line fp/no-let
      let departDateTimeTo;

      const searchObj: Record<string, string> = {
        searchQuery: additionalParams.search
      };

      if(!additionalParams.site){
        return Promise.resolve({
          currentPage: 0,
          items: [],
          totalPages: 0,
          totalItems: 0
        });
      }

      Object.keys(searchObj).forEach(function(key, index) {
        if(searchObj[key]){
          if(index !== 0 && searchQuery){
            searchQuery += " AND ";
          }

          if(key === "searchQuery"){
            searchQuery += `visitorRequestID LIKE '%25${additionalParams.search}%25' OR firstName LIKE '%25${additionalParams.search}%25'`;
          } else {
            searchQuery += `${key}='${searchObj[key]}'`;
          }
        }
      });

      if(additionalParams.site){
        additionalFilterQuery += `&siteId=${additionalParams.site}`;
      }
      if(additionalParams.status){
        additionalFilterQuery += `&status=${additionalParams.status}`;
      }

      if(additionalParams.arrivalDateFrom && additionalParams.arrivalTimeFrom){
        arrivalDateTimeFrom = dayjs(new Date(`${dayjs(additionalParams.arrivalDateFrom).format('YYYY-MM-DD')}T${additionalParams.arrivalTimeFrom}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&arrivalDateTimeFrom=${arrivalDateTimeFrom}`;
      }
      if(additionalParams.arrivalDateTo && additionalParams.arrivalTimeTo){
        arrivalDateTimeTo = dayjs(new Date(`${dayjs(additionalParams.arrivalDateTo).format('YYYY-MM-DD')}T${additionalParams.arrivalTimeTo}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&arrivalDateTimeTo=${arrivalDateTimeTo}`;
      }
      if(additionalParams.departureDateFrom && additionalParams.departureTimeFrom){
        departDateTimeFrom = dayjs(new Date(`${dayjs(additionalParams.departureDateFrom).format('YYYY-MM-DD')}T${additionalParams.departureTimeFrom}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&departDateTimeFrom=${departDateTimeFrom}`;
      }
      if(additionalParams.departureDateTo && additionalParams.departureTimeTo){
        departDateTimeTo = dayjs(new Date(`${dayjs(additionalParams.departureDateTo).format('YYYY-MM-DD')}T${additionalParams.departureTimeTo}`)).format("YYYY-MM-DDTHH:mm:ss");

        additionalFilterQuery += `&departDateTimeTo=${departDateTimeTo}`;
      }

      if(additionalParams.timeZone){
        additionalFilterQuery += `&siteTimeZone=${additionalParams.timeZone}`;
      }

      const visitorsData = await getVisitorsData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        searchQuery,
        additionalFilterQuery
      );

      dispatch.visitorManagement.SET_VISITORS(visitorsData);
      return visitorsData;
    },

    async getVisitNotesData(_: void, state: RootState): Promise<void> {

      const { selectedVisitRequest } = state.visitorManagement;
      const visitNotesData = await getVisitNotesData(selectedVisitRequest?._id || "");

      dispatch.visitorManagement.SET_VISIT_NOTES(visitNotesData.items);
    },

    async getVisitVehiclesData(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<IVisitVehicle>> {

      const { selectedVisitRequest } = state.visitorManagement;
      const visitVehiclesData = await getVisitVehiclesData(
        selectedVisitRequest?._id || "",
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort
      );

      dispatch.visitorManagement.SET_VISIT_VEHICLES(visitVehiclesData);

      return visitVehiclesData;
    },
    
    async getDocumentTypes(): Promise<PaginatedResult<ReferenceData>> {
      const documentTypes = await getReferenceData(ReferenceType.DOCUMENT_TYPE);
      return documentTypes;
    },
    
    async getVisitRequest(id?: string): Promise<void> {
      if(id){
        const result = await getVisitRequest(id);

        if (result) {
          dispatch.visitorManagement.SET_SELECTED_VISIT_REQUEST(result);
        }      
      }

    },
    async clearSelectedVisitRequest(): Promise<void> {
      dispatch.visitorManagement.SET_SELECTED_VISIT_REQUEST(undefined);
    },
    async saveVisitRequest(params: VisitRequestParams): Promise<string> {
      // eslint-disable-next-line fp/no-let
      let savedId = "";
      const result = params._id ? await updateVisitRequest(params).catch(handleError)
        : await saveVisitRequest(params).catch(handleError);

      if (result) {
        dispatch.visitorManagement.SET_SELECTED_VISIT_REQUEST(result);
        savedId = result._id;
      }

      return savedId;
    },

    async saveNote(params: { note: string }, state: RootState): Promise<boolean> {
      const { selectedVisitNote, selectedVisitRequest } = state.visitorManagement;

      const data = {
        visitRequestId: selectedVisitRequest?._id || "",
        visitNoteId: selectedVisitNote?._id || "",
        note: params.note
      };
      const result = selectedVisitNote?._id ? await updateVisitNote(data).catch(handleError)
        : await saveVisitNote(data).catch(handleError);

      if (result) {
        dispatch.visitorManagement.SET_SELECTED_VISIT_NOTE(undefined);
      }

      return !!result;
    },

    async deleteVisitNote(visitNoteId: string, state: RootState): Promise<boolean> {
      const result = await deleteVisitNote(state.visitorManagement.selectedVisitRequest?._id || "", visitNoteId).catch(handleError);
      return !!result;
    },

    handleEditVisitNote(visitNoteId: string, state: RootState): void {
      const visitNote = state.visitorManagement.visitNotesData?.find(x => x._id === visitNoteId);
      dispatch.visitorManagement.SET_SELECTED_VISIT_NOTE(visitNote);
    },

    async saveVisitVehicle(params: IVisitVehicle, state: RootState): Promise<boolean> {
      const { selectedVisitVehicle, selectedVisitRequest } = state.visitorManagement;

      // eslint-disable-next-line no-param-reassign
      params.visitRequestId = selectedVisitRequest?._id;
      const result = selectedVisitVehicle?._id ? await updateVisitVehicle(params).catch(handleError)
        : await saveVisitVehicle(params).catch(handleError);

      if (result) {
        dispatch.visitorManagement.SET_SELECTED_VISIT_VEHICLE(undefined);
      }

      return !!result;
    },

    async deleteVisitVehicle(visitVehicleId: string, state: RootState): Promise<boolean> {
      const result = await deleteVisitVehicle(state.visitorManagement.selectedVisitRequest?._id || "", visitVehicleId).catch(handleError);
      return !!result;
    },

    handleEditVisitVehicle(visitVehicleId: string, state: RootState): void {
      const visitVehicle = state.visitorManagement.visitVehiclesData?.items.find(x => x._id === visitVehicleId);

      if (visitVehicle) {
        dispatch.visitorManagement.SET_SELECTED_VISIT_VEHICLE({
          vehicleTid: visitVehicle?._id ?? '',
          colour: visitVehicle.colour,
          licensePlateNumber: visitVehicle.licensePlateNumber,
          make: visitVehicle.make,
          model: visitVehicle.model,
          vehicleType: visitVehicle.vehicleType,
          _id: visitVehicle._id
        });
      }
    },

    async getListOfExistingProfiles(payload: {
        gridParams: GridParams,
      }, state: RootState): Promise<PaginatedResult<ExistingVisitorProfile>> {
      dispatch.visitorManagement.TOGGLE_EXISTING_VISITOR_PROFILES_LOAD(true);
      const { selectedVisitRequest } = state.visitorManagement;

      const query = payload?.gridParams.searchQuery;
      if (query) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `firstName LIKE %27%25${query}%25%27 OR email LIKE %27%25${query}%25%27 OR profileId LIKE %27%25${query}%25%27 OR lastName LIKE %27%25${query}%25%27 OR companyName LIKE %27%25${query}%25%27 OR email LIKE %27%25${query}%25%27 OR mobileNumber LIKE %27%25${query}%25%27 OR employeeId LIKE %27%25${query}%25%27`;
      }

      const existingProfiles = await getListOfExistingProfiles(
        selectedVisitRequest?._id,
        payload?.gridParams.page,
        payload?.gridParams.size,
        payload?.gridParams.sort,
        payload?.gridParams.searchQuery
      );

      dispatch.visitorManagement.TOGGLE_EXISTING_VISITOR_PROFILES_LOAD(false);

      dispatch.visitorManagement.SET_EXISTING_VISITOR_PROFILES(existingProfiles);

      return existingProfiles;
    },

    async addExistingProfileToVisitRequest(visitor: ExistingVisitor, state: RootState): Promise<void> {
      const { selectedVisitRequest } = state.visitorManagement;
      if (!selectedVisitRequest?._id) {
        return;
      }
      dispatch.visitorManagement.TOGGLE_EXISTING_VISITOR_PROFILES_LOAD(true);

      await postAddVisitorRequest(selectedVisitRequest?._id, visitor)
        // TODO add visitor id (_id) to the response object in BE
        .then((res) => dispatch.visitorManagement.SET_CREATED_NEW_VISITOR_ID(res?._id))
        .catch(handleError);

      dispatch.visitorManagement.TOGGLE_EXISTING_VISITOR_PROFILES_LOAD(false);
    },

    async deleteVisitorFromRequest(visitorId: string, state: RootState): Promise<void> {

      const { selectedVisitRequest } = state.visitorManagement;
      if (!selectedVisitRequest?._id) {
        return;
      }

      await deleteVisitor(selectedVisitRequest?._id, { visitorTid: visitorId }).catch(handleError);
    },

    async getListOfVisitors(payload: {
        gridParams: GridParams,
      }, state: RootState): Promise<PaginatedResult<ExistingVisitorProfile>> {

      const { selectedVisitRequest } = state.visitorManagement;
      const query = payload?.gridParams.searchQuery;
      if (query) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `firstName LIKE %27%25${query}%25%27 OR email LIKE %27%25${query}%25%27 OR profileId LIKE %27%25${query}%25%27 OR lastName LIKE %27%25${query}%25%27 OR employeeId LIKE %27%25${query}%25%27`;
      }

      const visitors = await getListOfVisitors(
        selectedVisitRequest?._id,
        payload?.gridParams.page,
        payload?.gridParams.size,
        payload?.gridParams.sort,
        payload?.gridParams.searchQuery
      );

      return visitors;
    },

    async saveVisitorDetails(params: VisitorDetailsParams): Promise<boolean> {
      const result = await updateVisitorDetails(params).catch(handleError);

      if (result) {
        dispatch.visitorManagement.SET_SELECTED_VISITOR(result);
      }

      return !!result;
    },

    async getVisitorDetails(params: {
      visitRequestId: string,
      visitorId: string
    }): Promise<boolean> {
      const result = await getVisitorDetails(params.visitRequestId, params.visitorId).catch(handleError);

      if (result) {
        dispatch.visitorManagement.SET_SELECTED_VISITOR(result);
      }

      return !!result;
    },

    // TODO implement search query when BE is ready
    async getListOfVehiclesData(query?: string): Promise<void> {
      const searchQuery = query
        ?
        `licensePlateNumber LIKE %27%25${query}%25%27 OR vehicleType LIKE %27${query}%25%27 OR make LIKE %27%25${query}%25%27 OR model LIKE %27%25${query}%25%27 OR colour LIKE %27%25${query}%25%27`
        :
        '';

      dispatch.visitorManagement.SET_IS_LIST_OF_ALL_VEHICLE_LOADING(true);
      await getListOfVehiclesData(searchQuery)
        .then((response) => dispatch.visitorManagement.SET_LIST_OF_ALL_VEHICLE(response.items))
        .catch(handleError)
        .finally(() => dispatch.visitorManagement.SET_IS_LIST_OF_ALL_VEHICLE_LOADING(false));
    },

    async postCreateNewVisitorProfile(params: {
        visitRequestId: string,
        data: CreateNewVisitorProfile
      }): Promise<boolean> {

      const res = await postCreateNewVisitorProfile(params.visitRequestId, params.data).catch(handleError);

      if(res){
        dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_PROFILE(res);
        dispatch.visitorManagement.addExistingProfileToVisitRequest({
          profileId: res.profileId,
          profileTid: res._id,
          firstName: res.firstName,
          lastName: res.lastName,
          primaryEmail: res.mobileNumber,
          email: res.email
        } as ExistingVisitor);
      }

      return !!res;
    },

    async postCreateNewVisitorCompany(params: {
        visitRequestId: string,
        data: CreateNewVisitorCompany
      }): Promise<boolean> {
      const result = await postCreateNewVisitorCompany(params.visitRequestId, params.data)
        .then((res) => dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_COMPANY(res))
        .catch(handleError);

      return !!result;
    },

    async postCreateNewVisitorContact(params: {
        visitRequestId: string,
        data: CreateNewVisitorContact
      }): Promise<boolean> {
      const result = await postCreateNewVisitorContact(params.visitRequestId, params.data)
        .then((res) => dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_CONTACT(res))
        .catch(handleError);

      return !!result;
    },

    async postCreateNewVisitorEmergencyContact(params: {
        visitRequestId: string,
        data: CreateNewVisitorEmergencyContact
      }): Promise<boolean> {
      const result = await postCreateNewVisitorEmergencyContact(params.visitRequestId, params.data)
        .then((res) => dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_EMERGENCY_CONTACT(res))
        .catch(handleError);

      return !!result;
    },

    async postCreateNewVisitorGroup(params: {
        visitRequestId: string,
      data: CreateNewVisitorGroup
    }): Promise<void> {

      await postCreateOrDeleteNewVisitorGroup(params.visitRequestId, params.data)
        .then((res) => dispatch.visitorManagement.SET_NEW_VISITOR_GROUP(res)).catch(handleError);
    },

    async getNewVisitorGroupList(_: void, state: RootState): Promise<void> {

      const profileId = state.visitorManagement?.createNewVisitorProfile?._id;

      if (!profileId) {
        return;
      }
      await getNewVisitorGroupList(profileId)
        .then((res) => dispatch.visitorManagement.SET_NEW_VISITOR_GROUP_LIST(res?.items)).catch(handleError);
    },

    async deleteVisitorRequest(requestId: string, _state: RootState): Promise<boolean> {
      const result = await deleteVisitorRequest(requestId).catch(handleError);
      return !!result;
    },
    async changeVisitRequestStatus(payload: {
      id: string,
      domainModel: string
    }): Promise<string | false> {
      const result = await changeVisitRequestStatus(payload.id, payload.domainModel).catch(handleError);
      return result;
    },
    async changeVisitStatus(payload: {
      visitId: string,
      requestId: string,
      domainModel: string
    }): Promise<string | false> {
      const result = await changeVisitStatus(payload.requestId , payload.visitId, payload.domainModel)
        .catch(handleError);

      return result;
    },
    async fetchVisitsForUser(userId: string): Promise<void> {
      const result = await fetchVisitsForUser(userId)
        .catch(handleError);

      if(result){
        dispatch.visitorManagement.SET_USER_VISITS_INFO(result);
      }
    },
    async clearAddNewPersonForm(): Promise<void> {
      dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_PROFILE(undefined);
      dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_EMERGENCY_CONTACT(undefined);
      dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_COMPANY(undefined);
      dispatch.visitorManagement.SET_CREATE_NEW_VISITOR_CONTACT(undefined);
      dispatch.visitorManagement.SET_NEW_VISITOR_GROUP(undefined);
      dispatch.visitorManagement.SET_NEW_VISITOR_GROUP_LIST([]);
    }
  }
  )
};

export const visitorManagement = createModel<RootModel>()(visitorManagementsDataModel);
