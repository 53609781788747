/* eslint-disable react/jsx-props-no-spreading */
import { Select, TextInput } from "@mantine/core";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldError, RegisterOptions } from "react-hook-form";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import PhoneInput from "@/common/components/PhoneInput";
import { RootState } from "@/core/store";
import { unshiftDefaultItem } from '@/tenant-context/common/util/profile-reference-data';
import {
  ProfileEmergencyContactsModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileEmergencyContactsModal/ProfileEmergencyContactsModal.context";
import {
  useEmergencyContactModalContentStyles
} from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileEmergencyContact } from "@/tenant-context/control-profile/types/profile";

export type ProfileEmergencyContactsModalFormData = Pick<ProfileEmergencyContact,
  | 'type'
  | 'relationship'
  | 'firstName'
  | 'lastName'
  | 'line1'
  | 'line2'
  | 'line3'
  | 'postCode'
  | 'region'
  | 'city'
  | 'country'
  | 'email'
  | 'latitude'
  | 'longitude'
> & {
  phone1: string,
  phone2: string
};

type Props = {
  mode?: 'add' | 'edit',
  editableContact?: ProfileEmergencyContact,
  handleRemove: () => void
}

const ProfileEmergencyContactsModal: FC<Props> = ({
  mode = 'add',
  editableContact,
  handleRemove
}) => {
  const { classes } = useEmergencyContactModalContentStyles();
  const {
    onSubmit,
    formControls: { register, formState: { errors, isDirty }, setValue, watch }
  } = useContext(ProfileEmergencyContactsModalContext);

  const referenceData = useSelector((state: RootState) => state.profile.referenceData);

  const {
    phone1,
    phone2,
    type
  } = watch();

  const [relationshipValue, setRelationshipValue] = useState<string | undefined>(editableContact?.relationship);
  const [relationshipOptions, setRelationshipOptions] = useState<{ value: string, label: string }[]>([]);

  const countries = useMemo(() => {
    return unshiftDefaultItem(referenceData?.country?.map((country) => {
      return { value: country?.name, label: country?.name };
    }));
  }, [referenceData]);

  const emergencyContactType = useMemo(() => {
    return unshiftDefaultItem(referenceData?.emergencyContacts?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const relationship = useMemo(() => {
    return unshiftDefaultItem(referenceData?.relationship?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const businessEmergencyContactRelations = useMemo(() => {
    return unshiftDefaultItem(referenceData?.BECRelationship?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const handleEmergencyContactTypeChange = useCallback((val) => {
    setValue('type', val, { shouldDirty: true });
    setValue('relationship', '', { shouldDirty: true });
    setRelationshipValue('');
  }, [setValue]);

  const handleRelationshipChange = useCallback((val) => {
    setRelationshipValue(val);
    setValue('relationship', val, { shouldDirty: true });
  }, [setValue]);

  const handleCountryChange = useCallback((val) => {
    setValue('country', val, { shouldDirty: true });
  }, [setValue]);

  useEffect(() => {
    if (type === 'Business Emergency Contact') {
      setRelationshipOptions(businessEmergencyContactRelations || []);
    } else {
      setRelationshipOptions(relationship || []);
    }
  }, [businessEmergencyContactRelations, relationship, type]);

  const createSetPhone = (num: 1 | 2) => (val: string) => setValue(`phone${num}`, val, { shouldDirty: true });

  const isEdit = mode === 'edit';

  if (mode === 'edit' && !editableContact) {
    return (
      <p>Loading...</p>
    );
  }

  const renderTextInput = (
    label: string,
    id: keyof ProfileEmergencyContact,
    options: RegisterOptions,
    required?: boolean
  ) => {
    const error: FieldError | undefined = errors?.[id] as FieldError;
    return (
      <TextInput
        label={ label }
        required={ required }
        defaultValue={ editableContact ? editableContact[id]?.toString() : undefined }
        error={ error?.message }
        { ...register(id, addErrorMessages(label, options)) }
      />
    );
  };

  return (
    <form onSubmit={ onSubmit }>
      <ProfileLoadingOverlay />

      <div className={ classes.grid }>

        <Select
          { ...register('type', addErrorMessages('Type', {
            required: true
          })) }
          searchable
          required={ true }
          onChange={ handleEmergencyContactTypeChange }
          data={ emergencyContactType ? emergencyContactType : [] }
          label="Type"
          defaultValue={ editableContact?.type }
          error={ errors?.type?.message }
        />

        <Select
          { ...register('relationship') }
          searchable
          onChange={ handleRelationshipChange }
          data={ relationshipOptions }
          label="Relationship"
          value={ relationshipValue }
          error={ errors?.relationship?.message }
        />

        { renderTextInput('First Name', 'firstName', {
          required: true
        }, true) }

        { renderTextInput('Last Name', 'lastName', {
          required: true
        }, true) }

        <div/>

      </div>

      { renderTextInput('Address Line 1', 'line1', {
        required: false
      }) }

      <div className={ classes.grid }>

        { renderTextInput('Address Line 2', 'line2', {
          required: false
        }) }

        { renderTextInput('Address Line 3', 'line3', {
          required: false
        }) }

        { renderTextInput('Post Code / ZIP', 'postCode', {
          required: false
        }) }

        { renderTextInput('County / Region', 'region', {
          required: false
        }) }

        { renderTextInput('City', 'city', {
          required: false
        }) }
       
        <Select
          { ...register('country', addErrorMessages('Country', {
            required: false
          })) }
          searchable
          onChange={ handleCountryChange }
          data={ countries ? countries : [] }
          label="Country"
          defaultValue={ editableContact?.country }
          error={ errors?.country?.message }
        />

        { renderTextInput('Latitude', 'latitude', {
          required: false
        }) }
        
        { renderTextInput('Longitude', 'longitude', {
          required: false
        }) }
        
        <PhoneInput
          value={ phone1 || '' }
          isRequired={ true }
          placeholder="Enter phone number"
          error={ errors?.phone1?.message ?? '' }
          label={ 'Phone 1' }
          onChange={ createSetPhone(1) }
          registerData={ register('phone1', addErrorMessages('Phone', {
            required: true
          })) }
        />

        <PhoneInput
          value={ phone2 || '' }
          placeholder="Enter phone number"
          error={ errors?.phone2?.message ?? '' }
          label={ 'Phone 2' }
          onChange={ createSetPhone(2) }
          registerData={ register('phone2', addErrorMessages('Phone', {
            required: false
          })) }
        />

        { renderTextInput('Email', 'email', {
          required: false
        }) }
      </div>

      <ModalControlGroup
        additionalLabel={ isEdit ? "Remove Emergency Contact" : undefined }
        onAdditional={ isEdit ? handleRemove : undefined }
        primaryButtonDisabled={  !isDirty }
      />
    </form>
  );
};

export default ProfileEmergencyContactsModal;
