import { useMantineTheme } from "@mantine/core";
import { FeatureCollection } from "geojson";
import { FC, RefObject } from "react";
import { Layer, Source } from "react-map-gl";
import { useSelector } from "react-redux";

import { Popup } from "@/common/components/Popup/Popup.components";
import { RootState } from "@/core/store";
import { semanticColorPalette } from "@/core/styles/mantine/palettes";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import { LocationAddress } from "@/tenant-context/control-location-management/types/ManageLocations.types";
import {
  SITE_INFO_CONTROL_DRAWER_ID
} from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import { SiteLocationSnapshotProperties } from "@/tenant-context/visualisation-site/types/site.types";

import CriticalLocationPopup from "../../components/CriticalLocationPopup";
import { CriticalLocationLayerConfig } from "../../config/CriticalLocationLayerConfig";

type Props = {
  geoData: FeatureCollection,
  isSiteNamePopupShown: boolean,
  isSiteDetailPopupShown: boolean,
  popupCoordinates?: PopupCoordinates,
  popupContent: SiteLocationSnapshotProperties | undefined,
  siteNamePopupRef?: RefObject<HTMLDivElement>,
  siteDetailsPopupRef?: RefObject<HTMLDivElement>,
  siteGeoJson?: FeatureCollection
  musterSiteData?: FeatureCollection
}

const popupOffsetY = 14;

const CriticalLocationLayerComponent: FC<Props> = ({
  geoData,
  isSiteNamePopupShown,
  popupCoordinates,
  popupContent,
  siteGeoJson
}) => {
  const theme = useMantineTheme();
  const openDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);

  return (
    <>
      { siteGeoJson && <Source id="sites-location-outline" type="geojson" data={ siteGeoJson }>
        <Layer
          id="site-outline"
          type="line"
          paint={ {
            "line-color": semanticColorPalette.accessibility.outer,
            "line-opacity": 1,
            "line-width": 2
          } }
        />
        <Layer
          id="site-outline-layer"
          type="fill"
          paint={ {
            "fill-color": semanticColorPalette.accessibility.outer,
            "fill-opacity": 0.2
          } }
        />
      </Source> }


      <Source
        id="r__criticalLocationSource"
        type="geojson"
        cluster={ true }
        clusterRadius={ 50 }
        clusterMaxZoom={ 14 }
        tolerance={ 0 }
        data={ geoData }
      >
        <Layer
          id={ `r__criticalLocationLayer-cluster` }
          type={ 'symbol' }
          filter={ ['has', 'point_count'] }
          paint={ {
            "text-color": '#FFFFFF'
          } }
          layout={ {
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': 'location-cluster-marker',
            'icon-size': .8,
            'text-font': ['Arial Unicode MS Bold'],
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
            'text-anchor': 'center'
          } }
          source="r__criticalLocationSource"
        />

        { CriticalLocationLayerConfig.map((layer) => (
          <DynamicallyVisibleLayer
            key={ layer.id }
            toggleableLayerType={ layer.toggleableLayerType }
            id={ layer.id }
            type={ 'symbol' }
            paint={ {
              ...layer.paint
            } }
            layout={ layer.layout }
            filter={ [
              'all',
              ['!has', 'point_count'],
              layer.filter
            ] }
            interactive
            source="r__criticalLocationSource"
          />)) }


        { isSiteNamePopupShown && popupCoordinates
          && popupContent && (openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID) && (
          <Popup
            longitude={ popupCoordinates.longitude }
            latitude={ popupCoordinates.latitude }
            /* eslint-disable-next-line no-magic-numbers */
            backgroundColor={ theme.colors.neutral[8] }
            padding={ '0' }
            anchor="top-right"
            offset={ [0, popupOffsetY] }
          >
            <LocalErrorBoundary>
              <CriticalLocationPopup
                popupContent={ popupContent }
                locationAddress= { typeof(popupContent.address) === 'string' ? (JSON.parse(popupContent.address) as LocationAddress) : undefined }
              />
            </LocalErrorBoundary>
          </Popup>
        ) }

      </Source>
    </>
  );

};

export default CriticalLocationLayerComponent;
