import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Products } from "@/common/types/products";
import {
  DECODED_REGULAR_TOKEN_KEY,
  DECODED_SITE_TOKEN_KEY,
  DECODED_TENANT_TOKEN_KEY,
  decodeToken,
  REGULAR_REFRESH_TOKEN_KEY,
  REGULAR_TOKEN_KEY,
  SITE_TOKEN_KEY,
  TENANT_REFRESH_TOKEN_KEY,
  TENANT_TOKEN_KEY
} from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { handleError } from "@/common/util/common-functions";
import { showNotification } from "@/common/util/notification";
import { refreshToken } from "@/core/api/auth";
import { getSiteToken } from "@/core/api/policies";
import { environment } from "@/core/config/env";
import { OutsystemsTokenResponse } from "@/core/navigation/types/navigation";
import { Dispatch, RootState } from "@/core/store";
import { CONTEXT_KEY, CONTEXT_TYPES, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import { getRegularToken, getTenantToken } from "@/tenant-context/control-profile/api/profile";
import { LOGOUT_WARNING_PERIOD, TOKEN_REFRESH_PERIOD } from '@/tenant-context/core/config/consts.';
import { TenantTokenPayload } from "@/tenant-context/navigation/types/user";

export type AuthContextType = {
  isAuthenticated: boolean;
  accessToken: string | undefined;
  tenantToken: string | undefined;
  siteToken: string | undefined;
  tenantRefreshToken: string | undefined;
  setAuthenticated: (isAuthenticated: boolean) => void;
  setAccessToken: (accessToken: string) => void;
  setTenantToken: (tenantToken: string) => void;
  setTenantRefreshToken: (refreshToken: string) => void;
  logout: () => void;
  isProductSubscribed: (productName: Products) => boolean;
  fetchUserIdFromSession: () => string,
  fetchContextBasedToken: () => TenantTokenPayload | null,
  handleSiteTokenForSelectedSite: (siteId: string) => void
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  accessToken: undefined,
  tenantToken: undefined,
  tenantRefreshToken: undefined,
  siteToken: undefined
} as AuthContextType);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: FC = ({
  children
}) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessTokenString] = useState<string | undefined>(undefined);
  const [tenantToken, setTenantTokenString] = useState<string | undefined>(undefined);
  const [siteToken, setSiteTokenString] = useState<string | undefined>(undefined);
  const [tenantRefreshToken, setTenantRefreshTokenString] = useState<string | undefined>(undefined);
  const [accessRefreshToken, setAccessRefreshTokenString] = useState<string | undefined>(undefined);
  const [siteRefreshToken, setSiteRefreshTokenString] = useState<string | undefined>(undefined);
  const [decodedTenantToken, setDecodedTenantToken] = useState<Record<string, string> | undefined>(undefined);
  const [decodedAccessToken, setDecodedAccessToken] = useState<Record<string, string> | undefined>(undefined);
  const [decodedSiteToken, setDecodedSiteToken] = useState<Record<string, string> | undefined>(undefined);

  const tokenCheckInterval = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const logoutTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const logoutNotificationTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const isTenantContext = useSelector((state: RootState) => state.sharedData.currentContext) === "tenant";
  const { tenantSubscribedProducts, isSubscribedProductsLoaded } = useSelector((state: RootState) => state.commonData);

  const {
    commonData: {
      setTenant
    }
  } = useDispatch<Dispatch>();

  const refreshApp = useCallback(() => {
    const url = `${window.location.protocol}//${window.location.host}`;
    window.open(url, '_self');
  }, []);

  const setAuthenticated = useCallback((authenticated: boolean) => {
    setIsAuthenticated(authenticated);
  }, []);

  const setAccessToken = useCallback((token: string | undefined, refresh = false) => {
    setAccessTokenString(token);
    browserDatabase.setItem(REGULAR_TOKEN_KEY, token);

    if (!token) {
      return;
    }

    try {
      const decodedToken = decodeToken(token);

      browserDatabase.setItem(
        DECODED_REGULAR_TOKEN_KEY,
        decodedToken
      );

      setDecodedAccessToken(decodedToken);
    } catch (err) {
      console.error(err);
      showNotification({
        message: 'Error happened while handling login! Please login again',
        color: 'error'
      });
    }

    if (refresh) {
      refreshApp();
    }
  }, [refreshApp]);

  const setSiteToken = useCallback((tknObj: OutsystemsTokenResponse) => {
    if (!tknObj) {
      return;
    }

    setSiteTokenString(tknObj.access_token);
    setSiteRefreshTokenString(tknObj.refresh_token || "");

    browserDatabase.setItem(SITE_TOKEN_KEY, tknObj.access_token);

    try {
      const decodedToken = decodeToken(tknObj.access_token);

      setDecodedSiteToken(decodedToken);
      browserDatabase.setItem(
        DECODED_SITE_TOKEN_KEY,
        decodedToken
      );
    } catch (err) {
      console.error(err);
      showNotification({
        message: 'Error happened while handling site access! Please try again',
        color: 'error'
      });
    }
  }, []);

  const handleSiteTokenForSelectedSite = useCallback((siteId: string)=>{
    getSiteToken(siteId).then(setSiteToken).catch(handleError);
  }, [setSiteToken]);
  
  const setTenantToken = useCallback((token: string | undefined, refresh = false) => {
    setTenantTokenString(token);
    browserDatabase.setItem(TENANT_TOKEN_KEY, token);

    if (!token) {
      return;
    }

    try {
      const decodedToken = decodeToken(token);

      setDecodedTenantToken(decodedToken);
      browserDatabase.setItem(
        DECODED_TENANT_TOKEN_KEY,
        decodedToken
      );

      setTenant({
        tenantId: decodedToken.tenantId,
        refreshBrowser: false
      });
    } catch (err) {
      console.error(err);
      showNotification({
        message: 'Error happened while handling login! Please login again',
        color: 'error'
      });
    }
    if (refresh) {
      refreshApp();
    }
  }, [refreshApp, setTenant]);

  const setTenantRefreshToken = useCallback((token: string | undefined) => {
    setTenantRefreshTokenString(token);
    browserDatabase.setItem(TENANT_REFRESH_TOKEN_KEY, token);
  }, []);

  const setAccessRefreshToken = useCallback((token: string | undefined) => {
    setAccessRefreshTokenString(token);
    browserDatabase.setItem(REGULAR_REFRESH_TOKEN_KEY, token);
  }, []);

  const logout = useCallback(() => {
    setAccessToken(undefined);
    setTenantToken(undefined);
    setTenantRefreshToken(undefined);
    setAuthenticated(false);

    browserDatabase.deleteItem(REGULAR_TOKEN_KEY);
    browserDatabase.deleteItem(TENANT_TOKEN_KEY);
    browserDatabase.deleteItem(TENANT_REFRESH_TOKEN_KEY);
    browserDatabase.deleteItem(DECODED_REGULAR_TOKEN_KEY);
    browserDatabase.deleteItem(DECODED_TENANT_TOKEN_KEY);

    window.location.replace(`${environment.unifiedExperienceUrl}/login`);
  }, [setAccessToken, setAuthenticated, setTenantRefreshToken, setTenantToken]);

  useEffect(() => {
    const currentTenantToken: string | null | undefined = browserDatabase.getItem(TENANT_TOKEN_KEY);
    const currentRegularToken: string | null | undefined = browserDatabase.getItem(REGULAR_TOKEN_KEY);
    const currentTenantRefreshToken: string | null | undefined = browserDatabase.getItem(TENANT_REFRESH_TOKEN_KEY);
    const currentRegularRefreshToken: string | null | undefined = browserDatabase.getItem(REGULAR_REFRESH_TOKEN_KEY);

    if (isTenantContext) {
      if (currentTenantToken && currentRegularToken) {
        setAccessToken(currentRegularToken);
        setTenantToken(currentTenantToken);
        setTenantRefreshToken(currentTenantToken);
        setAuthenticated(true);

        if (currentTenantRefreshToken) {
          setTenantRefreshToken(currentTenantRefreshToken);
        }
      } else {
        setAuthenticated(false);
        setAccessTokenString(undefined);
        setTenantRefreshTokenString(undefined);
      }
    } else {
      //platform context
      if (currentRegularToken) {
        setAccessToken(currentRegularToken);
        setAuthenticated(true);

        if (currentRegularRefreshToken) {
          setAccessRefreshToken(currentRegularRefreshToken);
        }
      } else {
        setAuthenticated(false);
        setAccessTokenString(undefined);
        setAccessRefreshTokenString(undefined);
      }
    }
  }, [isTenantContext, setAccessToken, setAuthenticated, setAccessRefreshToken,
    setTenantRefreshToken, setTenantToken]);

  const scheduleLogout = useCallback((timeToLogout: number) => {
    if (logoutTimeout?.current) {
      clearTimeout(logoutTimeout.current);
    }

    if (logoutNotificationTimeout?.current) {
      clearTimeout(logoutNotificationTimeout.current);
    }

    logoutNotificationTimeout.current = setTimeout(() => {
      showNotification({
        message: 'Will logout shortly! Please login again',
        color: 'warning'
      });
    }, timeToLogout - LOGOUT_WARNING_PERIOD);

    logoutTimeout.current = setTimeout(() => {
      console.log(`[${new Date().toLocaleString('en-US', {
        weekday: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })}] Token expired! logging out...`);
      logout();
    }, timeToLogout);

  }, [logout]);

  const refreshTenantToken = useCallback(() => {
    // Todo - Need to remove this console.log after a while.
    //  Kept for debugging in higher envs
    console.log('Checking and refreshing token');
    // Check if the tenant token will expire in the next 5 minutes. If so, refresh it
    if (decodedTenantToken && decodedTenantToken.exp
      && (Number(decodedTenantToken.exp) * 1000) < (Date.now() + TOKEN_REFRESH_PERIOD)) {
      // Refreshing the tenant token
      refreshToken(tenantRefreshToken as string)
        .then((response) => {
          setTenantToken(response.access_token);
          setTenantRefreshToken(response.refresh_token);
        })
        .catch((err) => {
          console.error(err);
          showNotification({
            message: 'Error happened while refreshing token! Please login again',
            color: 'error'
          });

          // If failed to refresh token, logout
          scheduleLogout((Number(decodedTenantToken?.exp || 0) * 1000) - Date.now());
        });
    }
  }, [decodedTenantToken, scheduleLogout, setTenantRefreshToken, setTenantToken, tenantRefreshToken]);

  const refreshSiteToken = useCallback(() => {
    console.log('Checking and refreshing site token');

    // Check if the tenant token will expire in the next 5 minutes. If so, refresh it
    if (decodedSiteToken && decodedSiteToken.exp
      && (Number(decodedSiteToken.exp) * 1000) < (Date.now() + TOKEN_REFRESH_PERIOD)) {
      // Refreshing the site token
      refreshToken(siteRefreshToken as string)
        .then(setSiteToken)
        .catch(console.error);
    }
  }, [decodedSiteToken, setSiteToken, siteRefreshToken]);

  const refreshAccessToken = useCallback(() => {
    console.log('Checking and refreshing access token');
    // Check if the access token will expire in the next 5 minutes. If so, refresh it
    if (decodedAccessToken && decodedAccessToken.exp
      && (Number(decodedAccessToken.exp) * 1000) < (Date.now() + TOKEN_REFRESH_PERIOD)) {
      // Refreshing the access token
      refreshToken(accessRefreshToken as string)
        .then((response) => {
          setAccessToken(response.access_token);
          setAccessRefreshToken(response.refresh_token);
        })
        .catch((err) => {
          console.error(err);
          showNotification({
            message: 'Error happened while refreshing token! Please login again',
            color: 'error'
          });

          // If failed to refresh token, logout
          scheduleLogout((Number(decodedAccessToken?.exp || 0) * 1000) - Date.now());
        });
    }
  }, [accessRefreshToken, decodedAccessToken, scheduleLogout, setAccessRefreshToken, setAccessToken]);

  const checkAndRefreshToken = useCallback(() => {
    if (isTenantContext) {
      refreshTenantToken();
      refreshAccessToken();
      refreshSiteToken();
    } else {
      refreshAccessToken();
    }
  }, [isTenantContext, refreshAccessToken, refreshSiteToken, refreshTenantToken]);

  const isProductSubscribed = useCallback((productName: Products): boolean => {
    if (isSubscribedProductsLoaded) {
      if (tenantSubscribedProducts?.find((product) => product.name === productName)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }, [isSubscribedProductsLoaded, tenantSubscribedProducts]);

  const fetchUserIdFromSession = useCallback(() => {
    const currentContext: ContextTypes | null = browserDatabase.getItem(CONTEXT_KEY);

    const token = currentContext === CONTEXT_TYPES.platform ? getRegularToken() : getTenantToken();

    if (!token) {
      return "";
    }
    const tokenEncodedPayload = token.split('.')[1];
    const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

    const { userId } = tokenPayloadJson;

    return userId;
  }, []);

  const fetchContextBasedToken = useCallback(() => {
    const currentContext: ContextTypes | null = browserDatabase.getItem(CONTEXT_KEY);
    const token = currentContext === CONTEXT_TYPES.platform ? getRegularToken() : getTenantToken();

    if (!token) {
      return null;
    }

    const tokenEncodedPayload = token.split('.')[1];
    const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

    return tokenPayloadJson;
  }, []);

  useEffect(() => {
    if (tokenCheckInterval.current) {
      clearInterval(tokenCheckInterval.current);
    }

    tokenCheckInterval.current = setInterval(() => checkAndRefreshToken(), TOKEN_REFRESH_PERIOD);

    return () => {
      if (tokenCheckInterval.current) {
        clearInterval(tokenCheckInterval.current);
      }
    };
  }, [checkAndRefreshToken, decodedTenantToken, decodedAccessToken]);

  return (
    <AuthContext.Provider value={ {
      isAuthenticated,
      accessToken,
      tenantToken,
      siteToken,
      tenantRefreshToken,
      setAuthenticated,
      setAccessToken,
      setTenantToken,
      setTenantRefreshToken,
      isProductSubscribed,
      logout,
      fetchUserIdFromSession,
      fetchContextBasedToken,
      handleSiteTokenForSelectedSite
    } }>
      { children }
    </AuthContext.Provider>
  );

};
