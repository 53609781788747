/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
import { FileWithPath } from "file-selector";

import { ArcImpactDetail, ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

export type DeliveryItems = 'email' | 'sms' | 'voice' | 'app'
//subject to change with service
export type TemplateList = Array<{
  value: string,
  label: string
}>

export type DeliveryChannel = {
  app: boolean,
  email: boolean,
  sms: boolean,
  voice: boolean
};

export type ResponseMethod = 'NO_RESPONSE' | 'SAFETY_POLL' | 'CUSTOM_POLL' | 'ACKNOWLEDGE';

export type CommunicationConfigRequest = {
  tenantId: string,
  communicationName: string,
  sameContent: boolean,
  deliveryChannels?: Array<DeliveryItems>,
  meta?: {
    tenantId: string,
    createdTime: number,
    updatedTime: number
  },
  appConfiguration: {
    subject: string,
    content: string,
    attachments? : Array<string>,
    criticalAlertIOS: boolean,
    confirmWithBiometrics: boolean
  },
  emailConfiguration?: {
    subject: string,
    content: string,
    attachments?: Array<string>
    settings : {
      originatorDisplayName: string,
      originatorEmail: string
    }
  },
  smsConfiguration?: {
    content: string,
  },
  voiceConfiguration?: {
    originator: string,
    attempts: number,
    audioType: VOICE_MESSAGE_TYPES,
    audioContent: string
  },
  responseMethod: {
    type: string,
    app: {
      configurations: {
        buttonLabel: string,
        buttonColor: string,
        responseLabel: string,
        responseLabelColor?: string,
        safetyStatus?: string,
      }[]
    },
    email: {
      configurations: {
        buttonLabel: string,
        buttonColor: string,
        responseLabel: string,
        responseLabelColor?: string,
        safetyStatus?: string,
      }[]
    },
    sms: {
      configurations: {
        replyCode: number,
        responseLabel: string,
        responseLabelColor?: string,
        safetyStatus?: string,
      }[]
    },
    voice: {
      configurations: {
        replyCode: number,
        responseLabel: string,
        responseLabelColor?: string,
        safetyStatus?: string,
      }[]
    }
  },
  source?: {
    type: 'alert' | 'manual';
    id: string;
  }
}

export type AddOrUpdateRecipientsRequest = {
  parentTid: string,
  communicationName: string,
  communicationLinks: Array<CommunicationLink>
}

export type CommunicationLink = {
  personId: string,
  displayValue: string,
  filter: string,
  communicationChannels: [
    {
      commType: string,
      commValue: string
    }
  ]
}

export type RecipientMainSelection = 'impactSelect' | 'singleGroupSelect' | undefined

export type SearchedDeliveryEntities = {
  people: Array<PeopleEntity>,
  groups: Array<GroupEntity>
}

export type GroupEntity = {
  _id: string,
  name: string,
  isApplicableToAllService: boolean,
  applicableToAllService: string,
  applicableServices: string,
  isActive: boolean,
  active: string,
  profileCount: number,
  isSelected?: boolean
}

export type PeopleEntity = {
  profileTid: string,
  profileId: string,
  firstName: string,
  lastName: string,
  gender: string,
  dateOfBirth: string,
  nationality: string,
  countryOfResidenceRef: string,
  tenantCode: string,
  tenantName: string,
  email?: string,
  isSelected?: boolean,
  phoneNumber?: string;
  appInstalled?: boolean,
}

export type ImpactResponseName = 'allImpacted' | 'notSafe' | 'noResponse' | 'okPeople' | 'otherPeople';


export type PickedPeopleEntity = PeopleEntity & {
  reason: string,
  isAppInstalled?: boolean,
  isWithEmail: boolean,
  isWithPhone: boolean,
  groupId?: string
}

export type SearchPeopleResponseTypes = {
  totalItems: number,
  totalPages: number,
  currentPage: number,
  items: Array<PeopleEntity>
}

export type GroupProfilesResponseType = {
  totalItems: number,
  totalPages: number,
  currentPage: number,
  items: Array<GroupEntity>
}

export type Base64EmailAttachment = {
  name: string,
  extension: string,
  base64: string,
  size?: number
}

export type AppCommunicationContent = {
  header: string,
  content: string,
  attachments?: FileWithPath[],
  encodedAttachments?: Base64EmailAttachment[],
  criticalAlertIOS: boolean,
  isBiometricConfirmationTurnedOn: boolean
}

export type EmailContent = {
  subject: string,
  body: string,
  bodyWithStyling?: string,
  attachments?: FileWithPath[],
  encodedAttachments?: Base64EmailAttachment[]
  fontColor?: string
}

export type SmsContent = {
  body: string | undefined,
  length: number,
  units: number
}

export type ReceiveCountByCommunicationChannel = {
  // all combination for app, email, sms, voice with none and all
  none: number,
  app: number,
  email: number,
  sms: number,
  voice: number,
  appAndEmail: number,
  appAndSms: number,
  appAndVoice: number,
  appAndEmailAndSms: number,
  appAndEmailAndVoice: number,
  appAndSmsAndVoice: number,
  emailAndSmsAndVoice: number,
  emailAndSms: number,
  emailAndVoice: number,
  smsAndVoice: number,
  all: number,
  allEmail: number,
  allSMS: number,
  allVoice: number,
  allApp: number
  // 'email&phone': number
}

export type MassCommsSummaryChartDataItem = {
  field: string,
  count: number,
  channel?: DeliveryChannelTypes
}

export type MassCommsGenericResponseItem = {
  id: number,
  responseLabel: string;
  responseLabelColor?: string;
  safetyStatus?: ArcImpactStatus | 'ACKNOWLEDGE' | 'NO_MAPPING';
}

export type MassCommsAppCommunicationsResponseItem = MassCommsGenericResponseItem & {
  buttonLabel: string;
  buttonColor: string;
}

export type MassCommsEmailResponseItem = MassCommsGenericResponseItem & {
  buttonLabel: string;
  buttonColor: string;
}

export type MassCommsPhoneResponseItem = MassCommsGenericResponseItem & {
  replyCode: number;
}

export type SequenceNumberResponse = {
  tid: string,
  parentTid: string | null,
  meta: {
    tenantId: string,
    createdTime: string,
    updatedTime: string
  },
  id: string,
}

export type CommSequenceNoTypes = SequenceNumberResponse & {
  lastCommunicationNumber: number
}

export type AlertSequenceNumber = SequenceNumberResponse & {
  lastAlertSequenceNumber: number
}

export type VoiceContent = {
  voiceMessageType: VOICE_MESSAGE_TYPES,
  audioContent: string
}

export type ResponseMethodMessageContent = {
  app: string | undefined,
  email: string | undefined,
  sms: string | undefined
}

export enum MASS_COMMS_STEPS {
  'CREATE_COMMS' = 0,
  'RECIPIENT_SELECTION' = 1,
  'SUMMARY' = 2,
  'FINALIZED' = 3
}

export type MassCommsNavigation= {
  isOpen: boolean,
  fromArc: boolean,
  from?: 'look-up',
  lookupKey?: string,
  isSliderOpen: boolean
}

export enum VOICE_MESSAGE_TYPES {
  'UPLOAD_AUDIO' = 'UPLOAD_AUDIO' ,
  'RECORD_AUDIO' = 'RECORD_AUDIO',
  'TEXT_TO_SPEECH' = 'TEXT_TO_SPEECH'
}

export type SelectedConfigTab = {
  defaultTab: DeliveryItems | undefined,
  fromSummary: boolean
}

export type NavItems = 'logo' | 'divider' | 'spacer' | 'image' | 'main';

export type EmailConfigItem = {
  logoLink: string,
  logoURL: string,
  alignment: string,
  altText: string,
  bgColor: string,
  padding: {
    top: number,
    right: number,
    bottom: number,
    left: number
  }
}

export type EmailConfigKeys = 'logoLink' | 'alignment'| 'altText' | 'bgColor' | 'logoURL';
export type EmailConfigPadding = 'top' | 'bottom' | 'right' | 'left';

export type EmailStylingTemplate = {
  tid: string,
  parentTid: number | null,
  meta: {
    tenantId: string,
    createdTime: string,
    updatedTime: string
  },
  id: string,
  content: string,
  contentBodyColor: string,
  bodyColor: string,
  fontColor: string
}

export type EmailStylingContent = {
  tid: string,
  parentTid: string,
  meta: {
    tenantId: string,
    createdTime: string,
    updatedTime: string
  },
  name: string,
  header: EmailConfigItem,
  footer: EmailConfigItem,
  contentBodyColor: string,
  bodyColor: string,
  fontColor: string
}

export type SaveOrUpdateEmailStyling = {
  tenantId: string,
  name: string,
  header: EmailConfigItem,
  footer: EmailConfigItem,
  contentBodyColor: string,
  bodyColor: string,
  fontColor: string
}

export type EmailTemplates = {
  originalTemplate: string, modifiedTemplate: string
}
export type CommunicationTemplate = Omit<CommunicationConfigRequest, 'communicationName'> & {
  tid?: string;
  templateName: string
}

export type EmailSettingsConfig = {
  originatorName: string,
  originatorEmail: string
}
export type ImpactedPeopleSet = {
  allImpacted: ImpactedPeopleType,
  notSafe: ImpactedPeopleType,
  noResponse: ImpactedPeopleType,
  okPeople: ImpactedPeopleType,
  otherPeople: ImpactedPeopleType
}

export type ImpactedPeopleType = {
  isChecked: boolean,
  people: Array<ArcImpactDetail>
}

export type ReportCommContent = {
  emailConent: string,
  smsContent: string,
  callContent: string,
  appContent: string
}

export type ReportResponseConfigs = null | {
  type: string,
  app: null | {
    configurations: Array<{
      buttonLabel: string,
      buttonColor: string,
      responseLabel: string,
      safetyStatus?: string | undefined
    }>
  },
  email: null | {
    configurations: Array<{
      buttonLabel: string,
      buttonColor: string,
      responseLabel: string,
      safetyStatus?: string | undefined
    }>
  },
  sms: null | {
    configurations: Array<{
      replyCode: string | number,
      responseLabel: string,
      safetyStatus?: string | undefined
    }>
  },
  voice: null | {
    configurations: Array<{
      replyCode: string | number,
      responseLabel: string,
      safetyStatus?: string | undefined
    }>
  },
}

export type ReportPollResponseItem = Array<{ title: string, count: number, color: string }>

export type TotalReportStat = { title: string, count: number }

export type ReportPollResponseTypes = {
  items: ReportPollResponseItem,
  total: TotalReportStat
}

export type ReportStatItem = {
  field: 'fail' | 'pending' | 'success' | 'total',
  count: number
}

export type ReportDeliveryResponses = {
  initialData: {
    email: TotalReportStat,
    sms: TotalReportStat,
    call: TotalReportStat,
    app: TotalReportStat,
    total: TotalReportStat
  },
  deliveryStats: {
    email: Array<ReportStatItem>,
    sms: Array<ReportStatItem>,
    call: Array<ReportStatItem>,
    app: Array<ReportStatItem>
    total: Array<ReportStatItem>
  }
}

export type ReportTimeDetails = {
  sentTime: number,
  lastResponse: number
}

export type SentCommsList = {
  tid: string,
  parentTid: string | null,
  meta: {
    tenantId: string,
    createdTime: string,
    updatedTime: string
  },
  communicationName: string,
  sameContent: boolean,
  deliveryChannels: Array<string | null>,
  appConfiguration?: {
    subject: string,
    content: string,
    attachments?: Array<string>,
    confirmWithBiometrics: boolean,
    criticalAlertIOS: boolean
  },
  emailConfiguration?: {
    subject: string,
    content: string,
    attachments?: Array<string>
    settings: {
      originatorDisplayName: string,
      originatorEmail: string
    }
  },
  smsConfiguration?: {
    content: string,
  },
  voiceConfiguration?: {
    originator: string,
    attempts: number,
    audioType: VOICE_MESSAGE_TYPES,
    audioContent: string
  },
  responseMethod: {type: string},
  source: null | { type: string, id: null | string },
  impactedComms?: Array<SentCommsList>,
  isExpanded?: boolean
}

export type SentCommunications = {
  communicationTid: string,
  communicationName: string,
  sentDate: number,
  recipientCount: number,
  sender: null | string,
  responseMethod: null | string,
  sourceType: null | string,
  sourceId: null | string,
  channels: Array<string> | null,
  sentOn: null | number,
  impactedComms?: Array<SentCommunications>,
  isExpanded?: boolean
}

export type DeliverySuccessStream = {
  communicationTid: null | string,
  appSummary: { fail: number, pending: number, success: number, total: number}
  emailSummary: { fail: number, pending: number, success: number, total: number}
  smsSummary: { fail: number, pending: number, success: number, total: number },
  voiceSummary: { fail: number, pending: number, success: number, total: number }
}

export type DeliverySuccessData = {
  communicationTid: null | string,
  appCommsSummary: [
    { field: 'fail', count: number },
    { field: 'pending', count: number },
    { field: 'success', count: number },
    { field: 'total', count: number }],
  emailSummary: [
    { field: 'fail', count: number },
    { field: 'pending', count: number },
    { field: 'success', count: number },
    { field: 'total', count: number }],
  smsSummary: [
    { field: 'fail', count: number },
    { field: 'pending', count: number },
    { field: 'success', count: number },
    { field: 'total', count: number }],
  voiceSummary: [
    { field: 'fail', count: number },
    { field: 'pending', count: number },
    { field: 'success', count: number },
    { field: 'total', count: number }]
}

export type OverallDeliverySuccessStream = {
  communicationTid: string,
  recipientCount: number,
  success: number,
  pending: number,
  fail: number
}

export type OverallDeliverySuccessData = [
  { field: 'fail', count: number },
  { field: 'pending', count: number },
  { field: 'success', count: number },
  { field: 'recipientCount', count: number }
]

export type OverallDeliveryKeys = 'fail' | 'pending' | 'success' | 'recipientCount'

export type PollResponsesStream = {
  communicationTid: string,
  responseMethodType: string,
  responses: null | Array<{ responseLabel: string, responseLabelColor: null | string, count: number }>
}
export type MassCommsReportsRecipientsResponse = {
  recipientProfileId: string;
  contact: string;
  deliveryChannelType: 'SMS' | 'EMAIL' | 'VOICE' | 'APP';
  deliveryStatus: MassCommsDeliveryStatus;
  deliveryDate: number;
  recipientName: string;
  responseChannel: {
    status: 'NO_REPLY' | 'REPLY';
    response: string | null;
    userResponseDate: number;
  },
  responseMethod: {
    type: ResponseMethod;
    configurations: Array<{
      buttonColor: string;
      responseLabel: string;
      replyCode: string;
      responseLabelColor: string;
      safetyStatus: 'SAFE' | 'NOT_SAFE' | 'NO_MAPPING';
    }>
  }
};

export type MassCommsReportsRecipientDetail = {
  recipientProfileId: string;
  userResponseDate: number;
  recipientName: string;
  contact: string;
  deliveryChannels: Array<{
    type: 'SMS' | 'EMAIL' | 'VOICE' | 'APP';
    status: MassCommsDeliveryStatus;
  }>;
  responseChannels: Array<'SMS' | 'EMAIL' | 'VOICE' | 'APP'>;
  pollResponse: string;
  safetyStatus: 'SAFE' | 'NOT_SAFE' | 'NO_MAPPING' | 'N/A';
  responseMethodType: ResponseMethod;
  responseMethodConfig?: {
    buttonColor: string;
    responseLabel: string
    responseLabelColor: string
    safetyStatus: 'SAFE' | 'NOT_SAFE' | 'NO_MAPPING'
  },
  individualChannels: Array<MassCommsReportsIndividualChannel>
}

export type MassCommsReportsIndividualChannel = {
  channelType: 'SMS' | 'EMAIL' | 'VOICE' | 'APP';
  deliveryStatus: MassCommsDeliveryStatus;
  deliveryDate: number;
  contact?: string;
  responseStatus: 'NO_REPLY' | 'REPLY';
  pollResponse: string;
  pollResponseColor: string;
  userResponseDate: number;
}

export enum MassCommsDeliveryStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

export type FilterType = "deliveryStatus" | "pollResponse";

export type FilterState = {
  deliveryStatus:  "ALL" | "FAIL" | "SUCCESS" | "PENDING",
  pollResponse: string,
  channel: DeliveryChannelTypes
}
export type FilterParams = {
  type: FilterType,
  field: string,
  channel?: DeliveryChannelTypes
}

export type DeliveryChannelTypes = "APP" | "SMS" | "EMAIL" | "VOICE" | "ALL"
