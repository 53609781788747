export function standardizeTimestamp(timeStamp: number | string): number {
  if (typeof timeStamp === 'string') {
    try {
      return standardizeTimestamp(parseInt(timeStamp, 10));
    } catch (e) {
      return NaN;
    }
  }

  if (timeStamp.toString().length === 10) {
    return timeStamp * 1000;
  }

  return timeStamp;
}

export function formatDateTime(timestamp: number | string, format: string): string {
  const date = new Date(standardizeTimestamp(timestamp));

  const pad = (n: number, width: number) => {
    const z = '0';
    const nStr = n.toString();
    return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(z) + n;
  };

  const replacements: { [key: string]: string } = {
    'YYYY': date.getFullYear().toString(),
    'MM': pad(date.getMonth() + 1, 2),
    'DD': pad(date.getDate(), 2),
    'HH': pad(date.getHours(), 2),
    'mm': pad(date.getMinutes(), 2),
    'ss': pad(date.getSeconds(), 2)
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, match => replacements[match]);
}

export function dateTimeStringToTimestamp(dateTimeString: string): number {
  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date-time string");
  }

  return standardizeTimestamp(date.getTime());
}

export function formatNumberToDecimalPoints(num: number, decimalPoints: number): number {
  if (decimalPoints < 0) {
    console.error("Decimal points must be a non-negative integer.");
    return num;
  }

  try {
    return parseFloat(num.toFixed(decimalPoints));
  } catch (e) {
    console.error("Error occurred while formatting number to decimal points.");
    return num;
  }
}
