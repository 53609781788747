import { FC, useContext, useEffect, useMemo, useState } from "react";

import { ReactComponent as MapDimmed } from '@/common/icons/map-dimmed.svg';
import { usePersonDidNotShareLocationStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/PersonDidNotShareLocation/PersonDidNotShareLocation.style";
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";

const LOADING_GRACE_PERIOD = 1000;

const PersonDidNotShareLocation: FC = () => {
  const { classes } = usePersonDidNotShareLocationStyles();

  const {
    locationEvents
  } = useContext(EaArcContext);

  const [shouldEventsBeLoadedIfExist, setShouldEventsBeLoadedIfExist] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldEventsBeLoadedIfExist(true);
    }, LOADING_GRACE_PERIOD);
  }, []);

  const isLoading = useMemo(() => {
    return shouldEventsBeLoadedIfExist && (locationEvents.length === 0);
  }, [locationEvents.length, shouldEventsBeLoadedIfExist]);

  // If yet loading or already loaded, don't show this component
  if (!shouldEventsBeLoadedIfExist || locationEvents.length) {
    return null;
  }

  // If no location events, show this component
  return (
    <div className={ classes.root }>
      <div className={ classes.contentBox }>
        <MapDimmed />
        <h6>{ isLoading ? 'Loading Location' : 'Not Sharing Location' }</h6>
        <span>
          { isLoading ?
            'Please wait. Location data is still loading ' :
            'Ask lone worker to switch their tracking on' }
        </span>
      </div>
    </div>
  );
};

export default PersonDidNotShareLocation;
