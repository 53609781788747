import { Divider } from "@mantine/core";
import { FC } from "react";

import IconProgressBar from "@/common/components/IconProgressBar/IconProgressBar.component";
import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip/TruncatedTextWithTooltip.component";
import IconTextMarker from "@/tenant-context/control-profile/components/ui/IconTextMarker/IconTextMarker.component";
import ProfileItineraryItem from "@/tenant-context/control-profile/components/ui/ProfileItineraryItem";
import { TravelMethods } from "@/tenant-context/control-profile/types/mini-profile";
import { PersonCurrentTravelInfo, PersonPopupAssetRankingEvent } from "@/tenant-context/visualisation-people/types/people.types";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";
import { TravelIcon } from "@/tenant-context/visualisation-people/util/TravelIcons";
import { handleTravelStatus } from "@/tenant-context/visualisation-people/util/utils";

import { usePeopleLocationPopupStyles } from "../PeopleLocationPopup.styles";
import { UtcTimeObj } from "./PeopleTravelPopup.container";

type Props = {
  assetSummary: PersonPopupAssetRankingEvent,
  travelInfo: PersonCurrentTravelInfo | undefined,
  utcTime: UtcTimeObj,
  progressPercent: number,
  currentLocation: string,
  isProfilePicturError: boolean,
  onImageError: () => void
}

export const PeopleTravelPopupComponent: FC<Props> = ({
  assetSummary,
  travelInfo,
  utcTime,
  progressPercent,
  currentLocation,
  onImageError,
  isProfilePicturError
}) => {
  const { classes, cx } = usePeopleLocationPopupStyles();

  return (
    <div className={ classes.travellerPopupcontainer }>
      <div className={ classes.personDetailWrapper }>
        <div className={ classes.personPic }>
          { (assetSummary.profilePictureThumbnailUrl && !isProfilePicturError) ?
            <img onError={ onImageError } alt="profile-img" src={ assetSummary.profilePictureThumbnailUrl } />
            : assetSummary.firstName && assetSummary.lastName ? getPersonInitials(`${assetSummary.firstName} ${assetSummary.lastName}`).toString().toUpperCase() :
              getPersonInitials(assetSummary.personFullName || 'N/A').toString().toUpperCase() }</div>
        <div className={ classes.profileInfo }>
          <div className={ classes.profileHeader }>{ <TruncatedTextWithTooltip
            fullText={ assetSummary.lastName && assetSummary.firstName ? `${assetSummary.firstName} ${assetSummary.lastName}` : assetSummary.personFullName } maxLength={ 15 } /> }</div>
          <div className={ classes.profileDesignation }>{ assetSummary.jobTitle }</div>
          <div className={ classes.profileCompany }>{ assetSummary.company }</div>
        </div>
      </div>
      <div className={ classes.personInfoContainer }>
        <Divider orientation="horizontal" />
        <div className={ classes.personInfo }>
          <div className={ classes.infoItem }>
            <div className={ classes.personInfoHeader }>PERSON TYPE</div>
            <div className={ classes.personInfoData }>Employee</div>
          </div>
          <div className={ classes.infoItem }>
            <div className={ classes.personInfoHeader }>NATIONALITY</div>
            <div className={ classes.personInfoData }>{ assetSummary.nationality || 'N/A' }</div>
          </div>
          <div className={ classes.infoItem }>
            <div className={ classes.personInfoHeader }>RESIDENCE</div>
            <div className={ classes.personInfoData }>{ assetSummary.countryOfResidenceRef || 'N/A' }</div>
          </div>
        </div>
        <Divider orientation="horizontal" />
      </div>
      <div className={ classes.travelInfoContainer }>
        { assetSummary?.lastKnownLocation &&
          <div className={ cx(classes.personInfoHeader) }>LAST KNOWN LOCATION</div> }
        <div className={ cx(classes.sourceInfo) }>{ `Via Travel Itinerary` }</div>
        <div className={ classes.personLocationInfo }>
          <div className={ classes.locationIconContainer }><IconTextMarker
            iconText={ assetSummary.personFullName } /></div>
          <div className={ classes.locationInfoBody }>
            { currentLocation }
            <IconProgressBar indicatorIcon={ <TravelIcon iconName={ travelInfo?.type || '' } /> } progress={ progressPercent } />
          </div>
        </div>
        <div className={ classes.personInfoData }>{ handleTravelStatus(
          travelInfo?.type,
          travelInfo?.start_date,
          travelInfo?.end_date
        ) }
        </div>
        <div className={ classes.lastKnownloc }>{ `Between ${utcTime.from.time} - ${utcTime.to.time}` }</div>

        { travelInfo && <ProfileItineraryItem
          address={ travelInfo.origin_address }
          travelType={ travelInfo.type as TravelMethods }
          timeframe={ progressPercent === 100 ? 'past' : progressPercent > 0 ? 'present' :'future' }
          progressConfigs={ {
            isWithProgress: false,
            percentCompleted: progressPercent,
            indicatorIcon: <TravelIcon iconName={ travelInfo.type }/>
          } }
          from={ {
            flagCode: travelInfo.origin_country,
            name: travelInfo.origin_name,
            date: utcTime.from.date,
            time: utcTime.from.time
          } }
          to={ {
            flagCode: travelInfo.destination_country,
            name: travelInfo?.destination_name,
            date: utcTime.to.date,
            time: utcTime.to.time
          } }
        /> }

      </div>
    </div>
  )
  ;
};
