import { ButtonStylesParams } from "@mantine/core";
import { ButtonVariant } from "@mantine/core/lib/Button/Button.styles";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { primitiveNumbers } from "../constants";

const getBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  // Added !important because .mapboxgl-ctrl button:not(:disabled):hover is overriding this style when in map
  const variantMap: Record<string, string> = {
    default: `${theme.other.semantic.button.default.default} !important`,
    outline: 'transparent !important',
    subtle: 'transparent',
    gradient: `${theme.other.semantic.button.white.default} !important`,
    light: `transparent`
  };

  return variantMap[variant] || `${theme.colors.blue[6]} !important`;
};

const getBorder = (variant: ButtonVariant, color: string, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'none',
    outline: `0.5px solid ${theme.other.semantic.button.outline.default}`,
    gradient: 'none',
    subtle: 'none',
    light: 'none'
  };

  return variantMap[variant] || "";
};

const getHoveredBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: `${theme.other.semantic.button.default.hover} !important`,
    outline: `${theme.colors.neutralDarkShades[3]}`,
    gradient: `${theme.other.semantic.button.white.hover} !important`,
    subtle: 'transparent',
    light: `${theme.colors.neutralDarkShades[3]}`
  };

  return variantMap[variant] || theme.fn.darken(getBackgroundColor(variant, theme), 0.05);
};

const getHoveredBorder = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'none',
    outline: `1px solid ${theme.other.semantic.button.outline.hover} !important`,
    gradient: 'none',
    subtle: 'none'
  };

  return variantMap[variant] || "";
};

const getFocusedBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: `${theme.other.semantic.button.default.focused}`,
    outline: `${theme.colors.neutralDarkShades[3]}`,
    gradient: `${theme.other.semantic.button.white.focused}`,
    subtle: 'transparent',
    light: `${theme.colors.neutralDarkShades[3]}`
  };

  return variantMap[variant] || "";
};

const getFocusedBorder = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: `2px solid ${theme.other.semantic.button.default.pressed} !important`,
    outline: `2px solid ${theme.other.semantic.button.outline.pressed} !important`,
    gradient: `2px solid ${theme.other.semantic.button.white.pressed} !important`,
    subtle: `1px solid ${theme.other.semantic.button.subtle.focused} !important`,
    light: `2px solid ${theme.other.semantic.button.light.pressed} !important`
  };

  return variantMap[variant] || "";
};

const getActiveBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: theme.other.semantic.button.default.pressed,
    outline: `${theme.colors.neutralDarkShades[3]}`,
    gradient: theme.other.semantic.button.white.pressed,
    subtle: 'transparent',
    light: `${theme.colors.neutralDarkShades[3]}`
  };

  return variantMap[variant] || theme.fn.darken(getBackgroundColor(variant, theme), 0.1);
};

const getActiveBorder = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'none',
    outline: `1px solid ${theme.other.semantic.button.outline.pressed}`,
    gradient: 'none',
    subtle: 'transparent',
    light: 'none'
  };

  return variantMap[variant] || "";
};

const getPadding = (size: string, theme: MantineTheme): string => {
  const paddingMap: Record<string, string> = {
    xs: `0 ${theme.other.spacing.md}px`,
    sm: `0 ${theme.other.spacing.md}px`,
    md: `0 ${theme.other.spacing.md}px`,
    lg: `${theme.other.spacing.md}px`,
    xl: `${theme.other.spacing.md}px`
  };

  return paddingMap[size];
};

const getHeight = (size: string): string => {
  const heightMap: Record<string, string> = {
    xs: `${primitiveNumbers['3x']}px`,
    sm: `${primitiveNumbers['3x']}px`,
    md: `${primitiveNumbers['4x']}px`,
    lg: `${primitiveNumbers['6x']}px`,
    xl: `${primitiveNumbers['6x']}px`
  };

  return heightMap[size];
};

const getFontSize = (size: string, theme: MantineTheme): number => {
  const fontSizeMap: Record<string, number> = {
    xl: theme.fontSizes.md,
    lg: theme.fontSizes.md,
    md: theme.fontSizes.sm,
    sm: theme.fontSizes.xs,
    xs: theme.fontSizes.xs
  };

  return fontSizeMap[size] || theme.fontSizes.md;
};

const getIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: 14,
    sm: 14,
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['2x'],
    xl: primitiveNumbers['2x']
  };

  return iconSizeMap[size];
};

const getTextColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  const variantMap: Record<string, string> = {
    default: theme.white,
    outline: theme.other.semantic.button.outline.default,
    gradient: theme.other.semantic.text.button.primary,
    subtle: theme.other.semantic.button.subtle.default,
    light: theme.other.semantic.button.light.default
  };

  return variantMap[variant] || theme.white;
};

const getHoveredTextColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  const variantMap: Record<string, string> = {
    default: theme.white,
    outline: theme.other.semantic.button.outline.default,
    gradient: theme.other.semantic.text.button.primary,
    subtle: theme.other.semantic.button.subtle.hover,
    light: theme.other.semantic.button.light.default
  };

  return variantMap[variant] || theme.white;
};

const getIconColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  const variantMap: Record<string, string> = {
    default: theme.other.semantic.icon.button.secondary,
    outline: theme.other.semantic.button.outline.default,
    gradient: theme.other.semantic.text.button.primary,
    subtle: theme.other.semantic.button.subtle.default,
    light: theme.other.semantic.button.light.default
  };

  return variantMap[variant] || theme.white;
};

const getPressedIconColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  const variantMap: Record<string, string> = {
    default: theme.other.semantic.icon.button.secondary,
    outline: theme.other.semantic.button.outline.default,
    gradient: theme.other.semantic.text.button.primary,
    subtle: theme.other.semantic.button.subtle.default,
    light: theme.other.semantic.button.light.default  
  };

  return variantMap[variant] || theme.white;
};

export const ButtonComponentStyle = {
  defaultProps: {
    size: 'lg',
    variant: 'default'
  },
  styles: (theme: MantineTheme, params: ButtonStylesParams) => ({
    root: {
      maxHeight: getHeight(params.size),
      padding: params.variant === 'subtle' ? '0 !important' : `${getPadding(params.size, theme)} !important`,
      boxSizing: 'border-box !important' as 'border-box',
      backgroundColor: getBackgroundColor(params.variant, theme),
      backgroundImage: 'none',
      border: getBorder(params.variant, params.color, theme),
      fontSize: getFontSize(params.size, theme),
      display: 'flex',
      justifyContent: 'space-between',
      div: {
        width: '100%',
        justifyContent: 'space-between',
        gap: theme.other.spacing.sm
      },
      span:{
        margin: 0,
        color: getTextColor(params.variant, theme),
        fontWeight:theme.other.fw.regular,
        '& svg':{
          width: getIconSize(params.size),
          height: getIconSize(params.size),
          path:{
            fill: getIconColor(params.variant, theme)
          }
        }
      },
      '&:disabled':{
        backgroundColor: params.variant === 'subtle' ? 'transparent' : `${theme.other.semantic.button.default.disabled} !important`,
        cursor:'not-allowed',
        pointerEvents: "unset" as React.CSSProperties["pointerEvents"],
        span:{
          color:`${theme.other.semantic.button.default.disabledText} !important`,
          '& svg':{
            path:{
              fill: `${theme.other.semantic.icon.general.disabled} !important`
            }
          }
        },
        '&:hover':{
          boxShadow:`inset 0px 250px 0px rgba(0, 0, 0, 0)`,
          backgroundColor: params.variant === 'subtle' ? 'transparent' : `${theme.other.semantic.button.default.disabled} !important`,
          span:{
            color:`${theme.other.semantic.button.default.disabledText} !important`,
            '& svg':{
              path:{
                fill: `${theme.other.semantic.icon.general.disabled} !important`
              }
            }
          }
        }
      },
      '&:hover': {
        backgroundColor: getHoveredBackgroundColor(params.variant, theme),
        border: getHoveredBorder(params.variant, theme),
        span:{
          color: getHoveredTextColor(params.variant, theme),
          '& svg':{
            path:{
              fill: getIconColor(params.variant, theme)
            }
          }
        }
      },
      '&:focus-visible':{
        border: getFocusedBorder(params.variant, theme),
        backgroundColor: `${getFocusedBackgroundColor(params.variant, theme)} !important`,
        outline:'none',
        span:{
          padding: params.variant === 'subtle' ? theme.other.spacing.xs : '0'
        }
      },
      '&:active':{
        backgroundColor: `${getActiveBackgroundColor(params.variant, theme)} !important`,
        border: getActiveBorder(params.variant, theme),
        span:{
          color: getTextColor(params.variant, theme),
          '& svg':{
            path:{
              fill: getPressedIconColor(params.variant, theme)
            }
          }
        }
      }
    }
  })
};
